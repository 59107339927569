import Vue from 'vue'
import QtiItemBody from './qtiElements/qti-item-body/QtiItemBody'
import QtiContentBody from './qtiElements/qti-content-body/QtiContentBody'
import QtiFeedbackBlock from './qtiElements/qti-feedback-block/QtiFeedbackBlock'
import QtiModalFeedback from './qtiElements/qti-modal-feedback/QtiModalFeedback'
import QtiTemplateBlock from './qtiElements/qti-template-block/QtiTemplateBlock'
import QtiTextEntryInteraction from './qtiInteractions/qti-text-entry-interaction/QtiTextEntryInteraction'
import QtiCompanionMaterialsInfo from './qtiElements/qti-companion-materials-info/QtiCompanionMaterialsInfo'
import QtiDigitalMaterial from './qtiElements/qti-digital-material/QtiDigitalMaterial'
import QtiFileHref from './qtiElements/qti-file-href/QtiFileHref'
import QtiPrompt from './qtiElements/qti-prompt/QtiPrompt'
import QtiChoiceInteraction from './qtiInteractions/qti-choice-interaction/QtiChoiceInteraction'
import QtiInlineChoiceInteraction from './qtiInteractions/qti-choice-interaction/QtiInlineChoiceInteraction'
import QtiSimpleChoice from './qtiInteractions/qti-choice-interaction/QtiSimpleChoice'
import QtiInlineChoice from './qtiInteractions/qti-choice-interaction/QtiInlineChoice'
import QtiOrderInteraction from './qtiInteractions/qti-order-interaction/QtiOrderInteraction'
import QtiCustomInteraction from './qtiInteractions/qti-custom-interaction/QtiCustomInteraction'

import JumpNumberLine from './customElements/JumpNumberLine'
import AudioButton from './customElements/AudioButton'
import PromptAudioButton from './customElements/PromptAudioButton'
import AnalogClock from './customElements/AnalogClock'
import DigitalClock from './customElements/DigitalClock'
import CountPicture from './customElements/count/CountPicture'
import NumberLine from './customElements/NumberLine'
import Sequence from './customElements/Sequence'
import PieChart from './customElements/PieChart'
import Chart from './customElements/Chart'
import MathComponent from './math/MathComponent'
import Mfrac from './math/Mfrac'
import Mi from './math/Mi'
import Img from './html/Img'
import PronounceInput from './qtiInteractions/qti-custom-interaction/pronounce-input/PronounceInput'
import SplitNumber from './qtiInteractions/qti-custom-interaction/split-number/SplitNumber'
import Jump from './qtiInteractions/qti-custom-interaction/jump/Jump'
import QtiSelectPointInteraction from './qtiInteractions/qti-select-point-interaction/QtiSelectPointInteraction'

const components = {
  'qti-item-body': QtiItemBody,
  'qti-content-body': QtiContentBody,
  'qti-feedback-block': QtiFeedbackBlock,
  'qti-modal-feedback': QtiModalFeedback,
  'qti-template-block': QtiTemplateBlock,
  'qti-template-inline': QtiTemplateBlock,
  'qti-text-entry-interaction': QtiTextEntryInteraction,
  'qti-companion-materials-info': QtiCompanionMaterialsInfo,
  'qti-digital-material': QtiDigitalMaterial,
  'qti-file-href': QtiFileHref,
  'qti-prompt': QtiPrompt,
  'qti-choice-interaction': QtiChoiceInteraction,
  'qti-inline-choice-interaction': QtiInlineChoiceInteraction,
  'qti-simple-choice': QtiSimpleChoice,
  'qti-inline-choice': QtiInlineChoice,
  'qti-order-interaction': QtiOrderInteraction,
  'qti-custom-interaction': QtiCustomInteraction,
  'qti-select-point-interaction': QtiSelectPointInteraction,

  'math-component': MathComponent,
  mfrac: Mfrac,
  mi: Mi,

  'img-component': Img,

  pronounce: PronounceInput,
  'split-number': SplitNumber,
  jump: Jump,

  'jump-number-line': JumpNumberLine,
  'audio-button': AudioButton,
  'prompt-audio-button': PromptAudioButton,
  'analog-clock': AnalogClock,
  'digital-clock': DigitalClock,
  'count-picture': CountPicture,
  'number-line': NumberLine,
  sequence: Sequence,
  chart: Chart,
  'pie-chart': PieChart
}

export default () => {
  Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component)
  })
}
