<template>
  <div class="search-box" :class="{ 'search-box--focused': focus }">
    <input
      ref="input"
      v-model="searchValue"
      class="outline-none w-full placeholder-gray-dark"
      style="background: transparent"
      type="text"
      :placeholder="placeholder"
      @focusin="focus = true"
      @focusout="focus = false"
    />
    <IconSearch v-if="value === ''" />
    <div v-else class="cursor-pointer" @click="reset"><IconClose /></div>
  </div>
</template>

<script>
import { IconSearch, IconClose } from './icons'

export default {
  components: { IconClose, IconSearch },
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: 'Zoek' }
  },
  data() {
    return {
      searchValue: '',
      focus: false
    }
  },
  watch: {
    value(value) {
      this.searchValue = value
    },
    searchValue(value) {
      if (value === this.value) {
        return
      }
      this.$emit('input', value)
    }
  },
  mounted() {
    this.searchValue = this.value
  },
  methods: {
    reset() {
      this.searchValue = ''
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-box {
  @apply inline-flex flex-row items-center border-2 border-gray py-2 px-4 rounded-lg bg-white;
}
.search-box--in-list {
  @apply flex border-l-0 border-t-0 border-r-0 rounded-none border-gray;
}
.search-box--focused:not(.search-box--in-list) {
  @apply border-blue;
}
</style>
