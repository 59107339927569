<template>
  <IconBase
    stroke="currentColor"
    view-box="0 0 56.693 56.689"
    enable-background="new 0 0 56.693 56.689"
  >
    <path
      fill="none"
      stroke-width="3"
      stroke-miterlimit="10"
      d="M8.3,12.199h40.095c3.461,0,6.266,2.979,6.266,6.65
	v18.617c0,3.673-2.805,6.648-6.266,6.648H8.3c-3.461,0-6.267-2.977-6.267-6.648V18.849C2.033,15.177,4.839,12.199,8.3,12.199z"
    />
  </IconBase>
</template>

<script>
import IconBase from './IconBase.vue'
export default {
  components: { IconBase }
}
</script>
