import Hearts from './Hearts'
import Balloons from './Balloons'
import Dice from './Dice'
import Stars from './Stars'
import Bar from './Bar'
import EggsWhite from './EggsWhite'
import EggsBlack from './EggsBlack'

export default function (type) {
  if (type === 'hearts') {
    return Hearts
  }
  if (type === 'balloons') {
    return Balloons
  }
  if (type === 'dice') {
    return Dice
  }
  if (type === 'stars') {
    return Stars
  }
  if (type === 'bar') {
    return Bar
  }
  if (type === 'eggs-white') {
    return EggsWhite
  }
  if (type === 'eggs-black') {
    return EggsBlack
  }
  throw new Error(`${type} not supported yet`)
}
