<template>
  <div class="math">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    display: { type: String, default: 'inline' }
  }
}
</script>
