<template>
  <div class="flex flex-col items-center justify-center text-center">
    <div style="font-size: 120%">
      <slot name="prompt" />
    </div>
    <SelectableNumberLine
      :labels="labels"
      :input-state="response.inputState"
      :value="response.value"
      :correct-value="response.correctValue"
      @input="(value) => responseSet.handleInput(responseIdentifier, value)"
      @input-ready="responseSet.handleInputReady(responseIdentifier)"
    />
  </div>
</template>

<script>
import QtiBaseMixin from '../../QtiBaseMixin'
import SelectableNumberLine from './SelectableNumberLine'

export default {
  components: { SelectableNumberLine },
  mixins: [QtiBaseMixin],
  props: {
    responseIdentifier: { type: String, required: true },
    maxChoices: { type: String, required: true }
  },
  computed: {
    response() {
      return this.responseSet.responseById(this.responseIdentifier)
    },
    labels() {
      const domParser = new DOMParser()
      const objects = this.getComponentsWithTags(['object'])
      if (objects.length > 0) {
        const firstObject = objects[0]
        if (
          firstObject.data &&
          firstObject.data.attrs &&
          firstObject.data.attrs.data
        ) {
          const { data } = this.parseDataUri(firstObject.data.attrs.data)
          const doc = domParser.parseFromString(data, 'application/xml')
          const labels = doc.children[0].getAttribute('labels')
          return labels.split(',')
        }
      }
      return []
    }
  },
  created() {
    this.updateSlots()
  },
  mounted() {
    this.updateSlots()
  },
  beforeUpdate() {
    this.updateSlots()
  },
  methods: {
    updateSlots() {
      this.$slots.prompt = this.getPrompt()
    },
    getPrompt() {
      return this.getComponentsWithTags(['qti-prompt'])
    },
    parseDataUri(uri) {
      // data:[<media type>][;base64],<data>
      const firstComma = uri.indexOf(',')
      const data = uri.substr(firstComma + 1)
      return { data }
    }
  }
}
</script>
