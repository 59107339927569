import {
  QTI_COMPANION_MATERIALS_INFO,
  QTI_ITEM_BODY
} from '../services/qti/constants'
import extractResponses from '../services/qti/response/extractResponses'
import { determineWillAutoplayAudio } from '../services/qti/utils'

export default class QtiItem {
  constructor(doc, settings, assets) {
    this._doc = doc
    this._settings = settings
    this._assets = assets
  }

  get settings() {
    return this._settings
  }

  set settings(settings) {
    this._settings = settings
  }

  getResponses() {
    return extractResponses(this._doc, this._settings)
  }

  get interactionXml() {
    return new XMLSerializer().serializeToString(
      this._doc.querySelector(QTI_ITEM_BODY)
    )
  }

  get companionMaterialsXml() {
    const companionMaterials = this._doc.querySelector(
      QTI_COMPANION_MATERIALS_INFO
    )
    return companionMaterials
      ? new XMLSerializer().serializeToString(companionMaterials)
      : null
  }

  get willAutoplayAudio() {
    return determineWillAutoplayAudio(
      this._doc,
      this._settings.autoPlayPromptAudio
    )
  }

  get hasStrategyContent() {
    return this.hasFeedbackBlock('STRATEGY')
  }

  get hasJumpNumberLineContent() {
    return this.hasFeedbackBlock('NUMBER_LINE')
  }

  get hasCorrectAnswerFeedback() {
    return this.hasModalFeedback('CORRECT_ANSWER')
  }

  get correctAnswerFeedbackXml() {
    return new XMLSerializer().serializeToString(
      this.getModalFeedback('CORRECT_ANSWER')
    )
  }

  get showStrategy() {
    return this._settings.showStrategy && this.hasStrategyContent
  }

  get showJumpNumberLine() {
    return this._settings.showJumpNumberLine && this.hasJumpNumberLineContent
  }

  assetUrlByPath(path) {
    const asset = this._assets.find((asset) => asset.path === path)
    return asset ? asset.url : null
  }

  setCompanionMaterialVisible(material, visible) {
    this._companionMaterials = this._companionMaterials.map(
      (companionMaterial) => {
        if (companionMaterial === material) {
          return { ...companionMaterial, visible }
        }
        return companionMaterial
      }
    )
  }

  hasFeedbackBlock(identifier) {
    return (
      this._doc.querySelector(
        `qti-feedback-block[identifier="${identifier}"]`
      ) !== null
    )
  }

  hasTemplate(identifier) {
    return (
      this._doc.querySelector(
        `qti-template-block[template-identifier="${identifier}"]`
      ) !== null
    )
  }

  hasModalFeedback(identifier) {
    return (
      this._doc.querySelector(
        `qti-modal-feedback[identifier="${identifier}"]`
      ) !== null
    )
  }

  getModalFeedback(identifier) {
    return this._doc.querySelector(
      `qti-modal-feedback[identifier="${identifier}"]`
    )
  }
}
