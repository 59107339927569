import Coordinate from './Coordinate'

export default class Circle {
  /**
   *
   * @param {Coordinate} center
   * @param {number} radius
   */
  constructor(center, radius) {
    this.center = center
    this.radius = radius
  }

  static fromString(string) {
    const data = string.split(',')
    if (data.length !== 3) {
      throw new Error(
        `cannot create circle shape from string: data must contain a coordinate and radius: ${string}`
      )
    }
    return new Circle(new Coordinate(data[0], data[1]), data[2])
  }

  /**
   * @param {Coordinate} coordinate
   */
  contains(coordinate) {
    return (
      Math.pow(coordinate.x - this.center.x, 2) +
        Math.pow(coordinate.y - this.center.y, 2) <=
      this.radius * this.radius
    )
  }
}
