import Rectangle from '../../../models/geometry/Rectangle'
import Circle from '../../../models/geometry/Circle'

export default class AreaMapping {
  /**
   * @param {string} defaultValue
   * @param {string} mappedValue
   * @param shape
   */
  constructor(defaultValue, mappedValue, shape) {
    this.defaultValue = defaultValue
    this.mappedValue = mappedValue
    this.shape = shape
  }

  /**
   * @param {Element} element
   */
  static createFromElement(element) {
    const defaultValue = element.getAttribute('default-value') || null
    const areaMapEntry = element.querySelector('qti-area-map-entry')
    if (!areaMapEntry) {
      throw new Error('expected element "qti-area-mapping" not present')
    }
    const shapeName = areaMapEntry.getAttribute('shape')
    const coords = areaMapEntry.getAttribute('coords')
    const mappedValue = areaMapEntry.getAttribute('mapped-value')

    let shape
    if (shapeName === 'rect') {
      shape = Rectangle.fromString(coords)
    }
    if (shapeName === 'circle') {
      shape = Circle.fromString(coords)
    }

    return new AreaMapping(defaultValue, mappedValue, shape)
  }
}
