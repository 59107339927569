<template>
  <div class="main-container">
    <Nuxt />
    <ModalContainer />
  </div>
</template>
<script>
import { ModalContainer } from 'component-library'

export default {
  components: { ModalContainer }
}
</script>
