export default class Item {
  /**
   * @param {string} id
   * @param {string} title
   * @param {string} content
   * @param {string|null} exampleItemId
   * @param {string} assetHost
   * @param {Array<Object>} assets
   * @param {Object} context
   */
  constructor(id, title, content, exampleItemId, assetHost, assets, context) {
    this._id = id
    this._title = title
    this._content = content
    this._exampleItemId = exampleItemId
    this._assetHost = assetHost
    this._assets = assets
    this._context = context
  }

  static createFromObject(object) {
    return new Item(
      object.id,
      object.title,
      object.content,
      object.exampleItemId,
      object.assetHost,
      object.assets,
      object.context
    )
  }

  get id() {
    return this._id
  }

  get title() {
    return this._title
  }

  get content() {
    return this._content
  }

  get exampleItemId() {
    return this._exampleItemId
  }

  get assetHost() {
    return this._assetHost
  }

  /**
   * @return {Array<Object>}
   */
  get assets() {
    return this._assets
  }

  get context() {
    return this._context
  }
}
