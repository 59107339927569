import { render, staticRenderFns } from "./PlaceholderContainer.vue?vue&type=template&id=57bb364d&scoped=true"
var script = {}
import style0 from "./PlaceholderContainer.vue?vue&type=style&index=0&id=57bb364d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17._dw5h7cef4ed45xyb3rc3if34cq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57bb364d",
  null
  
)

export default component.exports