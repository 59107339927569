<template>
  <g :transform="transform">
    <!-- background segments
    <g fill="#f1f1f1">
      <polygon
        points="0.93,13.68 0.54,14.07 0,14.07 0,3.18 0.93,2.25 3.72,5.01 3.72,10.92 	"
      />
      <polygon
        points="0.93,27.75 0,26.85 0,15.93 0.54,15.93 0.93,16.32 3.72,19.08 3.72,24.99 	"
      />
      <polygon
        points="2.25,0.93 3.15,0 15.39,0 16.29,0.93 13.53,3.72 5.01,3.72 	"
      />
      <polygon
        points="16.29,29.07 15.39,30 3.15,30 2.25,29.07 5.01,26.28 13.53,26.28 	"
      />
      <polygon
        points="17.61,2.25 18.54,3.15 18.54,14.07 18,14.07 17.61,13.68 14.82,10.92 14.82,5.01 	"
      />
      <polygon
        points="17.61,16.32 18,15.93 18.54,15.93 18.54,26.85 17.61,27.75 14.82,24.99 14.82,19.08 	"
      />
    </g>
    end background segments -->
    <g fill="currentColor">
      <polygon
        v-if="segmentOneVisible"
        points="0.93,13.68 0.54,14.07 0,14.07 0,3.18 0.93,2.25 3.72,5.01 3.72,10.92 	"
      />
      <polygon
        v-if="segmentTwoVisible"
        points="0.93,27.75 0,26.85 0,15.93 0.54,15.93 0.93,16.32 3.72,19.08 3.72,24.99 	"
      />
      <polygon
        v-if="segmentThreeVisible"
        points="2.25,0.93 3.15,0 15.39,0 16.29,0.93 13.53,3.72 5.01,3.72 	"
      />
      <polygon
        v-if="segmentFourVisible"
        points="14.46,13.14 16.29,15 14.46,16.86 4.08,16.86 2.25,15 4.08,13.14 	"
      />
      <polygon
        v-if="segmentFiveVisible"
        points="16.29,29.07 15.39,30 3.15,30 2.25,29.07 5.01,26.28 13.53,26.28 	"
      />
      <polygon
        v-if="segmentSixVisible"
        points="17.61,2.25 18.54,3.15 18.54,14.07 18,14.07 17.61,13.68 14.82,10.92 14.82,5.01 	"
      />
      <polygon
        v-if="segmentSevenVisible"
        points="17.61,16.32 18,15.93 18.54,15.93 18.54,26.85 17.61,27.75 14.82,24.99 14.82,19.08 	"
      />
    </g>
  </g>
</template>

<script>
export default {
  props: {
    number: { type: Number, default: 0 },
    translateX: { type: Number, default: 0 }
  },
  computed: {
    transform() {
      return `translate(${this.translateX}, 0)`
    },
    segmentOneVisible() {
      const visibleWhen = [0, 4, 5, 6, 8, 9]
      return visibleWhen.includes(this.number)
    },
    segmentTwoVisible() {
      const visibleWhen = [0, 2, 6, 8]
      return visibleWhen.includes(this.number)
    },
    segmentThreeVisible() {
      const visibleWhen = [0, 2, 3, 5, 6, 7, 8, 9]
      return visibleWhen.includes(this.number)
    },
    segmentFourVisible() {
      const visibleWhen = [2, 3, 4, 5, 6, 8, 9]
      return visibleWhen.includes(this.number)
    },
    segmentFiveVisible() {
      const visibleWhen = [0, 2, 3, 5, 6, 8, 9]
      return visibleWhen.includes(this.number)
    },
    segmentSixVisible() {
      const visibleWhen = [0, 1, 2, 3, 4, 7, 8, 9]
      return visibleWhen.includes(this.number)
    },
    segmentSevenVisible() {
      const visibleWhen = [0, 1, 3, 4, 5, 6, 7, 8, 9]
      return visibleWhen.includes(this.number)
    }
  }
}
</script>
