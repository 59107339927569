import { Howl } from 'howler'

const { isImage, isAudio } = require('../utils/mediaTypes')

let preloadedSounds = new Map()

const preloadImage = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = resolve
    image.onerror = reject
    image.src = url
  })
}

const preloadAudio = (url) => {
  console.log('preloading audio', url)
  return new Promise((resolve, reject) => {
    const sound = createSound(url)
    sound.once('load', () => resolve(sound))
    sound.once('loaderror', () => reject(new Error('Failed to load audio.')))
  })
}

export const createSound = (url) => {
  return new Howl({
    src: [url],
    html5: true,
    preload: true,
    autoplay: false
  })
}

/**
 * @param {Object} asset
 * @param {ItemSettings} settings
 * @returns {Promise<void>}
 */
const addAndPreloadAsset = async (asset, settings) => {
  const { url, mimeType } = asset
  if (isAudio(mimeType)) {
    if (!settings.autoPlayPromptAudio && !settings.showPromptAudioButtons) {
      return
    }
    if (preloadedSounds.has(url)) {
      return
    }
    preloadedSounds.set(url, preloadAudio(url))
    return
  }
  if (isImage(mimeType)) {
    await preloadImage(url)
    return
  }
  console.warn(
    `Cannot preload asset with URL ${asset.url} to asset store, unknown MIME type`
  )
}

export const addAssetsToStore = async (assets, settings) => {
  for (const asset of assets) {
    try {
      await addAndPreloadAsset(asset, settings)
    } catch (err) {
      console.log('Error preloading asset ' + asset.url, err)
    }
  }
}

export const getPreloadedSoundByUrl = (url) => {
  if (!preloadedSounds.has(url)) {
    return new Promise(null)
  }
  // Send promise
  return preloadedSounds.get(url) || new Promise(null)
}
