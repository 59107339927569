<template>
  <IconBase
    stroke="currentColor"
    view-box="0 0 56.693 56.689"
    enable-background="new 0 0 56.693 56.689"
  >
    <g>
      <g>
        <path
          stroke-miterlimit="10"
          d="M52.53,23.036h-0.846v-2.301c0-2.651-2.158-4.808-4.811-4.808
			h-1.198c-0.509-1.893-2.236-3.286-4.285-3.286c-2.447,0-4.438,1.989-4.438,4.438v7.345H19.738V17.08
			c0-2.448-1.99-4.438-4.438-4.438c-2.049,0-3.776,1.395-4.283,3.286H9.816c-2.651,0-4.809,2.155-4.809,4.808v2.301H4.162
			c-1.867,0-3.387,1.521-3.387,3.385v3.834c0,1.868,1.52,3.387,3.387,3.387h0.847v2.313c0,2.651,2.156,4.811,4.809,4.811h1.201
			c0.509,1.891,2.234,3.284,4.283,3.284c2.446,0,4.438-1.99,4.438-4.438v-7.344h14.755c0.446,0,0.811-0.362,0.811-0.808
			c0-0.446-0.362-0.809-0.811-0.809H19.738V26.04h17.216v13.571c0,2.447,1.989,4.438,4.438,4.438c2.049,0,3.776-1.396,4.286-3.284
			h1.197c2.65,0,4.811-2.157,4.811-4.811V33.64h0.846c1.867,0,3.387-1.519,3.387-3.387V26.42
			C55.916,24.555,54.397,23.036,52.53,23.036z M5.009,32.027H4.162l0,0c-0.976,0-1.771-0.795-1.771-1.771v-3.834
			c0-0.978,0.795-1.77,1.771-1.77h0.847V32.027z M18.123,39.61c0,1.557-1.265,2.823-2.822,2.823c-1.557,0-2.821-1.269-2.821-2.823
			V23.314c0-0.446-0.362-0.81-0.809-0.81c-0.445,0-0.809,0.362-0.809,0.81v15.834H9.816c-1.761,0-3.194-1.435-3.194-3.194V20.736
			c0-1.76,1.435-3.193,3.194-3.193h1.046v2.194c0,0.447,0.362,0.807,0.809,0.807c0.445,0,0.809-0.358,0.809-0.807v-2.656
			c0-1.557,1.265-2.822,2.821-2.822c1.558,0,2.822,1.268,2.822,2.822V39.61L18.123,39.61z M44.212,39.61
			c0,1.557-1.265,2.823-2.82,2.823c-1.557,0-2.822-1.269-2.822-2.823V17.08c0-1.557,1.268-2.822,2.822-2.822
			c1.558,0,2.82,1.268,2.82,2.822V39.61z M50.07,35.954c0,1.762-1.435,3.194-3.194,3.194h-1.049V17.542h1.049
			c1.763,0,3.194,1.435,3.194,3.193V35.954z M54.301,30.256c0,0.978-0.794,1.771-1.771,1.771h-0.846v-7.375h0.846
			c0.978,0,1.771,0.792,1.771,1.77V30.256z"
        />
      </g>
    </g>
  </IconBase>
</template>

<script>
import IconBase from './IconBase.vue'
export default {
  components: { IconBase }
}
</script>
