/**
 * @param {String} number
 * @param {String} other
 */
export default function isReverseOf(number, other) {
  if (number === other) {
    return false // numbers can be symmetric ( 11, 606, etc)
  }
  const reverse = number.split('').reverse()
  // if the number is 11, 22, etc, it is never a reverse:
  if (
    reverse.every((value) => {
      return value === reverse[0]
    })
  ) {
    return false
  }
  return reverse.join('') === other
}
