<template>
  <Dialog
    :buttons="[
      { title: 'Ja', handler: () => $emit('ok') },
      { title: 'Nee', handler: () => $emit('cancel') }
    ]"
  >
    <template #content>
      <div v-html="message" />
    </template>
  </Dialog>
</template>

<script>
import { Dialog } from 'component-library'

export default {
  components: { Dialog },
  props: {
    message: { type: String, required: true }
  }
}
</script>
