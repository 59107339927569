<template>
  <svg :viewBox="`0 0 ${width} ${height}`" class="max-w-full jump-number-line">
    <defs>
      <marker
        id="arrowhead"
        markerWidth="2.5"
        markerHeight="4"
        refX="1.5"
        refY="2"
        orient="auto"
        fill="currentColor"
      >
        <polygon points="0 0, 2.5 2, 0 4" />
      </marker>
    </defs>
    <line
      x1="0"
      :y1="verticalLineY"
      x2="1000"
      :y2="verticalLineY"
      stroke="currentColor"
      stroke-width="3"
    />
    <line
      :x1="startPosX"
      :y1="verticalLineY - height * 0.25"
      :x2="startPosX"
      :y2="height"
      stroke="currentColor"
      stroke-width="3"
    />
    <g v-for="(jump, index) in jumpsParsed" :key="index">
      <path
        :d="pathForJumpAtIndex(index)"
        fill="none"
        stroke="currentColor"
        stroke-width="4"
        marker-end="url(#arrowhead)"
      />
      <text
        style="font-size: 2em"
        fill="currentColor"
        text-anchor="middle"
        :x="textPosForJumpAtIndex(index).x"
        :y="textPosForJumpAtIndex(index).y"
      >
        {{ jump.jumpText }}
      </text>
      <g v-if="jump.markerText">
        <line
          stroke="currentColor"
          stroke-width="3"
          v-bind="markerCoordinatesForJumpAtIndex(index)"
        ></line>
        <text
          fill="currentColor"
          font-size="1.25em"
          text-anchor="middle"
          v-bind="labelPositionForJumpAtIndex(index)"
        >
          {{ jump.markerText }}
        </text>
      </g>
    </g>
  </svg>
</template>

<script>
import Coordinate from '../../models/geometry/Coordinate'

export default {
  props: {
    direction: {
      type: String,
      required: true,
      validator(value) {
        return ['forward', 'backward'].includes(value)
      }
    },
    jumps: { type: String, required: true }
  },
  data() {
    const isBackward = this.direction === 'backward'
    const width = 750
    const height = 200
    const startPosX = isBackward ? width - 50 : 50
    return {
      width,
      verticalLineY: height - height / 4,
      height,
      isBackward,
      startPosX
    }
  },
  computed: {
    jumpsParsed() {
      return JSON.parse(this.jumps)
    }
  },
  methods: {
    archToPath(arc) {
      return [
        'M',
        arc.startCoordinate.x, // x1
        arc.startCoordinate.y,
        'C',
        arc.startCoordinate.x + arc.arcCurveDelta,
        arc.startCoordinate.y - arc.arcHeight,
        arc.endCoordinate.x - arc.arcCurveDelta,
        arc.startCoordinate.y - arc.arcHeight,
        arc.endCoordinate.x,
        arc.endCoordinate.y
      ].join(' ')
    },
    arcAtIndex(index) {
      // the jumps needs a small horizontal margin; first jump slightly less
      // than margin 'between' jumps:
      let marginX = 10
      if (this.isBackward) {
        marginX = -marginX
      }
      const posX =
        index === 0
          ? this.startPosX + marginX
          : this.arcAtIndex(index - 1).endCoordinate.x + marginX
      // vertical position is in middle with a small top margin:
      const posY = this.verticalLineY - 10
      const startCoordinate = new Coordinate(posX, posY)
      const large = this.jumpsParsed[index].type === 'large'
      // a 'large' jump has more width:
      let width = large ? 300 : 150
      if (this.isBackward) {
        width = -width
      }
      const endCoordinate = new Coordinate(startCoordinate.x + width, posY)
      // set values that determine arc curve:
      const arcHeight = large ? 80 : 50
      const arcCurveDelta = (endCoordinate.x - startCoordinate.x) / 4
      return {
        startCoordinate,
        endCoordinate,
        arcCurveDelta,
        arcHeight,
        large,
        direction: this.direction
      }
    },
    pathForJumpAtIndex(index) {
      return this.archToPath(this.arcAtIndex(index))
    },
    textPosForJumpAtIndex(index) {
      const arc = this.arcAtIndex(index)
      return new Coordinate(
        (arc.endCoordinate.x - arc.startCoordinate.x) / 2 +
          arc.startCoordinate.x,
        this.verticalLineY - arc.arcHeight - 10
      )
    },
    markerCoordinatesForJumpAtIndex(index) {
      const arc = this.arcAtIndex(index)
      let marginX = 5
      if (this.isBackward) {
        marginX = -marginX
      }
      return {
        x1: arc.endCoordinate.x + marginX,
        y1: this.verticalLineY - 5,
        x2: arc.endCoordinate.x + marginX,
        y2: this.verticalLineY + 5
      }
    },
    labelPositionForJumpAtIndex(index) {
      const arc = this.arcAtIndex(index)
      let marginX = 5
      if (this.isBackward) {
        marginX = -marginX
      }
      return {
        x: arc.endCoordinate.x + marginX,
        y: this.verticalLineY + 40
      }
    }
  }
}
</script>

<style>
.jump-number-line {
  font-size: 0.675em;
  width: 16em;
  margin-bottom: 1.25em;
}
</style>
