import InputMode from '../../../models/InputMode'
import {
  getChoices,
  isChoiceInputElement,
  isNumberInputElement,
  isPronounceInput,
  isSelectPointInteraction,
  isSplitNumberInteraction,
  isJumpInteraction
} from './extractResponses'

const inputModeSpeechAndKeyboard = [
  InputMode.createSpeech(),
  InputMode.createKeyboard()
]

export default function getSupportedInputModes(interactionElement) {
  if (
    isNumberInputElement(interactionElement) ||
    isSplitNumberInteraction(interactionElement) ||
    isJumpInteraction(interactionElement)
  ) {
    return inputModeSpeechAndKeyboard
  }
  if (isChoiceInputElement(interactionElement)) {
    const choices = getChoices(interactionElement)
    // Only allow speech for pure numeric choices to prevent listening issues
    const speechAvailable = choices.every((choice) =>
      new RegExp('[0-9]+').test(choice.content)
    )
    return [
      InputMode.createClick(),
      ...(speechAvailable ? [InputMode.createSpeech()] : [])
    ]
  }
  if (isPronounceInput(interactionElement)) {
    return [InputMode.createSpeech()]
  }
  if (isSelectPointInteraction(interactionElement)) {
    return [InputMode.createClick()]
  }
  return []
}
