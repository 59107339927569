<template>
  <div v-if="active" class="dropdown-content" :class="alignClasses">
    <slot />
  </div>
</template>

<script>
export default {
  inject: ['dropdownState'],
  props: {
    align: { type: String, default: 'top left' }
  },
  computed: {
    active() {
      return this.dropdownState.open
    },
    alignHorizontal() {
      return this.align.split(' ').includes('right') ? 'right' : 'left'
    },
    alignVertical() {
      return this.align.split(' ').includes('bottom') ? 'bottom' : 'top'
    },
    alignClasses() {
      return (
        'dropdown-content--align-' +
        this.alignHorizontal +
        ' dropdown-content--align-' +
        this.alignVertical
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-content {
  @apply z-20 mt-1 absolute whitespace-no-wrap w-auto;
}

.dropdown-content--align-top {
  top: 100%;
}

.dropdown-content--align-bottom {
  bottom: 100%;
}

.dropdown-content--align-left {
  left: 0;
}

.dropdown-content--align-right {
  right: 0;
}
</style>
