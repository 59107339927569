<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style="width: 6em; height: auto"
    viewBox="0 0 130.26 74.78"
  >
    <path
      v-if="count === 10"
      id="_x31_0"
      :stroke="black"
      :fill="hexColor"
      d="M123.18,74.28c3.64,0,6.59-3.82,6.59-8.54c0-4.72-2.95-8.54-6.59-8.54
	c-3.64,0-6.59,3.83-6.59,8.54C116.59,70.45,119.54,74.28,123.18,74.28"
    />
    <path
      v-if="count >= 9"
      id="_x39_"
      :stroke="black"
      :fill="hexColor"
      d="M94.15,74.28c3.64,0,6.59-3.82,6.59-8.54c0-4.72-2.95-8.54-6.59-8.54
	c-3.64,0-6.59,3.83-6.59,8.54C87.57,70.45,90.52,74.28,94.15,74.28"
    />
    <path
      v-if="count >= 8"
      id="_x38_"
      :stroke="black"
      :fill="hexColor"
      d="M65.13,74.28c3.64,0,6.59-3.82,6.59-8.54c0-4.72-2.95-8.54-6.59-8.54
	c-3.64,0-6.59,3.83-6.59,8.54C58.55,70.45,61.49,74.28,65.13,74.28"
    />
    <path
      v-if="count >= 7"
      id="_x37_"
      :stroke="black"
      :fill="hexColor"
      d="M36.11,74.28c3.64,0,6.59-3.82,6.59-8.54c0-4.72-2.95-8.54-6.59-8.54
	c-3.64,0-6.59,3.83-6.59,8.54C29.52,70.45,32.47,74.28,36.11,74.28"
    />
    <path
      v-if="count >= 6"
      id="_x36_"
      :stroke="black"
      :fill="hexColor"
      d="M7.09,74.28c3.64,0,6.59-3.82,6.59-8.54c0-4.72-2.95-8.54-6.59-8.54
	c-3.64,0-6.59,3.83-6.59,8.54C0.5,70.45,3.45,74.28,7.09,74.28"
    />
    <path
      v-if="count >= 5"
      id="_x35_"
      :stroke="black"
      :fill="hexColor"
      d="M123.18,17.59c3.64,0,6.59-3.82,6.59-8.54c0-4.72-2.95-8.54-6.59-8.54
	c-3.64,0-6.59,3.83-6.59,8.54C116.59,13.76,119.54,17.59,123.18,17.59"
    />
    <path
      v-if="count >= 3"
      id="_x33_"
      :stroke="black"
      :fill="hexColor"
      d="M65.13,17.59c3.64,0,6.59-3.82,6.59-8.54c0-4.72-2.95-8.54-6.59-8.54
	c-3.64,0-6.59,3.83-6.59,8.54C58.55,13.76,61.49,17.59,65.13,17.59"
    />
    <path
      v-if="count >= 2"
      id="_x32_"
      :stroke="black"
      :fill="hexColor"
      d="M36.11,17.59c3.64,0,6.59-3.82,6.59-8.54c0-4.72-2.95-8.54-6.59-8.54
	c-3.64,0-6.59,3.83-6.59,8.54C29.52,13.76,32.47,17.59,36.11,17.59"
    />
    <path
      v-if="count >= 1"
      id="_x31_"
      :stroke="black"
      :fill="hexColor"
      d="M7.09,17.59c3.64,0,6.59-3.82,6.59-8.54c0-4.72-2.95-8.54-6.59-8.54
	C3.45,0.5,0.5,4.33,0.5,9.04C0.5,13.76,3.45,17.59,7.09,17.59"
    />
    <path
      v-if="count >= 4"
      id="_x33__1_"
      :stroke="black"
      :fill="hexColor"
      d="M94.15,17.59c3.64,0,6.59-3.82,6.59-8.54c0-4.72-2.95-8.54-6.59-8.54
	c-3.64,0-6.59,3.83-6.59,8.54C87.57,13.76,90.52,17.59,94.15,17.59"
    />
  </svg>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: function (value) {
        if (value < 0) {
          return false
        }
        return value <= 10
      }
    },
    color: {
      type: String,
      default: 'black',
      validator: function (value) {
        return ['white', 'black'].includes(value)
      }
    }
  },
  data() {
    return {
      black: '#222222',
      white: '#ffffff'
    }
  },
  computed: {
    hexColor() {
      return this.color === 'black' ? this.black : this.white
    }
  }
}
</script>
