<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5em"
    height="5em"
    style="margin-bottom: 1em"
    viewBox="0 0 80 80"
  >
    <g id="clockFace">
      <path
        d="M40,80C17.94,80,0,62.06,0,40C0,17.94,17.94,0,40,0c22.06,0,40,17.94,40,40C80,62.06,62.06,80,40,80z M40,2.16
		C19.14,2.16,2.16,19.14,2.16,40c0,20.86,16.97,37.84,37.84,37.84c20.86,0,37.84-16.97,37.84-37.84C77.84,19.14,60.86,2.16,40,2.16z
		"
      />
      <path
        d="M78.92,40.72h-5.04c-0.4,0-0.72-0.32-0.72-0.72s0.32-0.72,0.72-0.72h5.04c0.4,0,0.72,0.32,0.72,0.72
		S79.32,40.72,78.92,40.72z"
      />
      <path
        d="M75.12,36.67c-0.18,0-0.34-0.14-0.36-0.32c-0.02-0.2,0.12-0.38,0.32-0.4l3.58-0.37c0.19-0.02,0.38,0.12,0.4,0.32
		c0.02,0.2-0.12,0.38-0.32,0.4l-3.58,0.37C75.15,36.67,75.13,36.67,75.12,36.67z M74.54,33.02c-0.17,0-0.32-0.12-0.35-0.29
		c-0.04-0.19,0.08-0.39,0.28-0.43l3.52-0.75c0.19-0.04,0.39,0.08,0.43,0.28c0.04,0.19-0.08,0.39-0.28,0.43l-3.52,0.75
		C74.59,33.01,74.57,33.02,74.54,33.02z M73.58,29.45c-0.15,0-0.29-0.1-0.34-0.25c-0.06-0.19,0.04-0.39,0.23-0.45l3.43-1.12
		c0.19-0.06,0.39,0.04,0.45,0.23c0.06,0.19-0.04,0.39-0.23,0.45l-3.43,1.12C73.66,29.44,73.62,29.45,73.58,29.45z M72.26,26
		c-0.14,0-0.27-0.08-0.33-0.21c-0.08-0.18,0-0.39,0.18-0.48l3.29-1.46c0.18-0.08,0.39,0,0.48,0.18c0.08,0.18,0,0.39-0.18,0.48
		l-3.29,1.46C72.36,25.99,72.31,26,72.26,26z"
      />
      <path
        d="M69.33,23.78c-0.25,0-0.49-0.13-0.62-0.36c-0.2-0.34-0.08-0.79,0.26-0.98l4.37-2.52c0.34-0.2,0.79-0.08,0.98,0.26
		c0.2,0.34,0.08,0.79-0.26,0.98l-4.37,2.52C69.58,23.75,69.46,23.78,69.33,23.78z"
      />
      <path
        d="M68.57,19.6c-0.11,0-0.22-0.05-0.29-0.15c-0.12-0.16-0.08-0.39,0.08-0.5l2.92-2.12c0.16-0.11,0.39-0.08,0.5,0.08
		c0.12,0.16,0.08,0.39-0.08,0.5l-2.92,2.12C68.72,19.58,68.64,19.6,68.57,19.6z M66.24,16.73c-0.1,0-0.2-0.04-0.27-0.12
		c-0.13-0.15-0.12-0.38,0.03-0.51l2.68-2.41c0.15-0.13,0.38-0.12,0.51,0.03c0.13,0.15,0.12,0.38-0.03,0.51l-2.68,2.41
		C66.41,16.7,66.33,16.73,66.24,16.73z M63.63,14.12c-0.09,0-0.17-0.03-0.24-0.09c-0.15-0.13-0.16-0.36-0.03-0.51l2.41-2.68
		c0.13-0.15,0.36-0.16,0.51-0.03c0.15,0.13,0.16,0.36,0.03,0.51L63.9,14C63.83,14.08,63.73,14.12,63.63,14.12z M60.76,11.79
		c-0.07,0-0.15-0.02-0.21-0.07c-0.16-0.12-0.2-0.34-0.08-0.5l2.12-2.92c0.12-0.16,0.34-0.2,0.5-0.08c0.16,0.12,0.2,0.34,0.08,0.5
		l-2.12,2.92C60.98,11.74,60.87,11.79,60.76,11.79z"
      />
      <path
        d="M56.94,11.39c-0.12,0-0.25-0.03-0.36-0.1c-0.34-0.2-0.46-0.64-0.26-0.98l2.52-4.37c0.2-0.34,0.64-0.46,0.98-0.26
		c0.34,0.2,0.46,0.64,0.26,0.98l-2.52,4.37C57.43,11.26,57.18,11.39,56.94,11.39z"
      />
      <path
        d="M54.36,8.1c-0.05,0-0.1-0.01-0.15-0.03c-0.18-0.08-0.26-0.29-0.18-0.48L55.5,4.3c0.08-0.18,0.3-0.26,0.48-0.18
		c0.18,0.08,0.26,0.29,0.18,0.48l-1.46,3.29C54.63,8.02,54.5,8.1,54.36,8.1z M50.91,6.78c-0.04,0-0.07-0.01-0.11-0.02
		c-0.19-0.06-0.29-0.27-0.23-0.45l1.12-3.43c0.06-0.19,0.26-0.29,0.45-0.23c0.19,0.06,0.29,0.27,0.23,0.45l-1.12,3.43
		C51.2,6.68,51.06,6.78,50.91,6.78z M47.34,5.82c-0.02,0-0.05,0-0.08-0.01c-0.19-0.04-0.32-0.23-0.28-0.43l0.75-3.52
		c0.04-0.19,0.23-0.32,0.43-0.28c0.19,0.04,0.32,0.23,0.28,0.43L47.7,5.53C47.66,5.7,47.51,5.82,47.34,5.82z M43.69,5.24
		c-0.01,0-0.03,0-0.04,0c-0.2-0.02-0.34-0.2-0.32-0.4l0.37-3.58c0.02-0.2,0.2-0.34,0.4-0.32c0.2,0.02,0.34,0.2,0.32,0.4l-0.37,3.58
		C44.03,5.1,43.87,5.24,43.69,5.24z"
      />
      <path
        d="M40,6.85c-0.4,0-0.72-0.32-0.72-0.72V1.08c0-0.4,0.32-0.72,0.72-0.72s0.72,0.32,0.72,0.72v5.04
		C40.72,6.52,40.4,6.85,40,6.85z"
      />
      <path
        d="M25.64,8.1c-0.14,0-0.27-0.08-0.33-0.21l-1.46-3.29c-0.08-0.18,0-0.39,0.18-0.48c0.18-0.08,0.39,0,0.48,0.18l1.46,3.29
		c0.08,0.18,0,0.39-0.18,0.48C25.74,8.09,25.69,8.1,25.64,8.1z M29.09,6.78c-0.15,0-0.29-0.1-0.34-0.25L27.63,3.1
		c-0.06-0.19,0.04-0.39,0.23-0.45c0.19-0.06,0.39,0.04,0.45,0.23l1.12,3.43c0.06,0.19-0.04,0.39-0.23,0.45
		C29.16,6.77,29.13,6.78,29.09,6.78z M32.65,5.82c-0.17,0-0.32-0.12-0.35-0.29l-0.75-3.52c-0.04-0.19,0.08-0.39,0.28-0.43
		c0.2-0.04,0.39,0.08,0.43,0.28l0.75,3.52c0.04,0.19-0.08,0.39-0.28,0.43C32.7,5.81,32.68,5.82,32.65,5.82z M36.31,5.24
		c-0.18,0-0.34-0.14-0.36-0.32l-0.37-3.58c-0.02-0.2,0.12-0.38,0.32-0.4c0.2-0.02,0.38,0.12,0.4,0.32l0.37,3.58
		c0.02,0.2-0.12,0.38-0.32,0.4C36.33,5.24,36.32,5.24,36.31,5.24z"
      />
      <path
        d="M23.06,11.39c-0.25,0-0.49-0.13-0.62-0.36l-2.52-4.37c-0.2-0.34-0.08-0.79,0.26-0.98c0.34-0.2,0.78-0.08,0.98,0.26
		l2.52,4.37c0.2,0.34,0.08,0.79-0.26,0.98C23.31,11.36,23.19,11.39,23.06,11.39z"
      />
      <path
        d="M11.43,19.6c-0.07,0-0.15-0.02-0.21-0.07L8.3,17.42c-0.16-0.12-0.2-0.34-0.08-0.5c0.12-0.16,0.34-0.2,0.5-0.08l2.92,2.12
		c0.16,0.12,0.2,0.34,0.08,0.5C11.65,19.55,11.54,19.6,11.43,19.6z M13.76,16.73c-0.09,0-0.17-0.03-0.24-0.09l-2.68-2.41
		c-0.15-0.13-0.16-0.36-0.03-0.51c0.13-0.15,0.36-0.16,0.51-0.03L14,16.1c0.15,0.13,0.16,0.36,0.03,0.51
		C13.96,16.69,13.86,16.73,13.76,16.73z M16.37,14.12c-0.1,0-0.2-0.04-0.27-0.12l-2.41-2.68c-0.13-0.15-0.12-0.38,0.03-0.51
		c0.15-0.13,0.38-0.12,0.51,0.03l2.41,2.68c0.13,0.15,0.12,0.38-0.03,0.51C16.54,14.09,16.45,14.12,16.37,14.12z M19.24,11.79
		c-0.11,0-0.22-0.05-0.29-0.15l-2.12-2.92c-0.12-0.16-0.08-0.39,0.08-0.5c0.16-0.12,0.39-0.08,0.5,0.08l2.12,2.92
		c0.12,0.16,0.08,0.39-0.08,0.5C19.39,11.77,19.32,11.79,19.24,11.79z"
      />
      <path
        d="M10.67,23.78c-0.12,0-0.25-0.03-0.36-0.1l-4.37-2.52c-0.34-0.2-0.46-0.64-0.26-0.98c0.2-0.35,0.64-0.46,0.98-0.26
		l4.37,2.52c0.34,0.2,0.46,0.64,0.26,0.98C11.16,23.66,10.92,23.78,10.67,23.78z"
      />
      <path
        d="M4.88,36.67c-0.01,0-0.03,0-0.04,0l-3.58-0.37c-0.2-0.02-0.34-0.2-0.32-0.4c0.02-0.2,0.19-0.34,0.4-0.32l3.58,0.37
		c0.2,0.02,0.34,0.2,0.32,0.4C5.22,36.53,5.06,36.67,4.88,36.67z M5.46,33.02c-0.03,0-0.05,0-0.08-0.01l-3.52-0.75
		c-0.19-0.04-0.32-0.23-0.28-0.43c0.04-0.19,0.23-0.32,0.43-0.28l3.52,0.75c0.19,0.04,0.32,0.23,0.28,0.43
		C5.77,32.9,5.62,33.02,5.46,33.02z M6.41,29.45c-0.04,0-0.07-0.01-0.11-0.02l-3.43-1.12c-0.19-0.06-0.29-0.27-0.23-0.45
		c0.06-0.19,0.27-0.29,0.45-0.23l3.43,1.12c0.19,0.06,0.29,0.27,0.23,0.45C6.71,29.35,6.57,29.45,6.41,29.45z M7.74,26
		c-0.05,0-0.1-0.01-0.15-0.03L4.3,24.5c-0.18-0.08-0.26-0.29-0.18-0.48c0.08-0.18,0.29-0.27,0.48-0.18l3.29,1.46
		c0.18,0.08,0.26,0.29,0.18,0.48C8.01,25.92,7.88,26,7.74,26z"
      />
      <path
        d="M6.13,40.72H1.08c-0.4,0-0.72-0.32-0.72-0.72s0.32-0.72,0.72-0.72h5.05c0.4,0,0.72,0.32,0.72,0.72S6.52,40.72,6.13,40.72z"
      />
      <path
        d="M4.45,56.19c-0.14,0-0.27-0.08-0.33-0.21c-0.08-0.18,0-0.39,0.18-0.48l3.29-1.46c0.18-0.08,0.39,0,0.48,0.18
		c0.08,0.18,0,0.39-0.18,0.48l-3.29,1.46C4.55,56.18,4.5,56.19,4.45,56.19z M2.98,52.39c-0.15,0-0.29-0.1-0.34-0.25
		c-0.06-0.19,0.04-0.39,0.23-0.45l3.43-1.12c0.19-0.06,0.39,0.04,0.45,0.23c0.06,0.19-0.04,0.39-0.23,0.45L3.1,52.37
		C3.06,52.38,3.02,52.39,2.98,52.39z M1.93,48.45c-0.17,0-0.32-0.12-0.35-0.28c-0.04-0.19,0.08-0.39,0.28-0.43l3.52-0.75
		c0.19-0.04,0.39,0.08,0.43,0.28c0.04,0.19-0.08,0.39-0.28,0.43l-3.52,0.75C1.98,48.45,1.96,48.45,1.93,48.45z M1.3,44.42
		c-0.18,0-0.34-0.14-0.36-0.32c-0.02-0.2,0.12-0.38,0.32-0.4l3.58-0.37c0.2-0.03,0.38,0.12,0.4,0.32c0.02,0.2-0.12,0.38-0.32,0.4
		l-3.58,0.37C1.32,44.42,1.31,44.42,1.3,44.42z"
      />
      <path
        d="M6.29,60.18c-0.25,0-0.49-0.13-0.62-0.36c-0.2-0.34-0.08-0.79,0.26-0.98l4.37-2.52c0.34-0.2,0.79-0.08,0.98,0.26
		s0.08,0.79-0.26,0.98l-4.37,2.52C6.54,60.15,6.42,60.18,6.29,60.18z"
      />
      <path
        d="M17.12,71.85c-0.07,0-0.15-0.02-0.21-0.07c-0.16-0.12-0.2-0.34-0.08-0.5l2.12-2.92c0.12-0.16,0.34-0.2,0.5-0.08
		c0.16,0.12,0.2,0.34,0.08,0.5l-2.12,2.92C17.35,71.8,17.24,71.85,17.12,71.85z M13.96,69.28c-0.09,0-0.17-0.03-0.24-0.09
		c-0.15-0.13-0.16-0.36-0.03-0.51L16.1,66c0.13-0.15,0.36-0.16,0.51-0.03c0.15,0.13,0.16,0.36,0.03,0.51l-2.41,2.68
		C14.16,69.24,14.06,69.28,13.96,69.28z M11.08,66.4c-0.1,0-0.2-0.04-0.27-0.12c-0.13-0.15-0.12-0.38,0.03-0.51l2.68-2.41
		c0.15-0.13,0.38-0.12,0.51,0.03c0.13,0.15,0.12,0.38-0.03,0.51l-2.68,2.41C11.25,66.37,11.16,66.4,11.08,66.4z M8.51,63.24
		c-0.11,0-0.22-0.05-0.29-0.15c-0.12-0.16-0.08-0.39,0.08-0.5l2.92-2.12c0.16-0.12,0.39-0.08,0.5,0.08c0.12,0.16,0.08,0.39-0.08,0.5
		l-2.92,2.12C8.66,63.21,8.59,63.24,8.51,63.24z"
      />
      <path
        d="M20.54,74.43c-0.12,0-0.25-0.03-0.36-0.1c-0.34-0.2-0.46-0.64-0.26-0.98l2.52-4.38c0.2-0.34,0.64-0.46,0.98-0.26
		c0.34,0.2,0.46,0.64,0.26,0.98l-2.52,4.38C21.03,74.3,20.79,74.43,20.54,74.43z"
      />
      <path
        d="M35.94,79.06c-0.01,0-0.03,0-0.04,0c-0.2-0.02-0.34-0.2-0.32-0.4l0.37-3.58c0.02-0.2,0.19-0.35,0.4-0.32
		c0.2,0.02,0.34,0.2,0.32,0.4l-0.37,3.58C36.27,78.93,36.12,79.06,35.94,79.06z M31.91,78.43c-0.03,0-0.05,0-0.08-0.01
		c-0.19-0.04-0.32-0.23-0.28-0.43l0.75-3.52c0.04-0.2,0.23-0.32,0.43-0.28s0.32,0.23,0.28,0.43l-0.75,3.52
		C32.22,78.31,32.07,78.43,31.91,78.43z M27.97,77.38c-0.04,0-0.07-0.01-0.11-0.02c-0.19-0.06-0.29-0.26-0.23-0.45l1.12-3.43
		c0.06-0.19,0.27-0.29,0.45-0.23c0.19,0.06,0.29,0.26,0.23,0.45l-1.12,3.43C28.26,77.28,28.12,77.38,27.97,77.38z M24.17,75.91
		c-0.05,0-0.1-0.01-0.15-0.03c-0.18-0.08-0.26-0.29-0.18-0.48l1.46-3.29c0.08-0.18,0.29-0.26,0.48-0.18
		c0.18,0.08,0.26,0.29,0.18,0.48L24.5,75.7C24.44,75.83,24.31,75.91,24.17,75.91z"
      />
      <path
        d="M40,79.64c-0.4,0-0.72-0.32-0.72-0.72v-5.05c0-0.4,0.32-0.72,0.72-0.72s0.72,0.32,0.72,0.72v5.05
		C40.72,79.32,40.4,79.64,40,79.64z"
      />
      <path
        d="M44.07,79.06c-0.18,0-0.34-0.14-0.36-0.32l-0.38-3.58c-0.02-0.2,0.12-0.38,0.32-0.4c0.21-0.03,0.38,0.12,0.4,0.32
		l0.38,3.58c0.02,0.2-0.12,0.38-0.32,0.4C44.1,79.06,44.09,79.06,44.07,79.06z M48.09,78.43c-0.17,0-0.32-0.12-0.35-0.28l-0.75-3.52
		c-0.04-0.19,0.08-0.39,0.28-0.43c0.2-0.05,0.39,0.08,0.43,0.28l0.75,3.52c0.04,0.19-0.08,0.39-0.28,0.43
		C48.14,78.43,48.12,78.43,48.09,78.43z M52.03,77.38c-0.15,0-0.29-0.1-0.34-0.25l-1.12-3.43c-0.06-0.19,0.04-0.39,0.23-0.45
		c0.19-0.06,0.39,0.04,0.45,0.23l1.12,3.43c0.06,0.19-0.04,0.39-0.23,0.45C52.1,77.37,52.07,77.38,52.03,77.38z M55.83,75.91
		c-0.14,0-0.27-0.08-0.33-0.21l-1.46-3.29c-0.08-0.18,0-0.39,0.18-0.48c0.18-0.08,0.39,0,0.48,0.18l1.46,3.29
		c0.08,0.18,0,0.39-0.18,0.48C55.93,75.9,55.88,75.91,55.83,75.91z"
      />
      <path
        d="M59.46,74.43c-0.25,0-0.49-0.13-0.62-0.36l-2.52-4.38c-0.2-0.34-0.08-0.79,0.26-0.98c0.35-0.2,0.79-0.08,0.98,0.26
		l2.52,4.38c0.2,0.34,0.08,0.79-0.26,0.98C59.71,74.4,59.58,74.43,59.46,74.43z"
      />
      <path
        d="M62.88,71.85c-0.11,0-0.22-0.05-0.29-0.15l-2.12-2.92c-0.12-0.16-0.08-0.39,0.08-0.5c0.16-0.11,0.39-0.08,0.5,0.08
		l2.12,2.92c0.12,0.16,0.08,0.39-0.08,0.5C63.02,71.83,62.95,71.85,62.88,71.85z M66.04,69.28c-0.1,0-0.2-0.04-0.27-0.12l-2.41-2.68
		c-0.13-0.15-0.12-0.38,0.03-0.51c0.15-0.13,0.38-0.12,0.51,0.03l2.41,2.68c0.13,0.15,0.12,0.38-0.03,0.51
		C66.21,69.25,66.13,69.28,66.04,69.28z M68.92,66.4c-0.09,0-0.17-0.03-0.24-0.09L66,63.9c-0.15-0.13-0.16-0.36-0.03-0.51
		c0.13-0.15,0.36-0.16,0.51-0.03l2.68,2.41c0.15,0.13,0.16,0.36,0.03,0.51C69.12,66.36,69.02,66.4,68.92,66.4z M71.49,63.24
		c-0.07,0-0.15-0.02-0.21-0.07l-2.92-2.12c-0.16-0.12-0.2-0.34-0.08-0.5c0.12-0.16,0.34-0.2,0.5-0.08l2.92,2.12
		c0.16,0.12,0.2,0.34,0.08,0.5C71.71,63.18,71.6,63.24,71.49,63.24z"
      />
      <path
        d="M73.71,60.18c-0.12,0-0.25-0.03-0.36-0.1l-4.37-2.52c-0.34-0.2-0.46-0.64-0.26-0.98c0.2-0.34,0.64-0.46,0.98-0.26
		l4.37,2.52c0.34,0.2,0.46,0.64,0.26,0.98C74.2,60.05,73.96,60.18,73.71,60.18z"
      />
      <path
        d="M75.55,56.19c-0.05,0-0.1-0.01-0.15-0.03l-3.29-1.46c-0.18-0.08-0.26-0.29-0.18-0.48c0.08-0.18,0.3-0.26,0.48-0.18
		l3.29,1.46c0.18,0.08,0.26,0.29,0.18,0.48C75.82,56.11,75.69,56.19,75.55,56.19z M77.02,52.39c-0.04,0-0.07-0.01-0.11-0.02
		l-3.43-1.12c-0.19-0.06-0.29-0.26-0.23-0.45c0.06-0.19,0.26-0.29,0.45-0.23l3.43,1.12c0.19,0.06,0.29,0.26,0.23,0.45
		C77.31,52.29,77.17,52.39,77.02,52.39z M78.07,48.45c-0.02,0-0.05,0-0.08-0.01l-3.52-0.75c-0.19-0.04-0.32-0.23-0.28-0.43
		c0.04-0.19,0.23-0.32,0.43-0.28l3.52,0.75c0.19,0.04,0.32,0.23,0.28,0.43C78.39,48.34,78.24,48.45,78.07,48.45z M78.7,44.42
		c-0.01,0-0.03,0-0.04,0l-3.58-0.37c-0.2-0.02-0.34-0.2-0.32-0.4c0.02-0.2,0.2-0.35,0.4-0.32l3.58,0.37c0.2,0.02,0.34,0.2,0.32,0.4
		C79.04,44.29,78.89,44.42,78.7,44.42z"
      />
    </g>
    <g id="numbers">
      <g>
        <path
          d="M66.92,42.46c-0.32-0.39-0.48-0.87-0.48-1.43h0.79c0.03,0.39,0.11,0.68,0.22,0.85c0.2,0.32,0.55,0.48,1.07,0.48
			c0.4,0,0.72-0.11,0.96-0.32c0.24-0.21,0.36-0.49,0.36-0.83c0-0.42-0.13-0.71-0.38-0.87c-0.25-0.17-0.61-0.25-1.06-0.25
			c-0.05,0-0.1,0-0.15,0c-0.05,0-0.1,0-0.16,0.01v-0.67c0.08,0.01,0.14,0.01,0.2,0.02c0.05,0,0.11,0,0.17,0
			c0.28,0,0.52-0.04,0.7-0.13c0.32-0.16,0.48-0.44,0.48-0.84c0-0.3-0.11-0.53-0.32-0.7c-0.21-0.16-0.46-0.24-0.75-0.24
			c-0.51,0-0.86,0.17-1.05,0.51c-0.11,0.19-0.17,0.45-0.18,0.79h-0.75c0-0.45,0.09-0.83,0.27-1.15c0.31-0.56,0.85-0.85,1.63-0.85
			c0.62,0,1.09,0.14,1.43,0.41c0.34,0.27,0.51,0.67,0.51,1.19c0,0.37-0.1,0.67-0.3,0.9c-0.12,0.14-0.28,0.26-0.48,0.34
			c0.32,0.09,0.57,0.26,0.75,0.5c0.18,0.25,0.27,0.55,0.27,0.91c0,0.58-0.19,1.05-0.57,1.41s-0.92,0.54-1.62,0.54
			C67.77,43.05,67.25,42.85,66.92,42.46z"
        />
      </g>
      <g>
        <path
          d="M63.08,27.11c0.19-0.39,0.55-0.74,1.1-1.05l0.81-0.47c0.36-0.21,0.62-0.39,0.76-0.54c0.23-0.23,0.35-0.5,0.35-0.8
			c0-0.35-0.11-0.63-0.32-0.84c-0.21-0.21-0.49-0.31-0.84-0.31c-0.52,0-0.88,0.2-1.08,0.59c-0.11,0.21-0.17,0.51-0.18,0.88h-0.77
			c0.01-0.53,0.11-0.96,0.29-1.29c0.33-0.59,0.91-0.88,1.74-0.88c0.69,0,1.2,0.19,1.52,0.56c0.32,0.37,0.48,0.79,0.48,1.25
			c0,0.48-0.17,0.9-0.51,1.24c-0.2,0.2-0.55,0.44-1.06,0.73l-0.58,0.32c-0.28,0.15-0.49,0.3-0.65,0.44
			c-0.28,0.25-0.46,0.52-0.53,0.82h3.3v0.72h-4.15C62.79,27.94,62.9,27.49,63.08,27.11z"
        />
      </g>
      <g>
        <path
          d="M52.65,13.52v-0.58c0.55-0.05,0.93-0.14,1.15-0.27c0.22-0.13,0.38-0.42,0.49-0.89h0.6v6.02h-0.81v-4.28H52.65z"
        />
      </g>
      <g>
        <path
          d="M35.64,11.21v-0.58c0.55-0.05,0.93-0.14,1.15-0.27c0.22-0.13,0.38-0.42,0.49-0.89h0.6v6.02h-0.81v-4.28H35.64z"
        />
        <path
          d="M40.22,14.13c0.19-0.39,0.55-0.74,1.1-1.05l0.81-0.47c0.36-0.21,0.62-0.39,0.76-0.54c0.23-0.23,0.35-0.5,0.35-0.8
			c0-0.35-0.11-0.63-0.32-0.84c-0.21-0.21-0.49-0.31-0.84-0.31c-0.52,0-0.88,0.2-1.08,0.59c-0.11,0.21-0.17,0.51-0.18,0.88h-0.77
			c0.01-0.53,0.11-0.96,0.29-1.29c0.33-0.59,0.91-0.88,1.74-0.88c0.69,0,1.2,0.19,1.52,0.56c0.32,0.37,0.48,0.79,0.48,1.25
			c0,0.48-0.17,0.9-0.51,1.24c-0.2,0.2-0.55,0.44-1.06,0.73l-0.58,0.32c-0.28,0.15-0.49,0.3-0.65,0.44
			c-0.28,0.25-0.46,0.52-0.53,0.82h3.3v0.72H39.9C39.92,14.97,40.03,14.52,40.22,14.13z"
        />
      </g>
      <g>
        <path
          d="M21.22,14.96v-0.58c0.55-0.05,0.93-0.14,1.15-0.27c0.22-0.13,0.38-0.42,0.49-0.89h0.6v6.02h-0.81v-4.28H21.22z"
        />
        <path
          d="M26.03,14.96v-0.58c0.55-0.05,0.93-0.14,1.15-0.27c0.22-0.13,0.38-0.42,0.49-0.89h0.6v6.02h-0.81v-4.28H26.03z"
        />
      </g>
      <g>
        <path
          d="M10.56,25.62v-0.58c0.55-0.05,0.93-0.14,1.15-0.27c0.22-0.13,0.38-0.42,0.49-0.89h0.6v6.02h-0.81v-4.28H10.56z"
        />
        <path
          d="M18.58,24.82c0.27,0.5,0.41,1.18,0.41,2.05c0,0.82-0.12,1.5-0.37,2.04c-0.35,0.77-0.93,1.16-1.74,1.16
			c-0.73,0-1.27-0.32-1.62-0.95c-0.3-0.53-0.44-1.23-0.44-2.12c0-0.69,0.09-1.28,0.27-1.77c0.33-0.92,0.93-1.38,1.8-1.38
			C17.67,23.85,18.23,24.18,18.58,24.82z M17.82,28.85c0.23-0.35,0.35-1,0.35-1.95c0-0.69-0.08-1.25-0.25-1.7
			c-0.17-0.44-0.5-0.66-0.99-0.66c-0.45,0-0.78,0.21-0.98,0.63c-0.21,0.42-0.31,1.04-0.31,1.86c0,0.62,0.07,1.11,0.2,1.49
			c0.2,0.57,0.55,0.86,1.04,0.86C17.27,29.37,17.58,29.2,17.82,28.85z"
        />
      </g>
      <g>
        <path
          d="M9.73,41.41c0.02,0.42,0.18,0.71,0.49,0.87c0.15,0.08,0.33,0.13,0.52,0.13c0.36,0,0.67-0.15,0.93-0.45
			c0.26-0.3,0.44-0.92,0.54-1.84c-0.17,0.27-0.38,0.46-0.63,0.56c-0.25,0.11-0.52,0.16-0.8,0.16c-0.58,0-1.04-0.18-1.38-0.54
			c-0.34-0.36-0.51-0.83-0.51-1.4c0-0.55,0.17-1.03,0.5-1.45c0.34-0.42,0.83-0.62,1.48-0.62c0.88,0,1.49,0.4,1.82,1.19
			c0.19,0.44,0.28,0.98,0.28,1.64c0,0.74-0.11,1.4-0.33,1.97c-0.37,0.95-0.99,1.43-1.88,1.43c-0.59,0-1.04-0.16-1.35-0.46
			c-0.31-0.31-0.46-0.7-0.46-1.17H9.73z M11.71,39.87c0.25-0.2,0.37-0.54,0.37-1.04c0-0.44-0.11-0.78-0.34-0.99
			c-0.22-0.22-0.51-0.33-0.86-0.33c-0.37,0-0.67,0.12-0.88,0.37c-0.22,0.25-0.33,0.58-0.33,1c0,0.39,0.1,0.71,0.29,0.94
			c0.19,0.23,0.5,0.35,0.92,0.35C11.19,40.17,11.46,40.07,11.71,39.87z"
        />
      </g>
      <g>
        <path
          d="M13.15,53.75c-0.22-0.23-0.33-0.52-0.33-0.88c0-0.45,0.16-0.84,0.49-1.16c0.33-0.32,0.79-0.49,1.39-0.49
			c0.58,0,1.03,0.15,1.36,0.46c0.33,0.31,0.49,0.66,0.49,1.07c0,0.38-0.1,0.68-0.29,0.92c-0.11,0.13-0.27,0.26-0.5,0.39
			c0.25,0.12,0.45,0.25,0.59,0.4c0.27,0.28,0.4,0.65,0.4,1.1c0,0.53-0.18,0.98-0.54,1.35c-0.36,0.37-0.86,0.56-1.52,0.56
			c-0.59,0-1.09-0.16-1.49-0.48c-0.41-0.32-0.61-0.78-0.61-1.39c0-0.36,0.09-0.67,0.26-0.93c0.17-0.26,0.43-0.46,0.78-0.6
			C13.43,53.98,13.27,53.87,13.15,53.75z M15.6,56.49c0.23-0.19,0.35-0.47,0.35-0.85c0-0.39-0.12-0.69-0.36-0.89
			c-0.24-0.2-0.55-0.3-0.92-0.3c-0.36,0-0.66,0.1-0.89,0.31c-0.23,0.21-0.34,0.49-0.34,0.86c0,0.32,0.1,0.59,0.31,0.82
			c0.21,0.23,0.53,0.34,0.97,0.34C15.08,56.77,15.37,56.68,15.6,56.49z M15.46,53.5c0.19-0.19,0.28-0.41,0.28-0.67
			c0-0.23-0.09-0.43-0.27-0.62c-0.18-0.19-0.45-0.28-0.82-0.28c-0.37,0-0.63,0.09-0.79,0.28c-0.16,0.19-0.24,0.41-0.24,0.66
			c0,0.28,0.11,0.51,0.32,0.67s0.46,0.24,0.75,0.24C15.01,53.78,15.27,53.68,15.46,53.5z"
        />
      </g>
      <g>
        <path
          d="M27.51,62.01v0.66c-0.19,0.19-0.45,0.52-0.78,0.98c-0.32,0.47-0.61,0.97-0.85,1.51c-0.25,0.53-0.43,1.01-0.56,1.44
			c-0.08,0.28-0.19,0.73-0.32,1.35h-0.84c0.19-1.15,0.61-2.3,1.27-3.45c0.39-0.67,0.79-1.25,1.22-1.74h-3.34v-0.76H27.51z"
        />
      </g>
      <g>
        <path
          d="M41.35,66.15c0.27,0.35,0.4,0.71,0.4,1.08H41c-0.04-0.24-0.12-0.43-0.22-0.56c-0.18-0.25-0.46-0.38-0.83-0.38
			c-0.42,0-0.76,0.2-1.01,0.59c-0.25,0.39-0.39,0.96-0.42,1.69c0.17-0.26,0.39-0.45,0.66-0.57c0.24-0.11,0.51-0.17,0.81-0.17
			c0.51,0,0.95,0.16,1.33,0.49c0.38,0.32,0.57,0.81,0.57,1.45c0,0.55-0.18,1.04-0.54,1.46s-0.87,0.64-1.53,0.64
			c-0.57,0-1.05-0.21-1.46-0.64c-0.41-0.43-0.62-1.15-0.62-2.17c0-0.75,0.09-1.39,0.27-1.91c0.35-1,1-1.5,1.93-1.5
			C40.61,65.63,41.08,65.8,41.35,66.15z M40.78,70.78c0.2-0.27,0.3-0.59,0.3-0.95c0-0.31-0.09-0.6-0.27-0.88
			c-0.18-0.28-0.5-0.42-0.97-0.42c-0.33,0-0.61,0.11-0.86,0.33s-0.37,0.54-0.37,0.98c0,0.38,0.11,0.7,0.34,0.96
			c0.22,0.26,0.53,0.39,0.93,0.39C40.28,71.18,40.58,71.05,40.78,70.78z"
        />
      </g>
      <g>
        <path
          d="M52.89,66.41c0.05,0.43,0.25,0.73,0.6,0.9c0.18,0.08,0.39,0.13,0.62,0.13c0.45,0,0.78-0.14,1-0.43
			c0.22-0.29,0.33-0.61,0.33-0.96c0-0.42-0.13-0.75-0.39-0.98c-0.26-0.23-0.57-0.35-0.93-0.35c-0.26,0-0.49,0.05-0.67,0.15
			c-0.19,0.1-0.35,0.24-0.48,0.42l-0.66-0.04l0.46-3.26h3.14v0.73h-2.57l-0.26,1.68c0.14-0.11,0.27-0.19,0.4-0.24
			c0.23-0.09,0.49-0.14,0.78-0.14c0.55,0,1.03,0.18,1.41,0.54c0.39,0.36,0.58,0.81,0.58,1.36c0,0.57-0.18,1.08-0.53,1.51
			s-0.92,0.65-1.69,0.65c-0.49,0-0.93-0.14-1.31-0.42c-0.38-0.28-0.59-0.7-0.64-1.28H52.89z"
        />
      </g>
      <g>
        <path
          d="M65.36,57.29v-1.48h-2.65v-0.74l2.77-3.84h0.64v3.92h0.89v0.66h-0.89v1.48H65.36z M65.35,55.15V52.4l-1.94,2.75H65.35z"
        />
      </g>
    </g>
    <polygon
      id="hourHand"
      points="41.4,40.77 40.31,19.75 39.71,19.75 38.6,40.75 "
      :style="handStyle('hour')"
    />
    <polygon
      id="minuteHand"
      points="40.49,9.42 39.51,9.42 38.78,40 41.22,40 "
      :style="handStyle('minutes')"
    />
    <circle id="middle" cx="40" cy="40" r="1.77" />
  </svg>
</template>

<script>
export default {
  props: {
    hour: { type: [Number, String], default: 12 },
    minutes: { type: [Number, String], default: 60 }
  },
  computed: {
    castHour() {
      return typeof this.hour === 'string' ? parseInt(this.hour) : this.hour
    },
    castMinutes() {
      return typeof this.minutes === 'string'
        ? parseInt(this.minutes)
        : this.minutes
    },
    hourHandAngle() {
      const hour = this.castHour > 12 ? this.castHour - 12 : this.castHour
      return (360 / 12) * hour + this.castMinutes * 0.5
    },
    minuteHandAngle() {
      const minutes = this.castMinutes > 60 ? 60 : this.castMinutes
      return (360 / 60) * minutes
    }
  },
  methods: {
    handStyle(hand) {
      if (hand === 'minutes') {
        return {
          'transform-origin': 'center center',
          transform: `rotate(${this.minuteHandAngle}deg`
        }
      }
      return {
        'transform-origin': 'center center',
        transform: `rotate(${this.hourHandAngle}deg`
      }
    }
  }
}
</script>
