import Coordinate from './Coordinate'

export default class Rectangle {
  /**
   * @param {Number} x
   * @param {Number} y
   * @param {Number} width
   * @param {Number} height
   */
  constructor(x, y, width, height) {
    this.x = x
    this.y = y
    this.width = width
    this.height = height
  }

  /**
   * @param {DOMRect} domRect
   */
  static fromDOMRect(domRect) {
    return new Rectangle(
      domRect.left,
      domRect.top,
      domRect.width,
      domRect.height
    )
  }

  static fromData(data) {
    return new Rectangle(data.x, data.y, data.width, data.height)
  }

  static fromString(string) {
    const data = string.split(',')
    const topLeft = new Coordinate(data[0], data[1])
    const bottomRight = new Coordinate(data[2], data[3])
    const width = topLeft.x - bottomRight.x
    const height = bottomRight.y - topLeft.y

    return new Rectangle(topLeft.x, topLeft.y, width, height)
  }

  getCenter() {
    return new Coordinate(this.x + this.width * 0.5, this.y + this.height * 0.5)
  }

  getPosition() {
    return new Coordinate(this.x, this.y)
  }

  getShortestSide() {
    return Math.min(this.width, this.height)
  }

  /**
   * @param {Rectangle} rectangle
   */
  collisionWith(rectangle) {
    return (
      this.x + this.width >= rectangle.x &&
      this.x <= rectangle.x + rectangle.width &&
      this.y + this.height >= rectangle.y &&
      this.y <= rectangle.y + rectangle.height
    )
  }

  /**
   * @param {Coordinate} point
   */
  percentualPosition(point) {
    // if (!this.contains(point)) {
    //   throw new Error(
    //     `rect ${this.toString()} does not contain point ${point.toString()}: cannot calculate percental position`
    //   )
    // }
    return new Coordinate(
      Math.round((point.x / this.width) * 100),
      Math.round((point.y / this.height) * 100)
    )
  }

  /**
   * @param {Rectangle} rectangle
   */
  relativeTo(rectangle) {
    const position = this.getPosition().subtract(rectangle.getPosition())
    return new Rectangle(
      (position.x / rectangle.width) * 100,
      (position.y / rectangle.height) * 100,
      (this.width / rectangle.width) * 100,
      (this.height / rectangle.height) * 100
    )
  }

  /**
   * @param {Coordinate} point
   */
  contains(point) {
    return (
      this.x <= point.x &&
      point.x + this.width &&
      this.y <= point.y &&
      point.y <= this.y + this.height
    )
  }
}
