<template>
  <Button @click="onClick"><slot /></Button>
</template>

<script>
import Button from './Button.vue'

export default {
  components: { Button },
  props: {
    to: { type: String, required: true }
  },
  methods: {
    onClick() {
      this.$router.push({ path: this.to })
    }
  }
}
</script>
