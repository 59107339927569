<template>
  <div
    class="relative flex gap-5"
    :style="{
      'flex-direction': direction === 'backward' ? 'row-reverse' : 'row'
    }"
  >
    <div
      class="bg-gray"
      style="
        content: '';
        position: absolute;
        top: 3em;
        left: 0;
        right: 0;
        height: 0.1em;
      "
    />
    <div class="jump-box">
      {{ direction === 'backward' ? '-' : '+' }}{{ formatNumber(jump) }}
    </div>

    <svg
      :viewBox="`0 0 100 50`"
      class="absolute text-primary"
      style="width: 5.5em; transform: translateX(-50%); left: 50%; top: 0.5em"
    >
      <defs>
        <marker
          id="arrowhead"
          markerWidth="2.5"
          markerHeight="4"
          refX="1.5"
          refY="2"
          orient="auto"
          fill="currentColor"
        >
          <polygon points="0 0, 2.5 2, 0 4" />
        </marker>
      </defs>
      <g>
        <path
          :d="arrowPath"
          fill="none"
          stroke="currentColor"
          stroke-width="3"
          marker-end="url(#arrowhead)"
        ></path>
      </g>
    </svg>

    <div class="number-box">
      <div class="number-box-line" />
      {{ formatNumber(start) }}
    </div>
    <div class="number-box">
      <div class="number-box-line" />
      <NumberInput
        :id="id"
        :has-focus="hasFocus"
        :input-state="inputState"
        :input-mode="inputMode"
        :value="value"
        :input-timeout="inputTimeout"
        :expected-length="expectedLength"
        @input="onInput"
        @input-ready="onInputReady"
        @focus="onFocus"
        @keyboard-input="$emit('keyboard-input')"
      />
    </div>
  </div>
</template>

<script>
import NumberInput from '../../../input/NumberInput'
import InputState from '../../../../models/InputState'
import InputMode from '../../../../models/InputMode'
import { formatNumber } from 'shared'

export default {
  components: { NumberInput },
  props: {
    id: { type: String, required: true },
    value: { type: String, required: true },
    hasFocus: { type: Boolean, required: true },
    inputState: { type: InputState, required: true },
    inputMode: { type: InputMode, default: () => InputMode.createNone() },
    inputTimeout: { type: Number, default: 0 },
    direction: { type: String, required: true },
    start: { type: Number, required: true },
    jump: { type: Number, required: true },
    end: { type: Number, required: true }
  },
  computed: {
    arrowPath() {
      if (this.direction === 'forward') {
        return 'M 20 40 C 33 25 66 25 80 40'
      } else {
        return 'M 80 40 C 66 25 33 25 20 40'
      }
    },
    expectedLength() {
      return this.end.toString().length
    }
  },
  methods: {
    formatNumber,
    onInput(value) {
      this.$emit('input', value)
    },
    onInputReady() {
      this.$emit('input-ready')
    },
    onFocus() {
      this.$emit('focus')
    }
  }
}
</script>

<style lang="scss">
.jump-box {
  @apply absolute flex justify-center items-center border-2 border-gray rounded px-2;
  border-width: 0.1em;
  min-height: 2em;
  min-width: 3em;
  font-size: 0.8em;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.number-box {
  @apply relative flex justify-center items-center border-2 border-gray rounded px-2;
  border-width: 0.1em;
  margin-top: 4em;
  min-height: 3em;
  min-width: 3em;
  font-size: 1em;
}

.number-box-line {
  @apply bg-gray;
  content: '';
  position: absolute;
  top: -1em;
  left: 50%;
  width: 0.1em;
  height: 1em;
  transform: translateX(-50%);
}
</style>
