import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _04a37a74 = () => interopDefault(import('../pages/maintenance-mode.vue' /* webpackChunkName: "pages/maintenance-mode" */))
const _3b2dbf9e = () => interopDefault(import('../pages/no-license.vue' /* webpackChunkName: "pages/no-license" */))
const _b4db6094 = () => interopDefault(import('../pages/wall/index.vue' /* webpackChunkName: "pages/wall/index" */))
const _3ed78879 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _794157e7 = () => interopDefault(import('../pages/item/_id.vue' /* webpackChunkName: "pages/item/_id" */))
const _1734ff4e = () => interopDefault(import('../pages/task/_id.vue' /* webpackChunkName: "pages/task/_id" */))
const _55b41eca = () => interopDefault(import('../pages/test/_testId/index.vue' /* webpackChunkName: "pages/test/_testId/index" */))
const _1559f328 = () => interopDefault(import('../pages/test/_testId/instructions.vue' /* webpackChunkName: "pages/test/_testId/instructions" */))
const _3939d135 = () => interopDefault(import('../pages/test/_testId/intro.vue' /* webpackChunkName: "pages/test/_testId/intro" */))
const _1eec7064 = () => interopDefault(import('../pages/test/_testId/test-details.vue' /* webpackChunkName: "pages/test/_testId/test-details" */))
const _4f8b265f = () => interopDefault(import('../pages/test/_testId/task/_taskId/index.vue' /* webpackChunkName: "pages/test/_testId/task/_taskId/index" */))
const _55db9ee0 = () => interopDefault(import('../pages/test/_testId/task/_taskId/result.vue' /* webpackChunkName: "pages/test/_testId/task/_taskId/result" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/maintenance-mode",
    component: _04a37a74,
    name: "maintenance-mode"
  }, {
    path: "/no-license",
    component: _3b2dbf9e,
    name: "no-license"
  }, {
    path: "/wall",
    component: _b4db6094,
    name: "wall"
  }, {
    path: "/",
    component: _3ed78879,
    name: "index"
  }, {
    path: "/item/:id?",
    component: _794157e7,
    name: "item-id"
  }, {
    path: "/task/:id?",
    component: _1734ff4e,
    name: "task-id"
  }, {
    path: "/test/:testId",
    component: _55b41eca,
    name: "test-testId"
  }, {
    path: "/test/:testId?/instructions",
    component: _1559f328,
    name: "test-testId-instructions"
  }, {
    path: "/test/:testId?/intro",
    component: _3939d135,
    name: "test-testId-intro"
  }, {
    path: "/test/:testId?/test-details",
    component: _1eec7064,
    name: "test-testId-test-details"
  }, {
    path: "/test/:testId?/task/:taskId",
    component: _4f8b265f,
    name: "test-testId-task-taskId"
  }, {
    path: "/test/:testId?/task/:taskId?/result",
    component: _55db9ee0,
    name: "test-testId-task-taskId-result"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
