export default class Coordinate {
  /**
   * @param {Number} x
   * @param {Number} y
   */
  constructor(x, y) {
    this.x = x
    this.y = y
  }

  distanceSq(coordinate) {
    var dx = coordinate.x - this.x
    var dy = coordinate.y - this.y

    return dx * dx + dy * dy
  }

  subtract(coordinate) {
    return new Coordinate(this.x - coordinate.x, this.y - coordinate.y)
  }

  toString() {
    return `${this.x} ${this.y}`
  }

  static fromEvent(event) {
    if (event.type.indexOf('touch') === 0) {
      return new Coordinate(
        event.targetTouches[0].clientX,
        event.targetTouches[0].clientY
      )
    }
    return new Coordinate(event.clientX, event.clientY)
  }

  static fromEventRelative(event) {
    if (event.type.indexOf('touch') === 0) {
      return new Coordinate(
        event.targetTouches[0].offsetX,
        event.targetTouches[0].offsetY
      )
    }
    return new Coordinate(event.offsetX, event.offsetY)
  }

  static fromString(string) {
    const coordinates = string.split(' ')
    if (coordinates.length !== 2) {
      throw new Error(`cannot create coordinate from string ${string}`)
    }
    return new Coordinate(
      parseFloat(coordinates[0]),
      parseFloat(coordinates[1])
    )
  }
}
