<template>
  <div class="relative flex flex-row gap-2">
    <div
      class="bg-gray"
      style="
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 0.1em;
      "
    />
    <div v-for="(label, index) in castLabels" :key="index" class="sequence-box">
      <div
        class="bg-gray"
        style="
          content: '';
          position: absolute;
          top: -2em;
          left: 50%;
          width: 0.1em;
          height: 2em;
          transform: translateX(-50%);
        "
      />
      <slot :name="'label-' + index">
        <span>{{ formatNumber(label) }}</span>
      </slot>
    </div>
  </div>
</template>

<script>
import { formatNumber } from 'shared'

export default {
  props: {
    labels: { type: [Array, String], required: true }
  },
  data() {
    return {
      newSelection: this.selectionData?.selection || null,
      rectangle: null
    }
  },
  computed: {
    castLabels() {
      return typeof this.labels === 'string'
        ? this.labels.split(',')
        : this.labels
    },
    containerWidth() {
      return this.castLabels.length * 3 + 'em'
    }
  },
  created() {
    this.updateSlots()
  },
  beforeUpdate() {
    this.updateSlots()
  },
  async mounted() {
    this.updateSlots()
    await this.$nextTick()
  },
  methods: {
    formatNumber,
    updateSlots() {
      if (!this.$slots.default) {
        return
      }
      this.$slots.default.forEach((component) => {
        if (
          component.data &&
          component.data.attrs &&
          component.data.attrs['data-label-index']
        ) {
          this.$slots['label-' + component.data.attrs['data-label-index']] = [
            component
          ]
        }
      })
    }
  }
}
</script>

<style lang="scss">
.sequence-box {
  @apply relative flex justify-center items-center border-2 border-gray rounded px-2;
  border-width: 0.1em;
  margin-top: 2em;
  min-height: 3em;
  min-width: 3em;
  font-size: 1em;
}
</style>
