import Coordinate from './geometry/Coordinate'

function arraysHaveIdenticalElements(arrayOne, arrayTwo) {
  if (arrayOne.length !== arrayTwo.length) {
    return false
  }
  return !arrayOne.some((item) => {
    return !arrayTwo.includes(item)
  })
}

class Response {
  /**
   * @param {string} id
   * @param {string} value
   * @param {InputState} inputState
   * @param {string} cardinality
   * @param {string} baseType
   * @param {CorrectResponse} correctResponse
   * @param {AreaMapping} areaMapping
   * @param {(string) => string} handleSpeechInput
   * @param {Array<InputMode>} supportedInputModes
   */
  constructor(
    id,
    value,
    inputState,
    cardinality,
    baseType,
    correctResponse,
    areaMapping,
    handleSpeechInput,
    supportedInputModes
  ) {
    this.id = id
    this.value = value
    this.inputState = inputState
    this.baseType = baseType
    this.cardinality = cardinality
    this.correctResponse = correctResponse
    this.areaMapping = areaMapping
    this.handleSpeechInput = handleSpeechInput
    this.supportedInputModes = supportedInputModes
    this.hasFocus = false
  }

  isFloat() {
    return this.baseType === 'float'
  }

  isInteger() {
    return this.baseType === 'integer'
  }

  isString() {
    return this.baseType === 'string'
  }

  isIdentifier() {
    return this.baseType === 'identifier'
  }

  isPoint() {
    return this.baseType === 'point'
  }

  isCardinalitySingle() {
    return this.cardinality === 'single'
  }

  isCardinalityMultiple() {
    return this.cardinality === 'multiple'
  }

  isCardinalityOrdered() {
    return this.cardinality === 'ordered'
  }

  get correctValue() {
    if (this.isCardinalityOrdered()) {
      return this.getCorrectValues().join(',')
    }
    return this.getSingleCorrectValue()
  }

  getSingleCorrectValue() {
    if (!this.isCardinalitySingle()) {
      throw new Error(
        `cannot get single correct value: cardinality is ${this.cardinality}`
      )
    }
    return this.correctResponse.getFirstCorrectValue()
  }

  getCorrectValues() {
    if (this.isCardinalitySingle()) {
      throw new Error(
        `cannot get set of correct value, response has cardinality single`
      )
    }
    return this.correctResponse.correctValues
  }

  /**
   * @param {string} value
   */
  mapResponsePoint(value) {
    const point = Coordinate.fromString(value)
    if (!this.areaMapping) {
      return null
    }
    if (this.areaMapping.shape.contains(point)) {
      return parseFloat(this.areaMapping.mappedValue)
    }
    return parseFloat(this.areaMapping.defaultValue)
  }

  parseFloat(value) {
    return parseFloat(value.replace(/\./g, '').replace(/,/g, '.'))
  }

  parseInt(value) {
    return parseInt(value.replace(/\./g, '').replace(/,/g, '.'))
  }

  /**
   * @param {string} value
   * @param {string} correctValue
   * @returns {boolean}
   */
  matchCorrectValue(value, correctValue) {
    if (this.isFloat()) {
      const regexp = new RegExp(
        /^([0-9]{1,3}(\.[0-9]{3})*|[1-9]*[0-9]+)(,[0-9]+|)$/
      )
      return (
        regexp.test(value) &&
        this.parseFloat(correctValue) === this.parseFloat(value)
      )
    }
    if (this.isInteger()) {
      const regexp = new RegExp(
        /^([0-9]{1,3}(\.[0-9]{3})*|[1-9]*[0-9]+)(,0+|)$/
      )
      return (
        regexp.test(value) &&
        this.parseFloat(correctValue) === this.parseFloat(value)
      )
    }
    if (this.isPoint()) {
      return this.mapResponsePoint(value) === 1
    }
    return correctValue === value
  }

  isCorrect() {
    return this.matchCorrectResponse(this.value)
  }

  /**
   * @param {string} responseValue
   * @returns {boolean}
   */
  matchCorrectResponse(responseValue) {
    if (this.isCardinalitySingle()) {
      return this.matchCorrectValue(responseValue, this.getSingleCorrectValue())
    }
    if (this.isCardinalityOrdered()) {
      const responseValues = responseValue.split(',')
      if (responseValues.length !== this.getCorrectValues().length) {
        return false
      }
      return responseValues.every((value, index) =>
        this.matchCorrectValue(value, this.getCorrectValues()[index])
      )
    }
    if (this.isCardinalityMultiple()) {
      return arraysHaveIdenticalElements(
        responseValue.split(','),
        this.getCorrectValues()
      )
    }
    throw new Error(`cardinality "${this.cardinality}" not supported`)
  }
}

export { Response }
