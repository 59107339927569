<template>
  <div class="message-bar" :class="classObject">
    <component :is="iconComponent" class="message-bar-icon flex-none mr-3" />
    <div class="flex-auto">
      <slot />
    </div>
  </div>
</template>

<script>
import { IconInfo, IconExclamation, IconError } from './icons'

export default {
  props: {
    type: { type: String, default: 'info' },
    size: { type: String, default: 'medium' }
  },
  computed: {
    classObject() {
      return `message-bar--${this.type} message-bar--${this.size}`
    },
    iconComponent() {
      if (this.type === 'error') {
        return IconError
      }
      if (this.type === 'warning') {
        return IconExclamation
      }
      return IconInfo
    }
  }
}
</script>

<style lang="scss" scoped>
.message-bar {
  @apply flex flex-row rounded-xl items-center;
}
.message-bar--small {
  @apply p-3;
  font-size: 0.9em;
  .message-bar-icon {
    font-size: 2rem;
  }
}
.message-bar--medium {
  @apply p-4;
  font-size: 1em;
  .message-bar-icon {
    font-size: 2.5rem;
  }
}
.message-bar--large {
  @apply p-6;
  font-size: 1.1em;
  .message-bar-icon {
    font-size: 3rem;
  }
}
.message-bar--info {
  @apply bg-blue-light text-blue-dark;
}
.message-bar--warning {
  @apply bg-orange-light text-orange-dark;
}
.message-bar--error {
  @apply bg-red-light text-red-dark;
}
</style>
