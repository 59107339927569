<template>
  <IconBase
    :width="width"
    :height="height"
    viewBox="600 600 1466.6667 1466.6667"
    fill="currentColor"
    stroke="none"
  >
    <g id="g8" transform="matrix(1.333333,0,0,-1.3333333,0,2666.6667)">
      <g id="g10" transform="scale(0.1)">
        <path
          id="path36"
          d="m 8390.68,7644.7 c -0.4,0.4 -0.4,0.4 -0.79,0.8 -4.74,3.6 -9.09,7.1 -13.44,11.5 l -721.55,721.5 c -3.95,3.9 -7.9,8.3 -10.67,13 -0.79,0.4 -0.79,0.4 -0.79,0.8 L 6255.27,9780.1 c -60.46,60.5 -131.98,104.7 -196.78,121.3 -17.39,4.3 -33.99,6.7 -48.21,6.7 -18.97,0 -33.99,-4.3 -41.89,-12.2 L 5241.7,9169.6 c -13.43,-13.8 -15.41,-49 -5.13,-90.1 16.59,-64.8 60.46,-136.3 120.92,-196.8 l 408.98,-409 c 18.17,-15 35.96,-31.2 53.34,-48.6 L 8423.08,5821.5 c 15.81,-15.8 31.22,-32.4 45.05,-49.4 l 412.54,-412.6 c 60.46,-60.4 131.98,-104.3 196.79,-120.9 41.09,-10.7 76.65,-8.3 90.09,5.1 l 726.68,726.7 c 30.04,30 9.09,162.1 -115.77,286.9 L 8390.68,7644.7"
        />
        <path
          id="path38"
          d="m 8297.42,5606.9 c -0.79,0.8 -1.58,1.6 -2.37,2.8 L 5608.41,8296.3 c -110.65,90.1 -216.94,103.5 -243.41,76.6 l -726.3,-726.2 c -30.42,-30.1 -9.48,-162 115.78,-287.3 L 7357.75,4756.1 c 124.87,-124.8 256.85,-145.8 286.88,-115.8 l 726.29,726.7 c 13.83,13.5 15.8,49.1 5.53,90.1 -12.65,48.6 -40.3,101.2 -79.03,149.8"
        />
        <path
          id="path40"
          d="m 14641.6,11120.8 -412.5,412.6 c -17,13.8 -33.6,29.3 -49.4,45 l -2603.3,2603.3 c -15.8,15.8 -31.2,32.4 -45.1,49 -0.7,0.8 -1.1,1.2 -1.5,2 l -411,410.9 c -124.9,124.9 -256.8,145.9 -287.3,115.8 l -726.3,-726.3 c -30,-30.4 -9,-162.4 115.8,-287.2 l 3522.8,-3522.8 c 125.3,-124.9 256.9,-146.2 287.3,-115.8 l 726.3,726.3 c 13.8,13.8 15.8,49 5.1,90.5 -16.2,64.4 -60.4,136.3 -120.9,196.7"
        />
        <path
          id="path42"
          d="m 15365.9,12447 c -16.2,64.4 -60.4,136.3 -120.9,196.8 L 12641.7,15247 c -60.4,60.5 -132.3,104.4 -196.8,121 -41.1,10.2 -76.6,8.3 -90.4,-5.2 l -726.3,-726.3 c -26.5,-26.8 -13.5,-133.1 76.6,-243.8 l 2686.7,-2686.6 c 49.8,-39.9 103.1,-68.8 152.5,-81 41.1,-10.7 76.7,-8.7 90.5,5.1 l 726.3,726.3 c 13.8,13.8 15.8,49.4 5.1,90.5"
        />
        <path
          id="path44"
          d="m 7907.41,8463.4 554,-553.9 3630.69,3630.2 -554.4,554 -3630.29,-3630.3"
        />
      </g>
    </g>
  </IconBase>
</template>

<script>
import IconBase from './IconBase.vue'

export default {
  components: { IconBase },
  props: {
    width: { type: String, default: 'auto' },
    height: { type: String, default: '1em' }
  }
}
</script>
