<template>
  <div style="display: contents">
    <div
      v-if="phaseLabels !== 'off'"
      :key="phase.name + '-title'"
      class="bareka-header"
      :class="'wall2021-layer' + phase.number + '-title'"
    >
      <div v-if="phaseLabels === 'normal'" class="font-weight-500">
        {{ phase.name }}
      </div>
      <template v-else-if="phaseLabels === 'small'">
        {{ phase.number }}
      </template>
    </div>
    <div
      :key="phase.name"
      :class="'wall2021-layer' + phase.number + '-content'"
    >
      <template v-for="row in phase.rows">
        <div v-for="brick in row" :key="brick.id" :class="getBrickClass(brick)">
          <slot
            name="brick"
            :brick="brick"
            :get-content-class="getContentClass"
            :get-background-class="getBackGroundClass"
          >
            <div :class="getContentClass(brick)">
              <div class="bareka-brick-overlay">
                <div
                  v-if="false && brickChanged"
                  class="rounded-4xl bg-red-dark text-white absolute px-2 py-1 text-sm"
                  style="
                    right: -2px;
                    top: -5px;
                    min-width: 15px;
                    min-height: 15px;
                  "
                />
                {{ brick.name }}
              </div>
            </div>
          </slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    phase: { type: Object, required: true },
    phaseLabels: { type: String, default: 'normal' },
    referenceDate: { type: String, default: null }
  },
  methods: {
    getValue(brick) {
      return brick.userResults &&
        brick.userResults[0] &&
        typeof brick.userResults[0].powerValue === 'number'
        ? brick.userResults[0].powerValue
        : null
    },
    getSkipped(brick) {
      return (
        brick.userResults &&
        brick.userResults[0] &&
        brick.userResults[0].skipped
      )
    },
    getColorBrick(brick) {
      return (
        brick.userResults &&
        brick.userResults[0] &&
        brick.userResults[0].enoughResults
      )
    },
    convertScoreToValue(score) {
      if (score >= 0.8) return 2
      if (score >= 0.6) return 1
      return 0
    },
    brickChanged(brick) {
      if (
        !this.referenceDate ||
        !brick.userResults ||
        !brick.userResults[0].datetime
      ) {
        return false
      }
      return (
        new Date(brick.userResults[0].datetime).toISOString().split('T')[0] ===
        new Date(this.referenceDate).toISOString().split('T')[0]
      )
    },
    getBackGroundClass(brick) {
      const value = this.getValue(brick)
      if (value === 0) {
        return 'bg-score-red'
      } else if (value === 1) {
        return 'bg-score-orange'
      } else if (value === 2) {
        if (brick.userResults[0].speedAchieved) {
          return 'bg-score-darkgreen'
        } else {
          return 'bg-score-green'
        }
      }
      if (this.getSkipped(brick)) {
        return 'bg-score-skipped'
      }
    },
    getContentClass(brick) {
      let classes = 'bareka-brick-content'
      if (brick.speed !== null) {
        classes = classes + ' font-weight-bold'
      }
      const value = this.getValue(brick)
      const color = this.getColorBrick(brick)
      let border = false
      if (color) {
        classes = classes + ' ' + this.getBackGroundClass(brick)
      } else {
        classes = classes + ' bg-white'
      }
      if (!color) {
        if (value !== null) {
          border = true
          classes = classes + ' bareka-border'
        }
        if (value === 0) {
          classes = classes + ' border-score-red'
        } else if (value === 1) {
          classes = classes + ' border-score-orange'
        } else if (value === 2) {
          if (brick.userResults[0].speedAchieved) {
            classes = classes + ' border-score-darkgreen'
          } else {
            classes = classes + ' border-score-green'
          }
        } else if (this.getSkipped(brick)) {
          classes = classes + ' bareka-border border-score-skipped'
        } else {
          classes = classes + ' border-2 border-gray'
        }
      }
      if (brick.speed !== null) {
        classes = classes + ' bareka-brick--speed'
      }
      if (!border) {
        classes = classes + ' bareka-padding'
      }
      if (this.brickChanged(brick)) {
        classes += ' highlight-pulse'
      }
      return classes
    },
    getBrickClass(brick) {
      return 'wall2021-cell bareka-brick r' + brick.id
    }
  }
}
</script>

<style lang="scss">
.bareka-header {
  @apply bg-gray-light border-gray border-2 rounded;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
}

.bareka-brick {
  position: relative;
  display: flex;
  background-clip: border-box;
  overflow: visible;
}

.bareka-brick-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0.6em;
  align-items: center;
  justify-content: center;
  display: flex;
}

.bareka-brick--speed {
  .bareka-brick-overlay {
    background: repeating-linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.05) 5px,
      transparent 5px,
      transparent 10px
    );
  }
}

.bareka-border {
  border-width: 0.6em;
  .bareka-brick-overlay {
    padding: 0 !important;
  }
}

.bareka-padding {
  padding: 0.6em;
  .bareka-brick-overlay {
    padding: 0 !important;
  }
}

.bareka-brick-content {
  @apply flex flex-1 rounded;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  background-clip: border-box;
  overflow: visible;
  button {
    color: inherit;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 100%;
    height: 100%;
    font-weight: 600;
  }
  button:focus {
    font-weight: bold;
  }
}

.bareka-brick--highlight {
  font-weight: bold;
  z-index: 31;
}

.table-darkgreen {
  background-color: #68ae94;
  color: #fff;
}

.wall2021-layer1-title {
  grid-area: layer1-title;
}
.wall2021-layer2-title {
  grid-area: layer2-title;
}
.wall2021-layer3-title {
  grid-area: layer3-title;
}
.wall2021-layer4-title {
  grid-area: layer4-title;
}
.wall2021-layer5-title {
  grid-area: layer5-title;
}

.wall2021-layer1-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.3rem;
  grid-template-areas:
    'r31 r31 r31 r32 r32 r32 r32 r33 r33 r33 r33 r34 r34 r34'
    'r21 r21 r22 r22 r23 r23 r12 r12 r24 r24 r25 r25 r26 r26'
    'r11 r11 r11 r11 r11 r11 r12 r12 r13 r13 r13 r13 r13 r13';
  grid-area: layer1-content;
}

.r21 {
  grid-area: r21;
}
.r22 {
  grid-area: r22;
}
.r23 {
  grid-area: r23;
}
.r11 {
  grid-area: r11;
}
.r12 {
  grid-area: r12;
}
.r24 {
  grid-area: r24;
}
.r25 {
  grid-area: r25;
}
.r13 {
  grid-area: r13;
}
.r26 {
  grid-area: r26;
}
.r31 {
  grid-area: r31;
}
.r32 {
  grid-area: r32;
}
.r33 {
  grid-area: r33;
}
.r34 {
  grid-area: r34;
}

.wall2021-layer2-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.3rem;
  grid-template-areas:
    'r61 r61 r62 r62 r63 r63 r63 r63 r64 r64 r65 r65 r65 r65 r66 r66 r67 r67'
    'r51 r51 r52 r52 r53 r53 r54 r54 r54 r55 r55 r55 r56 r56 r57 r57 r58 r58'
    'r41 r41 r41 r41 r41 r41 r41 r41 r41 r41 r41 r41 r41 r41 r41 r41 r41 r41';
  grid-area: layer2-content;
}

.r41 {
  grid-area: r41;
}
.r51 {
  grid-area: r51;
}
.r52 {
  grid-area: r52;
}
.r53 {
  grid-area: r53;
}
.r58 {
  grid-area: r58;
}
.r57 {
  grid-area: r57;
}
.r56 {
  grid-area: r56;
}
.r55 {
  grid-area: r55;
}
.r54 {
  grid-area: r54;
}
.r64 {
  grid-area: r64;
}
.r63 {
  grid-area: r63;
}
.r62 {
  grid-area: r62;
}
.r61 {
  grid-area: r61;
}
.r65 {
  grid-area: r65;
}
.r66 {
  grid-area: r66;
}
.r67 {
  grid-area: r67;
}

.wall2021-layer3-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0.3rem;
  grid-template-areas:
    'r81 r82 r83 r84 r85 r86 r87 r88'
    'r71 r71 r71 r71 r71 r71 r71 r71';
  grid-area: layer3-content;
}
.r71 {
  grid-area: r71;
}
.r81 {
  grid-area: r81;
}
.r82 {
  grid-area: r82;
}
.r83 {
  grid-area: r83;
}
.r84 {
  grid-area: r84;
}
.r85 {
  grid-area: r85;
}
.r86 {
  grid-area: r86;
}
.r87 {
  grid-area: r87;
}
.r88 {
  grid-area: r88;
}

.wall2021-layer4-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0.3rem;
  grid-template-areas:
    'r101 r101 r101 r102 r102 r102 r103 r103 r103 r104 r104 r104'
    'r91 r91 r91 r91 r92 r92 r92 r92 r93 r93 r93 r93';
  grid-area: layer4-content;
}
.r91 {
  grid-area: r91;
}
.r92 {
  grid-area: r92;
}
.r93 {
  grid-area: r93;
}
.r101 {
  grid-area: r101;
}
.r102 {
  grid-area: r102;
}
.r103 {
  grid-area: r103;
}
.r104 {
  grid-area: r104;
}

.wall2021-layer5-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0.3rem;
  grid-template-areas:
    'r121 r121 r122 r122 r123 r123 r124 r124 r125 r125 r126 r126'
    'r111 r111 r111 r112 r112 r112 r113 r113 r113 r114 r114 r114';
  grid-area: layer5-content;
}
.r111 {
  grid-area: r111;
}
.r112 {
  grid-area: r112;
}
.r113 {
  grid-area: r113;
}
.r114 {
  grid-area: r114;
}
.r121 {
  grid-area: r121;
}
.r122 {
  grid-area: r122;
}
.r123 {
  grid-area: r123;
}
.r124 {
  grid-area: r124;
}
.r125 {
  grid-area: r125;
}
.r126 {
  grid-area: r126;
}
</style>
