<template>
  <NumberLine
    :labels="labels"
    :fixed-selection="selection"
    :selection-data="selectionData"
    :is-selectable="inputState.isEditable()"
    @new-selection="handleNewSelection"
  />
</template>

<script>
import NumberLine from '../../customElements/NumberLine'
import InputState from '../../../models/InputState'
import Coordinate from '../../../models/geometry/Coordinate'

export default {
  components: { NumberLine },
  props: {
    labels: { type: Array, required: true },
    inputState: {
      type: InputState,
      default: () => InputState.createEditable()
    },
    value: { type: String, default: '' },
    correctValue: { type: String, default: '' }
  },
  computed: {
    selection() {
      return this.value === '' ? null : Coordinate.fromString(this.value)
    },
    selectionData() {
      const selection =
        this.value === '' ? null : Coordinate.fromString(this.value)
      const correctSelection = Coordinate.fromString(this.correctValue)
      return {
        selection,
        correctSelection,
        inputState: this.inputState
      }
    }
  },
  watch: {
    value() {
      if (this.value !== '') {
        this.$emit('input-ready')
      }
    }
  },
  methods: {
    /** @param {Coordinate} coordinate */
    handleNewSelection(coordinate) {
      // coordinate gets converted to string "x y"
      this.$emit('input', coordinate.toString())
      this.$emit('keyboard-input')
    }
  }
}
</script>

<style scoped></style>
