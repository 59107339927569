<template>
  <DropDown :initially-open="open" @opened="open = true" @closed="open = false">
    <template #trigger>
      <div
        ref="trigger"
        class="inline-flex flex-row items-center bg-white border-2 border-gray py-3 px-4 rounded-lg select-none gap-x-2"
        :class="{ 'border-blue': open, 'shadow-lg': shadow }"
        :style="{ 'min-width': `${minWidth}px`, width: width }"
      >
        <span class="flex-auto">{{ selectedText }}</span>
        <IconChevronDown />
      </div>
    </template>
    <DropdownContent>
      <SelectableList
        class="bg-white"
        :allow-multiple="false"
        :filterable="filterable"
        :initial-items="items"
        :initial-selected-item-ids="[value]"
        :width="width"
        :scroll-enabled="scrollEnabled"
        @selected-items="onItemSelected"
      />
    </DropdownContent>
  </DropDown>
</template>
<script>
import DropDown from './DropDown.vue'
import DropdownContent from './DropdownContent.vue'
import SelectableList from '../SelectableList.vue'
import { IconChevronDown } from '../icons'

export default {
  components: {
    IconChevronDown,
    SelectableList,
    DropdownContent,
    DropDown
  },
  props: {
    value: { type: String, default: null },
    items: { type: Array, default: () => [] },
    initiallyOpen: { type: Boolean, default: false },
    placeholderText: { type: String, default: 'Kies...' },
    filterable: { type: Boolean, default: false },
    width: { type: String, default: 'auto' },
    scrollEnabled: { type: Boolean, default: true },
    shadow: { type: Boolean, default: false },
    closeWithoutDelay: { type: Boolean, default: false }
  },
  data() {
    return {
      minWidth: '100px',
      open: this.initiallyOpen,
      selectedItem: null
    }
  },
  computed: {
    listValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    selectedText() {
      if (this.selectedItem === null) {
        return this.placeholderText
      }
      return this.selectedItem.content
    }
  },
  watch: {
    value() {
      this.selectItemByValue()
    },
    initiallyOpen(open) {
      this.open = open
    },
    items() {
      this.selectItemByValue()
    }
  },
  mounted() {
    if (this.$refs.trigger !== undefined) {
      this.minWidth = this.$refs.trigger.offsetWidth
    }
    this.selectItemByValue()
  },
  methods: {
    onItemSelected(selectedItems) {
      this.selectedItem = selectedItems[0]
      this.listValue = this.selectedItem.id

      if (this.closeWithoutDelay) {
        this.open = false
      } else {
        setTimeout(() => {
          this.open = false
        }, 200)
      }
    },
    selectItemByValue() {
      if (this.listValue !== null) {
        const foundItem = this.items.find((item) => this.listValue === item.id)
        if (foundItem !== undefined) {
          this.selectedItem = foundItem
        }
      }
    }
  }
}
</script>
