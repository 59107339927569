<template>
  <QtiSimpleChoice v-bind="$props" @select="(value) => $emit('select', value)">
    <slot />
  </QtiSimpleChoice>
</template>

<script>
import QtiSimpleChoice from './QtiSimpleChoice'
export default {
  components: { QtiSimpleChoice },
  props: {
    identifier: { type: String, required: true }
  }
}
</script>
