<template>
  <Button
    class="image-button"
    :style="{
      'background-image': 'url(' + image + ')'
    }"
    :disabled="disabled"
    @click="click"
  >
    <slot />
  </Button>
</template>

<script>
import Button from './Button.vue'

export default {
  components: { Button },
  props: {
    image: { type: String, required: true },
    disabled: { type: Boolean, default: false }
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
.image-button {
  background-position: center;
  background-size: 94%;
  align-items: flex-end !important;
}

.image-button:hover {
  background-size: 108%;
}
</style>
