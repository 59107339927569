<template>
  <h2 :class="classes" class="flex items-center gap-2"><slot /></h2>
</template>

<script>
export default {
  props: {
    size: { type: String, default: 'medium' }
  },
  computed: {
    classes() {
      if (this.size === 'small') {
        return 'text-2xl'
      }
      if (this.size === 'large') {
        return 'text-5xl'
      }
      return 'text-3xl'
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  @apply leading-tight font-bold;
}
</style>
