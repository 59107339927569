<template>
  <div ref="container" class="relative" @click="$emit('click')">
    <div ref="child">
      <slot name="child" />
    </div>
    <div
      v-if="debug"
      class="absolute left-0 bottom-0 text-xs text-gray-light p-1"
    >
      {{ fontSize ? fontSize.toFixed(2) + 'x' : '' }}
    </div>
    <slot />
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill'

export default {
  props: {
    minScale: { type: Number, default: 0.5 },
    maxScale: { type: Number, default: 2 }
  },
  data() {
    return {
      fontSize: null
    }
  },
  computed: {
    debug() {
      return this.$config.appEnv === 'dev'
    }
  },
  async mounted() {
    await this.$nextTick()
    this.handleResize()
  },
  methods: {
    handleResize() {
      const containerEl = this.$refs.container
      const childEl = this.$refs.child // can be an item

      const resizeChild = () => {
        if (!containerEl) {
          return
        }
        const containerWidth = containerEl.offsetWidth - 30
        const containerHeight = containerEl.offsetHeight - 5
        containerEl.style.fontSize = this.minScale + 'rem'
        const childWidth = childEl.offsetWidth + 50
        const childHeight = childEl.offsetHeight + 20
        const childAspectRatio = childWidth / childHeight
        const containerAspectRatio = containerWidth / containerHeight
        let baseSize
        if (childAspectRatio > containerAspectRatio) {
          // width matters more
          baseSize = (1.1 * containerWidth) / childWidth
        } else {
          // height matters more
          baseSize = (1.1 * containerHeight) / childHeight
        }
        if (childWidth === 0 || childHeight === 0) {
          // child still loading / initializing
          return
        }
        this.fontSize = Math.min(
          this.maxScale,
          Math.max(this.minScale, baseSize * this.minScale)
        )
        containerEl.style.fontSize = this.fontSize + 'rem'
      }
      resizeChild()
      this.addCallback(childEl, () => {
        containerEl.style.fontSize = '0.01rem' // reset size to prevent scrollbars
        resizeChild()
      }) // Resize when content size changes
      this.addCallback(containerEl, resizeChild) // Resize when container size changes
    },
    addCallback(element, callback) {
      if (!element || element.resizeObserver) {
        // already initialized
        return
      }
      element.resizeObserver = new ResizeObserver(() => {
        window.requestAnimationFrame(callback)
      })
      element.resizeObserver.observe(element)
    }
  }
}
</script>
