<template>
  <div class="collapsible">
    <h3 class="collapsible-title" @click="toggle">
      <IconChevronRight
        class="transform transition-transform duration-200"
        :class="{ 'rotate-90': !collapsed }"
      />
      {{ title }}
    </h3>

    <div :style="{ display: collapsed ? 'none' : 'block' }">
      <slot />
    </div>
  </div>
</template>

<script>
import { IconChevronRight } from '../icons'

export default {
  components: { IconChevronRight },
  props: {
    title: { type: String, required: true }
  },
  data() {
    return {
      collapsed: true
    }
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsible {
  @apply flex flex-col border-b border-gray;
}

.collapsible-title {
  @apply flex items-center gap-1 text-lg font-bold cursor-pointer py-2;
}
</style>
