<template>
  <div class="selectable-list">
    <SearchBox
      v-if="filterable"
      class="search-box--in-list"
      @input="onFilterUpdate"
    />
    <div class="selectable-list-scroll-content">
      <ListItem
        v-for="(item, index) in filteredItems"
        :key="item.id"
        :selectable="isSelectable(item)"
        :selected="isSelected(item)"
        :first="index === 0"
        :last="index === filteredItems.length - 1"
        class="selectable-list-item"
        :style="{ width: width }"
        @click="onSelect(item)"
      >
        <div
          :id="'selectable-list-item__' + item.id"
          class="selectable-list-item-text"
          :class="getListItemClass(item)"
          :title="item.content"
          v-html="item.content"
        />
      </ListItem>
      <div v-if="filterable && filteredItems.length === 0" class="py-3 px-4">
        Geen resultaten
      </div>
    </div>
  </div>
</template>

<script>
import ListItem from './ListItem.vue'
import SearchBox from './SearchBox.vue'

export default {
  components: { SearchBox, ListItem },
  props: {
    initialItems: { type: Array, default: () => [] },
    initialSelectedItemIds: { type: Array, default: () => [] },
    allowMultiple: { type: Boolean, default: false },
    filterable: { type: Boolean, default: false },
    width: { type: String, default: 'auto' },
    scrollEnabled: { type: Boolean, default: true }
  },
  data() {
    return {
      filter: '',
      items: this.initialItems,
      selectedItems: this.initialSelectedItems()
    }
  },
  computed: {
    filteredItems() {
      if (!this.filterable) {
        return this.items
      }
      if (this.filter === '') {
        return this.items
      }
      const regex = new RegExp(this.filter, 'i')
      return this.items
        .filter((item) => {
          return item.content.match(regex) && this.isSelectable(item)
        })
        .map((item) => {
          return { ...item, indent: 0 }
        })
    }
  },
  mounted() {
    this.dropDown()
  },
  methods: {
    initialSelectedItems() {
      return this.initialItems.filter((item) => {
        return this.initialSelectedItemIds.includes(item.id)
      })
    },
    isSelected(item) {
      return this.selectedItems.includes(item)
    },
    isSelectable(item) {
      return item.selectable === undefined || item.selectable === true
    },
    getListItemClass(item) {
      if (item.indent > 0) {
        return 'list-item-indent-' + item.indent
      }
      return ''
    },
    onSelect(item) {
      if (!this.allowMultiple) {
        this.selectedItems = [item]
        this.$emit('selected-items', this.selectedItems)
        return
      }
      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter((selectedItem) => {
          return selectedItem !== item
        })
        this.$emit('selected-items', this.selectedItems)
        return
      }
      this.selectedItems.push(item)
      this.$emit('selected-items', this.selectedItems)
    },
    onFilterUpdate(value) {
      this.filter = value
    },
    dropDown() {
      if (this.allowMultiple || this.selectedItems.length === 0) {
        return
      }
      if (this.scrollEnabled) {
        document
          .getElementById('selectable-list-item__' + this.selectedItems[0].id)
          .scrollIntoView({ block: 'center' })
      }
    }
  }
}
</script>

<style lang="scss">
.selectable-list {
  @apply border-2 border-gray rounded-lg overflow-hidden shadow-sm;
  padding: 0.2em 0;
}

.selectable-list-scroll-content {
  max-height: 20em;
  overflow-y: auto;
}

.selectable-list-item {
  overflow: hidden;
}

.selectable-list-item-text {
  width: 100%;
  white-space: normal;
}

.list-item-indent-1 {
  padding-left: 1rem;
}
.list-item-indent-2 {
  padding-left: 2rem;
}
.list-item-indent-3 {
  padding-left: 3rem;
}
.list-item-indent-4 {
  padding-left: 4rem;
}
</style>
