<template>
  <div class="flex flex-col h-screen items-center justify-center">
    <h1 class="text-3xl mb-5 text-center" v-html="message" />
    <div v-if="$config.debug" class="mb-5">
      {{ error.message }} (zie console voor details)
    </div>
    <div v-if="sentryId" class="text-gray-dark small">
      Fout-ID: {{ sentryId }}
    </div>
    <IconButton class="flex flex-row gap-2 mt-20" @click="goBack">
      <IconLeft /> Ga terug
    </IconButton>
  </div>
</template>

<script>
import { IconLeft } from './icons'
import { mapGetters } from 'vuex'
import IconButton from './button/IconButton.vue'

export default {
  components: { IconButton, IconLeft },
  props: {
    error: { type: Object, default: null }
  },
  computed: {
    ...mapGetters({
      userIsTeacher: 'auth/userIsTeacher'
    }),
    message() {
      let message = ''

      if (this.error.statusCode === 404) {
        message = 'Oeps, deze pagina bestaat niet.'
      }
      if (this.error.statusCode === 403) {
        message = 'Oeps, je hebt hier geen toegang toe.'
      }
      if (this.error.statusCode === 500) {
        message = 'Oeps, hier ging iets mis.'
      }

      if (!message) {
        message = this.error.message
      }

      if (this.userIsTeacher) {
        message =
          message + ' Neem contact met ons op via het feedbackformulier.'
      }

      return message
    },
    showBackButton() {
      // If router has a history, show back button
      return this.$router.history.current.index > 0
    },
    sentryId() {
      return this.$sentryData.id
    }
  },
  methods: {
    goBack() {
      this.$router.go(this.error.statusCode === 404 ? -1 : 0)
    }
  }
}
</script>
