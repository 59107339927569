<template>
  <IconBase view-box="0 0 24 24" fill="none" stroke="currentColor">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </IconBase>
</template>
<script>
import IconBase from './IconBase.vue'
export default {
  components: { IconBase }
}
</script>
