<template>
  <Modal :size="size" @close="close">
    <ModalSection v-if="title" class="flex flex-col justify-center flex-none">
      <Heading size="small">{{ title }}</Heading>
      <p v-if="subtitle" class="text-gray-dark text-lg mt-2">
        {{ subtitle }}
      </p>
    </ModalSection>
    <ModalSection class="flex-auto overflow-auto">
      <slot />
    </ModalSection>
    <ModalSection
      v-if="$slots.footer"
      class="flex flex-row justify-center flex-none"
    >
      <slot name="footer" />
    </ModalSection>
  </Modal>
</template>
<script>
import Modal from './Modal.vue'
import Heading from '../Heading.vue'
import ModalSection from './ModalSection.vue'

export default {
  components: {
    ModalSection,
    Heading,
    Modal
  },
  props: {
    size: { type: String, default: 'default' },
    title: { type: String, default: null },
    subtitle: { type: String, default: null }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
