<template>
  <div class="item" @click="$emit('click')">
    <template v-if="contentReady">
      <component
        :is="interactionXml"
        :settings="settings"
        :response-set="responseSet"
        :input-handler="inputHandler"
        :asset-host="item.assetHost"
      />
      <component
        :is="companionMaterialsXml"
        v-if="hasCompanionMaterials"
        :asset-host="item.assetHost"
      />
      <component
        :is="correctAnswerFeedbackXml"
        v-if="showCorrectAnswerFeedback"
      />
      <QtiModalFeedback v-if="showWellDoneFeedback">
        Goed gedaan!
      </QtiModalFeedback>
    </template>
    <Loader v-else />
  </div>
</template>
<script>
import ReverseInputChoice from './ReverseInputChoice'
import { Loader } from 'component-library'
import qtiItemService from '../services/qtiItemService'
import ItemSettings from '../models/ItemSettings'
import Item from '../models/Item'
import ResponseSet from '../models/ResponseSet'

import registerQtiComponents from './registerQtiComponents'
import { addAssetsToStore } from '../utils/assetStore'
import QtiModalFeedback from './qtiElements/qti-modal-feedback/QtiModalFeedback.vue'
import InputHandler from '../services/InputHandler'

export default {
  components: { QtiModalFeedback, Loader },
  provide() {
    return {
      settings: () => this.settings,
      responseSet: () => this.responseSet,
      inputHandler: () => this.inputHandler,
      assetHost: () => this.item.assetHost
    }
  },
  props: {
    item: { type: Item, required: true },
    settings: { type: ItemSettings, required: true },
    responseValues: { type: Array, default: null },
    paused: { type: Boolean, default: false },
    processSubmit: { type: Function, default: () => {} },
    focus: { type: Boolean, default: true }
  },
  data() {
    return {
      qtiItem: null,
      responseSet: null,
      /** @type {InputHandler|null} */
      inputHandler: null,
      startTime: null,
      processing: false,
      contentReady: false,
      showCorrectAnswerFeedback: false,
      showWellDoneFeedback: false,
      speechInputTimeoutHandle: null,
      preloadedAudio: new Map()
    }
  },
  computed: {
    interactionXml() {
      return {
        name: 'QtiInteraction',
        template: this.qtiItem.interactionXml
      }
    },
    hasCompanionMaterials() {
      return this.qtiItem.companionMaterialsXml !== null
    },
    companionMaterialsXml() {
      return {
        name: 'QtiCompanionMaterials',
        template: this.qtiItem.companionMaterialsXml
      }
    },
    correctAnswerFeedbackXml() {
      return {
        template: `${this.qtiItem.correctAnswerFeedbackXml}`
      }
    }
  },
  watch: {
    async item() {
      await this.initQti()
    },
    async focus() {
      await this.initInputHandler()
      this.responseSet.hasFocus = this.focus
    },
    /**
     * @param {ItemSettings} oldSettings
     * @param {ItemSettings} newSettings
     */
    async settings(oldSettings, newSettings) {
      if (
        JSON.stringify(oldSettings.templateVariables) !==
          JSON.stringify(newSettings.templateVariables) ||
        oldSettings.readonly !== newSettings.readonly ||
        oldSettings.showAnswer !== newSettings.showAnswer
      ) {
        await this.initItem()
      }
    },
    responseValues() {
      this.processResponseValues()
    },
    paused() {
      if (this.paused) {
        this.pause()
      } else {
        this.resume()
      }
    }
  },
  created() {
    registerQtiComponents()
  },
  async mounted() {
    await this.initQti()
    await addAssetsToStore(this.item.assets, this.settings) // make sure all assets for this item are preloaded
    this.contentReady = true
    await this.$nextTick()
    this.$emit('content-ready', {
      hasStrategyContent: this.qtiItem.hasStrategyContent,
      hasJumpNumberLineContent: this.qtiItem.hasJumpNumberLineContent,
      willAutoplayAudio: this.qtiItem.willAutoplayAudio,
      responseSet: this.responseSet,
      inputHandler: this.inputHandler
    })
    this.startTime = new Date().getTime()
    this.processResponseValues()
  },
  beforeDestroy() {
    if (this.inputHandler) {
      this.inputHandler.stop()
    }
  },
  methods: {
    async initQti() {
      this.qtiItem = qtiItemService.extractFromItem(this.item, this.settings)
      await this.initItem()
    },
    async initItem() {
      this.initResponseSet()
      await this.initInputHandler()
    },
    initResponseSet() {
      if (!this.responseSet) {
        this.responseSet = new ResponseSet(
          this.qtiItem,
          this.settings,
          this.focus
        )
        this.responseSet.on('submit', this.submit)
        this.responseSet.on('done', this.onItemDone)
        this.responseSet.on('input', this.onInput)
      } else {
        this.responseSet.settings = this.settings
        this.responseSet.reset()
      }
      if (this.settings.showAnswer) {
        this.responseSet.showAnswers()
      } else {
        this.processResponseValues()
      }
      this.processing = false
    },

    async initInputHandler() {
      if (!this.inputHandler) {
        this.inputHandler = new InputHandler(this.responseSet)
      }
      if (!this.settings.readonly && this.focus) {
        console.log('init input handler')
        await this.inputHandler.init()
        await this.inputHandler.start()
      } else {
        await this.inputHandler.stop()
      }
    },

    onInput() {
      this.$emit('input')
      this.$emit('values-update', this.responseSet.responseValues)
    },
    async submit() {
      if (this.processing) {
        return
      }

      this.processing = true
      const allCorrect = this.responseSet.allCorrect()
      if (this.responseSet.canTryReverse()) {
        this.handleReverseInput()
        return
      }
      if (
        this.qtiItem.hasCorrectAnswerFeedback &&
        this.settings.showFeedback &&
        this.settings.showFeedbackModal
      ) {
        if (allCorrect) {
          this.showWellDoneFeedback = true
        } else {
          this.showCorrectAnswerFeedback = true
        }
      }
      await this.processSubmit({
        startTime: this.startTime,
        itemCorrect: this.responseSet.allCorrect(),
        answer: this.serializeResponses(),
        templateVariables: this.settings.templateVariables
      })
      if (this.settings.showFeedback) {
        if (allCorrect) {
          this.responseSet.setSubmitProcessed()
        }
        setTimeout(async () => {
          if (allCorrect) {
            this.responseSet.setDone()
          } else {
            this.responseSet.setSubmitProcessed()
          }
        }, 1200)
      } else {
        this.responseSet.setDone()
      }
    },
    onItemDone() {
      if (!this.responseSet.submitted) {
        return
      }
      this.showCorrectAnswerFeedback = false
      this.showWellDoneFeedback = false
      this.$emit('done')
    },
    handleReverseInput() {
      const { id, value, correctValue } = this.responseSet.finalResponse
      const choices = [
        { id: 'CHOICEA', content: value },
        { id: 'CHOICEB', content: correctValue }
      ]
      this.$emit('reverse-input-start', {
        component: ReverseInputChoice,
        attributes: {
          props: { id, choices },
          on: {
            'choice-made': (choiceId) => {
              const selectedChoice = choices.find(
                (choice) => choice.id === choiceId
              )
              const value = !selectedChoice ? '' : selectedChoice.content
              this.$emit('reverse-input-end')
              this.responseSet.handleInput(id, value)
              this.responseSet.handleInputReady(id)
            }
          }
        }
      })
    },
    processResponseValues() {
      if (this.responseValues === null) {
        return
      }
      this.responseValues.forEach((responseValue) => {
        this.responseSet.setResponseValue(responseValue.id, responseValue.value)
        this.responseSet.setResponseReady(responseValue.id)
      })
      if (this.settings.autoProcessResponses) {
        this.responseSet.processResponses()
      }
    },
    serializeResponses() {
      return this.responseSet.responses.map(({ id, value }) => {
        return { id, value }
      })
    },
    pause() {
      this.inputHandler.stop()
    },
    resume() {
      this.inputHandler.start()
    }
  }
}
</script>

<style lang="scss">
.item {
  @apply flex flex-col items-center justify-center text-center;
  font-size: 2em;

  h2,
  .section,
  .interaction,
  .qti3-strategy {
    @apply flex flex-row items-center justify-center;
    & > * {
      @apply align-middle;
    }
  }

  .section,
  .interaction {
    @apply w-full flex-wrap;
    line-height: 1.1em;
  }

  .mb-6 {
    margin-bottom: 1em;
  }

  .mb-12 {
    margin-bottom: 2em;
  }

  h1 {
    font-size: 1.3em;
    //color: #888;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 0.9em;
    //color: #888;
    margin-bottom: 1em;
  }

  .section {
    margin-bottom: 0.5em;
    font-size: 1.3em;
  }

  h2 {
    @apply leading-tight;
  }

  .prompt p {
    @apply leading-tight;
    font-size: 0.75em;
    margin-bottom: 0.25em;
  }

  .column {
    @apply flex flex-col justify-center items-center mx-2 gap-2;
  }
}

.strategy {
  @apply flex flex-row items-center justify-center text-3xl mt-4;
  span {
    @apply align-middle mr-2 my-2;
    &:last-child {
      @apply mr-0;
    }
  }
}

.dictation-sheet {
  @apply border-collapse border-none;
  th,
  td {
    @apply text-center border-2 border-gray-light box-content;
    @apply p-1;
    min-width: 2.175em; // match min-width of number input
  }
}
</style>
