export function isTemplateNode(node) {
  if (node.nodeType !== Node.ELEMENT_NODE) {
    return false
  }
  return ['qti-template-block', 'qti-template-inline'].includes(node.nodeName)
}

export function includeForTemplate(node, templateVariables) {
  const { templateIdentifier, identifier, showTemplateContent } =
    extractTemplateAttributes(node)
  if (templateVariables[templateIdentifier] === undefined) {
    throw new Error(`missing template variable ${templateIdentifier} in item`)
  }
  return (
    showTemplateContent && templateVariables[templateIdentifier] === identifier
  )
}

function extractTemplateAttributes(node) {
  const templateIdentifier = node.getAttribute('template-identifier')
  const identifier = node.getAttribute('identifier')
  const showTemplateContent =
    !node.hasAttribute('show-hide') || node.getAttribute('show-hide') === 'show'
  return { templateIdentifier, identifier, showTemplateContent }
}

function determineWillAutoplayAudio(doc, autoPlayPromptAudio) {
  // if there are any <audio> elements in the content with missing autoplay
  // property (default = true) or explicit autoplay, will autoplay audio is true
  const audioButtons = Array.from(doc.querySelectorAll('audio-button'))
  for (const audioButton of audioButtons) {
    if (
      !audioButton.hasAttribute('autoplay') ||
      audioButton.getAttribute('autoplay') === 'autoplay'
    ) {
      return true
    }
  }
  // content may include spoken content, if this is disabled return false:
  if (!autoPlayPromptAudio) {
    return false
  }
  // content with spoken text is identified with the qti catalog ref property,
  // if there are any elements with this property, item will autoplay audio
  const textToSpeechContent = doc.querySelector('[data-catalog-idref]')

  return textToSpeechContent !== null
}

export { determineWillAutoplayAudio }
