<template>
  <div :id="id" style="z-index: 6">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    id: { type: String, required: true },
    stickUnder: { type: String, default: null },
    top: { type: Number, default: 0 },
    stickyClass: { type: String, default: '' },
    stickToBottom: { type: Boolean, default: false },
    position: { type: String, default: 'relative' },
    scrollY: { type: Boolean, default: true },
    flexibleWidth: { type: Boolean, default: false },
    containerId: { type: String, default: null }
  },
  data() {
    return {
      marginTop: null
    }
  },
  computed: {
    stickyClasses() {
      return [...this.stickyClass.split(' '), 'sticky'].filter((c) => c !== '')
    },
    observers() {
      if (!this.$stickyDivs.has(this.id)) {
        this.$stickyDivs.set(this.id, {
          stickUnder: this.stickUnder,
          top: this.top
        })
      }
      return this.$stickyDivs.get(this.id)
    },
    scrollListener() {
      return this.containerId
        ? document.getElementById(this.containerId)
        : document
    },
    container() {
      return this.containerId
        ? document.getElementById(this.containerId)
        : document.documentElement
    }
  },
  updated() {
    this.determineHeight()
  },
  mounted() {
    this.$nextTick(() => {
      this.getStickyDiv().style.position = this.position
      this.updateMarginTop()
      this.determineHeight()
      this.observeChanges()
    })
  },
  destroyed() {
    this.disableObserver()
  },
  methods: {
    getStickyDiv() {
      return document.getElementById(this.id)
    },
    getClone() {
      return document.getElementById(this.id + '-clone')
    },
    getStickUnderHeight(id) {
      let height = 0
      if (this.$stickyDivs.has(id)) {
        const stickUnder = this.$stickyDivs.get(id).stickUnder
        if (stickUnder) {
          height = this.getStickUnderHeight(stickUnder)
        }
        const top = this.$stickyDivs.get(id).top
        height += top
      }
      const element = document.getElementById(id)
      if (!element) return height
      return height + element.offsetHeight
    },
    updateMarginTop() {
      const baseTop = this.containerId
        ? this.getOffsetTop(document.getElementById(this.containerId))
        : 0
      this.marginTop =
        baseTop +
        this.top +
        (this.stickUnder ? this.getStickUnderHeight(this.stickUnder) : 0)
    },
    getOffsetTop(element) {
      let offsetTop = 0
      while (element) {
        offsetTop += element.offsetTop
        element = element.offsetParent
      }
      return offsetTop
    },
    observeChanges() {
      // first, remove any existing listeners
      this.disableObserver()
      this.observeDomMutations()
      this.observeScrolling()
      this.observeResize()
      this.observeTransitions()
    },
    observeDomMutations() {
      this.observers.mutations = new MutationObserver(() => {
        this.determineHeight()
        this.determineStickiness()
      })
      this.observers.mutations.observe(this.container, {
        childList: true,
        subtree: true
      })
    },
    observeScrolling() {
      this.observers.scrollListener = () => {
        this.updateMarginTop()
        this.determineHeight()
        this.determineStickiness()
        setTimeout(() => {
          this.updateMarginTop()
          this.determineHeight()
          this.determineStickiness()
        }, 100)
      }
      this.scrollListener.addEventListener(
        'scroll',
        this.observers.scrollListener
      )
    },
    observeResize() {
      this.observers.resizeListener = () => {
        const clone = this.getClone()
        if (clone) {
          const stickyDiv = this.getStickyDiv()
          if (!this.flexibleWidth) {
            stickyDiv.style.width = clone.offsetWidth + 'px'
          }
        }
        this.determineHeight()
        this.determineStickiness()
      }
      window.addEventListener('resize', this.observers.resizeListener)
    },
    observeTransitions() {
      if (this.stickUnder) {
        const stickUnderElement = document.getElementById(this.stickUnder)
        if (!stickUnderElement) {
          throw new Error(`Element with id ${this.stickUnder} not found in DOM`)
        }
        this.observers.transitionStart = () => {
          if (this.observers.transitionInterval) {
            return
          }
          this.observers.transitionInterval = setInterval(() => {
            try {
              this.updateMarginTop()
              this.determineHeight()
              this.determineStickiness()
            } catch (e) {
              this.clearTransitionObservers()
            }
          }, 50)
        }
        this.observers.transitionEnd = () => {
          this.clearTransitionObservers()
          this.updateMarginTop()
          this.determineHeight()
          this.determineStickiness()
        }
        stickUnderElement.addEventListener(
          'transitionstart',
          this.observers.transitionStart
        )
        stickUnderElement.addEventListener(
          'transitionend',
          this.observers.transitionEnd
        )
      }
    },
    clearTransitionObservers() {
      if (this.observers.transitionInterval) {
        clearInterval(this.observers.transitionInterval)
        this.observers.transitionInterval = null
      }
      if (!this.stickUnder) return
      const stickUnderElement = document.getElementById(this.stickUnder)
      if (!stickUnderElement) return
      stickUnderElement.removeEventListener(
        'transitionstart',
        this.observers.transitionStart
      )
      stickUnderElement.removeEventListener(
        'transitionend',
        this.observers.transitionEnd
      )
    },
    disableObserver() {
      if (this.observers.mutations) {
        this.observers.mutations.disconnect()
      }
      this.clearTransitionObservers()
      this.scrollListener.removeEventListener(
        'scroll',
        this.observers.scrollListener
      )
      window.removeEventListener('resize', this.observers.resizeListener)
    },
    determineHeight() {
      if (this.stickToBottom) {
        const stickyDiv = this.getStickyDiv()
        if (!stickyDiv) return
        const offsetTop = this.getOffsetTop(stickyDiv)
        let height = ''
        if (stickyDiv.classList.contains('sticky')) {
          height = `calc(100vh - ${offsetTop}px)`
        } else {
          height = `calc(100vh - ${offsetTop - this.container.scrollTop}px)`
        }
        if (height !== stickyDiv.style.height) {
          stickyDiv.style.height = height
        }
      }
    },
    determineStickiness() {
      const stickyDiv = this.getStickyDiv()
      if (!stickyDiv) return
      // See if clone exists
      let clone = this.getClone()
      let stickyDivTop = clone
        ? this.getOffsetTop(clone)
        : this.getOffsetTop(stickyDiv)
      if (this.container.scrollTop >= stickyDivTop - this.marginTop) {
        if (!clone) {
          this.disableObserver()
          clone = this.createClone(stickyDiv)
          this.makeSticky(stickyDiv)
          this.observeChanges()
        }
        const top = this.marginTop + 'px'
        if (stickyDiv.style.top !== top) {
          stickyDiv.style.top = top
        }
      } else {
        this.unstick(stickyDiv, clone)
      }
    },
    addStickyClasses(element) {
      for (const stickyClass of this.stickyClasses) {
        element.classList.add(stickyClass)
      }
    },
    createClone(stickyDiv) {
      // Create clone of sticky div
      const clone = stickyDiv.cloneNode(true)
      clone.id = this.id + '-clone'
      clone.style.visibility = 'hidden'
      stickyDiv.parentNode.insertBefore(clone, stickyDiv.nextSibling)
      return clone
    },
    makeSticky(stickyDiv) {
      this.addStickyClasses(stickyDiv)
      if (!this.flexibleWidth) {
        stickyDiv.style.width = stickyDiv.offsetWidth + 'px'
      }
      stickyDiv.style.position = 'fixed'
      if (this.stickToBottom) {
        if (this.scrollY) {
          stickyDiv.style.overflowY = 'auto'
        } else {
          stickyDiv.style.overflowY = 'hidden' // prevent vertical scrollbar
        }
        stickyDiv.style.overflowX = 'hidden' // prevent horizontal scrollbar
      }
    },
    unstick(stickyDiv, clone) {
      if (clone) {
        this.disableObserver()
        clone.parentNode.removeChild(clone)

        for (const stickyClass of this.stickyClasses) {
          stickyDiv.classList.remove(stickyClass)
        }
        stickyDiv.style.position = this.position
        if (!this.flexibleWidth) {
          stickyDiv.style.width = ''
        }
        stickyDiv.style.top = ''

        this.observeChanges()
      }
    }
  }
}
</script>
