<template>
  <div
    class="virtual-keyboard flex flex-col gap-1"
    :class="{ 'virtual-keyboard--show': show }"
  >
    <div class="flex flex-row justify-end gap-1">
      <VirtualKey value="Backspace">
        <IconBackspace class="text-red" />
      </VirtualKey>
    </div>
    <div class="flex flex-row justify-end gap-1">
      <VirtualKey value="7" />
      <VirtualKey value="8" />
      <VirtualKey value="9" />
    </div>
    <div class="flex flex-row justify-end gap-1">
      <VirtualKey value="4" />
      <VirtualKey value="5" />
      <VirtualKey value="6" />
    </div>
    <div class="flex flex-row justify-end gap-1">
      <VirtualKey value="1" />
      <VirtualKey value="2" />
      <VirtualKey value="3" />
    </div>
    <div class="flex flex-row justify-end gap-1">
      <VirtualKey value="." />
      <VirtualKey value="0" />
      <VirtualKey value="," />
    </div>
  </div>
</template>

<script>
import VirtualKey from './VirtualKey'
import { IconBackspace } from 'component-library'

export default {
  components: { VirtualKey, IconBackspace },
  props: {
    show: { type: Boolean, default: true }
  },
  methods: {
    triggerKey(key) {
      window.dispatchEvent(new KeyboardEvent('keypress', { key }))
    }
  }
}
</script>

<style>
.virtual-keyboard {
  width: 0;
  height: 0;
  margin-right: 1em;
  transition: 0.5s;
  overflow: hidden;
}

.virtual-keyboard--show {
  width: 13.5em;
  height: 21.5em;
  transition: 0.5s;
}
</style>
