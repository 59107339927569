<template>
  <ChoiceInput
    :id="responseIdentifier"
    :value="response.value"
    :has-focus="response.hasFocus"
    :input-state="response.inputState"
    :orientation="orientation"
    :choice-content-type="dataChoiceContentType"
    :size="size"
    :extra-classes="{
      'choice-input--inline': inline,
      'choice-input--block': !inline
    }"
    @input="handleInput"
    @input-ready="responseSet.handleInputReady(responseIdentifier)"
    @focus="responseSet.setFocusOnResponse(responseIdentifier)"
  >
    <template #prompt><slot name="prompt" /></template>
    <template #choices><slot name="choices" /></template>
  </ChoiceInput>
</template>

<script>
import ChoiceInput from '../../input/ChoiceInput'
import QtiBaseMixin from '../../QtiBaseMixin'
const shuffle = require('knuth-shuffle-seeded')

export default {
  components: { ChoiceInput },
  mixins: [QtiBaseMixin],
  props: {
    responseIdentifier: { type: String, default: '' },
    dataChoiceContentType: { type: String, default: 'text' },
    orientation: { type: String, default: 'horizontal' },
    shuffle: { type: Boolean, default: false },
    size: { type: String, default: 'large' },
    inline: { type: Boolean, default: false }
  },
  data() {
    return {
      seed: Math.random()
    }
  },
  computed: {
    response() {
      return this.responseSet.responseById(this.responseIdentifier)
    }
  },
  created() {
    this.updateSlots()
  },
  mounted() {
    this.updateSlots()
  },
  beforeUpdate() {
    this.updateSlots()
  },
  methods: {
    updateSlots() {
      this.$slots.prompt = this.getPrompt()
      this.$slots.choices = this.getChoices()
    },
    getPrompt() {
      return this.getComponentsWithTags(['qti-prompt'])
    },
    getChoices() {
      const choices = this.getComponentsWithTags([
        'qti-simple-choice',
        'qti-inline-choice'
      ])
      if (this.shuffle) {
        return shuffle(choices, this.seed)
      }
      return choices
    },
    handleInput(choiceId) {
      this.responseSet.handleInput(this.responseIdentifier, choiceId)
      this.responseSet.setFocusOnNextResponse()
    }
  }
}
</script>
