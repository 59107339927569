<template>
  <div v-if="show">
    <slot />
  </div>
</template>

<script>
import QtiBaseMixin from '../../QtiBaseMixin'

export default {
  mixins: [QtiBaseMixin],
  props: {
    identifier: { type: String, required: true },
    outcomeIdentifier: { type: String, required: true },
    showHide: { type: String, default: 'show' }
  },
  computed: {
    show() {
      if (this.identifier === 'NUMBER_LINE') {
        return this.settings.showJumpNumberLine
      }
      if (this.identifier === 'STRATEGY') {
        return this.settings.showStrategy
      }
      return true
    }
  }
}
</script>
