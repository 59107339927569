<template>
  <div
    class="number-input"
    :class="classes"
    :style="{ minWidth }"
    @click="onClick"
  >
    <span class="number-input__field">{{ value }}</span>
  </div>
</template>
<script>
import InputState from '../../models/InputState'
import { generateCssClassesForInputState } from './utils'
import InputMode from '../../models/InputMode'

const DEFAULT_LENGTH_COMPLETED_TIMEOUT = 750

export default {
  props: {
    id: { type: String, required: true },
    value: { type: String, required: true },
    hasFocus: { type: Boolean, default: false },
    expectedLength: { type: Number, default: null },
    inputTimeout: { type: Number, default: null }, // in seconds
    inputState: { type: InputState, required: true },
    inputMode: { type: InputMode, default: () => InputMode.createNone() },
    finalResponse: { type: Boolean, default: false }
  },
  inputIntervalHandle: null,
  data() {
    return {
      elapsedTimeAfterInput: 0
    }
  },
  computed: {
    classes() {
      return {
        ...generateCssClassesForInputState('number-input', this.inputState),
        'number-input--focus': this.hasFocus
      }
    },
    minWidth() {
      const baseWidth = 2.175
      if (!this.expectedLength) {
        return baseWidth + 'em'
      }
      const minWidth = Math.max(this.expectedLength * 0.8, baseWidth)
      return `${minWidth}em`
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (this.value !== '') {
        this.$emit('input-ready')
      }
      if (!this.inputState.isEditable()) {
        return
      }
      if (this.inputMode.isKeyboard() && oldValue !== '') {
        // emit event containing time in ms between last input;
        // first input is ignored
        console.log(
          `elapsed ms after last input: ${this.elapsedTimeAfterInput}`
        )
        this.$emit('input-time', this.elapsedTimeAfterInput)
      }

      this.clearInputInterval()
      this.setInputIntervalWithTimeout(
        this.determineInputTimeoutLength(oldValue, newValue)
      )
    },
    hasFocus(focus) {
      if (!this.inputState.isEditable()) {
        return
      }
      if (!this.inputMode.isKeyboard()) {
        return
      }
      if (focus) {
        this.setInputIntervalWithTimeout(null)
      } else {
        this.clearInputInterval()
      }
    }
  },
  beforeDestroy() {
    this.clearInputInterval()
  },
  methods: {
    onClick() {
      this.$emit('keyboard-input')
      this.$emit('focus')
    },
    determineInputTimeoutLength(oldValue, newValue) {
      // if the input is empty, do not set an input ready timeout:
      if (newValue === '') {
        return null
      }
      // when inputmode is speech, always use short timeout:
      if (this.inputMode.isSpeech()) {
        return DEFAULT_LENGTH_COMPLETED_TIMEOUT
      }
      // input timeout may be switched off (via setting):
      if (this.inputTimeout === null) {
        return null
      }
      // if the new input is shorter (backspace used), do not set a input ready timeout:
      if (newValue.length < oldValue.length) {
        return null
      }
      // if the input length is the expected length and the response is not
      // the final, use a short timeout:
      if (this.lengthCompleted(newValue)) {
        return DEFAULT_LENGTH_COMPLETED_TIMEOUT
      }
      // use the configured input timeout:
      return this.inputTimeout * 1000
    },
    /**
     * @param {?Number} timeout
     */
    setInputIntervalWithTimeout(timeout) {
      const startTime = new Date().getTime()
      this.$options.inputIntervalHandle = setInterval(() => {
        const elapsed = new Date().getTime() - startTime
        this.elapsedTimeAfterInput = elapsed
        if (!timeout) {
          return
        }
        // fire event when elapsed time is larger than timeout:
        if (elapsed >= timeout) {
          this.$emit('input-done')
          this.clearInputInterval()
        }
      }, 100)
    },
    clearInputInterval() {
      this.elapsedTimeAfterInput = 0
      if (!this.$options.inputIntervalHandle) {
        return
      }
      clearInterval(this.$options.inputIntervalHandle)
      this.$options.inputIntervalHandle = null
    },
    lengthCompleted(input) {
      if (!this.expectedLength) {
        return false
      }
      return input.length === this.expectedLength
    }
  }
}
</script>

<style lang="scss">
@import '../../../../component-library/assets/scss/wall';

.number-input {
  @apply relative inline-block;
  @apply bg-white border-gray rounded-xl;
  border-width: 0.125em;
  margin: 0.1em 0.2em;
  padding: 0.2em;
}

.number-input:hover {
  @apply bg-gray-light;
}

.number-input--focus:not(.number-input--readonly) {
  @apply bg-yellow-100 border-yellow-500 outline-none;
}

.number-input--readonly {
  @apply border-gray-light text-gray-dark;
  outline: none;
  pointer-events: none;
}

.number-input--correct {
  @apply bg-score-green border-score-green;
}

.number-input--incorrect {
  @apply bg-score-red border-score-red;
}

.number-input__field {
  @apply relative inline-block text-center align-middle pointer-events-none;
  z-index: 0;
  font-size: 0.875em;
  padding-top: 0.3em;
  min-height: 1.9em;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
