import QtiItem from '../models/QtiItem'
// import extractFromItem from './qtiItemService'
import qtiItemService from '../services/qtiItemService'

export const externalExtractFromItem = (item, settings) => {
  return qtiItemService.extractFromItem(item, settings)
}

export default {
  extractFromItem(item, settings) {
    const domParser = new DOMParser()
    const doc = domParser.parseFromString(item.content, 'application/xml')
    this.initDoc(doc, item.assets)
    return new QtiItem(doc, settings, item.assets)
  },

  initDoc(doc, assets) {
    this.addAudioButtons(doc, assets)
    this.replaceTagName(doc, 'math', 'math-component')
    this.replaceTagName(doc, 'img', 'img-component')
  },

  getAssetUrlByRef(assets, ref) {
    const asset = assets.find((asset) => asset.ref === ref)
    return asset ? asset.url : null
  },

  addAudioButtons(doc, assets) {
    const nodeList = doc.querySelectorAll('[data-catalog-idref]')
    nodeList.forEach((node) => {
      const audioUrl = this.getAssetUrlByRef(
        assets,
        node.getAttribute('data-catalog-idref')
      )
      if (node.nodeName === 'audio-button') {
        node.setAttribute('audio-url', audioUrl)
      } else {
        const audioButtonNode = doc.createElement('prompt-audio-button')
        audioButtonNode.setAttribute('audio-url', audioUrl)
        node.insertBefore(audioButtonNode, node.firstChild)
      }
    })
    const audioElements = doc.querySelectorAll('audio')
    audioElements.forEach((audioElement) => {
      const audioButtonNode = doc.createElement('prompt-audio-button')
      audioButtonNode.setAttribute(
        'audio-url',
        audioElement.getAttribute('src')
      )
      audioElement.parentNode.replaceChild(audioButtonNode, audioElement)
    })
  },
  replaceTagName(doc, from, to) {
    const nodeList = doc.querySelectorAll(from)
    nodeList.forEach((mathNode) => {
      const mathComponentNode = doc.createElement(to)
      const attributes = [...mathNode.attributes]
      attributes.forEach((attr) => {
        mathComponentNode.setAttribute(attr.nodeName, attr.nodeValue)
      })
      mathNode.childNodes.forEach((childNode) => {
        mathComponentNode.appendChild(childNode)
      })
      mathNode.parentNode.replaceChild(mathComponentNode, mathNode)
    })
  }
}
