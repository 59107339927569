<template>
  <div class="main-container">
    <NavigationBar v-if="user" :user="user" @logout="logout()">
      <Logo class="mr-12" />
    </NavigationBar>
    <Nuxt />
    <ModalContainer />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ModalContainer, NavigationBar } from 'component-library'
import Logo from '@/components/Logo.vue'

export default {
  components: { ModalContainer, NavigationBar, Logo },
  computed: {
    ...mapState({
      user: (state) => state.auth.user
    })
  },
  methods: {
    async logout() {
      window.location.href = '/auth/logout'
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 4.125rem;
}
</style>
