<template>
  <div class="container" :class="classNames">
    <slot />
  </div>
</template>

<script>
const layouts = {
  COLUMNS: 'columns',
  ROWS: 'rows'
}

export default {
  props: {
    centered: { type: Boolean, default: true },
    layout: { type: String, default: layouts.ROWS }
  },
  computed: {
    classNames() {
      const classes = []
      if (this.centered) {
        classes.push('container--centered')
      }
      if (this.layout === layouts.COLUMNS) {
        classes.push('container--columns')
      }
      return classes.join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  @apply w-full flex flex-col flex-1;
}
.container--columns {
  @apply flex-row;
}
.container--centered {
  @apply items-center justify-center;
}
</style>
