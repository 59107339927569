<template>
  <div class="progress-bar" :class="classObject">
    <div class="progress-bar__meter" :style="{ width: percentage + '%' }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: { type: String, default: 'default' },
    color: { type: String, default: 'yellow' },
    value: { type: Number, default: 0 }
  },
  computed: {
    classObject() {
      const classes = []
      if (this.type !== 'default') {
        classes.push(`progress-bar--${this.type}`)
      }
      classes.push(`progress-bar--color-${this.color}`)

      return classes.join(' ')
    },
    percentage() {
      return this.value * 100
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-bar {
  @apply relative bg-gray-light w-full rounded-full;
  height: 1rem;
}

.progress-bar__meter {
  @apply max-w-full h-full bg-gray-dark relative;
  border-radius: inherit;
  transition: width 200ms;
}

.progress-bar--small {
  @apply bg-white rounded-none;
  height: 5px;
}

.progress-bar--color-green .progress-bar__meter {
  @apply bg-green;
}

.progress-bar--color-blue .progress-bar__meter {
  @apply bg-blue;
}

.progress-bar--color-yellow .progress-bar__meter {
  @apply bg-yellow-500;
}
</style>
