import InputState from './InputState'
import { EventEmitter } from 'events'
import isReverseOf from '../utils/isReverseOf'

export default class ResponseSet extends EventEmitter {
  /**
   * @param {QtiItem} qtiItem
   * @param {ItemSettings} settings
   */
  constructor(qtiItem, settings, hasFocus) {
    super()
    this._qtiItem = qtiItem
    this._settings = settings
    this._tryReverseResponded = false
    this._debugLog = window.__NUXT__.config.appEnv !== 'prod'
    this._hasFocus = hasFocus

    this.reset()
  }

  get settings() {
    return this._settings
  }

  get reversed() {
    return (
      this._qtiItem.hasTemplate('COUNT_DIRECTION') &&
      this._settings.templateVariables.COUNT_DIRECTION === 'rightToLeft'
    )
  }

  get qtiItem() {
    return this._qtiItem
  }

  get responses() {
    return this._responses
  }

  get responseValues() {
    return this._responses.reduce((values, response) => {
      return { ...values, [response.id]: response.value }
    }, {})
  }

  /**
   * @param {ItemSettings} settings
   */
  set settings(settings) {
    this._settings = settings
    this._qtiItem.settings = settings
  }

  get ready() {
    return this._responses.every(
      (response) => response.value !== '' && response.ready
    )
  }

  get submittable() {
    return this._settings.submittable && this.ready
  }

  get responseWithFocus() {
    return this._responseFocusIndex !== null
      ? this._responses[this._responseFocusIndex]
      : null
  }

  get finalResponse() {
    return this._responses.length > 0
      ? this._responses[this.reversed ? 0 : this._responses.length - 1]
      : null
  }

  isFinalResponse(response) {
    return this.finalResponse && this.finalResponse.id === response.id
  }

  get finalResponseHasFocus() {
    if (!this.finalResponse || !this.responseWithFocus) {
      return false
    }
    return this.finalResponse.id === this.responseWithFocus.id
  }

  get firstResponseHasFocus() {
    if (this._responses.length === 0 || !this.responseWithFocus) {
      return false
    }
    return (
      this._responses[this.reversed ? this._responses.length - 1 : 0].id ===
      this.responseWithFocus.id
    )
  }

  get possibleInputModes() {
    return this.responseWithFocus
      ? this.responseWithFocus.supportedInputModes.filter((mode) => {
          // Only allow speech input if all responses support speech input
          if (mode.isSpeech()) {
            return this._responses.every((response) =>
              response.supportedInputModes.some((mode) => mode.isSpeech())
            )
          }
          return true
        })
      : []
  }

  responseById(responseId) {
    const response = this._responses.find(
      (response) => response.id === responseId
    )
    if (response === undefined) {
      throw Error(`no matching response found for input ID=${responseId}`)
    }
    return response
  }

  setResponseReady(responseId) {
    this.responseById(responseId).ready = true
  }

  setResponseValue(id, value) {
    this.responseById(id).value = value
  }

  _setFocusByIndex(focusIndex) {
    if (this._responseFocusIndex === focusIndex) {
      return
    }
    this._responseFocusIndex = focusIndex
    this._responses.forEach((response, index) => {
      response.hasFocus = index === focusIndex
    })
    this.emit('focus-changed')
    if (this._debugLog && focusIndex !== null) {
      console.log(
        '%cExpected input: ' +
          this.responseWithFocus.correctResponse.correctValues.join(','),
        'font-weight: bold;'
      )
    }
  }

  setFocusOnResponse(id) {
    this._setFocusByIndex(
      this._responses.findIndex((response) => response.id === id)
    )
  }

  setFocusOnFirstResponse() {
    this._setFocusByIndex(
      this._responses.length > 0
        ? this.reversed
          ? this._responses.length - 1
          : 0
        : null
    )
  }

  setFocusOnLastResponse() {
    this._setFocusByIndex(
      this._responses.length > 0
        ? this.reversed
          ? 0
          : this._responses.length - 1
        : null
    )
  }

  setFocusOnNextResponse() {
    if (this._responseFocusIndex === null) {
      this.setFocusOnFirstResponse()
      return
    }
    if (this.finalResponseHasFocus) {
      return
    }
    this._setFocusByIndex(this._responseFocusIndex + (this.reversed ? -1 : 1))
  }

  setFocusOnPreviousResponse() {
    if (this._responseFocusIndex === null) {
      this.setFocusOnFirstResponse()
      return
    }
    if (this.firstResponseHasFocus) {
      return
    }
    this._setFocusByIndex(this._responseFocusIndex + (this.reversed ? 1 : -1))
  }

  canTryReverse() {
    if (this._tryReverseResponded) {
      // Answer already given
      return false
    }
    if (!this.finalResponse) {
      return false
    }
    const { value, correctValue, isNumberInput } = this.finalResponse
    if (isNumberInput === true && isReverseOf(value, correctValue)) {
      this._tryReverseResponded = true
      return true
    }
    return false
  }

  handleInput(id, value) {
    this._changed = true
    this.setResponseValue(id, value)
    this.setFocusOnResponse(id)
    this.emit('input')
  }

  handleKeyboardInput() {
    this.emit('keyboard-input')
  }

  submit() {
    if (this.submittable) {
      this.processResponses()
      this._submitted = true
      this.emit('submit')
    }
  }

  get changed() {
    return this._changed
  }

  get submitted() {
    return this._submitted
  }

  setSubmitProcessed() {
    this._submitProcessed = true
  }

  get submitProcessed() {
    return this._submitProcessed
  }

  setDone() {
    if (!this._done) {
      this.emit('done')
    }
    this._done = true
  }

  get done() {
    return this._done
  }

  handleInputReady(id) {
    this.setResponseReady(id)
    this.emit('input-ready')
  }

  setCorrectValues() {
    this._responses.forEach((response) => {
      response.value = response.correctValue
    })
  }

  processResponses() {
    this._responses.forEach((response) => {
      if (this._settings.showFeedback) {
        response.inputState = response.matchCorrectResponse(response.value)
          ? InputState.createCorrect()
          : InputState.createIncorrect()
      }
    })
  }

  get processed() {
    return this._responses.every(
      (response) => response.inputState.isEditable() === false
    )
  }

  editResponses() {
    this._submitted = false
    this._responses.forEach((response) => {
      response.inputState = InputState.createEditable()
    })
  }

  allCorrect() {
    return this._responses.every((response) =>
      response.matchCorrectResponse(response.value)
    )
  }

  set hasFocus(hasFocus) {
    this._hasFocus = hasFocus
    if (hasFocus) {
      this.setFocusOnFirstResponse()
    } else {
      this._setFocusByIndex(null)
    }
  }

  reset() {
    this._responses = this._qtiItem.getResponses()
    this._changed = false
    this._submitted = false
    this._submitProcessed = false
    this._done = false
    this._responseFocusIndex = null

    if (this._hasFocus) {
      this.setFocusOnFirstResponse()
    }
  }

  showAnswers() {
    this._responses.forEach((response) => {
      response.value = response.correctValue
    })
  }
}
