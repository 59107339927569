const types = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DANGER: 'danger',
  SUCCESS: 'success',
  ORANGE: 'orange',
  LINK: 'link',
  SCORE_RED: 'score-red'
}

const values = []
for (const type in types) {
  values.push(types[type])
}

export { types, values }
