<template>
  <div class="fraction">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.fraction {
  @apply inline-flex flex-col text-center;
  margin: 0.1em 0.2em;

  > :first-child {
    @apply border-b-4;
    border-style: solid;
    border-color: currentColor;
  }
}
</style>
