<template>
  <div>
    <Button type="primary" size="small" @click="show = true">
      {{ label }}
    </Button>
    <ContentModal v-if="show">
      <template #default>
        <slot />
      </template>
      <template #footer>
        <Button type="primary" @click="show = false"> Sluiten </Button>
      </template>
    </ContentModal>
  </div>
</template>

<script>
import { Button, ContentModal } from 'component-library'
import QtiBaseMixin from '../../QtiBaseMixin'

export default {
  components: { Button, ContentModal },
  mixins: [QtiBaseMixin],
  props: {
    label: { type: String, default: 'Toon hulp' },
    mimeType: { type: String, default: '' }
  },
  data() {
    return {
      show: false
    }
  }
}
</script>
