<template>
  <div
    class="flex flex-row justify-around items-center -mx-3 p-6"
    style="min-width: 7em"
  >
    <SingleDice v-if="showDiceTwo" :count="diceTwoCount" class="mx-3" />
    <SingleDice v-if="showDiceOne" :count="diceOneCount" class="mx-3" />
    <SingleDice v-if="showDiceThree" :count="diceThreeCount" class="mx-3" />
  </div>
</template>

<script>
import SingleDice from './SingleDice'
export default {
  components: { SingleDice },
  props: {
    count: {
      type: Number,
      required: true,
      validator: function (value) {
        if (value < 1) {
          return false
        }
        return value <= 12 // max 2 dices with 6
      }
    }
  },
  computed: {
    showDiceOne() {
      return this.count <= 6
    },
    showDiceTwo() {
      return this.count > 6
    },
    showDiceThree() {
      return this.count > 6
    },
    diceOneCount() {
      if (this.count > 6) {
        return 0
      }
      return this.count
    },
    diceTwoCount() {
      if (this.count <= 6) {
        return 0
      }
      if (this.count > 6 && this.count < 9) {
        return 4
      }
      return 5
    },
    diceThreeCount() {
      if (this.count <= 6) {
        return 0
      }
      if (this.count === 7) {
        return 3
      }
      if (this.count === 8 || this.count === 9) {
        return 4
      }
      return 5
    }
  }
}
</script>
