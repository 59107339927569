<template>
  <RoundButton @click="triggerKey">
    <slot>
      {{ value }}
    </slot>
  </RoundButton>
</template>

<script>
import { RoundButton } from 'component-library'
export default {
  components: { RoundButton },
  props: {
    value: { type: String, required: true }
  },
  methods: {
    triggerKey() {
      window.dispatchEvent(new KeyboardEvent('keydown', { key: this.value }))
    }
  }
}
</script>
