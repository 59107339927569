<template>
  <Modal>
    <div class="p-8 text-center">
      <p class="text-xl" style="line-height: 1.5">
        <slot name="content">
          {{ text }}
        </slot>
      </p>
    </div>
    <div
      class="flex flex-row items-stretch justify-center border-t border-gray"
    >
      <button
        v-for="button in buttons"
        :key="button.title"
        class="flex-auto py-4 text-lg font-normal bg-white hover:bg-gray-light border-r border-gray last:border-r-0 outline-none focus:outline-none active:outline-none"
        @click.prevent="button.handler"
      >
        {{ button.title }}
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from './Modal.vue'
export default {
  components: { Modal },
  props: {
    text: { type: String, default: '' },
    buttons: { type: Array, required: true }
  }
}
</script>
