<template>
  <Button
    v-if="showSubmit"
    :type="computedButtonType"
    :disabled="buttonDisabled"
    :size="size"
    @click="click"
  >
    <component :is="icon" /> {{ computedLabel }}
  </Button>
</template>

<script>
import { Button, IconChevronRight } from 'component-library'

import ItemSettings from '../models/ItemSettings'
import ResponseSet from '../models/ResponseSet'

export default {
  components: { Button, IconChevronRight },
  props: {
    responseSet: { type: ResponseSet, default: null },
    settings: { type: ItemSettings, required: true },
    disabled: { type: Boolean, default: false },
    lastItem: { type: Boolean, default: false },
    isForcedLastItem: { type: Boolean, default: false },
    label: { type: String, default: null },
    size: { type: String, default: 'medium' },
    buttonType: { type: String, default: 'success' },
    icon: { type: Object, default: () => IconChevronRight }
  },
  data() {
    return {
      canProceed: false,
      submittable: false
    }
  },
  computed: {
    showSubmit() {
      return this.responseSet && this.settings.readonly === false
    },
    buttonDisabled() {
      return (
        this.disabled ||
        (this.submitted && !this.responseSet.submitProcessed) ||
        (this.settings.allowSkipping ? false : !this.submittable)
      )
    },
    submitted() {
      return this.responseSet && this.responseSet.submitted
    },
    computedLabel() {
      if (this.label) return this.label
      if (this.isForcedLastItem) return 'Klaar'
      if (!this.submittable && this.settings.allowSkipping) return 'Overslaan'
      if (this.lastItem) return 'Klaar'
      return 'Volgende'
    },
    computedButtonType() {
      if (this.submitted && !this.responseSet.allCorrect()) return 'score-red'
      if (!this.submittable && this.settings.allowSkipping) return 'secondary'
      return this.buttonType
    }
  },
  watch: {
    responseSet() {
      this.init()
    }
  },
  async mounted() {
    this.init()
  },
  methods: {
    init() {
      this.submittable = this.responseSet ? this.responseSet.submittable : false
      if (!this.responseSet) {
        return
      }
      this.responseSet.on(
        'input',
        () => (this.submittable = this.responseSet.submittable)
      )
      this.responseSet.on(
        'input-ready',
        () => (this.submittable = this.responseSet.submittable)
      )
    },
    click() {
      if (!this.responseSet) {
        return
      }
      if (this.submitted) {
        // Already submitted (feedback shown), set to done
        this.responseSet.setDone()
      } else if (this.submittable) {
        this.$emit('click')
        this.responseSet.submit()
      } else {
        this.$emit('skip')
      }
    }
  }
}
</script>
