<template>
  <button @click="openFeedback">
    <slot> Feedback </slot>
  </button>
</template>

<script>
export default {
  methods: {
    openFeedback() {
      const context = {
        url: document.location.href
      }
      if (this.$sentryData.id) {
        context.sentryId = this.$sentryData.id
      }
      // const contextEncoded = encodeURIComponent(JSON.stringify(context))
      // document.location = `${this.$config.feedback.host}/feedback?application=${this.$config.feedback.application}&context=${contextEncoded}`
      document.location = `${this.$config.feedback.host}`
    }
  }
}
</script>
