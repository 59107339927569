<template>
  <ContentModal title="Voorbeeldsom" size="full">
    <template #default>
      <ScalingContainer
        :max-scale="1"
        class="flex-auto flex flex-col items-center justify-center h-full"
      >
        <template #child>
          <ItemPlayer
            :key="exampleItem.id"
            :item="exampleItem"
            :settings="itemSettings"
            class="text-3xl"
          />
        </template>
      </ScalingContainer>
    </template>
    <template #footer>
      <Button type="primary" @click="close">
        Ik heb het voorbeeld gezien
      </Button>
    </template>
  </ContentModal>
</template>
<script>
import ItemPlayer from './ItemPlayer'
import { Button, ContentModal, ScalingContainer } from 'component-library'
import ItemSettings from '../models/ItemSettings'
import Item from '../models/Item'
import InputMode from '../models/InputMode'

export default {
  components: {
    ContentModal,
    Button,
    ItemPlayer,
    ScalingContainer
  },
  props: {
    exampleItem: { type: Item, required: true },
    mainSettings: { type: ItemSettings, required: true }
  },
  computed: {
    itemSettings() {
      return new ItemSettings({
        autoPlayPromptAudio: this.mainSettings.autoPlayPromptAudio,
        templateVariables: this.mainSettings.templateVariables,
        readonly: true,
        showAnswer: true,
        preferredInputMode: InputMode.createNone(),
        showStrategy: this.mainSettings.showStrategy,
        showJumpNumberLine: this.mainSettings.showJumpNumberLine
      })
    }
  },
  mounted() {
    this.$options.enterListener = (event) => {
      if (event.keyCode === 13) this.close()
    }
    window.addEventListener('keydown', this.$options.enterListener)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.$options.enterListener)
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
