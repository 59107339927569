import UAParser from 'ua-parser-js'
import waitUntilReady from '../../utils/waitUntilReady'

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition
/**
 * @type {SpeechRecognition|null}
 */
let speechRecognition = null
let resultHandler = null
let errorHandler = null
const confidenceThreshold = 0.6

const speechRecognitionService = {
  initialized: false,
  started: false,
  speaking: false,
  listening: false,
  debugLog: false,
  blocked: false,

  async init() {
    if (this.initialized) {
      return
    }
    this.debugLog = window.__NUXT__.config.appEnv !== 'prod'
    const browserSupported = this.browserSupported()

    if (!browserSupported || !SpeechRecognition) {
      console.log(
        'SpeechRecognition is only supported when using latest version of Chrome'
      )
      return
    }
    console.log('initializing')
    speechRecognition = new SpeechRecognition()
    speechRecognition.continuous = true
    speechRecognition.interimResults = true
    speechRecognition.maxAlternatives = 3
    speechRecognition.lang = 'nl-NL'
    speechRecognition.onresult = (event) => {
      if (!resultHandler) {
        console.log('no result handler')
        return
      }
      const alternatives = []
      for (const resultList of event.results) {
        for (const result of resultList) {
          const { confidence, transcript } = result
          if (transcript === '') {
            continue
          }
          // how confident is the speech recognition system that the recognition
          // is correct:
          const alternative = {
            transcript,
            reliable: confidence >= confidenceThreshold,
            final: resultList.isFinal
          }
          if (this.debugLog) {
            console.log(
              `Recognized: ${alternative.transcript} ${
                alternative.reliable ? ' - reliable' : ''
              } ${alternative.final ? ' - final' : ''}`
            )
          }
          alternatives.push(alternative)
        }
      }
      if (alternatives.length > 0) {
        resultHandler(alternatives)
      }
    }
    speechRecognition.onend = () => {
      console.debug('SpeechRecognition ended')
      this.speaking = false
      this.listening = false
      if (this.started) {
        this.restart()
      }
    }
    speechRecognition.onstart = () => {
      console.debug('SpeechRecognition started')
      this.listening = true
    }
    speechRecognition.addEventListener('speechstart', () => {
      this.speaking = true
    })
    speechRecognition.addEventListener('speechend', () => {
      this.speaking = false
    })
    /**
     * @param {SpeechRecognitionErrorEvent} event
     */
    speechRecognition.onerror = async (event) => {
      if (['aborted', 'no-speech'].includes(event.error)) {
        // ignore these expected errors
        return
      }
      console.log('SpeechRecognition error event', event)
      if (event.error === 'not-allowed') {
        this.blocked = true
        this.started = false
        this.initialized = true
      }
      if (errorHandler === null) {
        return
      }
      errorHandler()
    }
    this.initialized = true
  },

  browserSupported() {
    const parser = new UAParser()
    const result = parser.getResult()
    return ['Chrome', 'Chromium'].includes(result.browser.name)
  },

  isSupported() {
    return speechRecognition !== null
  },

  async reset() {
    try {
      console.log('resetting SpeechRecognition')
      await this.stop()
      await this.start()
    } catch (error) {
      console.log('error resetting SpeechRecognition', error)
    }
  },

  restart() {
    try {
      if (this.listening) {
        return
      }
      console.debug('restarting SpeechRecognition')
      speechRecognition.start()
    } catch (error) {
      console.log(`unable to restart SpeechRecognition: ${error.message}`)
    }
  },

  async start() {
    if (!this.isSupported()) {
      console.debug('cannot start listening: speech not supported')
      return
    }
    if (this.started) {
      console.debug('cannot start listening: already started')
      return
    }
    this.speaking = false
    this.started = true
    speechRecognition.start()
    await waitUntilReady(() => this.listening || this.blocked, 50)
  },

  async stop() {
    if (!this.listening) {
      console.debug('speechRecognitionService: cannot stop (not started)')
      return
    }
    this.speaking = false
    this.started = false
    speechRecognition.abort()
    await waitUntilReady(() => !this.listening, 50)
  },

  setErrorHandler(handler) {
    errorHandler = handler
  },

  setResultHandler(handler) {
    resultHandler = handler
  }
}

export default speechRecognitionService
