<template>
  <IconBase fill="currentColor" view-box="0 0 220 350">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M64.744,125.968c-6.236,6.793-11.69,12.543-16.928,18.483
	c-7.268,8.243-14.298,16.695-21.592,24.914c-2.129,2.399-2.704,4.18-0.133,6.635c2.03,1.939,3.6,4.36,5.392,6.554
	c1.524,1.864,1.538,3.874-0.264,5.31c-1.935,1.541-4.044,1.182-5.736-0.793c-1.465-1.709-2.089-4.155-4.814-5.382
	c-0.981,2.715-1.927,5.294-2.846,7.882c-0.773,2.18-2.225,3.353-4.575,2.83c-2.548-0.566-2.854-2.454-2.419-4.655
	c0.129-0.653,0.232-1.313,0.357-1.967c1.22-6.376,1.221-6.369-5.429-5.802c-1.69,0.144-3.602,0.297-4.175-1.744
	c-0.567-2.019,0.522-3.512,2.42-4.366c0.304-0.137,0.604-0.369,0.916-0.388c10.315-0.617,15.736-7.981,21.578-15.138
	c13.054-15.99,25.554-32.485,40.254-47.045c5.527-5.476,11.078-11.066,17.913-15.045c3.635-2.115,7.557-3.529,11.703-4.109
	c1.932-0.27,3.146-0.74,3.115-2.939c-0.025-1.794-0.248-3.12-2.424-3.673c-11.529-2.924-19.66-10.071-24.854-20.629
	c-0.83-1.688-2.386-2.249-3.666-3.217c-3.188-2.409-5.445-5.43-6.213-9.341c-0.709-3.618-0.236-6.92,4.429-7.162
	c2.189-0.113,2.793-1.238,3.27-2.988c0.61-2.244,0.938-4.748,2.185-6.605c4.669-6.954,7.82-14.227,8.519-22.786
	c0.384-4.726,5.104-6.936,9.233-8.252c11.104-3.541,22.332-3.343,33.373,0.383c5.186,1.751,8.43,5.566,8.393,11.204
	c-0.029,4.274,1.883,6.252,5.771,7.279c1.875,0.495,3.58,1.727,5.277,2.763c1.588,0.969,3.305,2.129,2.063,4.355
	c-1.152,2.063-2.986,1.661-4.803,0.878c-0.859-0.371-1.596-1.21-2.949-0.718c1.473,4.307,2.941,8.607,4.412,12.908
	c0.469,1.371,1.576,1.446,2.807,1.531c4.35,0.302,5.051,3.319,4.494,6.749c-0.309,1.909-0.893,4.394-2.256,5.408
	c-6.939,5.173-9.928,13.555-16.189,19.245c-5.174,4.703-10.941,8.074-17.865,9.312c-1.848,0.33-3.539,0.472-3.541,3.157
	c-0.002,2.555,1.357,2.817,3.354,3.237c8.938,1.881,16.738,5.989,21.916,13.662c6.9,10.226,17.051,15.634,28.016,19.831
	c13.105,5.017,13.803,4.712,19.383-8.147c2.84-6.546,4.91-13.421,7.455-20.1c0.904-2.372,0.408-4.003-1.459-5.699
	c-2.461-2.234-4.6-4.817-6.928-7.2c-1.766-1.807-2.375-4.021-0.533-5.727c1.865-1.729,4.148-1.623,6.104,0.633
	c1.355,1.567,2.248,4.708,4.48,4.105c2.049-0.554,1.471-3.777,2.066-5.777c0.795-2.675,1.156-5.618,4.779-5.106
	c3.758,0.53,3.213,3.575,2.895,6.28c-0.156,1.323-0.23,2.657-0.416,3.976c-0.363,2.608,0.605,3.399,3.059,2.374
	c0.918-0.383,1.859-0.802,2.828-0.956c2.025-0.322,4.186-0.532,4.855,2.117c0.57,2.256-0.871,3.742-2.771,4.028
	c-9.813,1.475-12.484,8.624-14.521,16.919c-1.982,8.079-5.324,15.746-9.717,22.877c-4.756,7.724-6.896,8.458-15.531,6.066
	c-7.557-2.093-15.025-4.435-21.881-8.276c-3.084-1.728-3.559-0.87-3.531,2.187c0.102,11.331,0.295,22.671-0.014,33.995
	c-0.521,19.123-1.08,38.253-2.664,57.329c-0.633,7.637-2.184,9.32-9.904,10.605c-2.49,0.414-3.303,1.156-2.879,3.857
	c4.52,28.799,7.008,57.829,9.582,86.845c0.324,3.674,0.9,5.549,5.346,5.434c4.916-0.125,9.971,0.16,14.766,1.951
	c3.104,1.158,6.191,2.338,5.947,6.289c-0.268,4.365-3.889,5.184-7.236,5.375c-6.979,0.396-13.98,0.355-20.973,0.545
	c-3.154,0.086-6.307,0.451-9.457,0.408c-3.393-0.047-5.779-1.828-6.209-5.271c-0.455-3.656,1.305-6.363,5.035-6.764
	c5.51-0.592,6.143-3.162,5.477-8.313c-3.564-27.568-6.469-55.218-10.479-82.735c-0.689-4.736-1.844-7.305-7.467-6.633
	c-5.586,0.666-11.32,0.283-16.979,0.09c-2.969-0.102-4.184,0.92-4.344,3.902c-1.6,29.578-3.262,59.151-4.969,88.724
	c-0.123,2.15,0.238,3.512,2.65,3.826c1.475,0.193,2.904,0.727,4.355,1.1c3.408,0.879,5.125,3.158,4.807,6.584
	c-0.322,3.479-2.646,5.518-6.033,5.459c-10.479-0.178-20.958-0.549-31.429-1.025c-3.185-0.145-6.123-1.529-6.474-5.104
	c-0.351-3.572,2.341-5.125,5.347-6.264c5.254-1.99,10.775-2.02,16.234-2.238c2.632-0.105,3.74-0.5,3.819-3.514
	c0.699-26.791,1.582-53.577,2.415-80.366c0.031-0.996,0.098-1.994,0.189-2.988c0.779-8.438,0.781-8.438-7.333-9.334
	c-5.316-0.588-7.266-2.809-7.18-8.727c0.451-31.323-0.723-62.616-1.643-93.914C65.133,128.803,65.008,128.173,64.744,125.968z
	 M106.664,77.735c7.43,0.004,16.361-4.51,20.188-10.819c1.053-1.738,2.357-3.647-0.09-5.164c-2.146-1.329-3.305,0.414-4.391,1.947
	c-8.414,11.858-25.289,11.804-33.617,0.118c-1.189-1.667-2.578-3.554-4.809-1.816c-2.207,1.718-0.717,3.732,0.48,5.243
	C89.781,74.001,96.605,77.955,106.664,77.735z M89.754,46.072c3.15,0.364,5.379-0.923,5.107-4.282
	c-0.232-2.896-0.801-6.277-4.951-6.156c-3.496,0.103-4.781,2.332-4.842,5.638C85.01,44.518,86.16,46.393,89.754,46.072z
	 M128.131,41.371c-0.703-2.205-0.732-5.677-4.826-5.736c-3.457-0.05-4.975,2.184-5.125,5.446c-0.145,3.113,0.908,5.091,4.486,5.058
	C125.619,46.111,128.008,45.649,128.131,41.371z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="#E2E0E0"
      d="M106.664,77.735c-10.059,0.22-16.883-3.733-22.238-10.491
	c-1.197-1.511-2.687-3.525-0.48-5.243c2.23-1.737,3.619,0.149,4.809,1.816c8.328,11.686,25.203,11.74,33.617-0.118
	c1.086-1.533,2.244-3.276,4.391-1.947c2.447,1.517,1.143,3.426,0.09,5.164C123.025,73.225,114.094,77.739,106.664,77.735z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="#E2E0E0"
      d="M89.754,46.072c-3.594,0.321-4.744-1.554-4.686-4.801
	c0.061-3.306,1.346-5.535,4.842-5.638c4.15-0.121,4.719,3.261,4.951,6.156C95.133,45.149,92.904,46.436,89.754,46.072z
	 M89.975,39.304c-1.428-0.094-2.219,0.549-2.227,1.993c-0.01,1.729,0.973,2.358,2.576,2.116c1.207-0.183,2.359-0.65,2.408-2.06
	C92.797,39.49,91.33,39.401,89.975,39.304z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="#E2E0E0"
      d="M128.131,41.371c-0.123,4.278-2.512,4.74-5.465,4.768
	c-3.578,0.033-4.631-1.944-4.486-5.058c0.15-3.263,1.668-5.496,5.125-5.446C127.398,35.694,127.428,39.166,128.131,41.371z
	 M123.496,39.301c-1.471-0.042-2.725,0.249-2.672,2.092c0.039,1.365,0.84,2.217,2.193,2.181c1.412-0.038,2.68-0.624,2.754-2.273
	C125.838,39.843,124.832,39.316,123.496,39.301z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M89.975,39.304c1.355,0.097,2.822,0.186,2.758,2.05
	c-0.049,1.409-1.201,1.877-2.408,2.06c-1.604,0.242-2.586-0.387-2.576-2.116C87.756,39.853,88.547,39.21,89.975,39.304z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M123.498,39.299c1.334,0.017,2.34,0.544,2.273,2.001
	c-0.074,1.649-1.342,2.235-2.754,2.273c-1.354,0.036-2.154-0.815-2.193-2.181C120.771,39.55,122.025,39.259,123.498,39.299z"
    />
  </IconBase>
</template>

<script>
import IconBase from './IconBase.vue'
export default {
  components: { IconBase }
}
</script>
