<template>
  <div class="flex flex-1">
    <template v-if="canPracticeBrick">
      <Button
        type="link"
        size="small"
        class="flex flex-1"
        :class="backgroundClass"
        style="padding: 0 0 !important; font-size: inherit !important"
        @click="showPracticeDialog = true"
      >
        <div
          :class="contentClass"
          class="rounded h-full"
          style="border-radius: 0.65rem"
        >
          <div class="bareka-brick-overlay">
            {{ brick.name }}
            <slot />
          </div>
        </div>
      </Button>
      <PracticeDialog
        v-if="showPracticeDialog"
        :brick="brick"
        :message="confirmationMessage"
        @ok="$emit('start-practice', brick.id)"
        @cancel="showPracticeDialog = false"
      />
    </template>
    <template v-else>
      <div :class="contentClass" @click="showPracticeUnavailableDialog = true">
        <div class="bareka-brick-overlay">
          {{ brick.name }}
          <slot />
        </div>
      </div>
      <PracticeUnavailableDialog
        v-if="showPracticeUnavailableDialog"
        :message="practiceUnavailableMessage"
        @close="showPracticeUnavailableDialog = false"
      />
    </template>
  </div>
</template>

<script>
import Button from '../../button/Button.vue'
import PracticeDialog from './PracticeDialog.vue'
import PracticeUnavailableDialog from './PracticeUnavailableDialog.vue'

export default {
  components: { PracticeUnavailableDialog, PracticeDialog, Button },
  props: {
    brick: { type: Object, required: true },
    getContentClass: { type: Function, required: true },
    getBackgroundClass: { type: Function, required: true },
    practiceMessages: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      showPracticeDialog: false,
      showPracticeUnavailableDialog: false,
      unavailableMessages: {
        program: 'Je kunt op dit steentje nooit extra oefenen',
        hasProgram: 'Je hebt al een oefenprogramma voor dit steentje',
        score:
          'Je hebt nog niet op dit steentje geoefend of nog geen toets gemaakt',
        setting:
          'Je begeleider heeft niet aangezet dat je extra op steentjes kunt oefenen',
        noPracticeBrick: 'Je kunt op dit steentje nooit extra oefenen',
        plannedTasks: 'Je hebt al extra taken ingepland staan'
      }
    }
  },
  computed: {
    canPracticeBrick() {
      return this.brick.userResults[0].canPracticeBrick
    },
    backgroundClass() {
      return this.getBackgroundClass(this.brick)
    },
    contentClass() {
      return this.getContentClass(this.brick)
    },
    practiceUnavailableReason() {
      return this.brick.userResults[0].practiceUnavailableReason
    },
    confirmationMessage() {
      return (
        this.practiceMessages.confirmation ||
        `Wil je extra op steentje ${this.brick.name} oefenen?`
      )
    },
    practiceUnavailableMessage() {
      if (
        this.practiceMessages &&
        this.practiceMessages.unavailableMessages &&
        this.practiceMessages.unavailableMessages[
          this.practiceUnavailableReason
        ]
      ) {
        return this.practiceMessages?.unavailableMessages[
          this.practiceUnavailableReason
        ]
      }
      return this.unavailableMessages[this.practiceUnavailableReason]
    }
  }
}
</script>
