<template>
  <div ref="divContainer" style="width: 20em; height: auto; margin: 0.5em">
    <svg
      ref="svgContainer"
      xmlns="http://www.w3.org/2000/svg"
      :viewBox="`0 0 ${viewBoxWidth} ${numberLineHeight}`"
      :class="{ 'cursor-pointer': handleNewSelection }"
      @mousemove="handleMouseMove"
      @mouseout="handleMouseOut"
      @click="handleClick"
    >
      <line
        :x1="0"
        :y1="numberLineHeight / 2"
        :x2="viewBoxWidth"
        :y2="numberLineHeight / 2"
        stroke="currentColor"
        stroke-width="1"
      />
      <g v-for="(label, index) in castLabels" :key="index">
        <line
          v-bind="attributesForLine(index)"
          stroke="currentColor"
          stroke-linecap="round"
        />
      </g>
      <line v-if="newSelection" v-bind="newSelectionLineAttributes" />
      <line v-if="selection" v-bind="selectionLineAttributes" />
      <line v-if="showCorrectSelection" v-bind="correctLineAttributes" />
    </svg>
    <div class="relative w-full" style="min-height: 1em; margin-top: 0.15em">
      <div
        v-for="(label, index) in castLabels"
        :key="index"
        class="absolute top flex justify-center items-start"
        :style="labelStyleForIndex(index)"
      >
        <slot :name="'label-' + index">
          <span>{{ label }}</span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill'
import Coordinate from '../../models/geometry/Coordinate'
import Rectangle from '../../models/geometry/Rectangle'

export default {
  props: {
    labels: { type: [Array, String], required: true },
    isSelectable: { type: Boolean, default: false },
    selectionData: { type: Object, default: null }
  },
  data() {
    return {
      newSelection: this.selectionData?.selection || null,
      rectangle: null
    }
  },
  computed: {
    segmentWidth() {
      return (this.castLabels.length + 5) * 2
    },
    numberLineHeight() {
      return (this.castLabels.length + 5) * 3
    },
    selection() {
      return this.selectionData ? this.selectionData.selection : null
    },
    viewBoxWidth() {
      return (this.castLabels.length - 1) * this.segmentWidth
    },
    viewBoxRectangle() {
      return new Rectangle(0, 0, this.viewBoxWidth, this.numberLineHeight)
    },
    castLabels() {
      return typeof this.labels === 'string'
        ? this.labels.split(',')
        : this.labels
    },
    handleNewSelection() {
      if (this.rectangle === null) {
        return false
      }
      return this.isSelectable
    },
    selectionLineAttributes() {
      const horizontalPosition = this.viewBoxWidth * (this.selection.x / 100)
      const { inputState } = this.selectionData
      const color = inputState.isCorrect()
        ? '#58cc02'
        : inputState.isIncorrect()
        ? '#ff4b4b'
        : '#ffcc00'
      return this.createLineAttributes({
        color,
        horizontalPosition
      })
    },
    newSelectionLineAttributes() {
      const horizontalPosition = this.viewBoxWidth * (this.newSelection.x / 100)
      const color = '#ddd'
      return this.createLineAttributes({
        color,
        horizontalPosition
      })
    },
    correctLineAttributes() {
      const { correctSelection } = this.selectionData
      const horizontalPosition = this.viewBoxWidth * (correctSelection.x / 100)
      return {
        ...this.createLineAttributes({
          color: '#58cc02',
          horizontalPosition
        }),
        'stroke-dasharray': '4,2',
        'stroke-linecap': 'butt'
      }
    },
    showCorrectSelection() {
      if (!this.selectionData) {
        return false
      }
      if (
        !this.selectionData.inputState ||
        !this.selectionData.correctSelection
      ) {
        return false
      }
      return this.selectionData.inputState.isIncorrect()
    }
  },
  watch: {
    selectionData() {
      const { selection } = this.selectionData
      this.newSelection = selection || null
    }
  },
  beforeDestroy() {
    this.resizeObserver.disconnect()
  },
  created() {
    this.updateSlots()
  },
  beforeUpdate() {
    this.updateSlots()
  },
  async mounted() {
    this.updateSlots()
    await this.$nextTick()
    this.resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        const container = this.$refs.svgContainer
        if (!container) {
          return
        }
        this.rectangle = Rectangle.fromDOMRect(
          container.getBoundingClientRect()
        )
      })
    })
    this.resizeObserver.observe(this.$refs.divContainer)
  },
  methods: {
    updateSlots() {
      if (!this.$slots.default) {
        return
      }
      this.$slots.default.forEach((component) => {
        if (
          component.data &&
          component.data.attrs &&
          component.data.attrs['data-label-index']
        ) {
          this.$slots['label-' + component.data.attrs['data-label-index']] = [
            component
          ]
        }
      })
    },
    createLineAttributes({ color, horizontalPosition }) {
      return {
        stroke: color,
        x1: horizontalPosition,
        x2: horizontalPosition,
        y1: 5,
        y2: this.numberLineHeight - 5,
        'stroke-width': 2,
        'pointer-events': 'none',
        'stroke-linecap': 'round'
      }
    },
    handleMouseMove(event) {
      if (!this.handleNewSelection) {
        return
      }
      if (!this.rectangle) {
        return
      }
      const positionRelativeToRectangle = Coordinate.fromEventRelative(event)
      const positionOnViewBox = new Coordinate(
        positionRelativeToRectangle.x *
          (this.viewBoxWidth / this.rectangle.width),
        this.numberLineHeight / 2 // always set y in middle
      )
      this.newSelection =
        this.viewBoxRectangle.percentualPosition(positionOnViewBox)
    },
    handleMouseOut() {
      if (!this.handleNewSelection) {
        return
      }
      this.newSelection = null
    },
    handleClick() {
      if (!this.handleNewSelection) {
        return
      }
      if (!this.newSelection) {
        return
      }
      // const percentualPosition = this.rectangle.percentualPosition(
      //   this.newSelection
      // )
      this.$emit('new-selection', this.newSelection)
    },
    isLastLabel(index) {
      return index === this.castLabels.length - 1
    },
    attributesForLine(labelIndex) {
      const isThickLine = labelIndex % 5 === 0
      let positionX = labelIndex * this.segmentWidth
      if (labelIndex === 0) {
        positionX = positionX + 1
      }
      if (this.isLastLabel(labelIndex)) {
        positionX = positionX - 1
      }
      return {
        x1: positionX,
        x2: positionX,
        // think line needs to be slightly longer:
        y1: isThickLine ? 1 : 5,
        y2: isThickLine ? this.numberLineHeight - 1 : this.numberLineHeight - 5,
        'stroke-width': isThickLine ? 2 : 1
      }
    },
    positionXPercentageForLabel(labelIndex) {
      let positionX = labelIndex * this.segmentWidth
      if (labelIndex === 0) {
        positionX = positionX + 1
      }
      if (this.isLastLabel(labelIndex)) {
        positionX = positionX - 1
      }
      return (positionX / this.viewBoxWidth) * 100
    },
    labelStyleForIndex(labelIndex) {
      const style = {
        left: `${this.positionXPercentageForLabel(labelIndex)}%`,
        transform: 'translateX(-50%) translateY(-7.5%)',
        minHeight: '1em'
      }
      if (this.castLabels.length <= 11) {
        return {
          ...style,
          fontSize: '0.75em'
        }
      }

      return { ...style, fontSize: '0.525em' }
    }
  }
}
</script>
