<template>
  <Error :error="error" />
</template>

<script>
import { Error } from 'component-library'
export default {
  components: { Error },
  layout: 'blank',
  props: {
    error: { type: Object, default: null }
  },
}
</script>
