<template>
  <button
    class="round-button"
    :disabled="disabled"
    :class="classNames"
    @click.prevent="onClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    size: { type: String, default: 'medium' },
    disabled: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    color: { type: String, default: null }
  },
  computed: {
    classNames() {
      const classes = [`round-button--${this.size}`]
      if (this.color) {
        classes.push(`round-button--${this.color}`)
      }
      if (this.active) {
        classes.push('round-button--active')
      }
      return classes.join(' ')
    }
  },
  methods: {
    onClick() {
      if (this.disabled) {
        return
      }
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.round-button {
  @apply rounded-full inline-flex items-center justify-center outline-none;
  @apply text-gray-dark bg-gray-light;

  &:hover:not(.round-button--active):not([disabled]) {
    @apply bg-gray;
  }
}

.round-button--red:not([disabled]) {
  @apply text-white bg-red-dark;
  &:hover:not(.round-button--active):not([disabled]) {
    @apply bg-red;
  }
}

.round-button--blue:not([disabled]) {
  @apply text-white bg-blue;
  &:hover:not(.round-button--active):not([disabled]) {
    @apply bg-blue;
  }
}

.round-button:disabled {
  @apply cursor-default pointer-events-none text-gray;
}

.round-button--active:not([disabled]) {
  @apply bg-blue text-white cursor-default;
}

.round-button--medium {
  @apply w-16 h-16 text-xl;
}

.round-button--small {
  @apply w-12 h-12 text-lg;
}

.round-button--smaller {
  @apply w-8 h-8 text-base;
}

.round-button--large {
  font-size: 3.5rem;
  width: 7.5rem;
  height: 7.5rem;
}
</style>
