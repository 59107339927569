<template>
  <div class="flex flex-col mx-auto" style="width: 6em; height: 5em">
    <svg
      class="absolute z-0 text-gray"
      xmlns="http://www.w3.org/2000/svg"
      width="6em"
      height="5em"
      viewBox="0 0 288 192"
    >
      <rect
        x="15.58"
        y="92"
        transform="matrix(0.6805 -0.7328 0.7328 0.6805 -40.9496 98.0938)"
        fill="currentColor"
        width="152.84"
        height="8"
      />
      <rect
        x="192"
        y="19.58"
        transform="matrix(0.7328 -0.6804 0.6804 0.7328 -12.9546 159.0076)"
        fill="currentColor"
        width="8"
        height="152.84"
      />
    </svg>
    <div class="flex flex-row justify-center items-start flex-auto z-10">
      <ReadonlyInput :value="String(baseNumber)" />
    </div>
    <div class="flex flex-row justify-center items-end flex-auto z-10">
      <div class="flex-auto flex justify-start">
        <ReadonlyInput :value="String(firstPart)" />
      </div>
      <div class="flex-auto flex justify-end">
        <NumberInput
          :id="id"
          :has-focus="hasFocus"
          :input-state="inputState"
          :input-mode="inputMode"
          :value="value"
          :input-timeout="inputTimeout"
          :expected-length="expectedLength"
          @input="onInput"
          @input-ready="onInputReady"
          @focus="onFocus"
          @keyboard-input="$emit('keyboard-input')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputState from '../../../../models/InputState'
import NumberInput from '../../../input/NumberInput'
import InputMode from '../../../../models/InputMode'
import ReadonlyInput from './ReadonlyInput'

export default {
  components: { ReadonlyInput, NumberInput },
  props: {
    id: { type: String, required: true },
    value: { type: String, required: true },
    hasFocus: { type: Boolean, required: true },
    baseNumber: { type: Number, required: true },
    firstPart: { type: Number, required: true },
    inputState: { type: InputState, required: true },
    expectedLength: { type: Number, default: null },
    inputMode: { type: InputMode, default: () => InputMode.createNone() },
    inputTimeout: { type: Number, default: 0 }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    onInputReady() {
      this.$emit('input-ready')
    },
    onFocus() {
      this.$emit('focus')
    }
  }
}
</script>
