<template>
  <ImageButton class="home-button" :image="image" @click="$emit('click')">
    <slot />
  </ImageButton>
</template>

<script>
import ImageButton from './ImageButton'
export default {
  components: { ImageButton },
  props: {
    image: { type: String, required: true }
  }
}
</script>

<style lang="scss">
.home-button {
  width: 30vw;
  height: 30vw;
  max-width: calc(min(30vh, 18rem));
  max-height: calc(min(30vh, 18rem));
}
</style>
