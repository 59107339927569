<template>
  <div class="item-body">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    xmlns: { type: String, default: '' }
  }
}
</script>

<style lang="scss">
.item-body {
  @apply flex flex-col items-center justify-center text-center;
}
</style>
