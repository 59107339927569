<template>
  <div class="avatar cursor-pointer select-none">
    <IconUser />
  </div>
</template>

<script>
import { IconUser } from './icons'

export default {
  components: { IconUser },
  props: {
    name: { type: Object, default: null }
  },
  computed: {
    initials() {
      return this.name.firstName.charAt(0).toUpperCase()
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  @apply rounded-full bg-green flex items-center justify-center font-bold text-2xl text-white;
  padding-bottom: 0.15em;
  width: 3rem;
  height: 3rem;
}
</style>
