<template>
  <div class="flex flex-row items-center">
    <div v-if="showButtons" class="flex-none">
      <Button
        :size="'medium'"
        :disabled="itemCount === 0 || currentIndex === 0"
        class="flex-none"
        @click="previous"
      >
        <IconLeft />
      </Button>
      <slot name="left" />
    </div>
    <div class="flex flex-col flex-auto items-center px-2 gap-1">
      <span class="text-xl">
        {{ itemCount > 0 ? currentIndex + 1 : 0 }} van {{ itemCount }}
        <slot name="after-item" />
      </span>
      <ProgressBar
        :value="itemCount > 0 ? currentIndex / (itemCount - 1) : 0"
      />
      <slot />
    </div>
    <div v-if="showButtons" class="flex-none">
      <Button
        :size="'medium'"
        :disabled="itemCount === 0 || currentIndex + 1 === itemCount"
        @click="next"
      >
        <IconRight />
      </Button>
    </div>
  </div>
</template>

<script>
import { IconLeft, IconRight, Button, ProgressBar } from 'component-library'

export default {
  components: {
    IconRight,
    IconLeft,
    Button,
    ProgressBar
  },
  props: {
    showButtons: { type: Boolean, default: false },
    incorrectFeedback: { type: Array, default: () => [] },
    currentIndex: { type: Number, default: 0 },
    itemCount: { type: Number, default: 0 }
  },
  methods: {
    next() {
      this.$emit('next-item')
    },
    previous() {
      this.$emit('previous-item')
    }
  }
}
</script>
