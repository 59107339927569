<template>
  <div class="w-full h-full flex flex-col justify-center items-center">
    <div v-if="message !== ''" class="mb-6 leading-tight text-lg text-center">
      {{ message }}
    </div>
    <img
      :src="loaderImage"
      alt="Bezig met laden"
      :style="{ width: width, height: 'auto' }"
    />
  </div>
</template>

<script>
import loaderImage from '../assets/images/loader.svg'

export default {
  props: {
    message: { type: String, default: '' },
    size: { type: String, default: 'medium' }
  },
  data() {
    return { loaderImage }
  },
  computed: {
    width() {
      return (this.size === 'small' ? 50 : 100) + 'px'
    }
  }
}
</script>
