'use strict'

const MODE_NONE = 'none'
const MODE_KEYBOARD = 'keyboard'
const MODE_SPEECH = 'speech'
const MODE_CLICK = 'click'

export default class InputMode {
  constructor(mode) {
    this.mode = mode
  }

  static create(mode) {
    return new InputMode(mode)
  }

  static createNone() {
    return new InputMode(MODE_NONE)
  }

  static createKeyboard() {
    return new InputMode(MODE_KEYBOARD)
  }

  static createSpeech() {
    return new InputMode(MODE_SPEECH)
  }

  static createClick() {
    return new InputMode(MODE_CLICK)
  }

  isNone() {
    return this.mode === MODE_NONE
  }

  isKeyboard() {
    return this.mode === MODE_KEYBOARD
  }

  isSpeech() {
    return this.mode === MODE_SPEECH
  }

  isClick() {
    return this.mode === MODE_CLICK
  }

  equals(inputMode) {
    return this.mode === inputMode.mode
  }
}
