<template>
  <h2 v-if="hasContent">
    <slot />
  </h2>
</template>

<script>
export default {
  computed: {
    hasContent() {
      return !!this.$slots.default
    }
  }
}
</script>
