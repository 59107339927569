import InputMode from './InputMode'

export default class ItemSettings {
  constructor({
    autoPlayPromptAudio = true,
    autoProcessResponses = false,
    showPromptAudioButtons = true,
    templateVariables = {
      INTERACTION_MODE: 'withStrategy',
      STRATEGY_MODE: 'normal',
      COUNT_DIRECTION: 'leftToRight'
    },
    preferredInputMode = InputMode.createNone(),
    inputTimeout = null,
    showAnswer = false,
    readonly = false,
    submittable = true,
    showStrategy = false,
    showJumpNumberLine = false,
    showFeedback = true,
    showFeedbackModal = true,
    allowSkipping = false
  }) {
    this._autoPlayPromptAudio = autoPlayPromptAudio
    this._autoProcessResponses = autoProcessResponses
    this._showPromptAudioButtons = showPromptAudioButtons
    this._templateVariables = templateVariables
    this._preferredInputMode = preferredInputMode
    this._inputTimeout = inputTimeout
    this._showAnswer = showAnswer
    this._readonly = readonly
    this._submittable = submittable
    this._showStrategy = showStrategy
    this._showJumpNumberLine = showJumpNumberLine
    this._showFeedback = showFeedback
    this._showFeedbackModal = showFeedbackModal
    this._allowSkipping = allowSkipping
  }

  get autoPlayPromptAudio() {
    return this._autoPlayPromptAudio
  }

  get autoProcessResponses() {
    return this._autoProcessResponses
  }

  get showPromptAudioButtons() {
    return this._showPromptAudioButtons
  }

  get templateVariables() {
    return this._templateVariables
  }

  get preferredInputMode() {
    return this._preferredInputMode
  }

  get inputTimeout() {
    return this._inputTimeout
  }

  get showAnswer() {
    return this._showAnswer && this._readonly
  }

  get readonly() {
    return this._readonly
  }

  get submittable() {
    return this._submittable
  }

  get showStrategy() {
    return this._showStrategy
  }

  get showJumpNumberLine() {
    return this._showJumpNumberLine
  }

  get showFeedback() {
    return this._showFeedback
  }

  get showFeedbackModal() {
    return this._showFeedbackModal
  }

  get allowSkipping() {
    return this._allowSkipping
  }
}
