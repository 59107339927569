<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 189.101 107.923"
    style="width: 8em; height: auto"
  >
    <polygon
      v-if="showStarWithNumber(11)"
      id="_x31_1"
      fill="none"
      stroke="currentColor"
      stroke-width="0.665"
      stroke-linecap="round"
      stroke-linejoin="round"
      points="
	134.365,74.317 130.836,81.625 123.506,78.33 125.429,86.232 117.728,88.492 124.203,93.291 119.735,100.048 127.732,99.498
	128.587,107.591 134.365,101.949 140.143,107.591 140.998,99.498 148.995,100.048 144.527,93.291 151.002,88.492 143.301,86.232
	145.224,78.33 137.894,81.625 "
    />
    <polygon
      v-if="showStarWithNumber(10)"
      id="_x31_0"
      fill="none"
      stroke="currentColor"
      stroke-width="0.665"
      stroke-linecap="round"
      stroke-linejoin="round"
      points="
	95.452,74.317 91.923,81.625 84.593,78.33 86.516,86.232 78.815,88.492 85.29,93.291 80.822,100.048 88.819,99.498 89.674,107.591
	95.452,101.949 101.23,107.591 102.085,99.498 110.082,100.048 105.614,93.291 112.089,88.492 104.388,86.232 106.311,78.33
	98.981,81.625 "
    />
    <polygon
      v-if="showStarWithNumber(9)"
      id="_x39_"
      fill="none"
      stroke="currentColor"
      stroke-width="0.665"
      stroke-linecap="round"
      stroke-linejoin="round"
      points="
	56.194,74.317 52.665,81.625 45.335,78.33 47.258,86.232 39.557,88.492 46.032,93.291 41.564,100.048 49.561,99.498 50.416,107.591
	56.194,101.949 61.972,107.591 62.827,99.498 70.824,100.048 66.356,93.291 72.831,88.492 65.13,86.232 67.053,78.33 59.723,81.625
	"
    />
    <polygon
      v-if="showStarWithNumber(8)"
      id="_x38_"
      fill="none"
      stroke="currentColor"
      stroke-width="0.665"
      stroke-linecap="round"
      stroke-linejoin="round"
      points="
	172.131,38.11 168.602,45.418 161.272,42.123 163.195,50.025 155.494,52.285 161.969,57.084 157.501,63.841 165.498,63.291
	166.353,71.384 172.131,65.742 177.909,71.384 178.764,63.291 186.761,63.841 182.293,57.084 188.768,52.285 181.067,50.025
	182.99,42.123 175.66,45.418 "
    />
    <polygon
      v-if="showStarWithNumber(7)"
      id="_x37_"
      fill="none"
      stroke="currentColor"
      stroke-width="0.665"
      stroke-linecap="round"
      stroke-linejoin="round"
      points="
	134.365,38.11 130.836,45.418 123.506,42.123 125.429,50.025 117.728,52.285 124.203,57.084 119.735,63.841 127.732,63.291
	128.587,71.384 134.365,65.742 140.143,71.384 140.998,63.291 148.995,63.841 144.527,57.084 151.002,52.285 143.301,50.025
	145.224,42.123 137.894,45.418 "
    />
    <polygon
      v-if="showStarWithNumber(6)"
      id="_x36_"
      fill="none"
      stroke="currentColor"
      stroke-width="0.665"
      stroke-linecap="round"
      stroke-linejoin="round"
      points="
	94.824,38.11 91.295,45.418 83.965,42.123 85.888,50.025 78.187,52.285 84.662,57.084 80.194,63.841 88.191,63.291 89.046,71.384
	94.824,65.742 100.602,71.384 101.457,63.291 109.454,63.841 104.986,57.084 111.461,52.285 103.76,50.025 105.683,42.123
	98.353,45.418 "
    />
    <polygon
      v-if="showStarWithNumber(5)"
      id="_x35_"
      fill="none"
      stroke="currentColor"
      stroke-width="0.665"
      stroke-linecap="round"
      stroke-linejoin="round"
      points="
	56.194,38.11 52.665,45.418 45.335,42.123 47.258,50.025 39.557,52.285 46.032,57.084 41.564,63.841 49.561,63.291 50.416,71.384
	56.194,65.742 61.972,71.384 62.827,63.291 70.824,63.841 66.356,57.084 72.831,52.285 65.13,50.025 67.053,42.123 59.723,45.418
	"
    />
    <polygon
      v-if="showStarWithNumber(4)"
      id="_x34_"
      fill="none"
      stroke="currentColor"
      stroke-width="0.665"
      stroke-linecap="round"
      stroke-linejoin="round"
      points="
	16.97,38.11 13.44,45.418 6.11,42.123 8.034,50.025 0.333,52.285 6.807,57.084 2.339,63.841 10.336,63.291 11.192,71.384
	16.97,65.742 22.748,71.384 23.602,63.291 31.599,63.841 27.131,57.084 33.606,52.285 25.905,50.025 27.828,42.123 20.499,45.418
	"
    />
    <polygon
      v-if="showStarWithNumber(3)"
      id="_x33_"
      fill="none"
      stroke="currentColor"
      stroke-width="0.665"
      stroke-linecap="round"
      stroke-linejoin="round"
      points="
	133.738,0.333 130.209,7.641 122.879,4.346 124.802,12.248 117.101,14.508 123.576,19.307 119.108,26.064 127.105,25.514
	127.96,33.607 133.738,27.965 139.516,33.607 140.371,25.514 148.368,26.064 143.9,19.307 150.375,14.508 142.674,12.248
	144.597,4.346 137.267,7.641 "
    />
    <polygon
      v-if="showStarWithNumber(2)"
      id="_x32_"
      fill="none"
      stroke="currentColor"
      stroke-width="0.665"
      stroke-linecap="round"
      stroke-linejoin="round"
      points="
	94.824,0.333 91.295,7.641 83.965,4.346 85.888,12.248 78.187,14.508 84.662,19.307 80.194,26.064 88.191,25.514 89.046,33.607
	94.824,27.965 100.602,33.607 101.457,25.514 109.454,26.064 104.986,19.307 111.461,14.508 103.76,12.248 105.683,4.346
	98.353,7.641 "
    />
    <polygon
      v-if="showStarWithNumber(1)"
      id="_x31_"
      fill="none"
      stroke="currentColor"
      stroke-width="0.665"
      stroke-linecap="round"
      stroke-linejoin="round"
      points="
	55.911,0.333 52.382,7.641 45.052,4.346 46.975,12.248 39.274,14.508 45.749,19.307 41.281,26.064 49.278,25.514 50.133,33.607
	55.911,27.965 61.689,33.607 62.544,25.514 70.541,26.064 66.073,19.307 72.548,14.508 64.847,12.248 66.77,4.346 59.44,7.641 "
    />
  </svg>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: function (value) {
        if (value < 1) {
          return false
        }
        return value <= 10
      }
    }
  },
  methods: {
    showStarWithNumber(starNumber) {
      if (starNumber === 1) {
        return this.count > 1
      }
      if (starNumber === 2 || starNumber === 10) {
        return this.count >= 6
      }
      if (starNumber === 3 || starNumber === 9) {
        return this.count >= 4
      }
      if (starNumber === 4 || starNumber === 8) {
        return this.count === 10
      }
      if (starNumber === 5 || starNumber === 7) {
        return [8, 9, 10].includes(this.count)
      }
      if (starNumber === 6) {
        // odd:
        return this.count % 2
      }
      if (starNumber === 11) {
        return this.count >= 2
      }
    }
  }
}
</script>
