export const QTI_PROMPT = 'qti-prompt'
export const QTI_CUSTOM_INTERACTION = 'qti-custom-interaction'
export const QTI_TEXT_ENTRY_INTERACTION = 'qti-text-entry-interaction'
export const QTI_INLINE_CHOICE_INTERACTION = 'qti-inline-choice-interaction'
export const QTI_INLINE_CHOICE = 'qti-inline-choice'
export const QTI_CHOICE_INTERACTION = 'qti-choice-interaction'
export const QTI_SIMPLE_CHOICE = 'qti-simple-choice'
export const QTI_ORDER_INTERACTION = 'qti-order-interaction'
export const QTI_SELECT_POINT_INTERACTION = 'qti-select-point-interaction'

export const QTI_CORRECT_RESPONSE = 'qti-correct-response'
export const QTI_AREA_MAPPING = 'qti-area-mapping'
export const QTI_VALUE = 'qti-value'
export const QTI_ITEM_BODY = 'qti-item-body'
export const QTI_CONTENT_BODY = 'qti-content-body'

export const QTI_COMPANION_MATERIALS_INFO = 'qti-companion-materials-info'
export const QTI_DIGITAL_MATERIAL = 'qti-digital-material'

export const QTI_FILE_HREF = 'qti-file-href'
