/**
 * @param {string} baseClassName
 * @param {InputState} inputState
 * @return {string}
 */
export function generateCssClassesForInputState(baseClassName, inputState) {
  return {
    [`${baseClassName}--readonly`]: !inputState.isEditable(),
    [`${baseClassName}--correct`]: inputState.isCorrect(),
    [`${baseClassName}--incorrect`]: inputState.isIncorrect()
  }
}
