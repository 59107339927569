<template>
  <div
    ref="dropElement"
    class="dropzone"
    :class="{ 'dropzone--active': active, 'dropzone--disabled': disabled }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    id: { type: Number, required: true },
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    droppedChoices: { type: Array, default: () => [] }
  }
}
</script>

<style scoped lang="scss">
.dropzone {
  @apply relative;
  @apply bg-white rounded-2xl;
  @apply flex justify-center items-center;
  @apply border-dashed border-gray;
  border-width: 0.125em;
}

.dropzone--disabled {
  @apply border-none;
}

.dropzone--active {
  @apply bg-yellow-100 border-yellow-500 border-solid;
}
</style>
