<template>
  <div class="modal" :class="modalClass">
    <div class="modal__overlay" @click="close" />
    <div ref="content" class="modal__content" :class="contentClass">
      <IconButton
        v-if="closeButton"
        class="absolute top-0 right-0 p-5"
        style="z-index: 1"
        @click="close"
      >
        <IconClose />
      </IconButton>
      <slot />
    </div>
  </div>
</template>

<script>
import { keys } from 'shared'
const { ESCAPE } = keys
import IconButton from '../button/IconButton.vue'
import { IconClose } from '../icons'

export default {
  components: { IconClose, IconButton },
  props: {
    size: { type: String, default: 'default' },
    contentClass: { type: String, default: '' },
    closeButton: { type: Boolean, default: false }
  },
  computed: {
    modalClass() {
      return `modal--size-${this.size}`
    }
  },
  beforeMount() {
    this.boundCloseOnEscKey = this.closeOnEscKey.bind(this)
  },
  mounted() {
    document.addEventListener('keyup', this.boundCloseOnEscKey)
    this.$emit('show')
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.boundCloseOnEscKey)
  },
  methods: {
    closeOnEscKey(event) {
      if (event.key === ESCAPE) {
        this.close()
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  @apply flex items-center justify-center fixed inset-0 z-50;
}
.modal__content {
  @apply flex flex-col bg-white z-30 relative rounded-4xl max-h-screen overflow-hidden;
}

.modal__overlay {
  @apply absolute inset-0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
}

.modal--size-default .modal__content {
  margin: 3rem;
}

.modal--size-full .modal__content {
  @apply rounded-none h-screen w-screen;
  @screen lg {
    @apply rounded-xl;
    height: calc(100vh - 3rem);
    width: calc(100vw - 3rem);
  }
}
</style>
