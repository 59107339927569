import speechRecognitionService from './speech/speechRecognitionService'
import keyHandler, { allowedInputKeys } from '../components/keyHandler'
import { InputMode } from '../index'

// note: there is some delay needed to give the speech recognition
// time to come up with the final result when processing large words:
// 1. user says: "duizend tweehonderd"
// 2. SST generates "1000" first, then "1200" after some delay
// this delay must be taken into account when emitting the 'input-ready' event
const SPEECH_INPUT_READY_DELAY = 2000

// Global key listener:
let keyListener = null

export default class InputHandler {
  /**
   * @param {ResponseSet} responseSet
   */
  constructor(responseSet) {
    this._enabled = false
    this._speechInputTimeoutHandle = null
    this._responseSet = responseSet
    this._inputMode = InputMode.createNone()
    this._responseSet.on('focus-changed', async () => {
      if (this._inputMode.isSpeech()) {
        // wait for speech recognition to be reset:
        await speechRecognitionService.reset()
      }
    })
    this._debugLog = window.__NUXT__.config.appEnv !== 'prod'
  }

  get enabled() {
    return this._enabled
  }

  get inputMode() {
    return this._inputMode
  }

  get speechSupported() {
    return speechRecognitionService.isSupported()
  }

  isListening() {
    return speechRecognitionService.listening
  }

  isSpeaking() {
    return speechRecognitionService.speaking
  }

  get hasSpeechInput() {
    return this._responseSet.possibleInputModes.some((mode) => mode.isSpeech())
  }

  get hasKeyboardInput() {
    return this._responseSet.possibleInputModes.some((mode) =>
      mode.isKeyboard()
    )
  }

  get onlySpeechInput() {
    return (
      this._responseSet.possibleInputModes.length === 1 && this.hasSpeechInput
    )
  }

  inputModePossible(inputMode) {
    if (inputMode.isNone()) return true
    if (inputMode.isSpeech() && !this.speechSupported) return false
    return this._responseSet.possibleInputModes.some((mode) =>
      inputMode.equals(mode)
    )
  }
  /**
   * @param {InputMode} inputMode
   */
  async setInputMode(inputMode) {
    if (inputMode.equals(this._inputMode)) return
    if (!this.inputModePossible(inputMode)) {
      throw new Error(`Input mode not allowed: ${inputMode.mode}`)
    }
    this._inputMode = inputMode
    if (this._enabled) {
      if (inputMode.isSpeech()) {
        if (!this.isListening()) await speechRecognitionService.start()
      } else {
        if (this.isListening()) await speechRecognitionService.stop()
      }
    }
  }

  async start() {
    this._enabled = true
    await this.startSpeech()
    this.enableKeyHandler()
  }

  async stop() {
    this._enabled = false
    this.disableKeyHandler()
    await this.stopSpeech()
  }

  async startSpeech() {
    if (this.inputMode.isSpeech()) {
      if (!this.isListening()) {
        await speechRecognitionService.start()
      } else {
        await speechRecognitionService.reset()
      }
    }
  }

  async stopSpeech() {
    if (this.isListening()) await speechRecognitionService.stop()
  }

  async init() {
    await speechRecognitionService.init()
    speechRecognitionService.setResultHandler((alternatives) => {
      this.handleSpeechInput(alternatives)
    })
    if (this.inputModePossible(this._responseSet.settings.preferredInputMode)) {
      await this.setInputMode(this._responseSet.settings.preferredInputMode)
    } else {
      await this.setInputMode(
        this.possibleInputModes()[0] || InputMode.createNone()
      )
    }
    this.enableKeyHandler()
  }

  possibleInputModes() {
    return this._responseSet.possibleInputModes.filter((mode) =>
      this.inputModePossible(mode)
    )
  }

  enableKeyHandler() {
    this.disableKeyHandler()
    if (!this._responseSet.settings.readonly) {
      keyListener = async (event) => {
        keyHandler(this._responseSet)(event)
        if (this.hasKeyboardInput && allowedInputKeys.includes(event.key)) {
          await this.setInputMode(InputMode.createKeyboard())
        }
      }
      window.addEventListener('keydown', keyListener)
    }
  }

  disableKeyHandler() {
    if (keyListener) {
      window.removeEventListener('keydown', keyListener)
      keyListener = null
    }
  }

  handleSpeechInput(alternatives) {
    if (
      !this._enabled ||
      !this._responseSet.responseWithFocus ||
      !this._responseSet.responseWithFocus.inputState.isEditable()
    ) {
      return
    }

    const heardValue =
      this._responseSet.responseWithFocus.handleSpeechInput(alternatives)

    if (!heardValue) {
      return
    }
    if (this._debugLog) {
      console.log('%cAccepted value: ' + heardValue, 'font-weight: bold;')
    }
    this._responseSet.handleInput(
      this._responseSet.responseWithFocus.id,
      heardValue
    )
    this._responseSet.handleInputReady(this._responseSet.responseWithFocus.id)
    this.clearSpeechInputTimeout()

    if (this._responseSet.responseWithFocus.isCorrect()) {
      if (this._responseSet.submittable) {
        this._responseSet.submit()
      } else {
        this._responseSet.setFocusOnNextResponse()
      }
      return
    }
    // set the timeout:
    this._speechInputTimeoutHandle = setTimeout(() => {
      if (this._responseSet.submittable) {
        this._responseSet.submit()
      } else {
        this._responseSet.setFocusOnNextResponse()
      }
    }, SPEECH_INPUT_READY_DELAY)
  }

  clearSpeechInputTimeout() {
    if (this._speechInputTimeoutHandle) {
      clearTimeout(this._speechInputTimeoutHandle)
      this._speechInputTimeoutHandle = null
    }
  }
}
