<template>
  <div>
    <slot name="prompt" />
    <OrderInteraction
      :id="responseIdentifier"
      :choices="choices"
      :shuffle="shuffle"
      :has-focus="response.hasFocus"
      :input-state="response.inputState"
      :value="response.value"
      :correct-value="response.correctValue"
      @input="(value) => responseSet.handleInput(responseIdentifier, value)"
      @input-ready="responseSet.handleInputReady(responseIdentifier)"
    />
  </div>
</template>

<script>
import QtiBaseMixin from '../../QtiBaseMixin'
import OrderInteraction from './OrderInteraction'

export default {
  components: { OrderInteraction },
  mixins: [QtiBaseMixin],
  props: {
    responseIdentifier: { type: String, default: '' },
    shuffle: { type: Boolean, default: false }
  },
  data() {
    return {
      choices: []
    }
  },
  computed: {
    response() {
      return this.responseSet.responseById(this.responseIdentifier)
    }
  },
  created() {
    this.updateSlots()
  },
  mounted() {
    this.updateSlots()
  },
  beforeUpdate() {
    this.updateSlots()
  },
  methods: {
    updateSlots() {
      this.$slots.prompt = this.getPrompt()
      this.choices = this.getChoices()
    },
    getPrompt() {
      return this.getComponentsWithTags(['qti-prompt'])
    },
    getChoices() {
      const choices = this.getComponentsWithTags([
        'qti-simple-choice',
        'qti-inline-choice'
      ])
      return choices.map((component) => {
        return {
          id: component.componentOptions.propsData.identifier,
          content: component.componentOptions.children[0].text
        }
      })
    }
  }
}
</script>
