<template>
  <div
    class="list-item focus:outline-none hover:bg-gray-light"
    :class="{ 'list-item--selectable': selectable }"
    @click="onClick"
  >
    <slot />
    <IconCheck v-if="selected" class="ml-auto" />
  </div>
</template>

<script>
import { IconCheck } from './icons'
export default {
  components: { IconCheck },
  props: {
    first: { type: Boolean, default: false },
    last: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    selectable: { type: Boolean, default: true }
  },
  methods: {
    onClick() {
      if (this.selectable) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  @apply flex items-center gap-1;
  @apply px-4 py-3 w-full border-gray;
  @apply text-gray-dark;
}

.list-item--selectable {
  @apply cursor-pointer;
  @apply text-black;
}
</style>
