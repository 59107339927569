'use strict'

const EDITABLE = 'editable'
const CORRECT = 'correct'
const INCORRECT = 'incorrect'
const PREDEFINED = 'predefined'
const READONLY_WITH_BLANKS = 'readonly-with-blanks'

const states = [EDITABLE, CORRECT, INCORRECT, PREDEFINED, READONLY_WITH_BLANKS]

export default class InputState {
  constructor(state) {
    if (states.indexOf(state) === -1) {
      throw new Error(`invalid state ${state}`)
    }
    this.state = state
  }

  isCorrect() {
    return this.state === CORRECT
  }

  isIncorrect() {
    return this.state === INCORRECT
  }

  isEditable() {
    return this.state === EDITABLE
  }

  // todo simplify this:
  isPredefined() {
    return this.state === PREDEFINED
  }

  isReadOnlyWithBlanks() {
    return this.state === READONLY_WITH_BLANKS
  }

  isReadOnly() {
    return this.isPredefined() || this.isReadOnlyWithBlanks()
  }

  // inputs are filled with correct values:
  showCorrectValue() {
    return this.isCorrect() || this.isPredefined()
  }

  toString() {
    return this.state
  }

  // state where interaction is blank and inputs need to be given
  static createEditable() {
    return new InputState(EDITABLE)
  }

  // input is given and answer is correct
  static createCorrect() {
    return new InputState(CORRECT)
  }

  // input is given and answer is incorrect
  static createIncorrect() {
    return new InputState(INCORRECT)
  }

  // input is given and state is neutral
  static createPredefined() {
    return new InputState(PREDEFINED)
  }

  // inputs are shown as blank ... etc (for display only)
  static createReadOnlyWithBlanks() {
    return new InputState(READONLY_WITH_BLANKS)
  }
}
