<template>
  <div class="p-8 flex flex-col">
    <h2 style="margin-bottom: 1em">Welk antwoord bedoel je?</h2>
    <div class="flex flex-row justify-center">
      <ChoiceInput
        :id="id"
        :choices="choices"
        :value="value"
        :has-focus="true"
        :input-state="inputState"
        :size="'large'"
        :shuffle="true"
        @input="handleInput"
        @input-ready="handleInputReady"
      />
    </div>
  </div>
</template>

<script>
import InputState from '../models/InputState'
import ChoiceInput from './input/ChoiceInput'

export default {
  components: { ChoiceInput },
  props: {
    id: { type: String, required: true },
    choices: { type: Array, required: true }
  },
  data() {
    return {
      inputState: InputState.createEditable(),
      value: ''
    }
  },
  methods: {
    handleInput(value) {
      this.value = value
    },
    handleInputReady() {
      this.$emit('choice-made', this.value)
    }
  }
}
</script>
