<script>
import { EventBus, MODAL_OPEN, MODAL_CLOSE } from 'shared'

export default {
  data() {
    return {
      component: null,
      attributes: {},
      children: []
    }
  },
  computed: {
    showModal() {
      return this.component !== null
    }
  },
  created() {
    EventBus.$on(MODAL_OPEN, ({ component, attributes, children }) => {
      this.component = component
      this.attributes = attributes || {}
      this.children = children || []
    })
    EventBus.$on(MODAL_CLOSE, () => {
      this.component = null
      this.attributes = {}
      this.children = []
    })
  },
  render(h) {
    if (!this.showModal) {
      return null
    }
    return h(this.component, { ...this.attributes }, [...this.children])
  }
}
</script>
