<template>
  <div style="font-size: 0.9em">
    <div class="flex flex-row" style="margin-bottom: 1em">
      <div
        v-for="choice in orderableChoices"
        :key="choice.id"
        class="flex justify-center items-center"
        style="height: 2.75em; width: 3.85em; margin: 0"
      >
        <DraggableChoice
          :read-only="readOnly"
          :available="choice.available"
          :dragging="choiceIsDragging(choice)"
          @dragging-start="onChoiceStartDrag(choice)"
          @dragging="onChoiceDragging"
          @dropped="onChoiceDropped(choice)"
        >
          {{ choice.content }}
        </DraggableChoice>
      </div>
    </div>
    <div class="flex flex-row">
      <Dropzone
        v-for="(dropzone, dropzoneIndex) in dropzones"
        :id="dropzone.id"
        ref="dropzones"
        :key="dropzoneIndex"
        :disabled="readOnly"
        :active="dropzoneIsActive(dropzone)"
        :dropped-choices="dropzone.droppedChoices"
        style="
          height: 3em;
          width: 3.75em;
          margin-left: 0.05em;
          margin-right: 0.05em;
        "
      >
        <DraggableChoice
          v-for="droppedChoice in dropzone.droppedChoices"
          :key="`${dropzoneIndex}-${droppedChoice.id}`"
          :read-only="readOnly"
          :state="stateForChoice(droppedChoice, dropzoneIndex)"
          :dragging="choiceIsDragging(droppedChoice)"
          :available="true"
          @dragging-start="onChoiceStartDrag(droppedChoice)"
          @dragging="onChoiceDragging"
          @dropped="onChoiceDropped(droppedChoice)"
        >
          {{ droppedChoice.content }}
        </DraggableChoice>
      </Dropzone>
    </div>
  </div>
</template>

<script>
import shuffle from 'knuth-shuffle-seeded'
import DraggableChoice from './DraggableChoice'
import Dropzone from './Dropzone'
import Rectangle from '../../../models/geometry/Rectangle'
import InputState from '../../../models/InputState'

export default {
  components: { Dropzone, DraggableChoice },
  props: {
    id: { type: String, required: true },
    choices: { type: Array, required: true },
    shuffle: { type: Boolean, default: true },
    hasFocus: { type: Boolean, required: true },
    inputState: { type: InputState, required: true },
    value: { type: String, default: '' },
    correctValue: { type: String, default: '' }
  },
  data() {
    let orderableChoices = shuffle(this.choices).map((choice) => {
      return { ...choice, available: true }
    })
    return {
      orderableChoices,
      dropzones: [],
      draggingChoice: null,
      activeDropzone: null
    }
  },
  computed: {
    readOnly() {
      return this.inputState.isEditable() === false
    },
    outputValue() {
      return this.dropzones
        .map((dropzone) => {
          if (!dropzone.droppedChoices[0]) {
            return null
          }
          return dropzone.droppedChoices[0].id
        })
        .join(',')
    },
    dropzonesFilled() {
      return !this.dropzones.some((dropzone) => {
        return dropzone.droppedChoices.length === 0
      })
    }
  },
  watch: {
    dropzones() {
      this.$emit('input', this.outputValue)
      if (this.dropzonesFilled) {
        this.$emit('input-ready')
      }
    },
    value() {
      this.updateDropZones()
    }
  },
  mounted() {
    this.updateDropZones()
  },
  methods: {
    updateDropZones() {
      const values = this.value === '' ? [] : this.value.split(',')
      if (values.length && values.length !== this.choices.length) {
        throw new Error(
          `The provided values do not match the choice count. Provided values: ${this.value}, choice count: ${this.choices.length}`
        )
      }
      const dropzones = []
      this.orderableChoices = this.orderableChoices.map((orderableChoice) => {
        return { ...orderableChoice, available: true }
      })
      this.choices.forEach((choice, index) => {
        // the value is an array of identifiers e.g. ['I', 'A', 'C'] etc:
        const droppedChoice = this.choices.find((choice) => {
          return choice.id === values[index]
        })
        if (droppedChoice) {
          this.orderableChoices = this.orderableChoices.map(
            (orderableChoice) => {
              if (droppedChoice.id === orderableChoice.id) {
                return { ...orderableChoice, available: false }
              }
              return orderableChoice
            }
          )
        }
        dropzones.push({
          id: index,
          droppedChoices: droppedChoice ? [droppedChoice] : []
        })
      })
      this.dropzones = dropzones
    },
    choiceIsDragging(choice) {
      if (!this.draggingChoice) {
        return false
      }
      return this.draggingChoice.id === choice.id
    },
    dropzoneIsActive(dropzone) {
      if (!this.activeDropzone) {
        return false
      }
      return this.activeDropzone.id === dropzone.id
    },
    onChoiceStartDrag(choice) {
      if (!this.inputState.isEditable()) {
        return
      }
      this.draggingChoice = choice
    },
    onChoiceDragging({ rectangle, position }) {
      if (!this.inputState.isEditable()) {
        return
      }
      const intersectingDropzoneComponent = this.getIntersectingDropzone(
        rectangle,
        position
      )
      if (!intersectingDropzoneComponent) {
        this.activeDropzone = null
        return
      }
      const activeDropzone = this.dropzones.find((dropzone) => {
        return dropzone.id === intersectingDropzoneComponent.id
      })
      if (!activeDropzone) {
        throw new Error(
          'cannot find match dropzone component with dropzone data'
        )
      }
      this.activeDropzone = activeDropzone
    },
    onChoiceDropped(droppedChoice) {
      if (!this.inputState.isEditable()) {
        return
      }
      this.draggingChoice = null
      if (!this.activeDropzone) {
        return
      }
      const existingDroppedChoice =
        this.activeDropzone.droppedChoices[0] || null
      this.dropzones = this.dropzones.map((dropzone) => {
        if (dropzone.id === this.activeDropzone.id) {
          return {
            ...dropzone,
            droppedChoices: [droppedChoice]
          }
        }
        // swap:
        if (
          dropzone.droppedChoices.some((choice) => {
            return choice.id === droppedChoice.id
          })
        ) {
          return {
            ...dropzone,
            droppedChoices: existingDroppedChoice ? [existingDroppedChoice] : []
          }
        }
        return dropzone
      })
      // if none of the dropzones contain the choice: it is available
      this.orderableChoices = this.orderableChoices.map((orderableChoice) => {
        const available = !this.dropzones.some((dropzone) => {
          return dropzone.droppedChoices.some(
            (choice) => choice.id === orderableChoice.id
          )
        })
        return {
          ...orderableChoice,
          available
        }
      })
      this.activeDropzone = null
    },
    stateForChoice(choice, index) {
      if (this.inputState.isCorrect()) {
        return 'correct'
      }
      if (this.inputState.isIncorrect()) {
        const correctValues = this.correctValue.split(',')
        return correctValues[index] === choice.id ? 'correct' : 'incorrect'
      }
      return 'default'
    },
    getIntersectingDropzone(draggedOptionRectangle, inputPosition) {
      let nearestDistanceToInputPosition = Infinity
      let nearestDropzone = null

      for (const dropzone of this.$refs.dropzones) {
        const dropzoneRectangle = Rectangle.fromDOMRect(
          dropzone.$refs.dropElement.getBoundingClientRect()
        )
        if (!dropzoneRectangle.collisionWith(draggedOptionRectangle)) {
          continue
        }
        const dropzoneCenter = dropzoneRectangle.getCenter()
        const distanceToInputPosition = inputPosition.distanceSq(dropzoneCenter)
        if (distanceToInputPosition < nearestDistanceToInputPosition) {
          nearestDropzone = dropzone
          nearestDistanceToInputPosition = distanceToInputPosition
        }
      }
      return nearestDropzone
    }
  }
}
</script>

<style scoped></style>
