<template>
  <div>
    <div
      v-if="bricks !== null"
      id="wall-container"
      class="wall2021-grid-container w-full h-full relative"
      :style="{ 'font-size': fontSize }"
    >
      <template v-for="phase in wall">
        <WallPhase
          :key="'phase' + phase.number"
          :phase="phase"
          :phase-labels="phaseLabels"
          :changed-bricks="changedBricks"
          :reference-date="referenceDate"
        >
          <template v-for="(index, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
        </WallPhase>
      </template>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import WallPhase from './WallPhase.vue'
import Loader from '../Loader.vue'

export default {
  components: { Loader, WallPhase },
  props: {
    customBricks: { type: Array, default: undefined },
    phaseLabels: { type: String, default: 'normal' },
    changedBricks: { type: Array, default: undefined },
    referenceDate: { type: String, default: null }
  },
  data() {
    return {
      plainBricks: null,
      fontSize: '0.875rem'
    }
  },
  async fetch() {
    if (this.customBricks === undefined) {
      const resultData = await this.$axios.$get('/bareka')
      this.plainBricks = resultData.bricks
    }
  },
  computed: {
    bricks() {
      return this.customBricks ? this.customBricks : this.plainBricks
    },
    wall() {
      if (this.bricks === null) {
        return null
      }
      return this.phases
        .map((phase) => {
          return {
            name: phase.name,
            number: phase.name.replace('Laag ', ''),
            subTitle: phase.subTitle,
            rows: this.getRowsForPhase(phase.name).reverse()
          }
        })
        .reverse()
    },
    phases() {
      if (this.bricks === null) {
        return null
      }
      return this.bricks.reduce((phases, brick) => {
        if (
          phases.find((phase) => phase.name === brick.phaseName) === undefined
        ) {
          phases.push({
            name: brick.phaseName,
            subTitle: brick.phaseSubTitle
          })
        }
        return phases
      }, [])
    }
  },
  async mounted() {
    this.adjustFontSize()
    window.addEventListener('resize', this.adjustFontSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustFontSize)
  },
  methods: {
    getRowsForPhase(phaseName) {
      const rows = [
        ...new Set(
          this.bricks
            .filter((brick) => brick.phaseName === phaseName)
            .map((brick) => brick.row)
        )
      ]
      return rows.map((row) => {
        return this.bricks.filter(
          (brick) => brick.phaseName === phaseName && brick.row === row
        )
      })
    },
    adjustFontSize() {
      const container = this.$el.querySelector('#wall-container')
      if (!container) {
        return
      }
      const containerWidth = container.offsetWidth
      const containerHeight = container.offsetHeight
      this.fontSize =
        Math.min(containerWidth * 0.013 + 4, containerHeight * 0.021 + 4) + 'px'
    }
  }
}
</script>

<style lang="scss">
.wall2021-grid-container {
  padding: 0 !important;
  min-width: 700px;
  min-height: 500px;
  display: grid;
  gap: 0.3rem;
  grid-template-columns: 1fr 8fr;
  grid-template-areas:
    'layer5-title layer5-content'
    'layer4-title layer4-content'
    'layer3-title layer3-content'
    'layer2-title layer2-content'
    'layer1-title layer1-content';
}
</style>
