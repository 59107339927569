<template>
  <Eggs :count="count" color="black" />
</template>
<script>
import Eggs from './Eggs'
export default {
  components: { Eggs },
  props: {
    count: { type: Number, required: true }
  }
}
</script>
