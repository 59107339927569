<template>
  <Button type="secondary" :class="classNames" @click="onClick()">
    <slot />
  </Button>
</template>

<script>
import Button from './Button.vue'

export default {
  components: {
    Button
  },
  props: {
    toggle: { type: Boolean, default: false }
  },
  computed: {
    classNames() {
      return this.toggle ? 'toggle-on' : ''
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-on {
  @apply text-white bg-primary;
}
</style>
