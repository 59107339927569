<template>
  <NumberInput
    :id="responseIdentifier"
    :input-state="response.inputState"
    :value="response.value"
    :has-focus="response.hasFocus"
    :input-timeout="settings.inputTimeout"
    :input-mode="inputHandler.inputMode"
    :expected-length="response.correctValue.length"
    :final-response="responseSet.isFinalResponse(response)"
    @input="(value) => responseSet.handleInput(responseIdentifier, value)"
    @input-ready="responseSet.handleInputReady(responseIdentifier)"
    @input-done="inputDone"
    @focus="responseSet.setFocusOnResponse(responseIdentifier)"
    @keyboard-input="responseSet.handleKeyboardInput()"
  />
</template>

<script>
import NumberInput from '../../input/NumberInput'
import QtiBaseMixin from '../../QtiBaseMixin'

export default {
  components: { NumberInput },
  mixins: [QtiBaseMixin],
  props: {
    responseIdentifier: { type: String, default: '' }
  },
  computed: {
    response() {
      return this.responseSet.responseById(this.responseIdentifier)
    }
  },
  methods: {
    inputDone() {
      if (this.inputHandler.inputMode.isKeyboard()) {
        this.responseSet.setFocusOnNextResponse()
      }
    }
  }
}
</script>
