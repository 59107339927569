<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewBox"
    :style="{ width, height }"
    :fill="fill"
    :stroke="stroke"
    :stroke-width="strokeWidth"
  >
    <slot />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, default: 'currentColor' },
    stroke: { type: String, default: 'none' },
    strokeWidth: { type: String, default: '1' },
    viewBox: { type: String, default: '0 0 24 24' },
    width: { type: String, default: 'auto' },
    height: { type: String, default: '1em' }
  }
}
</script>

<style scoped lang="scss">
svg {
  @apply inline-block align-middle flex-none;
}
</style>
