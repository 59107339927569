<template>
  <button
    class="focus:outline-none text-2xl"
    style="line-height: 1"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  // todo add hover states etc
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>
