<template>
  <Bar
    :chart-data="chartData"
    :chart-options="chartOptions"
    style="width: 8em; position: relative"
  />
</template>

<script>
import { formatNumber } from 'chart.js/helpers'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

export default {
  components: { Bar },
  props: {
    type: { type: String, default: 'bar' },
    data: { type: String, required: true },
    direction: { type: String, default: 'horizontal' },
    max: { type: String, default: '' },
    step: { type: String, default: '' }
  },
  computed: {
    labels() {
      const data = JSON.parse(this.data)
      let labels = Object.keys(data)
      if (this.direction === 'horizontal') {
        // If horizontal, reverse the data so it goed from bottom to top
        labels = labels.reverse()
      }
      return labels
    },
    values() {
      const data = JSON.parse(this.data)
      let values = Object.values(data)
      if (this.direction === 'horizontal') {
        // If horizontal, reverse the data so it goed from bottom to top
        values = values.reverse()
      }
      return values
    },
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            data: this.values,
            backgroundColor: 'green'
          }
        ]
      }
    },
    chartOptions() {
      return {
        responsive: true,
        indexAxis: this.direction === 'horizontal' ? 'y' : 'x',
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        scales: {
          [this.direction === 'horizontal' ? 'x' : 'y']: {
            max: this.max ? Number(this.max) : undefined,
            ticks: {
              stepSize: this.step ? Number(this.step) : undefined,
              callback: function (value) {
                return formatNumber(value)
              }
            }
          }
        }
      }
    }
  }
}
</script>
