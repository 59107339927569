<template>
  <div class="pronounce-input" :class="classObject">
    <div>{{ content }}</div>
    <div v-if="showIncorrectAnswer" class="text-sm">Gehoord: {{ value }}</div>
  </div>
</template>

<script>
import InputState from '../../../../models/InputState'
import { generateCssClassesForInputState } from '../../../input/utils'
import { mapGetters } from 'vuex'

export default {
  props: {
    content: { type: [String, Number], required: true },
    value: { type: String, required: true },
    hasFocus: { type: Boolean, default: false },
    inputState: { type: InputState, required: true }
  },
  data() {
    return {
      correctValue: this.content
    }
  },
  computed: {
    ...mapGetters({
      userIsTeacher: 'auth/userIsTeacher'
    }),
    showIncorrectAnswer() {
      return (
        this.userIsTeacher &&
        !this.inputState.isCorrect() &&
        !this.inputState.isEditable()
      )
    },
    classObject() {
      return {
        ...generateCssClassesForInputState('pronounce-input', this.inputState),
        'pronounce-input--focus': this.hasFocus,
        'pronounce-input--filled': this.value !== '' && !this.hasFocus
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../../../component-library/assets/scss/wall';

.pronounce-input {
  @apply p-6 border-gray rounded-full rounded-bl-none;
  border-width: 0.125em;
  margin: 0.2em;
}
.pronounce-input:not(.pronounce-input--readonly) {
  &.pronounce-input--focus {
    @apply bg-yellow-100 border-yellow-500;
  }
  &.pronounce-input--filled {
    @apply bg-yellow-500 border-yellow-500;
  }
}
.pronounce-input--correct {
  @apply bg-score-green border-score-green text-white;
}
.pronounce-input--incorrect {
  @apply bg-score-red border-score-red text-white;
}
</style>
