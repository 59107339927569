<template>
  <NumberInput id="readonly-input" :value="value" :input-state="inputState" />
</template>

<script>
// this is just an instance of a NumberInput but without event handling
// and inputState=predefined
import NumberInput from '../../../input/NumberInput'
import InputState from '../../../../models/InputState'

export default {
  components: { NumberInput },
  props: {
    value: { type: String, default: '' }
  },
  data() {
    return {
      inputState: InputState.createPredefined()
    }
  }
}
</script>
