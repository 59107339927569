<template>
  <div v-if="qtiItem" class="flex flex-row items-center justify-center">
    <SwitchButton
      v-if="showAnswerEnabled"
      :value="settings.showAnswer"
      @input="(val) => $emit('show-answer', val)"
    >
      Toon antwoord
    </SwitchButton>
    <SwitchButton
      v-if="qtiItem.hasStrategyContent"
      :value="settings.showStrategy"
      class="ml-4"
      @input="(val) => $emit('show-strategy', val)"
    >
      Toon strategie
    </SwitchButton>
    <SwitchButton
      v-if="qtiItem.hasJumpNumberLineContent"
      :value="settings.showJumpNumberLine"
      class="ml-4"
      @input="(val) => $emit('show-jump-number-line', val)"
    >
      Toon getallenlijn
    </SwitchButton>
  </div>
</template>

<script>
import { SwitchButton } from 'component-library'
import Item from '../models/Item'
import ItemSettings from '../models/ItemSettings'
import qtiItemService from '../services/qtiItemService'

export default {
  components: { SwitchButton },
  props: {
    item: { type: Item, required: true },
    settings: { type: ItemSettings, required: true },
    showAnswerEnabled: { type: Boolean, default: false }
  },
  computed: {
    qtiItem() {
      return qtiItemService.extractFromItem(this.item, this.settings)
    }
  }
}
</script>
