<template>
  <IconBase view-box="0 0 384 384">
    <circle cx="192" cy="42.667" r="42.667" />
    <circle cx="192" cy="192" r="42.667" />
    <circle cx="192" cy="341.333" r="42.667" />
  </IconBase>
</template>

<script>
import IconBase from './IconBase.vue'
export default {
  components: { IconBase }
}
</script>
