<template>
  <IconBase
    stroke="currentColor"
    view-box="0 0 56.693 56.689"
    enable-background="new 0 0 56.693 56.689"
  >
    <path
      id="XMLID_1478_"
      d="M52.093,22.131L29.084,11.128c-0.229-0.11-0.483-0.167-0.738-0.167h-6.848
	c-0.946,0-1.713,0.768-1.713,1.712v1.143c0,2.202-1.792,3.994-3.995,3.994H2.094c-0.946,0-1.713,0.767-1.713,1.712v14.838v3.424
	c0,2.83,2.305,5.135,5.137,5.135h45.657c2.832,0,5.137-2.305,5.137-5.135v-3.424v-5.536C56.311,25.984,54.653,23.356,52.093,22.131z
	 M52.885,28.823v3.823h-4.564v-8.523l2.297,1.098C51.997,25.88,52.885,27.295,52.885,28.823z M23.186,14.385h4.77l2.1,1.004
	l-1.779,1.779c-0.669,0.668-0.669,1.752,0,2.421c0.333,0.334,0.772,0.502,1.211,0.502c0.438,0,0.877-0.167,1.209-0.502l2.637-2.635
	l3.676,1.757l-1.883,1.88c-0.668,0.669-0.668,1.753,0,2.422c0.336,0.334,0.773,0.502,1.211,0.502c0.439,0,0.877-0.167,1.213-0.502
	l2.734-2.735l4.613,2.207v10.16h-4.744L16.389,21.206C20.013,20.915,22.909,18.011,23.186,14.385z M3.805,21.232h4.746
	l23.706,11.414H3.805V21.232z M51.175,39.493H5.518c-0.943,0-1.713-0.767-1.713-1.711V36.07h35.948c0.002,0,0.006,0,0.008,0
	c0.004,0,0.008,0,0.012,0h13.113v1.713C52.885,38.727,52.12,39.493,51.175,39.493z"
    />
  </IconBase>
</template>

<script>
import IconBase from './IconBase.vue'
export default {
  components: { IconBase }
}
</script>
