<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style="width: auto; height: 1.25em"
    viewBox="0 0 283.965 28.846"
  >
    <g id="squares">
      <rect
        v-if="count === 10"
        id="_x31_0"
        x="255.368"
        y="0.25"
        :fill="squareColor"
        width="28.346"
        height="28.346"
      />
      <rect
        v-if="count >= 9"
        id="_x39_"
        x="227.021"
        y="0.25"
        :fill="squareColor"
        width="28.346"
        height="28.346"
      />
      <rect
        v-if="count >= 8"
        id="_x38_"
        x="198.675"
        y="0.25"
        :fill="squareColor"
        width="28.346"
        height="28.346"
      />
      <rect
        v-if="count >= 7"
        id="_x37_"
        x="170.329"
        y="0.25"
        :fill="squareColor"
        width="28.346"
        height="28.346"
      />
      <rect
        v-if="count >= 6"
        id="_x36_"
        x="141.982"
        y="0.25"
        :fill="squareColor"
        width="28.346"
        height="28.346"
      />
      <rect
        v-if="count >= 5"
        id="_x35_"
        x="113.636"
        y="0.25"
        :fill="squareColor"
        width="28.346"
        height="28.346"
      />
      <rect
        v-if="count >= 4"
        id="_x34_"
        x="85.289"
        y="0.25"
        :fill="squareColor"
        width="28.346"
        height="28.346"
      />
      <rect
        v-if="count >= 3"
        id="_x33_"
        x="56.943"
        y="0.25"
        :fill="squareColor"
        width="28.346"
        height="28.346"
      />
      <rect
        v-if="count >= 2"
        id="_x32_"
        x="28.596"
        y="0.25"
        :fill="squareColor"
        width="28.346"
        height="28.346"
      />
      <rect
        v-if="count >= 1"
        id="_x31_"
        x="0.25"
        y="0.25"
        :fill="squareColor"
        width="28.346"
        height="28.346"
      />
    </g>
    <g id="lines">
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="0"
        y1="0.25"
        x2="28.596"
        y2="0.25"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="0.25"
        y1="28.346"
        x2="0.25"
        y2="0.5"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="28.596"
        y1="0.25"
        x2="56.942"
        y2="0.25"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="28.596"
        y1="28.346"
        x2="28.596"
        y2="0.5"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="56.943"
        y1="0.25"
        x2="85.289"
        y2="0.25"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="56.943"
        y1="28.346"
        x2="56.943"
        y2="0.5"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="85.289"
        y1="0.25"
        x2="113.635"
        y2="0.25"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="85.289"
        y1="28.346"
        x2="85.289"
        y2="0.5"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="113.636"
        y1="0.25"
        x2="141.982"
        y2="0.25"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="113.636"
        y1="28.346"
        x2="113.636"
        y2="0.5"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="141.982"
        y1="0.25"
        x2="170.328"
        y2="0.25"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="170.329"
        y1="0.25"
        x2="198.675"
        y2="0.25"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="170.329"
        y1="28.346"
        x2="170.329"
        y2="0.5"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="198.675"
        y1="0.25"
        x2="227.021"
        y2="0.25"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="198.675"
        y1="28.346"
        x2="198.675"
        y2="0.5"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="227.022"
        y1="0.25"
        x2="255.368"
        y2="0.25"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="227.022"
        y1="28.346"
        x2="227.022"
        y2="0.5"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="255.368"
        y1="0.25"
        x2="283.964"
        y2="0.25"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="255.368"
        y1="28.346"
        x2="255.368"
        y2="0.5"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="283.715"
        y1="28.346"
        x2="283.715"
        y2="0.5"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="0"
        y1="28.596"
        x2="28.596"
        y2="28.596"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="28.596"
        y1="28.596"
        x2="56.942"
        y2="28.596"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="56.943"
        y1="28.596"
        x2="85.289"
        y2="28.596"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="85.289"
        y1="28.596"
        x2="113.635"
        y2="28.596"
      />

      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="113.636"
        y1="28.596"
        x2="141.982"
        y2="28.596"
      />

      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="141.982"
        y1="28.596"
        x2="170.328"
        y2="28.596"
      />

      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="170.329"
        y1="28.596"
        x2="198.675"
        y2="28.596"
      />

      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="198.675"
        y1="28.596"
        x2="227.021"
        y2="28.596"
      />

      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="227.022"
        y1="28.596"
        x2="255.368"
        y2="28.596"
      />

      <line
        fill="none"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-miterlimit="10"
        x1="255.368"
        y1="28.596"
        x2="283.964"
        y2="28.596"
      />
      <line
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        x1="141.982"
        y1="28.346"
        x2="141.982"
        y2="0.5"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: function (value) {
        if (value < 1) {
          return false
        }
        return value <= 10
      }
    }
  },
  data() {
    return {
      squareColor: '#929497'
    }
  }
}
</script>
