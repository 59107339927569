<template>
  <QtiChoiceInteraction v-bind="$props" size="medium" :inline="true">
    <slot />
  </QtiChoiceInteraction>
</template>

<script>
import QtiChoiceInteraction from './QtiChoiceInteraction'

export default {
  components: { QtiChoiceInteraction },
  props: {
    responseIdentifier: { type: String, default: '' },
    dataChoiceContentType: { type: String, default: 'text' },
    orientation: { type: String, default: 'horizontal' },
    shuffle: { type: Boolean, default: false }
  }
}
</script>
