<template>
  <div
    class="inline-flex flex-row items-center cursor-pointer"
    @click.prevent="onToggle"
  >
    <div class="switch-button" :class="{ 'switch-button--toggled': value }">
      <div class="switch-button__handle"></div>
    </div>
    <span class="text-base select-none ml-3">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  methods: {
    onToggle() {
      if (this.disabled) {
        return
      }
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style scoped lang="scss">
.switch-button {
  @apply w-12 h-8 flex items-center bg-gray-dark rounded-full p-1 duration-100 ease-in-out;
}
.switch-button__handle {
  @apply bg-white w-6 h-6 rounded-full shadow transform duration-100 ease-in-out;
}

.switch-button--toggled {
  @apply bg-blue;
  .switch-button__handle {
    @apply translate-x-4;
  }
}
</style>
