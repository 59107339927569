import escapeStringRegexp from 'escape-string-regexp'

import {
  getChoices,
  isChoiceInputElement,
  isNumberInputElement,
  isPronounceInput,
  isSplitNumberInteraction
} from './response/extractResponses'
import normalizeSpeechInput from '../speech/normalizeSpeechInput'

const matchExpression = (expression, value) => {
  // look behind start of line or whitespace:
  const expStart = '.*(\\s|^|,)'
  // look ahead end of line or whitespace:
  const expEnd = '(\\s|$|,).*'
  const regex = new RegExp(`${expStart}(${expression})${expEnd}`)
  const matches = value.match(regex) // regex consists of 3 groups: optional prefix (spaces etc), the value that
  // can be heard and the optional suffix. matches[2] contains the value:
  return matches !== null ? matches[2].trim() : null
}

function createSpeechInputHandlerForInteractionElement(
  interactionElement,
  correctResponse
) {
  if (
    isNumberInputElement(interactionElement) ||
    isSplitNumberInteraction(interactionElement) ||
    isPronounceInput(interactionElement)
  ) {
    // note: input may contain a thousand separator
    return (alternatives) => {
      normalizeSpeechInput(alternatives)
      for (const alternative of alternatives) {
        const matchCorrect = matchExpression(
          correctResponse.correctValues[0].replace(/\./g, ''),
          alternative.transcript
        )
        if (matchCorrect !== null) {
          return matchCorrect
        }
      }
      for (const alternative of alternatives) {
        if (!alternative.reliable || !alternative.final) {
          continue
        }
        const matchNumber = matchExpression(
          '\\d+(\\.{1})?(\\d+)?',
          alternative.transcript
        )
        if (matchNumber !== null) {
          return matchNumber
        }
      }
      return null
    }
  }
  if (isChoiceInputElement(interactionElement)) {
    const choices = getChoices(interactionElement)
    const choicesExpression = choices
      .map((choice) => escapeStringRegexp(choice.content.replace(/\./g, '')))
      .join('|')
    return (alternatives) => {
      normalizeSpeechInput(alternatives)
      for (const alternative of alternatives) {
        const match = matchExpression(choicesExpression, alternative.transcript)
        if (match !== null) {
          for (const { id, content } of choices) {
            if (content.replace(/\./g, '') === match) {
              return id
            }
          }
          console.warn(
            `matched speech input for response, but no matching choice found for ${match}`
          )
        }
      }
      return null
    }
  }
  return null
}

export { createSpeechInputHandlerForInteractionElement }
