<template>
  <IconBase
    stroke="currentColor"
    view-box="0 0 56.693 56.689"
    enable-background="new 0 0 56.693 56.689"
  >
    <path
      d="M55.928,28.346c0,4.343,0.002,8.685-0.002,13.026c-0.002,1.95-0.523,2.476-2.484,2.486
	c-1.85,0.011-3.699,0.015-5.549-0.003c-1.719-0.018-2.26-0.563-2.262-2.281c-0.006-8.738-0.004-17.476,0-26.214
	c0.002-0.347,0.02-0.697,0.061-1.042c0.105-0.858,0.604-1.429,1.457-1.449c2.41-0.055,4.822-0.08,7.232-0.015
	c1.014,0.026,1.482,0.672,1.523,1.821c0.045,1.23,0.021,2.465,0.023,3.697C55.93,21.699,55.928,25.023,55.928,28.346z"
    />
    <path
      d="M26.039,30.997c0,3.351,0.002,6.7-0.001,10.05c-0.001,0.429-0.001,0.859-0.048,1.283
	c-0.084,0.775-0.479,1.294-1.292,1.415c-0.264,0.039-0.528,0.103-0.794,0.104c-2.036,0.008-4.073,0.03-6.109-0.005
	c-1.496-0.026-2.054-0.577-2.058-2.068c-0.018-7.235-0.017-14.471-0.001-21.706c0.003-1.499,0.541-2.042,2.049-2.072
	c2.063-0.04,4.127-0.036,6.189-0.003c1.496,0.023,2.033,0.576,2.041,2.071c0.017,3.644,0.005,7.289,0.005,10.933
	C26.029,30.997,26.034,30.997,26.039,30.997z"
    />
    <path
      d="M41.026,33.495c0,2.654,0.01,5.309-0.004,7.961c-0.008,1.846-0.574,2.396-2.412,2.398
	c-1.875,0.002-3.752,0.006-5.629-0.011c-1.75-0.017-2.313-0.579-2.314-2.327c-0.008-5.334-0.008-10.668,0-16.002
	c0.004-1.774,0.441-2.271,2.217-2.31c2.113-0.046,4.232-0.033,6.348,0.032c1.258,0.04,1.75,0.561,1.766,1.814
	c0.033,2.813,0.012,5.628,0.012,8.441C41.012,33.495,41.018,33.495,41.026,33.495z"
    />
    <path
      d="M11.167,36.144c0,1.768,0.008,3.535-0.002,5.302c-0.01,1.836-0.589,2.418-2.396,2.421
	c-1.901,0.003-3.803,0.01-5.704-0.01c-1.676-0.019-2.26-0.547-2.276-2.204c-0.037-3.749-0.035-7.498-0.015-11.247
	c0.007-1.409,0.618-2.01,2.021-2.028c2.089-0.026,4.179-0.025,6.267,0c1.461,0.019,2.066,0.63,2.096,2.144
	c0.035,1.874,0.008,3.749,0.008,5.624C11.165,36.144,11.166,36.144,11.167,36.144z"
    />
  </IconBase>
</template>

<script>
import IconBase from './IconBase.vue'
export default {
  components: { IconBase }
}
</script>
