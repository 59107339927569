<script>
export default {
  inject: {
    injectedSettings: { from: 'settings' },
    injectedInputHandler: { from: 'inputHandler' },
    injectedResponseSet: { from: 'responseSet' },
    injectedAssetHost: { from: 'assetHost' }
  },
  computed: {
    /**
     * @return {ItemSettings}
     */
    settings() {
      return this.injectedSettings()
    },
    /**
     * @return {InputHandler}
     */
    inputHandler() {
      return this.injectedInputHandler()
    },
    /**
     * @return {ResponseSet}
     */
    responseSet() {
      return this.injectedResponseSet()
    },
    /** @return {string} **/
    assetHost() {
      return this.injectedAssetHost()
    }
  },
  methods: {
    getComponentsWithTags(tags) {
      return this.$slots.default.filter((node) => {
        if (node.componentOptions) {
          return tags.includes(node.componentOptions.tag)
        }
        return tags.includes(node.tag)
      })
    }
  }
}
</script>
