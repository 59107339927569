<template>
  <div class="modal-feedback" :class="classes">
    <p v-if="incorrect" class="text-xl mb-2">Het goede antwoord is:</p>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    identifier: { type: String, default: null }
  },
  computed: {
    incorrect() {
      return this.identifier === 'CORRECT_ANSWER'
    },
    classes() {
      return {
        'modal-feedback--incorrect': this.incorrect,
        'modal-feedback--correct': !this.incorrect
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../../component-library/assets/scss/wall';

.modal-feedback {
  @apply fixed bottom-0 inset-x-0 z-10 py-4;
  @apply flex flex-col justify-center items-center w-full;
}

.modal-feedback--correct {
  @apply bg-score-green-light text-score-green-dark;
}

.modal-feedback--incorrect {
  @apply bg-score-red-light text-score-red-dark;
}

// content will be rendered in a div:
.modal-feedback > div {
  @apply flex flex-row flex-wrap items-center;
  @apply text-3xl;
}
</style>
