<template>
  <div>
    <component
      :is="dataInteractionType"
      v-bind="props"
      :id="responseIdentifier"
      :value="response.value"
      :has-focus="response.hasFocus"
      :input-mode="inputHandler.inputMode"
      :input-state="response.inputState"
      @input="(value) => responseSet.handleInput(responseIdentifier, value)"
      @input-ready="responseSet.handleInputReady(responseIdentifier)"
      @focus="responseSet.setFocusOnResponse(responseIdentifier)"
      @keyboard-input="responseSet.handleKeyboardInput()"
    />
  </div>
</template>

<script>
import QtiBaseMixin from '../../QtiBaseMixin'

export default {
  mixins: [QtiBaseMixin],
  props: {
    dataInteractionType: { type: String, required: true },
    dataProps: { type: String, default: '{}' },
    responseIdentifier: { type: String, required: true }
  },
  computed: {
    response() {
      return this.responseSet.responseById(this.responseIdentifier)
    },
    props() {
      return JSON.parse(this.dataProps)
    }
  }
}
</script>
