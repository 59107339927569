<template>
  <AudioButton
    v-if="showPromptAudioButtons"
    class="flex-none mr-2"
    :audio-url="audioUrl"
    :autoplay="autoplay"
    :inline="true"
    @stopped="handleAudioPlayed"
  />
</template>

<script>
import AudioButton from './AudioButton'
import { mapActions } from 'vuex'
import QtiBaseMixin from '../QtiBaseMixin'

export default {
  components: { AudioButton },
  mixins: [QtiBaseMixin],
  props: {
    audioUrl: { type: String, required: true }
  },
  computed: {
    showPromptAudioButtons() {
      if (!this.settings) {
        return true
      }
      return this.settings.showPromptAudioButtons
    },
    autoplay() {
      if (!this.settings || !this.settings.autoPlayPromptAudio) {
        return false
      }
      const lastPlayedAudioUrl =
        this.$store.state.resultSession.lastPlayedPromptAudioUrl
      return lastPlayedAudioUrl !== this.audioUrl
    }
  },
  methods: {
    ...mapActions({
      promptAudioPlayed: 'resultSession/promptAudioUrlPlayed'
    }),
    handleAudioPlayed() {
      this.promptAudioPlayed(this.audioUrl)
    }
  }
}
</script>
