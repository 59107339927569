<template>
  <RoundButton
    :disabled="loading"
    :active="playing"
    :size="size"
    :class="classes"
    @click="play()"
  >
    <Loader v-if="loading" size="small" />
    <IconAudio v-else />
  </RoundButton>
</template>

<script>
import { RoundButton, IconAudio, Loader } from 'component-library'
import { AUDIO_STARTED, AUDIO_STOPPED, EventBus } from 'shared'
import { getPreloadedSoundByUrl, createSound } from '../../utils/assetStore'
import QtiBaseMixin from '../QtiBaseMixin'

export default {
  components: { RoundButton, IconAudio, Loader },
  mixins: [QtiBaseMixin],
  props: {
    audioUrl: { type: String, required: true },
    autoplay: { type: Boolean, default: true },
    size: { type: String, default: 'small' },
    inline: { type: Boolean, default: false }
  },
  data() {
    return {
      sound: null,
      loading: true,
      playing: false,
      destroyed: false
    }
  },
  computed: {
    classes() {
      return this.inline ? 'audio-button--inline' : ''
    }
  },
  async mounted() {
    await this.initAudio()
    await this.$nextTick()
    if (this.autoplay) {
      this.play()
    }
  },
  beforeDestroy() {
    this.destroyed = true
    if (!this.playing) {
      return
    }
    this.stop()
    this.detachListeners(this.sound)
    EventBus.$emit(AUDIO_STOPPED)
  },
  methods: {
    async initAudio() {
      try {
        const audioUrl = this.audioUrl.startsWith('http')
          ? this.audioUrl
          : this.assetHost + '/' + this.audioUrl
        const preloadedSound = await getPreloadedSoundByUrl(audioUrl)
        // exit if component destroyed
        if (this.destroyed) {
          return
        }
        this.sound = preloadedSound ? preloadedSound : createSound(audioUrl)
        this.attachListeners(this.sound)
      } catch (e) {
        // If the sound fails to load, we don't want to block the entire page
        console.error(e)
      }
      this.loading = false
    },
    stop() {
      this.sound.stop()
    },
    onPlayStart() {
      console.log('sound playing')
      this.playing = true
      EventBus.$emit(AUDIO_STARTED)
    },
    onPlayEnd() {
      console.log('sound stopped or ended')
      this.playing = false
      EventBus.$emit(AUDIO_STOPPED)
      this.$emit('stopped')
    },
    detachListeners() {
      this.sound.off('play', this.onPlayStart)
      this.sound.off('end', this.onPlayEnd)
      this.sound.off('stop', this.onPlayEnd)
    },
    attachListeners() {
      this.sound.on('play', this.onPlayStart)
      this.sound.on('end', this.onPlayEnd)
      this.sound.on('stop', this.onPlayEnd)
    },
    play() {
      if (this.playing || !this.sound || this.loading) {
        return
      }
      this.sound.play()
    }
  }
}
</script>

<style lang="scss" scoped>
.audio-button {
  @apply rounded-full w-12 h-12 inline-flex items-center justify-center outline-none;
  @apply text-gray-dark bg-gray-light text-base;
  &:hover:not(.audio-button--active) {
    @apply bg-gray;
  }
}
.audio-button--active {
  @apply bg-blue text-white;
}

.audio-button--inline {
  @apply mr-2;
}

.dictation-button {
  margin-bottom: 2em;
}
</style>
