<template>
  <IconBase view-box="0 0 20 20">
    <path
      fill-rule="evenodd"
      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
      clip-rule="evenodd"
    />
  </IconBase>
</template>
<script>
import IconBase from './IconBase.vue'
export default {
  components: { IconBase }
}
</script>
