<template>
  <div>
    <slot name="prompt" />
    <div class="choice-input" :class="classes">
      <slot name="choices" />
    </div>
  </div>
</template>

<script>
import InputState from '../../models/InputState'
import { generateCssClassesForInputState } from './utils'

export default {
  props: {
    id: { type: String, required: true },
    value: { type: String, required: true },
    hasFocus: { type: Boolean, required: true },
    inputState: { type: InputState, required: true },
    orientation: { type: String, default: 'horizontal' },
    size: { type: String, default: 'medium' },
    choiceContentType: { type: String, default: 'text' },
    extraClasses: { type: Object, default: () => {} }
  },
  computed: {
    classes() {
      return {
        ...this.extraClasses,
        ...generateCssClassesForInputState('choice-input', this.inputState),
        [`choice-input--${this.orientation}`]: true,
        [`choice-input--${this.size}`]: true,
        [`choice-input--${this.choiceContentType}`]: true,
        'choice-input--focus': this.hasFocus,
        'choice-input---has-selection': this.value !== '',
        'choice-input--readonly': !this.inputState.isEditable()
      }
    }
  },
  watch: {
    value() {
      this.$emit('input-ready')
    }
  },
  mounted() {
    this.$slots.choices.forEach((choiceComponent) => {
      choiceComponent.componentInstance.$on(
        'select',
        this.handleClick.bind(this)
      )
    })
  },
  methods: {
    isSelected(choiceId) {
      return choiceId === this.value
    },
    handleClick(choiceId) {
      if (!this.inputState.isEditable()) {
        return
      }
      this.$emit('input', choiceId)
      this.$emit('keyboard-input')
    }
  }
}
</script>

<style lang="scss">
@import '../../../../component-library/assets/scss/wall';

.choice-input {
  @apply inline-flex relative;
  margin: 0.1em 0.2em;
}

.choice-input--block {
  gap: 0.8em;
}

.choice-input--horizontal {
  @apply flex-row;
  .choice-input__choice {
    @apply flex-none rounded-xl;
  }
}

.choice-input--vertical {
  @apply flex-col;
  .choice-input__choice {
    @apply flex-auto rounded-xl;
  }
}

.choice-input--correct,
.choice-input--incorrect,
.choice-input--readonly {
  .choice-input__choice {
    @apply pointer-events-none cursor-default;
  }
}
.choice-input--large {
  .choice-input__choice {
    @apply px-6;
    font-size: 1em;
  }
}

.choice-input--inline {
  &.choice-input--horizontal {
    .choice-input__choice {
      @apply rounded-none;
      margin-left: -4px;

      &:first-child {
        @apply rounded-l-xl;
        margin-left: 0;
      }

      &:last-child {
        @apply rounded-r-xl;
      }
    }
  }

  &.choice-input--vertical {
    @apply flex-col;
    .choice-input__choice {
      @apply rounded-none;
      margin-top: -4px;

      &:first-child {
        @apply rounded-t-xl;
        margin-top: 0;
      }

      &:last-child {
        @apply rounded-b-xl;
      }
    }
  }

  .choice-input__choice--selected {
    @apply cursor-default;
  }

  &.choice-input--correct {
    .choice-input__choice--selected {
      @apply bg-score-green border-score-green text-white;
    }
  }

  &.choice-input--incorrect {
    .choice-input__choice--selected {
      @apply border-score-red bg-score-red text-white;
    }
  }
}

.choice-input--block {
  .choice-input__choice {
    @apply p-8;
    border-width: 6px;
  }

  &.choice-input--correct {
    .choice-input__choice--selected {
      @apply bg-score-green border-score-green text-white;
    }
  }
  &.choice-input--incorrect {
    .choice-input__choice--selected {
      @apply border-score-red bg-score-red text-white;
    }
  }
}

.choice-input__choice {
  @apply cursor-pointer flex justify-center items-center;
  @apply border-gray text-gray-darkest py-2 px-4;
  border-width: 0.125em;
  font-size: 1em;
  z-index: 0;

  &:hover:not(.choice-input__choice--selected) {
    @apply bg-gray-light;
  }
}

.choice-input---has-selection {
  .choice-input__choice:not(.choice-input__choice--selected) {
    @apply text-gray;
    &:hover {
      @apply text-gray-darkest;
    }
  }
}

.choice-input__choice--selected {
  @apply text-gray-darkest bg-gray;
}

.choice-input--focus:not(.choice-input--readonly) {
  .choice-input__choice {
    @apply border-yellow-500;
    &:hover:not(.choice-input__choice--selected) {
      @apply bg-yellow-100;
    }
  }

  .choice-input__choice--selected {
    @apply text-gray-darkest bg-yellow-500;
  }
}
</style>
