const { parseTime } = require('shared')
const { replaceWord } = require('shared')

// import {
//   addThousandSeparatorToNumber,
//   shouldAddThousandSeparatorToNumber
// } from '../../utils/thousandSeparator'

const operators = {
  plus: '+',
  erbij: '+',
  min: '-',
  eraf: '-',
  is: '='
}

const numbers = {
  nul: 0,
  een: 1,
  twee: 2,
  drie: 3,
  vier: 4,
  vijf: 5,
  zes: 6,
  zeven: 7,
  acht: 8,
  negen: 9,
  tien: 10,
  elf: 11,
  twaalf: 12,
  dertien: 13,
  veertien: 14,
  vijftien: 15,
  zestien: 16,
  zeventien: 17,
  achttien: 18,
  negentien: 19,
  twintig: 20,
  // following words are often heard when the number is expected:
  ier: 4,
  fier: 4,
  ijf: 5,
  even: 7,
  ach: 8,
  '10e': 'tiende', // todo more generic naming needed
  duizend: 1000,
  tweeduizend: 2000,
  drieduizend: 3000,
  vierduizend: 4000,
  vijfduizend: 5000,
  zesduizend: 6000,
  zevenduizend: 7000,
  achtduizend: 8000,
  negenduizend: 9000,
  tienduizend: 10000
}

function wordToOperator(word) {
  return replaceWord(word, operators)
}

function wordToNumber(word) {
  return replaceWord(word, numbers)
}

/**
 * @param {Array<{ transcript: String; reliable: Boolean; final: Boolean }>} alternatives
 * @returns {string}
 */
export default function normalizeSpeechInput(alternatives) {
  alternatives.forEach((alternative) => {
    alternative.transcript = normalizeTranscript(alternative.transcript)
  })
  const newAlternatives = []
  alternatives.forEach((alternative) => {
    if (
      /^[0-9.]+$/.test(alternative.transcript) &&
      alternative.transcript.length > 2
    ) {
      // Workaround for bug in Google speech recognition, where it sometimes
      // not recognizes a zero in a number, for example 907000 is recognized as 97000
      for (let index = 1; index < alternative.transcript.length; index++) {
        newAlternatives.push({
          transcript:
            alternative.transcript.slice(0, index) +
            '0' +
            alternative.transcript.slice(index),
          reliable: alternative.reliable,
          final: alternative.final
        })
      }
    }
  })
  alternatives.push(...newAlternatives)
}

function normalizeTranscript(transcript) {
  transcript = parseTime(transcript)
  const tokens = transcript.split(' ')
  return tokens
    .map((token) => {
      // todo, make more efficient (stop on match)
      token = token.replace(/[€.]/g, '')
      token = wordToNumber(token)
      token = wordToOperator(token)
      // if (shouldAddThousandSeparatorToNumber(token)) {
      //   token = addThousandSeparatorToNumber(token)
      // }
      return token
    })
    .join(' ')
}
