<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style="width: 6em; height: auto"
      viewBox="0 0 142.2 107.5"
    >
      <g v-if="showMiddle" id="middle">
        <path
          class="fill-current"
          d="M71.013,66.321c-0.048,0.017-0.205,0.015-0.27,0.076c-0.1-0.001-0.201,0.042-0.265,0.083
		c-0.019-0.004-0.056-0.011-0.061,0.023c-0.23,0.091-0.482,0.149-0.69,0.281H69.69c-0.129,0.079-0.261,0.151-0.394,0.212
		c-0.006,0.05-0.07,0.045-0.076,0.098c-0.071,0.027-0.075,0.12-0.129,0.167l0.008,0.023c-0.012,0.014-0.024,0.026-0.038,0.038
		c-0.007,0.03,0.001,0.046-0.008,0.06c0.003,0.02-0.01,0.025-0.03,0.023c-0.025,0.114-0.07,0.26-0.091,0.386
		c0.009,0.024,0.014,0.052,0.03,0.068c-0.015,0.128,0.053,0.253,0.106,0.372c0.028,0.015,0.04,0.046,0.068,0.06
		c-0.021,0.081,0.098,0.129,0.114,0.22c0.022,0.009,0.035,0.025,0.053,0.038c0.012,0.059,0.044,0.097,0.091,0.121
		c0.008,0.081,0.097,0.11,0.099,0.174c0.035,0.043,0.075,0.081,0.091,0.144c0.025,0.021,0.045,0.045,0.075,0.061
		c0,0.025,0.022,0.029,0.015,0.061c0.053,0.032,0.058,0.113,0.122,0.136c0.007,0.063,0.068,0.138,0.129,0.174
		c0.001,0.014-0.009,0.017-0.008,0.031c0.043,0.007,0.03,0.069,0.083,0.068c0.008,0.055,0.034,0.092,0.076,0.113
		c0.132,0.23,0.274,0.47,0.439,0.682c-0.005,0.023,0.013,0.023,0.008,0.046c0.111,0.137,0.192,0.288,0.318,0.439
		c0.008,0.093,0.059,0.144,0.129,0.197c-0.005,0.028,0.013,0.033,0.008,0.061c0.073,0.114,0.106,0.268,0.197,0.364
		c-0.022,0.044,0.033,0.058,0.022,0.113l0.023,0.008c0.129,0.35,0.292,0.707,0.356,1.076c-0.006,0.014-0.012,0.028-0.015,0.045
		c0.044,0.066,0.005,0.173,0.053,0.228c-0.004,0.319,0.092,0.63,0.046,0.947l0.015,0.045c-0.033,0.08,0.025,0.22-0.03,0.296
		c0.013,0.096-0.031,0.179-0.023,0.272c-0.039,0.032-0.01,0.111-0.061,0.144c-0.084,0.384-0.311,0.84-0.515,1.197
		c-0.029-0.006-0.027,0.019-0.046,0.023c-0.015,0.068-0.083,0.084-0.09,0.159c-0.056,0.005-0.045,0.077-0.099,0.084
		c-0.013,0.035-0.02,0.075-0.061,0.083c-0.118,0.157-0.242,0.308-0.401,0.424c0,0.018-0.013,0.023-0.008,0.046
		c-0.019-0.004-0.016,0.014-0.038,0.007c-0.012,0.031-0.053,0.033-0.053,0.076c-0.083,0.025-0.096,0.121-0.182,0.144
		c-0.003,0.019-0.029,0.016-0.022,0.045c-0.106,0.039-0.171,0.159-0.281,0.205c-0.008,0.024-0.033,0.033-0.038,0.061
		c-0.115,0.059-0.251,0.158-0.363,0.272c-0.07,0.012-0.09,0.1-0.167,0.107c-0.298,0.29-0.695,0.45-1.015,0.636
		c-0.227,0.034-0.463,0.129-0.675,0.197l-0.022-0.015c-0.06,0.047-0.188,0.001-0.25,0.068c-0.29,0.007-0.609,0.105-0.902,0.167
		c-0.035-0.018-0.072-0.008-0.083,0.015c-0.113-0.007-0.24,0.022-0.326,0.068c-0.737,0.105-1.492,0.357-2.22,0.447
		c-0.038,0.051-0.086-0.001-0.114,0.053c-0.017-0.005-0.039-0.006-0.053-0.015c-0.24,0.143-0.491,0.169-0.704,0.288
		c-0.084,0.003-0.247,0.023-0.311,0.068c-0.053-0.021-0.092,0.023-0.129-0.015c-0.08,0.043-0.145-0.029-0.258-0.015
		c-0.041-0.041-0.101-0.061-0.151-0.091c-0.016-0.067-0.092-0.075-0.098-0.152c-0.04-0.012-0.048-0.065-0.084-0.06
		c0.01-0.085-0.01-0.202,0.015-0.273c-0.03-0.059-0.016-0.075-0.007-0.144c0.021-0.027,0.058-0.038,0.053-0.091
		c0.023-0.009,0.043-0.023,0.045-0.053l-0.007,0.008c-0.013-0.01,0.005-0.055,0.007-0.076c0.032-0.017,0.077-0.02,0.106-0.038
		c0.024-0.069,0.104-0.083,0.121-0.159c0.061,0.009,0.105-0.048,0.137-0.091c0.412-0.263,0.959-0.275,1.44-0.409
		c0.07,0.026,0.107-0.029,0.181-0.008c0.019-0.006,0.043-0.007,0.053-0.023c0.268-0.007,0.629-0.011,0.849-0.106
		c0.047,0.024,0.157,0.003,0.22,0c0.043-0.059,0.138-0.008,0.189-0.06c0.013-0.001,0.039,0.025,0.046-0.008
		c0.574-0.129,1.201-0.149,1.765-0.371c0.064,0.019,0.126-0.023,0.167-0.053c0.237-0.083,0.541-0.186,0.75-0.341
		c0.147-0.043,0.323-0.168,0.447-0.28c0.156-0.035,0.317-0.209,0.477-0.273c0.003-0.027,0.02-0.019,0.008-0.046
		c0.141-0.047,0.213-0.162,0.348-0.242C69.21,76.01,69.268,76,69.273,75.959c0.04,0.008,0.035-0.03,0.068-0.03
		c-0.001-0.057,0.054-0.057,0.053-0.114c0.041-0.01,0.071-0.03,0.076-0.076c0.03,0.003,0.068-0.043,0.076-0.075h0.022
		c0.022-0.026,0.045-0.067,0.053-0.099c0.023,0,0.026-0.02,0.046-0.023c0.043-0.101,0.146-0.141,0.174-0.257
		c0.175-0.141,0.273-0.326,0.349-0.568c0.054-0.037,0.049-0.133,0.106-0.167c0.191-0.565,0.2-1.24,0.136-1.788
		c-0.024-0.115-0.082-0.295-0.121-0.425l-0.023-0.007c-0.022-0.165-0.111-0.361-0.22-0.485c0.003-0.019-0.013-0.017-0.007-0.038
		c-0.03-0.015-0.052-0.039-0.076-0.061c0.006-0.031-0.015-0.035-0.015-0.06c-0.044-0.032-0.053-0.1-0.114-0.114
		c-0.021-0.052-0.038-0.108-0.098-0.121c-0.013-0.039-0.008-0.049-0.023-0.083c-0.033-0.013-0.03-0.062-0.068-0.069
		c-0.075-0.227-0.268-0.375-0.364-0.583c-0.162-0.164-0.234-0.39-0.409-0.53v-0.031c-0.182-0.192-0.288-0.42-0.439-0.621
		c0.01-0.024,0.001-0.025,0-0.053c-0.048-0.04-0.043-0.134-0.114-0.152c0.015-0.036-0.03-0.064-0.023-0.098
		c-0.056-0.053-0.071-0.142-0.129-0.197c0.004-0.051-0.017-0.057-0.015-0.091c-0.009-0.016-0.03-0.02-0.038-0.038
		c0.009-0.059-0.037-0.063-0.037-0.114c-0.007-0.011-0.007-0.029-0.031-0.022c0.028-0.087-0.047-0.154-0.076-0.228L68,68.519
		c-0.018-0.048-0.059-0.073-0.046-0.129c-0.221-0.628-0.155-1.337,0.251-1.872c-0.009-0.056,0.047-0.046,0.037-0.106
		c0.021,0.005,0.016-0.015,0.031-0.015c-0.001-0.049,0.022-0.073,0.053-0.091c0.01-0.068,0.077-0.079,0.091-0.144
		c0.123-0.111,0.241-0.235,0.356-0.348c0.062-0.004,0.09-0.042,0.106-0.091c0.127-0.042,0.211-0.128,0.348-0.159
		c0.075-0.072,0.229-0.079,0.288-0.144c0.093-0.014,0.138-0.052,0.235-0.046c0.01-0.005,0.014-0.016,0.015-0.03
		c0.041,0.034,0.037-0.028,0.084-0.008c0.04-0.04,0.131-0.03,0.166-0.075c0.071,0.001,0.172-0.037,0.243-0.076l0.023,0.015
		c0.061-0.024,0.113-0.058,0.204-0.053c0.01-0.013,0.035-0.009,0.03-0.038c0.067,0.028,0.128,0.005,0.182-0.03
		c0.021,0.005,0.061-0.01,0.069,0.007c0.096-0.047,0.198-0.107,0.303-0.113c0.006-0.014,0.03-0.01,0.022-0.038
		c0.057,0.023,0.069-0.015,0.091-0.046c0.049-0.001,0.1,0.021,0.122-0.038c0.112-0.001,0.159-0.12,0.265-0.151
		c0.014-0.022,0.041-0.029,0.045-0.061c0.047-0.021,0.106-0.03,0.121-0.083c0.089,0.005,0.128-0.105,0.212-0.129
		c0.067-0.078,0.149-0.126,0.16-0.25c0.178-0.131,0.225-0.394,0.257-0.606c0.02,0.005,0.015-0.015,0.031-0.015
		c0.051-0.203,0.09-0.384,0.06-0.599c0.028-0.02,0.034-0.058,0.015-0.091c0.046-0.238-0.006-0.598-0.045-0.826
		c-0.009-0.246-0.11-0.494-0.152-0.735c-0.029-0.037-0.058-0.087-0.06-0.128c-0.038,0.015-0.067,0.039-0.091,0.068
		c-0.193,0.082-0.357,0.225-0.546,0.295c-0.06,0.086-0.2,0.093-0.242,0.197c-0.113,0.02-0.18,0.148-0.281,0.197
		c-0.003,0.022-0.011,0.04-0.015,0.061c-0.064,0.003-0.078,0.045-0.113,0.091c-0.024-0.004-0.018,0.023-0.046,0.015
		c-0.012,0.028-0.042,0.039-0.053,0.068c-0.054,0.024-0.078,0.079-0.136,0.099c-0.019,0.057-0.08,0.072-0.099,0.129
		c-0.014,0.003-0.019,0.016-0.038,0.015c0.007,0.021-0.011,0.02-0.007,0.038c-0.147,0.139-0.309,0.303-0.409,0.469
		c-0.076,0.036-0.099,0.124-0.175,0.159c-0.025,0.084-0.101,0.117-0.136,0.19c-0.063,0.025-0.135,0.042-0.197,0.068
		c-0.053-0.016-0.124-0.02-0.174,0.008c-0.038-0.004-0.099-0.022-0.114,0.015c-0.025-0.015-0.049-0.024-0.098-0.03
		c-0.008-0.041-0.059-0.04-0.076-0.069c-0.032,0.01-0.047-0.007-0.076-0.007c-0.128-0.259-0.246-0.546-0.242-0.834l-0.023-0.022
		c-0.009-0.052,0.02-0.122-0.008-0.19c0.007-0.014,0.003-0.038,0.015-0.045c-0.046-0.215-0.035-0.405-0.045-0.637
		c0.062-0.103-0.02-0.269,0.045-0.379c-0.052-0.422,0.135-0.84,0.197-1.25c0.017,0.004,0.021-0.005,0.031-0.007
		c0.022-0.077,0.021-0.198,0.075-0.266c0.001-0.062,0.046-0.073,0.031-0.151c0.012-0.003,0.012,0.007,0.022,0.007
		c0.043-0.068,0.041-0.181,0.107-0.227c0.005-0.018,0.011-0.034,0.007-0.06c0.058-0.028,0.043-0.129,0.091-0.167
		c-0.004-0.036,0.015-0.049,0-0.076c0.071-0.097,0.144-0.206,0.197-0.326c0.047-0.025,0.102-0.063,0.106-0.136
		c0.028-0.02,0.055-0.042,0.083-0.061c0.052-0.141,0.124-0.244,0.205-0.371c-0.002-0.028,0.014-0.037,0.015-0.061
		c0.02-0.012,0.026-0.04,0.053-0.045c0.002-0.03,0.022-0.044,0.023-0.076c0.083-0.065,0.13-0.168,0.212-0.235
		c0.001-0.043,0.006-0.041,0.023-0.075c0.097-0.026,0.121-0.166,0.197-0.213c-0.037-0.029-0.058-0.073-0.114-0.083
		c-0.055-0.101-0.182-0.086-0.235-0.189L70.152,57.1c-0.014-0.006-0.026-0.055-0.046-0.022c-0.033-0.043-0.089-0.062-0.106-0.122
		c-0.073-0.014-0.077-0.094-0.159-0.098c-0.079-0.092-0.182-0.125-0.265-0.22c-0.029,0.006-0.037-0.009-0.061-0.007
		c-0.046-0.055-0.096-0.107-0.174-0.129c-0.081-0.091-0.168-0.176-0.28-0.235c0.006-0.024-0.013-0.024-0.015-0.038
		c-0.351-0.251-0.612-0.587-0.963-0.826c0.007-0.021-0.01-0.02-0.007-0.038c-0.024-0.001-0.052-0.016-0.069-0.03v-0.023
		c-0.077-0.038-0.093-0.135-0.189-0.166c-0.108-0.166-0.286-0.243-0.386-0.402c-0.101-0.029-0.108-0.15-0.212-0.174
		c-0.019-0.052-0.079-0.063-0.099-0.114c-0.009-0.006-0.02-0.01-0.038-0.008c-0.011-0.053-0.072-0.046-0.091-0.106
		c-0.113-0.072-0.235-0.166-0.31-0.265c-0.066-0.012-0.07-0.087-0.129-0.106c-0.061-0.109-0.173-0.167-0.235-0.273
		c-0.096-0.05-0.163-0.134-0.227-0.212c-0.013-0.002-0.014,0.006-0.016,0.015l-0.007-0.03c-0.056-0.033-0.101-0.076-0.129-0.136
		c-0.055-0.026-0.095-0.067-0.129-0.114c-0.026-0.003-0.019-0.021-0.045-0.008c-0.028-0.069-0.081-0.111-0.144-0.144
		c-0.022-0.087-0.118-0.099-0.144-0.181c-0.071-0.052-0.114-0.129-0.197-0.167c-0.046-0.094-0.132-0.147-0.22-0.197
		c-0.023-0.061-0.069-0.097-0.129-0.121c-0.019-0.047-0.078-0.086-0.129-0.114c-0.007-0.028-0.022-0.048-0.053-0.053
		c0.006-0.033-0.021-0.034-0.022-0.061c-0.051-0.01-0.051-0.07-0.106-0.075c-0.048-0.061-0.101-0.149-0.174-0.167
		c0.002-0.018-0.015-0.016-0.008-0.038c-0.063-0.045-0.11-0.107-0.19-0.136c-0.13-0.161-0.307-0.341-0.492-0.463
		c-0.009-0.044-0.03-0.075-0.076-0.083c-0.017-0.053-0.04-0.101-0.098-0.114c-0.051-0.072-0.127-0.116-0.167-0.197
		c-0.037,0.009-0.028-0.027-0.061-0.022c0.008-0.031-0.022-0.023-0.015-0.053c-0.101-0.024-0.127-0.144-0.219-0.197
		c-0.023-0.066-0.07-0.108-0.122-0.144c-0.001-0.024-0.04-0.033-0.023-0.053l-0.015,0.007c-0.056-0.111-0.181-0.209-0.257-0.326
		h-0.015c-0.028-0.053-0.071-0.109-0.129-0.151c-0.024-0.062-0.1-0.156-0.152-0.182c0.002-0.076-0.095-0.117-0.121-0.189
		c-0.021,0.006-0.02-0.01-0.038-0.008c-0.031-0.092-0.078-0.085-0.098-0.174c-0.036-0.023-0.047-0.07-0.091-0.084
		c-0.004-0.029-0.026-0.04-0.031-0.068c-0.125-0.108-0.187-0.282-0.303-0.386c-0.004-0.038-0.031-0.126-0.083-0.144
		c-0.013-0.043-0.033-0.078-0.068-0.099c-0.043-0.131-0.159-0.189-0.197-0.325c-0.022-0.007-0.018-0.038-0.046-0.038
		c-0.517-0.696-0.954-1.432-1.326-2.152c-0.071-0.31-0.259-0.602-0.386-0.887c0.005-0.022-0.013-0.022-0.008-0.045
		c-0.07-0.059-0.063-0.2-0.136-0.273c-0.002-0.071-0.035-0.143-0.083-0.189c-0.019-0.077-0.032-0.161-0.084-0.205
		c-0.043-0.182-0.125-0.264-0.144-0.485c-0.15-0.329-0.197-0.701-0.288-1.061c0.012-0.041,0.002-0.107-0.03-0.143
		c-0.07-0.501-0.129-1.026-0.083-1.516c-0.113-0.75,0.014-1.499,0.068-2.243c0.004-0.011,0.01,0.003,0.023,0
		c0.003-0.268,0.109-0.564,0.136-0.841c0.049-0.003,0.028-0.078,0.061-0.098c0.023-0.244,0.162-0.478,0.227-0.743l0.015-0.007
		l-0.007-0.015l0.022-0.016c0.014-0.053,0.013-0.082,0.046-0.136c-0.015-0.078,0.045-0.081,0.038-0.151
		c0.07-0.091,0.079-0.257,0.144-0.334l-0.007-0.004c0.005-0.041,0.065-0.089,0.06-0.162c0.06-0.056,0.082-0.151,0.106-0.243
		c0.024,0.009,0.006-0.024,0.03-0.015c-0.008-0.025-0.001-0.075,0.03-0.083c0.029-0.191,0.17-0.276,0.228-0.44
		c0.016-0.009,0.029-0.021,0.045-0.03c0.006-0.052,0.049-0.067,0.053-0.121c0.239-0.301,0.407-0.673,0.727-0.925
		c0.007-0.036,0.04-0.047,0.046-0.083c0.078-0.023,0.117-0.085,0.159-0.144c0.034-0.014,0.06-0.037,0.076-0.068
		c0.091-0.046,0.154-0.119,0.22-0.19c0.026,0.003,0.027-0.023,0.053-0.023c0.058-0.093,0.174-0.128,0.234-0.219
		c0.054-0.011,0.086-0.044,0.122-0.106c0.05-0.018,0.081-0.056,0.129-0.076c0.05-0.078,0.16-0.097,0.197-0.189
		c0.03,0.012,0.074-0.021,0.083-0.053c0.118-0.075,0.273-0.152,0.364-0.258c0.112-0.027,0.165-0.106,0.25-0.182
		c0.019,0.009,0.036-0.023,0.045,0c0.123-0.087,0.236-0.183,0.371-0.258c0.008-0.017,0.027-0.024,0.023-0.053
		c0.039-0.007,0.038-0.014,0.053-0.038c0.16-0.004,0.375-0.242,0.546-0.325c0.031,0.003,0.032-0.023,0.068-0.015
		c0.383-0.225,0.774-0.403,1.167-0.561c0.001-0.01,0.006-0.032,0.022-0.046c0.042,0.003,0.055-0.013,0.076-0.015
		c0.066-0.078,0.178-0.063,0.258-0.129l0.015,0.016c0.09-0.051,0.301-0.049,0.394-0.122c0.109-0.008,0.162-0.055,0.273-0.06
		c0.089-0.084,0.264-0.051,0.341-0.159c0.118,0.016,0.21-0.07,0.326-0.084l0.007-0.015c0.043-0.007,0.116,0.006,0.144-0.045
		c0.075-0.002,0.178-0.026,0.227-0.069c0.11,0.024,0.274-0.093,0.425-0.106c0-0.025,0.036-0.014,0.038-0.037
		c0.065,0.008,0.157-0.011,0.212-0.054c0.005,0.024,0.034,0.006,0.038,0.016c0.37-0.112,0.852-0.144,1.166-0.25
		c0.182,0.018,0.327-0.009,0.501-0.038c0.022,0.019,0.046-0.009,0.06,0.015c0.083-0.04,0.281,0.004,0.394-0.046
		c0.087,0.035,0.16-0.028,0.235,0c0.286-0.044,0.623-0.057,0.909-0.068l0.03-0.023c0.991,0.054,2.06,0.006,3.039,0.243
		c0.049-0.025,0.079-0.006,0.121,0.023c0.041-0.022,0.08,0.017,0.114,0c0.077,0.058,0.198,0.029,0.265,0.091
		c0.221,0.023,0.427,0.125,0.667,0.159c0.23,0.086,0.516,0.165,0.742,0.272c0.037-0.008,0.044,0.032,0.084,0.016
		c0.056,0.065,0.13,0.03,0.174,0.098c0.053-0.009,0.055,0.039,0.121,0.03c0.312,0.16,0.715,0.263,1,0.455
		c0.084,0.013,0.158,0.085,0.243,0.121c0.003,0.015,0.016,0.024,0.022,0.038c0.077,0.014,0.124,0.058,0.167,0.106
		c0.13,0.028,0.2,0.119,0.326,0.152c0.063,0.071,0.167,0.101,0.25,0.151c-0.004,0.017,0.005,0.021,0.008,0.031
		c0.155,0.058,0.242,0.174,0.401,0.242c0.037,0.058,0.094,0.066,0.159,0.083c0.009,0.037,0.039,0.052,0.076,0.061
		c0.035,0.061,0.098,0.093,0.174,0.114c0.004,0.022,0.048,0.027,0.053,0.068c0.026,0,0.029,0.021,0.061,0.015
		c0.007,0.025,0.03,0.035,0.038,0.061h0.038c0.007,0.045,0.064,0.041,0.075,0.083c0.123,0.063,0.256,0.189,0.349,0.303
		c0.039,0.009,0.055,0.04,0.098,0.046c0.007,0.036,0.053,0.032,0.053,0.075c0.025,0.009,0.041,0.026,0.069,0.031
		c0.001,0.031,0.033,0.032,0.053,0.045c0.022,0.048,0.06,0.081,0.113,0.099c0.021,0.053,0.08,0.065,0.099,0.121
		c0.026-0.001,0.031,0.019,0.053,0.023c0.081,0.136,0.214,0.231,0.333,0.333c-0.002,0.035,0.022,0.044,0.031,0.068
		c0.039,0.016,0.042,0.068,0.09,0.076c0.015,0.043,0.064,0.103,0.114,0.121v0.023c0.152,0.104,0.249,0.309,0.386,0.432
		c0.008,0.041,0.047,0.078,0.084,0.098c-0.009,0.034,0.02,0.031,0.023,0.053c0.015,0.012,0.03,0.026,0.053,0.031
		c0.003,0.044,0.035,0.046,0.068,0.075c0.015,0.061,0.038,0.148,0.106,0.144c0.049,0.135,0.153,0.213,0.204,0.341
		c0.056,0.028,0.085,0.087,0.114,0.159c0.029-0.01,0.028,0.051,0.076,0.061c0.008,0.116,0.103,0.274,0.197,0.379
		c-0.005,0.023,0.012,0.023,0.007,0.045c0.144,0.219,0.246,0.46,0.334,0.675c0.019,0.011,0.017,0.043,0.038,0.053
		c-0.001,0.034,0.021,0.062,0.03,0.091l-0.008,0.022c0.077,0.103,0.082,0.25,0.182,0.341c0.562,1.733,0.551,3.58,0.182,5.349
		c-0.012,0.013-0.014,0.037-0.03,0.046c-0.044,0.303-0.215,0.684-0.258,0.993c-0.012,0.007-0.015,0.025-0.03,0.03
		c-0.035,0.185-0.117,0.349-0.167,0.53c-0.026,0-0.011,0.039-0.038,0.038c-0.019,0.128-0.102,0.191-0.121,0.318h-0.023
		c-0.001,0.036-0.018,0.052-0.015,0.091c-0.03-0.01-0.019,0.022-0.038,0.023c-0.114,0.339-0.263,0.726-0.477,1.008
		c-0.019,0.119-0.11,0.285-0.197,0.394c0.001,0.081-0.073,0.121-0.076,0.219c-0.084,0.074-0.086,0.213-0.159,0.281v0.038
		c-0.035,0.039-0.077,0.106-0.076,0.159c-0.022,0.01-0.022,0.043-0.045,0.053c-0.082,0.254-0.222,0.427-0.295,0.667
		c-0.033,0.007-0.07,0.043-0.061,0.083c-0.144,0.204-0.281,0.446-0.409,0.689c-0.021,0.011-0.026,0.036-0.046,0.046
		c-0.182,0.367-0.423,0.701-0.666,1.045v0.038c-0.016-0.006-0.035,0.021-0.016,0.023c-0.089,0.065-0.118,0.203-0.197,0.258
		c-0.011,0.072-0.077,0.089-0.083,0.166h-0.023c-0.116,0.193-0.281,0.34-0.348,0.554c-0.016,0.012-0.01-0.002-0.031,0
		c-0.016,0.059-0.064,0.087-0.075,0.151h-0.031c-0.011,0.054-0.048,0.082-0.06,0.136c-0.057,0.017-0.048,0.099-0.106,0.114
		c-0.035,0.085-0.1,0.138-0.129,0.227c-0.015,0.004-0.019,0.017-0.038,0.016c-0.014,0.054-0.067,0.069-0.076,0.128
		c-0.016,0.009-0.029,0.022-0.045,0.031c-0.052,0.093-0.11,0.178-0.182,0.25c0.001,0.036-0.029,0.041-0.03,0.075
		c-0.049,0.033-0.082,0.08-0.099,0.144c-0.09,0.085-0.164,0.185-0.227,0.296c-0.019,0.004-0.017,0.029-0.046,0.023
		c-0.219,0.334-0.468,0.677-0.742,0.977c0.006,0.022-0.012,0.02-0.008,0.038c-0.083,0.071-0.146,0.162-0.22,0.242
		c-0.022-0.002-0.024,0.018-0.045,0.016c-0.032,0.059-0.09,0.152-0.152,0.166c-0.011,0.042-0.043,0.063-0.053,0.106
		c-0.02,0.003-0.022,0.023-0.045,0.023c-0.014,0.052-0.07,0.062-0.076,0.121c-0.029-0.006-0.012,0.033-0.045,0.023
		c-0.055,0.089-0.139,0.213-0.22,0.288c-0.004,0.044-0.051,0.045-0.046,0.098c-0.167,0.176-0.331,0.382-0.499,0.561
		c-0.121,0.065-0.157,0.212-0.289,0.265c-0.031,0.052-0.076,0.09-0.136,0.114c-0.006,0.019-0.021,0.03-0.023,0.053
		c-0.055,0.015-0.085,0.056-0.106,0.106c-0.049,0.022-0.097,0.044-0.106,0.106h-0.03c-0.013,0.025-0.034,0.042-0.046,0.068
		c-0.026,0.004-0.035,0.026-0.06,0.031c-0.151,0.164-0.362,0.278-0.478,0.469c-0.109,0.032-0.133,0.149-0.234,0.19
		c-0.008,0.053-0.065,0.058-0.076,0.106c-0.034,0.009-0.064,0.021-0.068,0.061c-0.056,0.035-0.127,0.056-0.144,0.128
		c-0.043,0.024-0.108,0.05-0.129,0.106c-0.056-0.002-0.066,0.093-0.137,0.099c-0.037,0.071-0.116,0.101-0.151,0.174
		c-0.123,0.072-0.222,0.178-0.311,0.281c0.254,0.266,0.632,0.467,0.894,0.719c0.084,0.023,0.105,0.108,0.205,0.114
		c0.061,0.083,0.162,0.125,0.22,0.227c0.059,0.012,0.075,0.051,0.136,0.076c-0.034,0.022,0.041,0.032,0.061,0.046
		c-0.01,0.03,0.025,0.015,0.015,0.045c0.005,0.01,0.016,0.014,0.03,0.015c0.013,0.015-0.001,0.01,0,0.03
		c0.168,0.127,0.323,0.311,0.493,0.417c0.036,0.092,0.115,0.143,0.189,0.197c-0.019,0.019,0.022,0.017,0.015,0.046
		c0.181,0.214,0.4,0.407,0.44,0.689c-0.017-0.004-0.016,0.011-0.016,0.023c-0.84,0.304-1.707,0.203-2.515,0.667
		c0.338,1.364,0.622,2.971-0.068,4.265c-0.058,0.021-0.061,0.096-0.114,0.122v0.053c-0.038,0.035-0.096,0.05-0.106,0.113
		c-0.037,0.004-0.05,0.031-0.06,0.061h-0.031c0,0.018-0.012,0.023-0.007,0.045c-0.026-0.012-0.058,0.026-0.069,0.053
		c-0.229,0.122-0.396,0.314-0.644,0.41c-0.066,0.074-0.208,0.074-0.265,0.159c-0.084,0.003-0.193,0.04-0.242,0.091
		c-0.125,0.034-0.225,0.064-0.303,0.121c-0.019-0.018-0.037,0.01-0.046-0.015c-0.161,0.074-0.379,0.084-0.515,0.174L71.013,66.321z"
        />
        <path
          fill="#FFFFFF"
          d="M81.752,40.734c-0.022-0.242-0.018-0.485-0.023-0.719l-0.015-0.008l0.008-0.023
		c-0.059-0.5-0.099-1.013-0.19-1.515c-0.02-0.008-0.009-0.047-0.03-0.053c-0.001-0.14-0.086-0.279-0.106-0.424
		c-0.048-0.048-0.053-0.141-0.106-0.182c-0.012-0.164-0.138-0.278-0.167-0.44c-0.04-0.017-0.05-0.065-0.06-0.113
		c-0.016-0.005-0.018-0.023-0.031-0.031c-0.051-0.154-0.142-0.264-0.204-0.401c-0.024-0.003-0.047-0.046-0.069-0.061l0.008-0.015
		c-0.096-0.17-0.238-0.296-0.311-0.44c-0.131-0.136-0.279-0.278-0.401-0.424h-0.031c-0.055-0.073-0.121-0.17-0.204-0.197
		c0.006-0.021-0.012-0.02-0.008-0.038c-0.106-0.048-0.13-0.178-0.235-0.227c0.005-0.033-0.021-0.034-0.022-0.061
		c-0.052-0.011-0.081-0.045-0.099-0.091c-0.042,0.004-0.05-0.025-0.083-0.03c-0.158-0.178-0.352-0.322-0.5-0.515
		c-0.06-0.016-0.075-0.077-0.144-0.083c-0.008-0.021-0.023-0.033-0.031-0.053c-0.082-0.032-0.117-0.11-0.196-0.144
		c-0.002-0.027-0.027-0.03-0.031-0.053c-0.031-0.009-0.049-0.032-0.076-0.046c-0.005-0.023-0.024-0.032-0.03-0.053
		c-0.247-0.127-0.4-0.321-0.652-0.424c0.009-0.024-0.011-0.019-0.015-0.031c-0.176-0.099-0.341-0.205-0.515-0.303
		c-0.034,0.007-0.045-0.01-0.076-0.007c-0.027-0.048-0.104-0.047-0.136-0.091c-0.04,0.021-0.054-0.038-0.091-0.015
		c-0.122-0.081-0.27-0.146-0.417-0.19c-0.007-0.025-0.04-0.025-0.068-0.03l-0.008-0.023c-0.094-0.015-0.113-0.067-0.212-0.083
		c-0.022-0.046-0.102-0.047-0.144-0.091c-0.109-0.009-0.19-0.087-0.28-0.091c0-0.013-0.001-0.024-0.007-0.03
		c-0.081-0.014-0.141-0.047-0.19-0.091c-0.106-0.009-0.16-0.108-0.257-0.099c0.011-0.031-0.026-0.009-0.038-0.015l-0.015-0.023
		c-0.098-0.002-0.174-0.072-0.273-0.075c-0.007-0.022-0.031-0.026-0.046-0.038c-0.019-0.002-0.036-0.002-0.045,0.007
		c-0.033-0.055-0.165-0.073-0.212-0.053c-0.014-0.019-0.033-0.033-0.046-0.053c-0.229-0.045-0.416-0.112-0.629-0.151
		c0.013-0.031-0.032-0.003-0.03-0.023c-0.067,0.004-0.083-0.001-0.136-0.023c-0.216-0.007-0.408-0.096-0.652-0.098
		c-0.015,0.01-0.043,0.007-0.061,0.015c-0.07-0.056-0.203,0.006-0.272-0.023l-0.023,0.008l-0.023-0.023
		c-0.016-0.001-0.034,0.006-0.053,0.015c0.003-0.015-0.01-0.015-0.015-0.023c-0.045,0.015-0.09,0.006-0.129-0.015l-0.007,0.015
		h-0.061l-0.008-0.015c-2.058-0.135-4.109,0.191-6.031,0.91c-0.005,0.017-0.019,0.026-0.03,0.037c-0.041-0.003-0.067,0.018-0.099,0
		c-0.025,0.033-0.068,0.048-0.128,0.046c-0.224,0.123-0.471,0.188-0.698,0.295c-0.005,0.021-0.027,0.023-0.037,0.038
		c-0.146,0-0.282,0.145-0.432,0.159c-0.169,0.125-0.375,0.167-0.531,0.281c-0.046,0.001-0.069,0.026-0.09,0.053
		c-0.085-0.009-0.134,0.083-0.228,0.091c-0.086,0.094-0.269,0.116-0.356,0.219c-0.175,0.068-0.277,0.178-0.47,0.273
		c-0.01,0.028-0.04,0.036-0.053,0.061c-0.041,0.016-0.07,0.045-0.121,0.053c-2.064,1.723-3.114,4.589-2.659,7.228
		c-0.048,0.117,0.07,0.282,0.022,0.387c0.068,0.644,0.247,1.226,0.432,1.818c0.029-0.004,0.034,0.016,0.046,0.03
		c-0.013,0.028,0.001,0.037-0.008,0.061c0.197,0.551,0.393,1.195,0.659,1.674c-0.009,0.032,0.053,0.076,0.038,0.122
		c0.11,0.211,0.225,0.404,0.296,0.613c0.095,0.19,0.235,0.418,0.333,0.622c0.19,0.289,0.414,0.586,0.606,0.894
		c0.037,0.006,0.043,0.043,0.084,0.045c-0.021,0.06,0.074,0.066,0.068,0.137c0.074,0.056,0.111,0.104,0.144,0.197
		c0.119,0.064,0.148,0.221,0.25,0.295c0.008,0.053,0.063,0.063,0.075,0.114h0.031c0.007,0.033,0.041,0.039,0.038,0.083
		c0.024,0.014,0.043,0.033,0.068,0.046c-0.009,0.001-0.009,0.011-0.008,0.022c0.066,0.056,0.121,0.12,0.159,0.205
		c0.096,0.027,0.074,0.178,0.182,0.197c-0.004,0.024,0.023,0.018,0.015,0.045c0.061,0.009,0.084,0.111,0.152,0.137
		c0.019,0.067,0.093,0.078,0.106,0.151c0.127,0.127,0.223,0.235,0.326,0.379c0.064,0.019,0.07,0.096,0.136,0.114
		c0.03,0.077,0.133,0.11,0.152,0.189c0.039,0.024,0.089,0.037,0.091,0.099c0.04,0.02,0.088,0.032,0.09,0.091
		c0.025-0.002,0.037,0.008,0.053,0.015c0.058,0.128,0.175,0.203,0.273,0.295c0,0.01,0.01,0.01,0.008,0.023
		c0.087,0.032,0.125,0.12,0.204,0.159c0.012,0.039,0.038,0.063,0.076,0.076c0.019,0.064,0.086,0.079,0.114,0.136
		c0.029-0.001,0.039,0.017,0.061,0.023c-0.007,0.023,0.026,0.009,0.015,0.038c0.233,0.153,0.443,0.418,0.636,0.614
		c0.025,0.005,0.037,0.024,0.061,0.03c0.014,0.069,0.101,0.066,0.113,0.136c0.034,0.009,0.051,0.036,0.084,0.046
		c0.043,0.07,0.108,0.118,0.159,0.182c0.044-0.005,0.065,0.036,0.083,0.068c0.146,0.056,0.213,0.234,0.364,0.272
		c0.069,0.143,0.276,0.199,0.348,0.341c0.163,0.075,0.266,0.23,0.394,0.349c0.075-0.006,0.065,0.072,0.137,0.068
		c0.002,0.025,0.021,0.033,0.03,0.053c0.089,0.039,0.13,0.128,0.22,0.167c-0.002,0.019,0.011,0.024,0.015,0.038
		c0.03,0.012,0.063,0.023,0.068,0.06c0.114,0.041,0.162,0.147,0.273,0.19c-0.001,0.014,0.009,0.016,0.008,0.03
		c0.032-0.002,0.039,0.022,0.068,0.023c0.094,0.112,0.238,0.174,0.333,0.288c0.048,0.001,0.073,0.034,0.076,0.06
		c0.175,0.118,0.31,0.276,0.477,0.402c-0.004,0.037,0.033,0.033,0.038,0.061c0.1,0.041,0.13,0.152,0.242,0.181
		c0.014,0.085,0.115,0.083,0.137,0.16c0.035,0.018,0.084,0.026,0.083,0.083c0.129,0.01,0.129,0.149,0.25,0.167
		c0.01,0.02,0.017,0.022,0.008,0.045c0.062,0.022,0.086,0.081,0.151,0.099c0.006,0.03,0.023,0.048,0.046,0.06
		c0.004,0.039,0.044,0.042,0.053,0.076c0.087,0.021,0.111,0.106,0.197,0.129c0.189,0.193,0.354,0.372,0.576,0.523
		c-0.002,0.012,0.001,0.024,0.007,0.03c0.028,0.007,0.046,0.025,0.069,0.038c0.143-0.096,0.347-0.221,0.454-0.387
		c0.048-0.007,0.068-0.043,0.114-0.053c0.126-0.136,0.294-0.231,0.424-0.363h0.03c0.098-0.124,0.23-0.167,0.334-0.281
		c0.043-0.006,0.044-0.044,0.083-0.038c0.233-0.201,0.478-0.398,0.697-0.629c0.001-0.022,0.017-0.034,0.023-0.053
		c0.021,0.007,0.02-0.01,0.038-0.007c0.007-0.015,0.015-0.03,0.023-0.046l0.022,0.008c0.079-0.095,0.179-0.187,0.281-0.265
		c0.005-0.061,0.082-0.054,0.098-0.129c0.107-0.075,0.171-0.193,0.28-0.265c0.003-0.023,0.025-0.027,0.016-0.061
		c0.026,0.013,0.019-0.005,0.045-0.007c0.119-0.144,0.264-0.262,0.379-0.41c0.01-0.002,0.014-0.011,0.03-0.007
		c0.063-0.123,0.182-0.19,0.235-0.303c0.078-0.036,0.106-0.121,0.174-0.167c-0.001-0.019,0.033-0.068,0.069-0.076
		c0.027-0.088,0.114-0.117,0.144-0.204c0.079-0.029,0.079-0.138,0.159-0.167c0.138-0.21,0.306-0.39,0.424-0.621
		c0.039-0.007,0.038-0.053,0.076-0.061c0.034-0.12,0.155-0.153,0.189-0.273l0.038-0.015c0.317-0.484,0.768-0.917,1.046-1.409
		c0.02,0.019,0.025-0.019,0.045-0.023c0.024-0.052,0.07-0.082,0.083-0.144c0.024-0.009,0.033-0.033,0.061-0.038
		c0.038-0.106,0.133-0.155,0.167-0.265c0.046-0.026,0.062-0.084,0.106-0.113c-0.008-0.046,0.026-0.05,0.022-0.091
		c0.038-0.007,0.042-0.047,0.069-0.046c0.001-0.039,0.03-0.05,0.03-0.091c0.039-0.004,0.032-0.054,0.076-0.053
		c0-0.04,0.034-0.047,0.03-0.091c0.038,0,0.027-0.049,0.061-0.053c0.044-0.148,0.164-0.22,0.204-0.371
		c0.026-0.016,0.077-0.053,0.038-0.076c0.043,0.005,0.029-0.047,0.068-0.045c0.067-0.113,0.152-0.243,0.205-0.372
		c0.052-0.032,0.082-0.094,0.083-0.151c0.019-0.009,0.037-0.019,0.053-0.03c0.013-0.104,0.104-0.129,0.114-0.235
		c0.035-0.02,0.043-0.068,0.083-0.084c0.026-0.093,0.095-0.142,0.122-0.235c0.031-0.003,0.031-0.039,0.06-0.045
		c-0.005-0.023,0.008-0.028,0.008-0.045c0.043-0.023,0.05-0.081,0.098-0.099v-0.045c0.05-0.022,0.076-0.098,0.099-0.167
		c0.084-0.052,0.082-0.191,0.159-0.25c0.021-0.076,0.072-0.178,0.129-0.243c0.005-0.083,0.063-0.113,0.075-0.189
		c0.089-0.061,0.09-0.208,0.167-0.28c-0.005-0.031,0.008-0.043,0.008-0.069c0.039-0.007,0.059-0.069,0.053-0.098
		c0.024-0.001,0.023-0.028,0.045-0.03c0.037-0.117,0.143-0.196,0.182-0.356c0.03-0.023,0.042-0.065,0.068-0.091v-0.053
		c0.021-0.021,0.036-0.046,0.038-0.084c0.022-0.014,0.033-0.038,0.053-0.053c0.009-0.074,0.024-0.141,0.076-0.174
		c-0.009-0.042,0.029-0.054,0-0.076c0.044-0.019,0.047-0.078,0.061-0.129c0.015-0.007,0.026-0.019,0.037-0.03
		c0.112-0.38,0.301-0.804,0.387-1.205c0.024,0.007,0.01-0.025,0.03-0.022c0.006-0.112,0.062-0.162,0.038-0.235
		c0.034-0.004,0-0.027,0.038-0.023c0.143-0.521,0.265-1.093,0.326-1.621c0.01,0,0.01,0.01,0.023,0.007
		c0.016-0.067,0.01-0.152-0.008-0.204c0.044,0.013-0.014-0.062,0.03-0.046c0.001-0.119,0.051-0.28,0.015-0.394
		c0.017-0.039,0.043-0.096,0.016-0.121c0.031-0.018,0.007-0.158,0.037-0.205C81.724,40.824,81.732,40.792,81.752,40.734"
        />
        <path
          fill="#FFFFFF"
          d="M73.319,59.237c0.02-0.023,0.053-0.033,0.06-0.068c-0.035-0.051-0.076-0.095-0.143-0.114
		c0.001-0.034-0.038-0.028-0.038-0.06c-0.041-0.012-0.051-0.051-0.099-0.053c-0.062-0.07-0.137-0.126-0.22-0.175
		c-0.023-0.048-0.065-0.077-0.121-0.091c-0.007-0.045-0.054-0.051-0.068-0.091c-0.062-0.026-0.083-0.093-0.159-0.106
		c-0.09-0.112-0.24-0.164-0.341-0.265c-0.068-0.01-0.057-0.052-0.129-0.053c-0.021-0.037-0.059-0.058-0.106-0.068
		c-0.016,0.021-0.035,0.04-0.038,0.076c-0.062,0.054-0.135,0.097-0.182,0.166c-0.021-0.006-0.02,0.01-0.037,0.008
		c-0.124,0.185-0.323,0.332-0.44,0.561c-0.048,0.025-0.073,0.073-0.114,0.106c-0.006,0.026-0.001,0.019-0.007,0.045
		c-0.039,0.024-0.065,0.062-0.076,0.114c-0.045,0.035-0.081,0.081-0.099,0.144c-0.018-0.018-0.02,0.024-0.045,0.023
		c-0.021,0.066-0.086,0.09-0.091,0.174c-0.079,0.049-0.101,0.156-0.136,0.25c-0.021,0.002-0.027,0.019-0.046,0.023
		c-0.043,0.166-0.142,0.276-0.174,0.454c-0.029,0.024-0.024,0.082-0.061,0.099c-0.02,0.103-0.068,0.2-0.06,0.303
		c-0.037,0.011-0.03,0.065-0.046,0.098c0.075-0.041,0.139-0.093,0.197-0.151l0.008,0.015c0.05-0.07,0.124-0.073,0.174-0.137
		c0.055-0.017,0.079-0.069,0.137-0.075c0.381-0.231,0.799-0.465,1.197-0.614c0.009-0.016,0.03-0.02,0.038-0.038
		c0.074-0.024,0.141-0.055,0.197-0.098c0.071,0.002,0.153-0.061,0.212-0.091c0.023,0.019,0.071-0.021,0.068-0.031
		c0.029,0.018,0.03-0.014,0.053-0.022c0.063,0.014,0.107-0.048,0.167-0.038c0.005-0.018,0.019-0.027,0.03-0.038
		C72.978,59.428,73.14,59.234,73.319,59.237"
        />
      </g>
      <g v-if="showTopLeft" id="top_x5F_left">
        <path
          class="fill-current"
          d="M12.302,35.778c-0.048,0.017-0.205,0.015-0.27,0.076c-0.1-0.001-0.201,0.042-0.265,0.083
		c-0.019-0.004-0.056-0.011-0.061,0.023c-0.23,0.091-0.482,0.149-0.69,0.281h-0.037c-0.129,0.079-0.261,0.151-0.394,0.212
		c-0.006,0.05-0.07,0.045-0.076,0.098c-0.071,0.027-0.075,0.12-0.129,0.167l0.008,0.023c-0.012,0.014-0.024,0.026-0.038,0.038
		c-0.007,0.03,0.001,0.046-0.008,0.06c0.003,0.02-0.01,0.025-0.03,0.023c-0.025,0.114-0.07,0.26-0.091,0.386
		c0.009,0.024,0.014,0.052,0.03,0.068c-0.015,0.128,0.053,0.253,0.106,0.372c0.028,0.015,0.04,0.046,0.068,0.06
		c-0.021,0.081,0.098,0.129,0.114,0.22c0.022,0.009,0.035,0.025,0.053,0.038c0.012,0.059,0.044,0.097,0.091,0.121
		c0.008,0.081,0.097,0.11,0.099,0.174c0.035,0.043,0.075,0.081,0.091,0.144c0.025,0.021,0.045,0.045,0.075,0.061
		c0,0.025,0.022,0.029,0.015,0.061c0.053,0.032,0.058,0.113,0.122,0.136c0.007,0.063,0.068,0.138,0.129,0.174
		c0.001,0.014-0.009,0.017-0.008,0.031c0.043,0.007,0.03,0.069,0.083,0.068c0.008,0.055,0.034,0.092,0.076,0.113
		c0.132,0.23,0.274,0.47,0.439,0.682c-0.005,0.023,0.013,0.023,0.008,0.046c0.111,0.137,0.193,0.288,0.318,0.439
		c0.008,0.093,0.059,0.144,0.129,0.197c-0.005,0.028,0.013,0.033,0.008,0.061c0.073,0.114,0.106,0.268,0.197,0.364
		c-0.022,0.044,0.033,0.058,0.022,0.113l0.023,0.008c0.13,0.35,0.292,0.707,0.356,1.076c-0.006,0.014-0.012,0.028-0.015,0.045
		c0.044,0.066,0.005,0.173,0.053,0.228c-0.004,0.319,0.092,0.63,0.046,0.947l0.015,0.045c-0.033,0.08,0.025,0.22-0.03,0.296
		c0.013,0.096-0.031,0.179-0.023,0.272c-0.039,0.032-0.01,0.111-0.061,0.144c-0.084,0.384-0.311,0.84-0.515,1.197
		c-0.029-0.006-0.027,0.019-0.046,0.023c-0.015,0.068-0.083,0.084-0.09,0.159c-0.056,0.005-0.045,0.077-0.099,0.084
		c-0.013,0.035-0.02,0.075-0.061,0.083c-0.118,0.157-0.242,0.308-0.401,0.424c0,0.018-0.013,0.023-0.008,0.046
		c-0.019-0.004-0.016,0.014-0.038,0.007c-0.012,0.031-0.053,0.033-0.053,0.076c-0.083,0.025-0.096,0.121-0.182,0.144
		c-0.003,0.019-0.029,0.016-0.022,0.045c-0.106,0.039-0.171,0.159-0.281,0.205c-0.008,0.024-0.033,0.033-0.038,0.061
		c-0.115,0.059-0.251,0.158-0.363,0.272c-0.069,0.012-0.09,0.1-0.167,0.107c-0.297,0.29-0.695,0.45-1.015,0.636
		c-0.228,0.034-0.462,0.129-0.675,0.197l-0.022-0.015c-0.06,0.047-0.188,0.001-0.25,0.068c-0.29,0.007-0.609,0.105-0.902,0.167
		C7.587,48.02,7.55,48.03,7.539,48.053c-0.113-0.007-0.24,0.022-0.326,0.068c-0.737,0.105-1.492,0.357-2.22,0.447
		c-0.038,0.051-0.086-0.001-0.114,0.053c-0.017-0.005-0.039-0.006-0.053-0.015c-0.24,0.143-0.491,0.169-0.704,0.288
		c-0.084,0.003-0.247,0.023-0.311,0.068c-0.053-0.021-0.092,0.023-0.129-0.015c-0.08,0.043-0.145-0.029-0.258-0.015
		c-0.041-0.041-0.101-0.061-0.151-0.091c-0.016-0.067-0.092-0.075-0.098-0.152c-0.04-0.012-0.048-0.065-0.084-0.06
		c0.01-0.085-0.01-0.202,0.015-0.273c-0.03-0.059-0.016-0.075-0.007-0.144c0.021-0.027,0.058-0.038,0.053-0.091
		c0.023-0.009,0.043-0.023,0.045-0.053L3.19,48.076C3.177,48.066,3.195,48.021,3.197,48c0.032-0.017,0.077-0.02,0.106-0.038
		c0.024-0.069,0.104-0.083,0.121-0.159c0.061,0.009,0.105-0.048,0.137-0.091c0.412-0.263,0.959-0.275,1.44-0.409
		c0.07,0.026,0.107-0.029,0.181-0.008c0.019-0.006,0.043-0.007,0.053-0.023c0.268-0.007,0.629-0.011,0.849-0.106
		c0.047,0.024,0.157,0.003,0.22,0c0.043-0.059,0.138-0.008,0.189-0.06c0.013-0.001,0.039,0.025,0.046-0.008
		c0.574-0.129,1.201-0.149,1.765-0.371c0.064,0.019,0.126-0.023,0.167-0.053c0.237-0.083,0.54-0.186,0.75-0.341
		c0.147-0.043,0.323-0.168,0.447-0.28c0.156-0.035,0.317-0.209,0.477-0.273c0.003-0.027,0.02-0.019,0.008-0.046
		c0.141-0.047,0.213-0.162,0.348-0.242c-0.002-0.025,0.056-0.035,0.061-0.076c0.04,0.008,0.035-0.03,0.068-0.03
		c-0.001-0.057,0.054-0.057,0.053-0.114c0.041-0.01,0.071-0.03,0.076-0.076c0.03,0.003,0.068-0.043,0.076-0.075h0.022
		c0.022-0.026,0.045-0.067,0.053-0.099c0.023,0,0.026-0.02,0.046-0.023c0.043-0.101,0.146-0.141,0.174-0.257
		c0.175-0.141,0.273-0.326,0.349-0.568c0.054-0.037,0.049-0.133,0.106-0.167c0.191-0.565,0.2-1.24,0.136-1.788
		c-0.024-0.115-0.082-0.295-0.121-0.425l-0.023-0.007c-0.022-0.165-0.111-0.361-0.22-0.485c0.003-0.019-0.013-0.017-0.007-0.038
		c-0.03-0.015-0.052-0.039-0.076-0.061c0.007-0.031-0.015-0.035-0.015-0.06c-0.044-0.032-0.053-0.1-0.114-0.114
		c-0.021-0.052-0.038-0.108-0.098-0.121c-0.013-0.039-0.008-0.049-0.023-0.083c-0.033-0.013-0.03-0.062-0.068-0.069
		c-0.075-0.227-0.268-0.375-0.364-0.583c-0.162-0.164-0.234-0.39-0.409-0.53v-0.031c-0.182-0.192-0.288-0.42-0.439-0.621
		c0.01-0.024,0.001-0.025,0-0.053c-0.048-0.04-0.043-0.134-0.114-0.152c0.015-0.036-0.03-0.064-0.023-0.098
		c-0.056-0.052-0.071-0.142-0.129-0.197c0.004-0.051-0.017-0.057-0.015-0.091c-0.009-0.016-0.03-0.02-0.038-0.038
		c0.009-0.059-0.037-0.063-0.037-0.114c-0.007-0.011-0.007-0.029-0.031-0.022c0.028-0.087-0.047-0.154-0.076-0.228l0.008-0.022
		c-0.018-0.048-0.059-0.073-0.046-0.129c-0.221-0.628-0.155-1.337,0.251-1.872c-0.009-0.056,0.047-0.046,0.037-0.106
		c0.021,0.005,0.016-0.015,0.031-0.015c-0.001-0.049,0.022-0.073,0.053-0.091c0.01-0.068,0.077-0.079,0.091-0.144
		c0.123-0.111,0.241-0.235,0.356-0.348c0.062-0.004,0.09-0.042,0.106-0.091c0.127-0.042,0.211-0.128,0.348-0.159
		c0.075-0.072,0.229-0.079,0.288-0.144c0.093-0.014,0.138-0.052,0.235-0.046c0.01-0.005,0.014-0.016,0.015-0.03
		c0.041,0.034,0.037-0.028,0.084-0.008c0.04-0.04,0.131-0.03,0.166-0.075c0.071,0.001,0.172-0.037,0.243-0.076l0.023,0.015
		c0.061-0.024,0.113-0.058,0.204-0.053c0.01-0.013,0.035-0.009,0.03-0.038c0.067,0.028,0.128,0.005,0.182-0.03
		c0.021,0.005,0.061-0.01,0.069,0.007c0.096-0.047,0.198-0.107,0.303-0.113c0.006-0.014,0.03-0.01,0.022-0.038
		c0.057,0.023,0.069-0.015,0.091-0.046c0.049-0.001,0.1,0.021,0.122-0.038c0.112-0.001,0.159-0.12,0.265-0.151
		c0.014-0.022,0.041-0.029,0.045-0.061c0.047-0.021,0.106-0.03,0.121-0.083c0.089,0.005,0.128-0.105,0.212-0.129
		c0.067-0.078,0.149-0.126,0.16-0.25c0.178-0.131,0.225-0.394,0.257-0.606c0.02,0.005,0.015-0.015,0.031-0.015
		c0.051-0.203,0.09-0.384,0.06-0.599c0.028-0.02,0.034-0.058,0.015-0.091c0.046-0.238-0.006-0.598-0.045-0.826
		c-0.009-0.246-0.11-0.494-0.152-0.735c-0.029-0.037-0.058-0.087-0.06-0.128c-0.038,0.015-0.067,0.039-0.091,0.068
		c-0.193,0.082-0.357,0.225-0.546,0.295c-0.06,0.086-0.2,0.093-0.242,0.197c-0.113,0.02-0.18,0.148-0.281,0.197
		c-0.003,0.022-0.011,0.04-0.015,0.061c-0.064,0.003-0.078,0.045-0.113,0.091c-0.024-0.004-0.018,0.023-0.046,0.015
		c-0.012,0.028-0.042,0.039-0.053,0.068c-0.054,0.024-0.078,0.079-0.136,0.099c-0.019,0.057-0.08,0.072-0.099,0.129
		c-0.014,0.003-0.019,0.016-0.038,0.015c0.007,0.021-0.011,0.02-0.007,0.038c-0.147,0.139-0.309,0.303-0.409,0.469
		c-0.076,0.036-0.099,0.124-0.175,0.159c-0.025,0.084-0.101,0.117-0.136,0.19c-0.063,0.025-0.135,0.042-0.197,0.068
		c-0.053-0.016-0.124-0.02-0.174,0.008c-0.038-0.004-0.099-0.022-0.114,0.015c-0.025-0.015-0.049-0.024-0.098-0.03
		c-0.008-0.041-0.059-0.04-0.076-0.069c-0.032,0.01-0.047-0.007-0.076-0.007c-0.128-0.259-0.246-0.546-0.242-0.834l-0.023-0.022
		c-0.009-0.052,0.02-0.122-0.008-0.19c0.007-0.014,0.003-0.038,0.015-0.045c-0.046-0.215-0.035-0.405-0.045-0.637
		c0.062-0.103-0.02-0.269,0.045-0.379c-0.051-0.421,0.134-0.841,0.198-1.25c0.016,0.004,0.02-0.005,0.03-0.007
		c0.022-0.077,0.021-0.198,0.075-0.266c0.001-0.062,0.046-0.073,0.031-0.151c0.012-0.003,0.012,0.007,0.022,0.007
		c0.043-0.068,0.041-0.181,0.107-0.227c0.005-0.018,0.011-0.034,0.007-0.06c0.058-0.028,0.043-0.129,0.091-0.167
		c-0.004-0.035,0.015-0.049,0-0.076c0.071-0.097,0.144-0.206,0.197-0.326c0.047-0.025,0.102-0.063,0.106-0.136
		c0.028-0.02,0.055-0.042,0.083-0.061c0.052-0.141,0.124-0.244,0.205-0.371c-0.002-0.028,0.014-0.037,0.015-0.061
		c0.02-0.012,0.026-0.04,0.053-0.045c0.002-0.03,0.022-0.044,0.023-0.076c0.083-0.065,0.13-0.168,0.212-0.235
		c0.001-0.043,0.006-0.041,0.023-0.075c0.097-0.026,0.121-0.166,0.197-0.213c-0.037-0.029-0.058-0.073-0.114-0.083
		c-0.055-0.101-0.182-0.086-0.235-0.189l-0.007,0.022c-0.014-0.006-0.026-0.055-0.046-0.022c-0.033-0.043-0.089-0.062-0.106-0.122
		c-0.073-0.014-0.077-0.094-0.159-0.098c-0.079-0.092-0.182-0.125-0.265-0.22c-0.029,0.006-0.037-0.009-0.061-0.007
		c-0.046-0.055-0.096-0.106-0.174-0.129c-0.081-0.091-0.168-0.176-0.28-0.235c0.006-0.024-0.013-0.024-0.015-0.038
		c-0.351-0.251-0.612-0.587-0.963-0.826c0.007-0.021-0.01-0.02-0.007-0.038c-0.024-0.001-0.052-0.016-0.068-0.03v-0.023
		c-0.079-0.037-0.093-0.135-0.19-0.166c-0.108-0.166-0.286-0.243-0.386-0.402c-0.101-0.029-0.108-0.15-0.212-0.174
		C8.49,23.975,8.43,23.964,8.41,23.913c-0.009-0.006-0.02-0.01-0.038-0.008c-0.011-0.053-0.072-0.046-0.091-0.106
		c-0.113-0.072-0.235-0.166-0.31-0.265c-0.066-0.012-0.07-0.087-0.129-0.106c-0.061-0.109-0.173-0.167-0.235-0.273
		c-0.096-0.05-0.163-0.134-0.227-0.212c-0.013-0.002-0.014,0.006-0.016,0.015l-0.007-0.03c-0.056-0.033-0.101-0.076-0.129-0.136
		c-0.055-0.026-0.095-0.067-0.129-0.114c-0.026-0.003-0.019-0.021-0.045-0.008c-0.028-0.069-0.081-0.111-0.144-0.144
		c-0.022-0.087-0.118-0.099-0.144-0.181c-0.071-0.052-0.114-0.129-0.197-0.167c-0.046-0.094-0.132-0.147-0.22-0.197
		C6.327,21.92,6.28,21.884,6.22,21.86c-0.019-0.047-0.078-0.086-0.129-0.114c-0.007-0.028-0.022-0.048-0.053-0.053
		c0.006-0.033-0.021-0.034-0.022-0.061c-0.051-0.01-0.051-0.07-0.106-0.075c-0.048-0.061-0.101-0.149-0.174-0.167
		c0.002-0.018-0.015-0.016-0.008-0.038c-0.063-0.045-0.11-0.107-0.19-0.136c-0.13-0.161-0.307-0.341-0.492-0.463
		c-0.009-0.044-0.03-0.075-0.076-0.083c-0.017-0.053-0.04-0.101-0.098-0.114c-0.051-0.072-0.127-0.116-0.167-0.197
		c-0.037,0.009-0.028-0.027-0.061-0.022c0.008-0.031-0.022-0.023-0.015-0.053C4.528,20.26,4.502,20.14,4.41,20.087
		c-0.023-0.066-0.07-0.108-0.122-0.144c-0.001-0.024-0.04-0.033-0.023-0.053L4.25,19.897c-0.056-0.111-0.181-0.209-0.257-0.326
		H3.978c-0.028-0.053-0.071-0.109-0.129-0.151c-0.024-0.062-0.1-0.155-0.152-0.182c0.002-0.076-0.095-0.117-0.121-0.189
		c-0.021,0.006-0.02-0.01-0.038-0.008c-0.031-0.092-0.078-0.085-0.098-0.174c-0.036-0.023-0.047-0.07-0.091-0.084
		c-0.004-0.029-0.026-0.04-0.031-0.068c-0.125-0.108-0.187-0.282-0.303-0.386c-0.004-0.038-0.031-0.126-0.083-0.144
		c-0.013-0.043-0.033-0.078-0.068-0.099c-0.043-0.131-0.159-0.189-0.197-0.325c-0.022-0.007-0.018-0.038-0.046-0.038
		c-0.517-0.696-0.954-1.432-1.326-2.152c-0.071-0.31-0.259-0.602-0.386-0.887c0.005-0.022-0.013-0.022-0.008-0.045
		c-0.07-0.059-0.063-0.2-0.136-0.273c-0.002-0.071-0.035-0.143-0.083-0.189C0.663,14.1,0.65,14.016,0.598,13.972
		c-0.043-0.182-0.125-0.264-0.144-0.485c-0.15-0.329-0.197-0.701-0.288-1.061c0.012-0.041,0.002-0.107-0.03-0.143
		c-0.07-0.501-0.129-1.026-0.083-1.516c-0.113-0.75,0.014-1.499,0.068-2.243c0.004-0.011,0.01,0.003,0.023,0
		C0.147,8.256,0.253,7.96,0.28,7.683C0.329,7.68,0.308,7.605,0.341,7.585c0.023-0.244,0.162-0.478,0.227-0.743l0.015-0.007
		L0.576,6.82l0.022-0.016c0.014-0.053,0.013-0.082,0.046-0.136C0.629,6.59,0.689,6.587,0.682,6.517
		c0.07-0.091,0.079-0.257,0.144-0.334L0.819,6.179c0.005-0.041,0.065-0.089,0.06-0.162c0.06-0.056,0.082-0.151,0.106-0.243
		c0.024,0.009,0.006-0.024,0.03-0.015c-0.008-0.025-0.001-0.075,0.03-0.083c0.029-0.191,0.17-0.276,0.228-0.44
		c0.016-0.009,0.029-0.021,0.045-0.03c0.006-0.052,0.049-0.067,0.053-0.121C1.61,4.784,1.778,4.412,2.098,4.16
		c0.007-0.036,0.04-0.047,0.046-0.083c0.078-0.023,0.117-0.085,0.159-0.144c0.034-0.014,0.06-0.037,0.076-0.068
		c0.091-0.046,0.154-0.119,0.22-0.19c0.026,0.003,0.027-0.023,0.053-0.023C2.71,3.559,2.826,3.524,2.886,3.433
		C2.94,3.422,2.972,3.389,3.008,3.327c0.05-0.018,0.081-0.056,0.129-0.076c0.05-0.078,0.16-0.097,0.197-0.189
		c0.03,0.012,0.074-0.021,0.083-0.053C3.535,2.934,3.69,2.857,3.781,2.751c0.112-0.027,0.165-0.106,0.25-0.182
		c0.019,0.009,0.036-0.023,0.045,0c0.123-0.087,0.236-0.183,0.371-0.258C4.455,2.294,4.474,2.287,4.47,2.258
		C4.509,2.251,4.508,2.244,4.523,2.22c0.16-0.004,0.375-0.242,0.546-0.325C5.1,1.898,5.101,1.872,5.137,1.88
		C5.52,1.655,5.911,1.477,6.304,1.319c0.001-0.01,0.006-0.032,0.023-0.046C6.368,1.276,6.381,1.26,6.402,1.258
		C6.468,1.18,6.58,1.195,6.66,1.129l0.015,0.016c0.09-0.051,0.301-0.049,0.394-0.122c0.109-0.008,0.162-0.055,0.273-0.06
		c0.089-0.084,0.264-0.051,0.341-0.159c0.118,0.016,0.21-0.07,0.326-0.084l0.007-0.015C8.059,0.698,8.132,0.711,8.16,0.66
		c0.075-0.002,0.178-0.026,0.227-0.069c0.11,0.024,0.274-0.093,0.425-0.106c0-0.025,0.036-0.014,0.038-0.037
		c0.065,0.008,0.157-0.011,0.212-0.054C9.067,0.418,9.096,0.4,9.1,0.41c0.37-0.112,0.852-0.144,1.166-0.25
		c0.182,0.018,0.327-0.009,0.501-0.038c0.022,0.019,0.046-0.009,0.06,0.015c0.083-0.04,0.281,0.004,0.394-0.046
		c0.087,0.035,0.16-0.028,0.235,0c0.286-0.044,0.623-0.057,0.909-0.068L12.395,0c0.991,0.054,2.06,0.006,3.039,0.243
		c0.049-0.025,0.079-0.006,0.121,0.023c0.041-0.022,0.08,0.017,0.114,0c0.077,0.058,0.198,0.029,0.265,0.091
		c0.221,0.023,0.427,0.125,0.667,0.159c0.23,0.086,0.516,0.165,0.742,0.272c0.037-0.008,0.044,0.032,0.084,0.016
		c0.056,0.065,0.13,0.03,0.174,0.098c0.053-0.009,0.055,0.039,0.121,0.03c0.312,0.16,0.715,0.263,1,0.455
		c0.084,0.013,0.158,0.085,0.243,0.121c0.003,0.015,0.016,0.024,0.022,0.038c0.077,0.014,0.124,0.058,0.167,0.106
		c0.13,0.028,0.2,0.119,0.326,0.152c0.063,0.071,0.167,0.101,0.25,0.151c-0.004,0.017,0.005,0.021,0.008,0.031
		c0.155,0.058,0.242,0.174,0.401,0.242c0.037,0.058,0.094,0.066,0.159,0.083c0.009,0.037,0.039,0.052,0.076,0.061
		c0.035,0.061,0.098,0.093,0.174,0.114c0.004,0.022,0.048,0.027,0.053,0.068c0.026,0,0.029,0.021,0.061,0.015
		c0.007,0.025,0.03,0.035,0.038,0.061h0.038c0.007,0.045,0.064,0.041,0.075,0.083c0.123,0.063,0.256,0.189,0.349,0.303
		c0.039,0.009,0.055,0.04,0.098,0.046c0.007,0.036,0.053,0.032,0.053,0.075c0.025,0.009,0.041,0.026,0.069,0.031
		c0.001,0.031,0.033,0.032,0.053,0.045c0.022,0.048,0.06,0.081,0.113,0.099c0.021,0.053,0.08,0.065,0.099,0.121
		c0.026-0.001,0.031,0.019,0.053,0.023c0.081,0.136,0.214,0.231,0.333,0.333c-0.002,0.035,0.022,0.044,0.031,0.068
		c0.039,0.016,0.042,0.068,0.09,0.076c0.015,0.043,0.064,0.103,0.114,0.121v0.023c0.152,0.104,0.249,0.309,0.386,0.432
		c0.008,0.041,0.047,0.078,0.084,0.098c-0.009,0.034,0.02,0.031,0.023,0.053c0.015,0.012,0.03,0.026,0.053,0.031
		c0.003,0.044,0.035,0.046,0.068,0.075c0.015,0.061,0.038,0.148,0.106,0.144c0.049,0.135,0.153,0.213,0.204,0.341
		c0.056,0.028,0.085,0.087,0.114,0.159c0.029-0.01,0.028,0.051,0.076,0.061c0.008,0.116,0.103,0.274,0.197,0.379
		c-0.005,0.023,0.012,0.023,0.007,0.045c0.144,0.219,0.246,0.46,0.334,0.675c0.019,0.011,0.017,0.043,0.038,0.053
		c-0.001,0.034,0.021,0.062,0.03,0.091L23.98,6.736c0.077,0.103,0.082,0.25,0.182,0.341c0.562,1.733,0.551,3.58,0.182,5.349
		c-0.012,0.013-0.014,0.037-0.03,0.046c-0.044,0.303-0.215,0.684-0.258,0.993c-0.012,0.007-0.015,0.025-0.03,0.03
		c-0.035,0.185-0.117,0.349-0.167,0.53c-0.026,0-0.011,0.039-0.038,0.038c-0.019,0.128-0.102,0.191-0.121,0.318h-0.023
		c-0.001,0.036-0.018,0.052-0.015,0.091c-0.03-0.01-0.019,0.022-0.038,0.023c-0.114,0.339-0.263,0.726-0.477,1.008
		c-0.019,0.119-0.11,0.285-0.197,0.394c0.001,0.081-0.073,0.121-0.076,0.219c-0.084,0.074-0.086,0.213-0.159,0.281v0.038
		c-0.035,0.039-0.077,0.106-0.076,0.159c-0.022,0.01-0.022,0.043-0.045,0.053c-0.082,0.254-0.222,0.427-0.295,0.667
		c-0.033,0.007-0.07,0.043-0.061,0.083c-0.144,0.204-0.281,0.446-0.409,0.689c-0.021,0.011-0.026,0.036-0.046,0.046
		c-0.182,0.367-0.423,0.701-0.666,1.045v0.038c-0.016-0.006-0.035,0.021-0.016,0.023c-0.089,0.065-0.118,0.203-0.197,0.258
		c-0.011,0.072-0.077,0.089-0.083,0.166h-0.023c-0.116,0.193-0.281,0.34-0.348,0.554c-0.016,0.012-0.01-0.002-0.031,0
		c-0.016,0.059-0.064,0.087-0.075,0.151h-0.031c-0.011,0.054-0.048,0.082-0.06,0.136c-0.057,0.017-0.048,0.099-0.106,0.114
		c-0.035,0.085-0.1,0.138-0.129,0.227c-0.015,0.004-0.019,0.017-0.038,0.016c-0.014,0.054-0.067,0.069-0.076,0.128
		c-0.016,0.009-0.029,0.022-0.045,0.031c-0.052,0.093-0.11,0.178-0.182,0.25c0.001,0.036-0.029,0.041-0.03,0.075
		c-0.049,0.033-0.082,0.08-0.099,0.144c-0.09,0.085-0.164,0.185-0.227,0.296c-0.019,0.004-0.017,0.029-0.046,0.023
		c-0.219,0.334-0.468,0.677-0.742,0.977c0.006,0.022-0.012,0.02-0.008,0.038c-0.083,0.071-0.146,0.162-0.219,0.242
		c-0.023-0.002-0.025,0.018-0.046,0.016c-0.032,0.059-0.09,0.152-0.152,0.166c-0.011,0.042-0.043,0.063-0.053,0.106
		c-0.02,0.003-0.022,0.023-0.045,0.023c-0.014,0.052-0.07,0.062-0.076,0.121c-0.029-0.006-0.012,0.033-0.045,0.023
		c-0.055,0.089-0.139,0.213-0.22,0.288c-0.004,0.044-0.051,0.045-0.046,0.098c-0.167,0.176-0.331,0.382-0.499,0.561
		c-0.121,0.065-0.157,0.212-0.289,0.265c-0.031,0.052-0.076,0.09-0.136,0.114c-0.006,0.019-0.021,0.03-0.023,0.053
		c-0.055,0.015-0.085,0.056-0.106,0.106c-0.049,0.022-0.097,0.044-0.106,0.106h-0.03c-0.013,0.025-0.034,0.042-0.046,0.068
		c-0.026,0.004-0.035,0.026-0.06,0.031c-0.151,0.164-0.362,0.278-0.478,0.469c-0.109,0.032-0.133,0.149-0.234,0.19
		c-0.008,0.053-0.065,0.058-0.076,0.106c-0.034,0.009-0.064,0.021-0.068,0.061c-0.056,0.035-0.127,0.056-0.144,0.128
		c-0.043,0.024-0.108,0.05-0.129,0.106c-0.056-0.002-0.066,0.093-0.137,0.099c-0.037,0.071-0.116,0.101-0.151,0.174
		c-0.123,0.072-0.222,0.178-0.311,0.281c0.254,0.266,0.632,0.467,0.894,0.719c0.084,0.023,0.105,0.108,0.205,0.114
		c0.061,0.083,0.162,0.125,0.22,0.227c0.059,0.012,0.075,0.051,0.136,0.076c-0.034,0.022,0.041,0.032,0.061,0.046
		c-0.01,0.03,0.025,0.015,0.015,0.045c0.005,0.01,0.016,0.014,0.03,0.015c0.013,0.015-0.001,0.01,0,0.03
		c0.168,0.127,0.323,0.311,0.493,0.417c0.036,0.092,0.115,0.143,0.189,0.197c-0.019,0.019,0.022,0.017,0.015,0.046
		c0.181,0.214,0.4,0.407,0.44,0.689c-0.017-0.004-0.016,0.011-0.016,0.023c-0.84,0.304-1.707,0.203-2.515,0.667
		c0.338,1.364,0.622,2.971-0.068,4.265c-0.058,0.021-0.061,0.096-0.114,0.122v0.053c-0.038,0.035-0.096,0.05-0.106,0.113
		c-0.037,0.004-0.05,0.031-0.06,0.061h-0.031c0,0.018-0.012,0.023-0.007,0.045c-0.026-0.012-0.058,0.026-0.069,0.053
		c-0.229,0.122-0.396,0.314-0.644,0.41c-0.066,0.074-0.208,0.074-0.265,0.159c-0.084,0.003-0.193,0.04-0.242,0.091
		c-0.125,0.034-0.225,0.064-0.303,0.121c-0.019-0.018-0.037,0.01-0.046-0.015c-0.161,0.074-0.379,0.084-0.515,0.174L12.302,35.778z"
        />
        <path
          fill="#FFFFFF"
          d="M23.041,10.191c-0.022-0.242-0.018-0.485-0.023-0.719l-0.015-0.008l0.008-0.023
		c-0.059-0.5-0.099-1.013-0.19-1.515c-0.02-0.008-0.009-0.047-0.03-0.053c-0.001-0.14-0.086-0.279-0.106-0.424
		c-0.048-0.048-0.053-0.141-0.106-0.182c-0.012-0.164-0.137-0.278-0.167-0.44c-0.04-0.017-0.05-0.065-0.06-0.113
		c-0.016-0.005-0.018-0.023-0.031-0.031c-0.051-0.154-0.142-0.264-0.204-0.401c-0.024-0.003-0.047-0.046-0.069-0.061l0.008-0.015
		c-0.096-0.17-0.238-0.296-0.311-0.44c-0.131-0.136-0.279-0.278-0.401-0.424h-0.031c-0.055-0.073-0.121-0.17-0.204-0.197
		c0.006-0.021-0.012-0.02-0.008-0.038c-0.106-0.048-0.13-0.178-0.235-0.227c0.005-0.033-0.021-0.034-0.022-0.061
		c-0.052-0.011-0.081-0.045-0.099-0.091c-0.042,0.004-0.05-0.025-0.083-0.03c-0.158-0.178-0.352-0.322-0.5-0.515
		c-0.06-0.016-0.075-0.077-0.144-0.083c-0.008-0.021-0.023-0.033-0.031-0.053c-0.082-0.032-0.117-0.11-0.196-0.144
		c-0.002-0.027-0.027-0.03-0.031-0.053c-0.031-0.009-0.049-0.032-0.076-0.046c-0.005-0.023-0.024-0.032-0.03-0.053
		c-0.247-0.127-0.4-0.321-0.652-0.424c0.009-0.024-0.011-0.019-0.015-0.031c-0.176-0.099-0.341-0.205-0.515-0.303
		C18.438,3,18.427,2.983,18.396,2.986c-0.027-0.048-0.104-0.047-0.136-0.091c-0.04,0.021-0.054-0.038-0.091-0.015
		c-0.122-0.081-0.27-0.146-0.417-0.19c-0.007-0.025-0.04-0.025-0.068-0.03l-0.008-0.023c-0.094-0.015-0.113-0.067-0.212-0.083
		c-0.022-0.046-0.102-0.047-0.144-0.091c-0.109-0.009-0.19-0.087-0.28-0.091c0-0.013-0.001-0.024-0.007-0.03
		c-0.081-0.014-0.141-0.047-0.19-0.091c-0.106-0.009-0.16-0.108-0.257-0.099c0.011-0.031-0.026-0.009-0.038-0.015l-0.015-0.023
		c-0.098-0.002-0.174-0.072-0.273-0.075c-0.007-0.022-0.031-0.026-0.046-0.038c-0.019-0.002-0.036-0.002-0.045,0.007
		c-0.033-0.055-0.165-0.073-0.212-0.053c-0.014-0.019-0.033-0.033-0.046-0.053c-0.229-0.045-0.416-0.112-0.629-0.151
		c0.013-0.031-0.032-0.003-0.03-0.023c-0.067,0.004-0.083-0.001-0.136-0.023c-0.216-0.007-0.408-0.096-0.652-0.098
		c-0.015,0.01-0.043,0.007-0.061,0.015c-0.07-0.056-0.203,0.006-0.272-0.023l-0.023,0.008l-0.023-0.023
		c-0.016-0.001-0.034,0.006-0.053,0.015c0.003-0.015-0.01-0.015-0.015-0.023c-0.045,0.015-0.09,0.006-0.129-0.015l-0.007,0.015
		H13.82l-0.008-0.015c-2.058-0.135-4.109,0.191-6.031,0.91c-0.005,0.017-0.019,0.026-0.03,0.037c-0.041-0.003-0.067,0.018-0.099,0
		C7.627,2.541,7.584,2.556,7.524,2.554C7.3,2.677,7.053,2.742,6.826,2.849C6.821,2.87,6.799,2.872,6.789,2.887
		c-0.146,0-0.282,0.145-0.432,0.159C6.188,3.171,5.982,3.213,5.826,3.327C5.78,3.328,5.757,3.353,5.736,3.38
		C5.651,3.371,5.602,3.463,5.508,3.471C5.423,3.565,5.239,3.587,5.152,3.69c-0.175,0.068-0.277,0.178-0.47,0.273
		c-0.01,0.028-0.04,0.036-0.053,0.061C4.588,4.04,4.559,4.069,4.508,4.077C2.444,5.8,1.394,8.666,1.849,11.305
		c-0.048,0.117,0.07,0.282,0.022,0.387c0.068,0.644,0.247,1.226,0.432,1.818c0.029-0.004,0.034,0.016,0.046,0.03
		c-0.013,0.028,0.001,0.037-0.008,0.061C2.538,14.152,2.735,14.796,3,15.275c-0.009,0.032,0.053,0.076,0.038,0.122
		c0.11,0.211,0.225,0.404,0.296,0.613c0.095,0.19,0.235,0.418,0.333,0.622c0.19,0.289,0.414,0.586,0.606,0.894
		c0.037,0.006,0.043,0.043,0.084,0.045c-0.021,0.06,0.074,0.066,0.068,0.137c0.074,0.056,0.111,0.104,0.144,0.197
		c0.119,0.064,0.148,0.221,0.25,0.295c0.008,0.053,0.063,0.063,0.075,0.114h0.031c0.007,0.033,0.041,0.039,0.038,0.083
		c0.024,0.014,0.043,0.033,0.068,0.046c-0.009,0.001-0.009,0.011-0.008,0.022c0.066,0.056,0.121,0.12,0.159,0.205
		c0.096,0.027,0.074,0.178,0.182,0.197c-0.004,0.024,0.023,0.018,0.015,0.045c0.061,0.009,0.084,0.111,0.152,0.137
		c0.019,0.067,0.093,0.078,0.106,0.151c0.127,0.127,0.224,0.235,0.326,0.379c0.064,0.019,0.07,0.096,0.136,0.114
		c0.03,0.077,0.133,0.11,0.152,0.189c0.039,0.024,0.089,0.037,0.091,0.099c0.04,0.02,0.088,0.032,0.09,0.091
		c0.025-0.002,0.037,0.008,0.053,0.015c0.058,0.128,0.175,0.203,0.273,0.295c0,0.01,0.01,0.01,0.008,0.023
		c0.088,0.032,0.125,0.12,0.204,0.159c0.012,0.039,0.038,0.063,0.076,0.076c0.019,0.064,0.086,0.079,0.114,0.136
		c0.029-0.001,0.039,0.017,0.061,0.023c-0.007,0.023,0.026,0.009,0.015,0.038c0.233,0.153,0.443,0.418,0.636,0.614
		c0.025,0.005,0.037,0.024,0.061,0.03c0.014,0.069,0.101,0.066,0.113,0.136c0.034,0.009,0.051,0.036,0.084,0.046
		c0.043,0.07,0.108,0.118,0.159,0.182c0.044-0.005,0.065,0.036,0.083,0.068c0.146,0.056,0.213,0.234,0.364,0.272
		c0.069,0.143,0.276,0.199,0.348,0.341c0.163,0.075,0.266,0.23,0.394,0.349c0.075-0.006,0.065,0.072,0.137,0.068
		c0.002,0.025,0.021,0.033,0.03,0.053c0.089,0.039,0.13,0.128,0.22,0.167c-0.001,0.019,0.011,0.024,0.015,0.038
		c0.03,0.012,0.063,0.023,0.068,0.06c0.114,0.041,0.162,0.147,0.273,0.19c-0.001,0.014,0.009,0.016,0.008,0.03
		c0.032-0.002,0.039,0.022,0.068,0.023c0.094,0.112,0.238,0.174,0.333,0.288c0.048,0.001,0.073,0.034,0.076,0.06
		c0.175,0.118,0.31,0.276,0.477,0.402c-0.004,0.037,0.033,0.033,0.038,0.061c0.1,0.041,0.13,0.152,0.242,0.181
		c0.014,0.085,0.115,0.083,0.137,0.16c0.035,0.018,0.084,0.026,0.083,0.083c0.129,0.01,0.129,0.149,0.25,0.167
		c0.01,0.02,0.017,0.022,0.008,0.045c0.062,0.022,0.086,0.081,0.151,0.099c0.006,0.03,0.023,0.048,0.046,0.06
		c0.004,0.039,0.044,0.042,0.053,0.076c0.087,0.021,0.111,0.106,0.197,0.129c0.189,0.193,0.354,0.372,0.576,0.523
		c-0.002,0.012,0.001,0.024,0.007,0.03c0.028,0.007,0.046,0.025,0.069,0.038c0.143-0.096,0.347-0.221,0.454-0.387
		c0.048-0.007,0.068-0.043,0.114-0.053c0.126-0.136,0.294-0.231,0.424-0.363h0.03c0.098-0.124,0.23-0.167,0.334-0.281
		c0.043-0.006,0.044-0.044,0.083-0.038c0.233-0.201,0.478-0.398,0.697-0.629c0.001-0.022,0.017-0.034,0.023-0.053
		c0.021,0.007,0.02-0.01,0.038-0.007c0.007-0.015,0.015-0.03,0.023-0.046l0.022,0.008c0.079-0.095,0.179-0.187,0.281-0.265
		c0.005-0.061,0.082-0.054,0.098-0.129c0.107-0.075,0.171-0.193,0.28-0.265c0.003-0.023,0.025-0.027,0.016-0.061
		c0.026,0.013,0.019-0.005,0.045-0.007c0.119-0.144,0.264-0.262,0.379-0.41c0.01-0.002,0.014-0.011,0.03-0.007
		c0.063-0.123,0.182-0.19,0.235-0.303c0.078-0.036,0.106-0.121,0.174-0.167c-0.001-0.019,0.033-0.068,0.069-0.076
		c0.027-0.088,0.114-0.117,0.144-0.204c0.079-0.029,0.079-0.138,0.159-0.167c0.138-0.21,0.306-0.39,0.424-0.621
		c0.039-0.007,0.038-0.053,0.076-0.061c0.034-0.12,0.155-0.153,0.189-0.273l0.038-0.015c0.317-0.484,0.768-0.917,1.046-1.409
		c0.02,0.019,0.025-0.019,0.045-0.023c0.024-0.052,0.07-0.082,0.083-0.144c0.024-0.009,0.033-0.033,0.061-0.038
		c0.038-0.106,0.133-0.155,0.167-0.265c0.046-0.026,0.062-0.084,0.106-0.113c-0.008-0.046,0.026-0.05,0.022-0.091
		c0.038-0.007,0.042-0.047,0.069-0.046c0.001-0.039,0.03-0.05,0.03-0.091c0.039-0.004,0.032-0.054,0.076-0.053
		c0-0.04,0.034-0.047,0.03-0.091c0.038,0,0.027-0.049,0.061-0.053c0.044-0.148,0.164-0.22,0.204-0.371
		c0.026-0.016,0.077-0.053,0.038-0.076c0.043,0.005,0.029-0.047,0.068-0.045c0.067-0.113,0.152-0.243,0.205-0.372
		c0.052-0.032,0.082-0.094,0.083-0.151c0.019-0.009,0.037-0.019,0.053-0.03c0.013-0.104,0.104-0.129,0.114-0.235
		c0.035-0.02,0.043-0.068,0.083-0.084c0.026-0.093,0.095-0.142,0.122-0.235c0.031-0.003,0.031-0.039,0.06-0.045
		c-0.005-0.023,0.008-0.028,0.008-0.045c0.043-0.023,0.05-0.081,0.098-0.099v-0.045c0.05-0.022,0.076-0.098,0.099-0.167
		c0.084-0.052,0.082-0.191,0.159-0.25c0.021-0.076,0.072-0.178,0.129-0.243c0.005-0.083,0.063-0.113,0.075-0.189
		c0.089-0.061,0.09-0.208,0.167-0.28c-0.005-0.031,0.008-0.043,0.008-0.069c0.039-0.007,0.059-0.069,0.053-0.098
		c0.024-0.001,0.023-0.028,0.045-0.03c0.037-0.117,0.142-0.196,0.182-0.356c0.03-0.023,0.042-0.065,0.068-0.091V14.95
		c0.021-0.021,0.036-0.046,0.038-0.084c0.022-0.014,0.033-0.038,0.053-0.053c0.009-0.074,0.024-0.141,0.076-0.174
		c-0.009-0.042,0.029-0.054,0-0.076c0.044-0.019,0.047-0.078,0.061-0.129c0.015-0.007,0.026-0.019,0.037-0.03
		c0.112-0.38,0.301-0.804,0.387-1.205c0.024,0.007,0.01-0.025,0.03-0.022c0.006-0.112,0.062-0.162,0.038-0.235
		c0.034-0.004,0-0.027,0.038-0.023c0.143-0.521,0.265-1.093,0.326-1.621c0.01,0,0.01,0.01,0.023,0.007
		c0.016-0.067,0.01-0.152-0.008-0.204c0.044,0.013-0.014-0.062,0.03-0.046c0.001-0.119,0.051-0.28,0.015-0.394
		c0.017-0.039,0.043-0.096,0.016-0.121c0.031-0.018,0.007-0.158,0.037-0.205C23.013,10.281,23.021,10.249,23.041,10.191"
        />
        <path
          fill="#FFFFFF"
          d="M14.608,28.694c0.02-0.023,0.053-0.033,0.06-0.068c-0.035-0.051-0.076-0.095-0.143-0.114
		c0.001-0.034-0.038-0.028-0.038-0.06c-0.041-0.012-0.051-0.051-0.099-0.053c-0.062-0.07-0.137-0.126-0.22-0.175
		c-0.023-0.048-0.065-0.077-0.121-0.091c-0.007-0.045-0.054-0.051-0.068-0.091c-0.062-0.026-0.083-0.093-0.159-0.106
		c-0.09-0.112-0.24-0.164-0.341-0.265c-0.068-0.01-0.057-0.052-0.129-0.053c-0.021-0.037-0.059-0.058-0.106-0.068
		c-0.016,0.021-0.035,0.04-0.038,0.076c-0.062,0.054-0.135,0.097-0.182,0.166c-0.021-0.006-0.02,0.01-0.037,0.008
		c-0.124,0.185-0.323,0.332-0.44,0.561c-0.048,0.025-0.073,0.073-0.114,0.106c-0.006,0.026-0.001,0.019-0.007,0.045
		c-0.039,0.024-0.065,0.062-0.076,0.114c-0.045,0.035-0.081,0.081-0.099,0.144c-0.018-0.018-0.02,0.024-0.045,0.023
		c-0.021,0.066-0.086,0.09-0.091,0.174c-0.079,0.049-0.101,0.156-0.136,0.25c-0.02,0.002-0.027,0.019-0.046,0.023
		c-0.043,0.166-0.142,0.276-0.174,0.454c-0.029,0.024-0.024,0.082-0.061,0.099c-0.02,0.103-0.068,0.2-0.06,0.303
		c-0.037,0.011-0.03,0.065-0.046,0.098c0.075-0.041,0.139-0.093,0.197-0.151l0.008,0.015c0.05-0.07,0.124-0.073,0.174-0.137
		c0.055-0.017,0.079-0.069,0.137-0.075c0.381-0.231,0.799-0.465,1.197-0.614c0.009-0.016,0.03-0.02,0.038-0.038
		c0.074-0.024,0.141-0.055,0.197-0.098c0.071,0.002,0.153-0.061,0.212-0.091c0.023,0.019,0.071-0.021,0.068-0.031
		c0.029,0.018,0.03-0.014,0.053-0.022c0.063,0.014,0.107-0.048,0.167-0.038c0.005-0.018,0.019-0.027,0.03-0.038
		C14.267,28.884,14.429,28.691,14.608,28.694"
        />
      </g>
      <g v-if="showTopRight" id="top_x5F_right">
        <path
          class="fill-current"
          d="M129.832,35.778c-0.048,0.017-0.205,0.015-0.27,0.076c-0.1-0.001-0.201,0.042-0.265,0.083
		c-0.019-0.004-0.056-0.011-0.061,0.023c-0.23,0.091-0.482,0.149-0.69,0.281h-0.037c-0.129,0.079-0.261,0.151-0.394,0.212
		c-0.006,0.05-0.07,0.045-0.076,0.098c-0.071,0.027-0.075,0.12-0.129,0.167l0.008,0.023c-0.012,0.014-0.024,0.026-0.038,0.038
		c-0.007,0.03,0.001,0.046-0.008,0.06c0.003,0.02-0.01,0.025-0.03,0.023c-0.025,0.114-0.07,0.26-0.091,0.386
		c0.009,0.024,0.014,0.052,0.03,0.068c-0.015,0.128,0.053,0.253,0.106,0.372c0.028,0.015,0.04,0.046,0.068,0.06
		c-0.021,0.081,0.098,0.129,0.114,0.22c0.022,0.009,0.035,0.025,0.053,0.038c0.012,0.059,0.044,0.097,0.091,0.121
		c0.008,0.081,0.097,0.11,0.099,0.174c0.035,0.043,0.075,0.081,0.091,0.144c0.025,0.021,0.045,0.045,0.075,0.061
		c0,0.025,0.022,0.029,0.015,0.061c0.053,0.032,0.058,0.113,0.122,0.136c0.007,0.063,0.068,0.138,0.129,0.174
		c0.001,0.014-0.009,0.017-0.008,0.031c0.043,0.007,0.03,0.069,0.083,0.068c0.008,0.055,0.034,0.092,0.076,0.113
		c0.132,0.23,0.274,0.47,0.439,0.682c-0.005,0.023,0.013,0.023,0.008,0.046c0.111,0.137,0.192,0.288,0.318,0.439
		c0.008,0.093,0.059,0.144,0.129,0.197c-0.005,0.028,0.013,0.033,0.008,0.061c0.073,0.114,0.106,0.268,0.197,0.364
		c-0.022,0.044,0.033,0.058,0.022,0.113l0.023,0.008c0.13,0.35,0.292,0.707,0.356,1.076c-0.006,0.014-0.012,0.028-0.015,0.045
		c0.044,0.066,0.005,0.173,0.053,0.228c-0.004,0.319,0.092,0.63,0.046,0.947l0.015,0.045c-0.033,0.08,0.025,0.22-0.03,0.296
		c0.013,0.096-0.031,0.179-0.023,0.272c-0.039,0.032-0.01,0.111-0.061,0.144c-0.084,0.384-0.311,0.84-0.515,1.197
		c-0.029-0.006-0.027,0.019-0.046,0.023c-0.015,0.068-0.083,0.084-0.09,0.159c-0.056,0.005-0.045,0.077-0.099,0.084
		c-0.013,0.035-0.02,0.075-0.061,0.083c-0.118,0.157-0.242,0.308-0.401,0.424c0,0.018-0.013,0.023-0.008,0.046
		c-0.019-0.004-0.016,0.014-0.038,0.007c-0.012,0.031-0.053,0.033-0.053,0.076c-0.083,0.025-0.096,0.121-0.182,0.144
		c-0.003,0.019-0.029,0.016-0.022,0.045c-0.106,0.039-0.171,0.159-0.281,0.205c-0.008,0.024-0.033,0.033-0.038,0.061
		c-0.116,0.06-0.25,0.158-0.363,0.272c-0.069,0.012-0.09,0.1-0.167,0.107c-0.298,0.29-0.695,0.45-1.015,0.636
		c-0.227,0.034-0.463,0.129-0.675,0.197l-0.022-0.015c-0.06,0.047-0.188,0.001-0.25,0.068c-0.29,0.007-0.609,0.105-0.902,0.167
		c-0.035-0.018-0.072-0.008-0.083,0.015c-0.113-0.007-0.24,0.022-0.326,0.068c-0.737,0.105-1.492,0.357-2.22,0.447
		c-0.038,0.051-0.086-0.001-0.114,0.053c-0.017-0.005-0.039-0.006-0.053-0.015c-0.24,0.143-0.491,0.169-0.704,0.288
		c-0.084,0.003-0.247,0.023-0.311,0.068c-0.053-0.021-0.092,0.023-0.129-0.015c-0.08,0.043-0.145-0.029-0.258-0.015
		c-0.041-0.041-0.101-0.061-0.151-0.091c-0.016-0.067-0.092-0.075-0.098-0.152c-0.04-0.012-0.048-0.065-0.084-0.06
		c0.01-0.085-0.01-0.202,0.015-0.273c-0.03-0.059-0.016-0.075-0.007-0.144c0.021-0.027,0.058-0.038,0.053-0.091
		c0.023-0.009,0.043-0.023,0.045-0.053l-0.007,0.008c-0.013-0.01,0.005-0.055,0.007-0.076c0.032-0.017,0.077-0.02,0.106-0.038
		c0.024-0.069,0.104-0.083,0.121-0.159c0.061,0.009,0.105-0.048,0.137-0.091c0.412-0.263,0.959-0.275,1.44-0.409
		c0.07,0.026,0.107-0.029,0.181-0.008c0.019-0.006,0.043-0.007,0.053-0.023c0.268-0.007,0.629-0.011,0.849-0.106
		c0.047,0.024,0.157,0.003,0.22,0c0.043-0.059,0.138-0.008,0.189-0.06c0.013-0.001,0.039,0.025,0.046-0.008
		c0.574-0.129,1.201-0.149,1.765-0.371c0.064,0.019,0.126-0.023,0.167-0.053c0.237-0.083,0.541-0.186,0.75-0.341
		c0.147-0.043,0.323-0.168,0.447-0.28c0.156-0.035,0.317-0.209,0.477-0.273c0.003-0.027,0.02-0.019,0.008-0.046
		c0.141-0.047,0.213-0.162,0.348-0.242c-0.002-0.025,0.056-0.035,0.061-0.076c0.04,0.008,0.035-0.03,0.068-0.03
		c-0.001-0.057,0.054-0.057,0.053-0.114c0.041-0.01,0.071-0.03,0.076-0.076c0.031,0.003,0.068-0.043,0.076-0.075h0.022
		c0.022-0.026,0.045-0.067,0.053-0.099c0.023,0,0.026-0.02,0.046-0.023c0.043-0.101,0.146-0.141,0.174-0.257
		c0.175-0.141,0.273-0.326,0.349-0.568c0.054-0.037,0.049-0.133,0.106-0.167c0.191-0.565,0.2-1.24,0.136-1.788
		c-0.024-0.115-0.082-0.295-0.121-0.425l-0.023-0.007c-0.022-0.165-0.111-0.361-0.22-0.485c0.003-0.019-0.013-0.017-0.007-0.038
		c-0.03-0.015-0.052-0.039-0.076-0.061c0.006-0.031-0.015-0.035-0.015-0.06c-0.044-0.032-0.053-0.1-0.114-0.114
		c-0.021-0.052-0.038-0.108-0.098-0.121c-0.013-0.039-0.008-0.049-0.023-0.083c-0.033-0.013-0.03-0.062-0.068-0.069
		c-0.075-0.227-0.268-0.375-0.364-0.583c-0.161-0.164-0.235-0.389-0.409-0.53v-0.031c-0.182-0.192-0.288-0.42-0.439-0.621
		c0.01-0.024,0.001-0.025,0-0.053c-0.048-0.04-0.043-0.134-0.114-0.152c0.015-0.036-0.03-0.064-0.023-0.098
		c-0.056-0.052-0.071-0.142-0.129-0.197c0.004-0.051-0.017-0.057-0.015-0.091c-0.009-0.016-0.03-0.02-0.038-0.038
		c0.009-0.059-0.037-0.063-0.037-0.114c-0.007-0.011-0.007-0.029-0.031-0.022c0.028-0.087-0.047-0.154-0.076-0.228l0.008-0.022
		c-0.018-0.048-0.059-0.073-0.046-0.129c-0.221-0.628-0.155-1.337,0.251-1.872c-0.009-0.056,0.047-0.046,0.037-0.106
		c0.021,0.005,0.016-0.015,0.031-0.015c-0.001-0.049,0.022-0.073,0.053-0.091c0.01-0.068,0.077-0.079,0.091-0.144
		c0.123-0.111,0.241-0.235,0.356-0.348c0.062-0.004,0.09-0.042,0.106-0.091c0.127-0.042,0.211-0.128,0.348-0.159
		c0.075-0.072,0.229-0.079,0.288-0.144c0.093-0.014,0.138-0.052,0.235-0.046c0.01-0.005,0.014-0.016,0.015-0.03
		c0.041,0.034,0.037-0.028,0.084-0.008c0.04-0.04,0.131-0.03,0.166-0.075c0.071,0.001,0.172-0.037,0.243-0.076l0.023,0.015
		c0.061-0.024,0.113-0.058,0.204-0.053c0.01-0.013,0.035-0.009,0.03-0.038c0.067,0.028,0.128,0.005,0.182-0.03
		c0.021,0.005,0.061-0.01,0.069,0.007c0.096-0.047,0.198-0.107,0.303-0.113c0.006-0.014,0.03-0.01,0.022-0.038
		c0.057,0.023,0.069-0.015,0.091-0.046c0.049-0.001,0.1,0.021,0.122-0.038c0.112-0.001,0.159-0.12,0.265-0.151
		c0.014-0.022,0.041-0.029,0.045-0.061c0.047-0.021,0.106-0.03,0.121-0.083c0.089,0.005,0.128-0.105,0.212-0.129
		c0.067-0.078,0.149-0.126,0.16-0.25c0.178-0.131,0.225-0.394,0.257-0.606c0.02,0.005,0.015-0.015,0.03-0.015
		c0.053-0.203,0.091-0.384,0.061-0.599c0.028-0.02,0.034-0.058,0.015-0.091c0.046-0.238-0.006-0.598-0.045-0.826
		c-0.009-0.246-0.11-0.494-0.152-0.735c-0.029-0.037-0.058-0.087-0.06-0.128c-0.038,0.015-0.067,0.039-0.091,0.068
		c-0.193,0.082-0.357,0.225-0.546,0.295c-0.06,0.086-0.2,0.093-0.242,0.197c-0.113,0.02-0.18,0.148-0.281,0.197
		c-0.003,0.022-0.011,0.04-0.015,0.061c-0.064,0.003-0.078,0.045-0.113,0.091c-0.024-0.004-0.018,0.023-0.046,0.015
		c-0.012,0.028-0.042,0.039-0.053,0.068c-0.054,0.024-0.078,0.079-0.136,0.099c-0.019,0.057-0.08,0.072-0.099,0.129
		c-0.014,0.003-0.019,0.016-0.038,0.015c0.007,0.021-0.011,0.02-0.007,0.038c-0.147,0.139-0.309,0.303-0.409,0.469
		c-0.076,0.036-0.099,0.124-0.175,0.159c-0.025,0.084-0.101,0.117-0.136,0.19c-0.063,0.025-0.135,0.042-0.197,0.068
		c-0.053-0.016-0.124-0.02-0.174,0.008c-0.038-0.004-0.099-0.022-0.114,0.015c-0.025-0.015-0.049-0.024-0.098-0.03
		c-0.008-0.041-0.059-0.04-0.076-0.069c-0.032,0.01-0.047-0.007-0.076-0.007c-0.128-0.259-0.246-0.546-0.242-0.834l-0.023-0.022
		c-0.009-0.052,0.02-0.122-0.008-0.19c0.007-0.014,0.003-0.038,0.015-0.045c-0.046-0.215-0.035-0.405-0.045-0.637
		c0.062-0.103-0.02-0.269,0.045-0.379c-0.051-0.421,0.134-0.841,0.198-1.25c0.016,0.004,0.02-0.005,0.03-0.007
		c0.022-0.077,0.021-0.198,0.075-0.266c0.001-0.062,0.046-0.073,0.031-0.151c0.012-0.003,0.012,0.007,0.022,0.007
		c0.043-0.068,0.041-0.181,0.107-0.227c0.005-0.018,0.011-0.034,0.007-0.06c0.058-0.028,0.043-0.129,0.091-0.167
		c-0.004-0.035,0.015-0.049,0-0.076c0.071-0.097,0.144-0.206,0.197-0.326c0.047-0.025,0.102-0.063,0.106-0.136
		c0.028-0.02,0.055-0.042,0.083-0.061c0.052-0.141,0.124-0.244,0.205-0.371c-0.002-0.028,0.014-0.037,0.015-0.061
		c0.02-0.012,0.026-0.04,0.053-0.045c0.002-0.03,0.022-0.044,0.023-0.076c0.083-0.065,0.13-0.168,0.212-0.235
		c0.001-0.043,0.006-0.041,0.023-0.075c0.097-0.026,0.121-0.166,0.197-0.213c-0.037-0.029-0.058-0.073-0.114-0.083
		c-0.055-0.101-0.182-0.086-0.235-0.189l-0.007,0.022c-0.014-0.006-0.026-0.055-0.046-0.022c-0.033-0.043-0.089-0.062-0.106-0.122
		c-0.073-0.014-0.077-0.094-0.159-0.098c-0.079-0.092-0.182-0.125-0.265-0.22c-0.029,0.006-0.037-0.009-0.061-0.007
		c-0.046-0.055-0.096-0.106-0.174-0.129c-0.081-0.091-0.168-0.176-0.28-0.235c0.006-0.024-0.013-0.024-0.015-0.038
		c-0.351-0.251-0.612-0.587-0.963-0.826c0.007-0.021-0.01-0.02-0.007-0.038c-0.024-0.001-0.052-0.016-0.069-0.03v-0.023
		c-0.077-0.038-0.093-0.135-0.189-0.166c-0.108-0.166-0.286-0.243-0.386-0.402c-0.101-0.029-0.108-0.15-0.212-0.174
		c-0.019-0.052-0.079-0.063-0.099-0.114c-0.009-0.006-0.02-0.01-0.038-0.008c-0.011-0.053-0.072-0.046-0.091-0.106
		c-0.113-0.072-0.235-0.166-0.31-0.265c-0.066-0.012-0.07-0.087-0.129-0.106c-0.061-0.109-0.173-0.167-0.235-0.273
		c-0.096-0.05-0.163-0.134-0.227-0.212c-0.013-0.002-0.014,0.006-0.016,0.015l-0.007-0.03c-0.056-0.033-0.101-0.076-0.129-0.136
		c-0.055-0.026-0.095-0.067-0.129-0.114c-0.026-0.003-0.019-0.021-0.045-0.008c-0.028-0.069-0.081-0.111-0.144-0.144
		c-0.022-0.087-0.118-0.099-0.144-0.181c-0.071-0.052-0.114-0.129-0.197-0.167c-0.046-0.094-0.132-0.147-0.22-0.197
		c-0.023-0.061-0.069-0.097-0.129-0.121c-0.019-0.047-0.078-0.086-0.129-0.114c-0.007-0.028-0.022-0.048-0.053-0.053
		c0.006-0.033-0.021-0.034-0.022-0.061c-0.051-0.01-0.051-0.07-0.106-0.075c-0.048-0.061-0.101-0.149-0.174-0.167
		c0.002-0.018-0.015-0.016-0.008-0.038c-0.063-0.045-0.11-0.107-0.19-0.136c-0.13-0.161-0.307-0.341-0.492-0.463
		c-0.009-0.044-0.03-0.075-0.076-0.083c-0.017-0.053-0.04-0.101-0.098-0.114c-0.051-0.072-0.127-0.116-0.167-0.197
		c-0.037,0.009-0.028-0.027-0.061-0.022c0.008-0.031-0.022-0.023-0.015-0.053c-0.101-0.024-0.127-0.144-0.219-0.197
		c-0.023-0.066-0.07-0.108-0.122-0.144c-0.001-0.024-0.04-0.033-0.023-0.053l-0.015,0.007c-0.056-0.111-0.181-0.209-0.257-0.326
		h-0.015c-0.028-0.053-0.071-0.109-0.129-0.151c-0.024-0.062-0.1-0.155-0.152-0.182c0.002-0.076-0.095-0.117-0.121-0.189
		c-0.021,0.006-0.02-0.01-0.038-0.008c-0.031-0.092-0.078-0.085-0.098-0.174c-0.036-0.023-0.047-0.07-0.091-0.084
		c-0.004-0.029-0.026-0.04-0.031-0.068c-0.125-0.108-0.187-0.282-0.303-0.386c-0.004-0.038-0.031-0.126-0.083-0.144
		c-0.013-0.043-0.033-0.078-0.068-0.099c-0.043-0.131-0.159-0.189-0.197-0.325c-0.022-0.007-0.018-0.038-0.046-0.038
		c-0.517-0.696-0.954-1.432-1.326-2.152c-0.071-0.31-0.259-0.602-0.386-0.887c0.005-0.022-0.013-0.022-0.008-0.045
		c-0.07-0.059-0.063-0.2-0.136-0.273c-0.002-0.071-0.035-0.143-0.083-0.189c-0.019-0.077-0.032-0.161-0.084-0.205
		c-0.043-0.182-0.125-0.264-0.144-0.485c-0.15-0.329-0.197-0.701-0.288-1.061c0.012-0.041,0.002-0.107-0.03-0.143
		c-0.07-0.501-0.129-1.026-0.083-1.516c-0.113-0.75,0.014-1.499,0.068-2.243c0.004-0.011,0.01,0.003,0.023,0
		c0.003-0.268,0.109-0.564,0.136-0.841c0.049-0.003,0.028-0.078,0.061-0.098c0.023-0.244,0.162-0.478,0.227-0.743l0.015-0.007
		l-0.007-0.015l0.022-0.016c0.014-0.053,0.013-0.082,0.046-0.136c-0.015-0.078,0.045-0.081,0.038-0.151
		c0.07-0.091,0.079-0.257,0.144-0.334l-0.007-0.004c0.005-0.041,0.065-0.089,0.06-0.162c0.06-0.056,0.082-0.151,0.106-0.243
		c0.024,0.009,0.006-0.024,0.03-0.015c-0.008-0.025-0.001-0.075,0.03-0.083c0.029-0.191,0.17-0.276,0.228-0.44
		c0.016-0.009,0.029-0.021,0.045-0.03c0.006-0.052,0.049-0.067,0.053-0.121c0.239-0.301,0.407-0.673,0.727-0.925
		c0.007-0.036,0.04-0.047,0.046-0.083c0.078-0.023,0.117-0.085,0.159-0.144c0.034-0.014,0.06-0.037,0.076-0.068
		c0.091-0.046,0.154-0.119,0.22-0.19c0.026,0.003,0.027-0.023,0.053-0.023c0.058-0.093,0.174-0.128,0.234-0.219
		c0.054-0.011,0.086-0.044,0.122-0.106c0.05-0.018,0.081-0.056,0.129-0.076c0.05-0.078,0.16-0.097,0.197-0.189
		c0.03,0.012,0.074-0.021,0.083-0.053c0.118-0.075,0.273-0.152,0.364-0.258c0.112-0.027,0.165-0.106,0.25-0.182
		c0.019,0.009,0.036-0.023,0.045,0c0.123-0.087,0.236-0.183,0.371-0.258c0.008-0.017,0.027-0.024,0.023-0.053
		c0.039-0.007,0.038-0.014,0.053-0.038c0.16-0.004,0.375-0.242,0.546-0.325c0.031,0.003,0.032-0.023,0.068-0.015
		c0.383-0.225,0.774-0.403,1.167-0.561c0.001-0.01,0.006-0.032,0.022-0.046c0.042,0.003,0.055-0.013,0.076-0.015
		c0.066-0.078,0.178-0.063,0.258-0.129l0.015,0.016c0.09-0.051,0.301-0.049,0.394-0.122c0.109-0.008,0.162-0.055,0.273-0.06
		c0.089-0.084,0.264-0.051,0.341-0.159c0.118,0.016,0.21-0.07,0.326-0.084l0.007-0.015c0.043-0.007,0.116,0.006,0.144-0.045
		c0.075-0.002,0.178-0.026,0.227-0.069c0.11,0.024,0.274-0.093,0.425-0.106c0-0.025,0.036-0.014,0.038-0.037
		c0.065,0.008,0.157-0.011,0.212-0.054c0.005,0.024,0.034,0.006,0.038,0.016c0.37-0.112,0.852-0.144,1.166-0.25
		c0.182,0.018,0.327-0.009,0.501-0.038c0.022,0.019,0.046-0.009,0.06,0.015c0.083-0.04,0.281,0.004,0.394-0.046
		c0.087,0.035,0.16-0.028,0.235,0c0.286-0.044,0.623-0.057,0.909-0.068L129.925,0c0.991,0.054,2.06,0.006,3.039,0.243
		c0.049-0.025,0.079-0.006,0.121,0.023c0.041-0.022,0.08,0.017,0.114,0c0.077,0.058,0.198,0.029,0.265,0.091
		c0.221,0.023,0.427,0.125,0.667,0.159c0.23,0.086,0.516,0.165,0.742,0.272c0.037-0.008,0.044,0.032,0.084,0.016
		c0.056,0.065,0.13,0.03,0.174,0.098c0.053-0.009,0.055,0.039,0.121,0.03c0.312,0.16,0.715,0.263,1,0.455
		c0.084,0.013,0.158,0.085,0.243,0.121c0.003,0.015,0.016,0.024,0.022,0.038c0.077,0.014,0.124,0.058,0.167,0.106
		c0.13,0.028,0.2,0.119,0.326,0.152c0.063,0.071,0.167,0.101,0.25,0.151c-0.004,0.017,0.005,0.021,0.008,0.031
		c0.155,0.058,0.242,0.174,0.401,0.242c0.037,0.058,0.094,0.066,0.159,0.083c0.009,0.037,0.039,0.052,0.076,0.061
		c0.035,0.061,0.098,0.093,0.174,0.114c0.004,0.022,0.048,0.027,0.053,0.068c0.026,0,0.029,0.021,0.061,0.015
		c0.007,0.025,0.03,0.035,0.038,0.061h0.038c0.007,0.045,0.064,0.041,0.075,0.083c0.123,0.063,0.256,0.189,0.349,0.303
		c0.039,0.009,0.055,0.04,0.098,0.046c0.007,0.036,0.053,0.032,0.053,0.075c0.025,0.009,0.041,0.026,0.069,0.031
		c0.001,0.031,0.033,0.032,0.053,0.045c0.022,0.048,0.06,0.081,0.113,0.099c0.021,0.053,0.08,0.065,0.099,0.121
		c0.026-0.001,0.031,0.019,0.053,0.023c0.081,0.136,0.214,0.231,0.333,0.333c-0.002,0.035,0.022,0.044,0.031,0.068
		c0.039,0.016,0.042,0.068,0.09,0.076c0.015,0.043,0.064,0.103,0.114,0.121v0.023c0.152,0.104,0.249,0.309,0.386,0.432
		c0.008,0.041,0.047,0.078,0.084,0.098c-0.009,0.034,0.02,0.031,0.023,0.053c0.015,0.012,0.03,0.026,0.053,0.031
		c0.003,0.044,0.035,0.046,0.068,0.075c0.015,0.061,0.038,0.148,0.106,0.144c0.049,0.135,0.153,0.213,0.204,0.341
		c0.056,0.028,0.085,0.087,0.114,0.159c0.029-0.01,0.028,0.051,0.076,0.061c0.008,0.116,0.103,0.274,0.197,0.379
		c-0.005,0.023,0.012,0.023,0.007,0.045c0.144,0.219,0.246,0.46,0.334,0.675c0.019,0.011,0.017,0.043,0.038,0.053
		c-0.001,0.034,0.021,0.062,0.03,0.091l-0.008,0.022c0.077,0.103,0.082,0.25,0.182,0.341c0.562,1.733,0.551,3.58,0.182,5.349
		c-0.012,0.013-0.014,0.037-0.03,0.046c-0.044,0.303-0.215,0.684-0.258,0.993c-0.012,0.007-0.015,0.025-0.03,0.03
		c-0.035,0.185-0.117,0.349-0.167,0.53c-0.026,0-0.011,0.039-0.038,0.038c-0.019,0.128-0.102,0.191-0.121,0.318h-0.023
		c-0.001,0.036-0.018,0.052-0.015,0.091c-0.03-0.01-0.019,0.022-0.038,0.023c-0.114,0.339-0.263,0.726-0.477,1.008
		c-0.019,0.119-0.11,0.285-0.197,0.394c0.001,0.081-0.073,0.121-0.076,0.219c-0.084,0.074-0.086,0.213-0.159,0.281v0.038
		c-0.035,0.039-0.077,0.106-0.076,0.159c-0.022,0.01-0.022,0.043-0.045,0.053c-0.082,0.254-0.222,0.427-0.295,0.667
		c-0.033,0.007-0.07,0.043-0.061,0.083c-0.144,0.204-0.281,0.446-0.409,0.689c-0.021,0.011-0.026,0.036-0.046,0.046
		c-0.182,0.367-0.423,0.701-0.666,1.045v0.038c-0.016-0.006-0.035,0.021-0.016,0.023c-0.089,0.065-0.118,0.203-0.197,0.258
		c-0.011,0.072-0.077,0.089-0.083,0.166h-0.023c-0.116,0.193-0.281,0.341-0.348,0.554c-0.016,0.012-0.01-0.002-0.031,0
		c-0.016,0.059-0.064,0.087-0.075,0.151h-0.031c-0.011,0.054-0.048,0.082-0.06,0.136c-0.057,0.017-0.048,0.099-0.106,0.114
		c-0.035,0.085-0.1,0.138-0.129,0.227c-0.015,0.004-0.019,0.017-0.038,0.016c-0.014,0.054-0.067,0.069-0.076,0.128
		c-0.016,0.009-0.029,0.022-0.045,0.031c-0.052,0.093-0.11,0.178-0.182,0.25c0.001,0.036-0.029,0.041-0.03,0.075
		c-0.049,0.033-0.082,0.08-0.099,0.144c-0.09,0.085-0.164,0.185-0.227,0.296c-0.019,0.004-0.017,0.029-0.046,0.023
		c-0.219,0.334-0.468,0.677-0.742,0.977c0.006,0.022-0.012,0.02-0.008,0.038c-0.083,0.071-0.146,0.162-0.22,0.242
		c-0.022-0.002-0.024,0.018-0.045,0.016c-0.032,0.059-0.09,0.152-0.152,0.166c-0.011,0.042-0.043,0.063-0.053,0.106
		c-0.02,0.003-0.022,0.023-0.045,0.023c-0.014,0.052-0.07,0.062-0.076,0.121c-0.029-0.006-0.012,0.033-0.045,0.023
		c-0.055,0.089-0.139,0.213-0.22,0.288c-0.004,0.044-0.051,0.045-0.046,0.098c-0.167,0.176-0.331,0.382-0.499,0.561
		c-0.121,0.065-0.157,0.212-0.289,0.265c-0.031,0.052-0.076,0.09-0.136,0.114c-0.006,0.019-0.021,0.03-0.023,0.053
		c-0.055,0.015-0.085,0.056-0.106,0.106c-0.049,0.022-0.097,0.044-0.106,0.106h-0.03c-0.013,0.025-0.034,0.042-0.046,0.068
		c-0.026,0.004-0.035,0.026-0.06,0.031c-0.151,0.164-0.362,0.278-0.478,0.469c-0.109,0.032-0.133,0.149-0.234,0.19
		c-0.008,0.053-0.065,0.058-0.076,0.106c-0.034,0.009-0.064,0.021-0.068,0.061c-0.056,0.035-0.127,0.056-0.144,0.128
		c-0.043,0.024-0.108,0.05-0.129,0.106c-0.056-0.002-0.066,0.093-0.137,0.099c-0.037,0.071-0.116,0.101-0.151,0.174
		c-0.123,0.072-0.222,0.178-0.311,0.281c0.254,0.266,0.632,0.467,0.894,0.719c0.084,0.023,0.105,0.108,0.205,0.114
		c0.061,0.083,0.162,0.125,0.22,0.227c0.059,0.012,0.075,0.051,0.136,0.076c-0.034,0.022,0.041,0.032,0.061,0.046
		c-0.01,0.03,0.025,0.015,0.015,0.045c0.005,0.01,0.016,0.014,0.03,0.015c0.013,0.015-0.001,0.01,0,0.03
		c0.168,0.127,0.323,0.311,0.493,0.417c0.036,0.092,0.115,0.143,0.189,0.197c-0.019,0.019,0.022,0.017,0.015,0.046
		c0.181,0.214,0.4,0.407,0.44,0.689c-0.017-0.004-0.016,0.011-0.016,0.023c-0.84,0.304-1.707,0.203-2.515,0.667
		c0.338,1.364,0.622,2.971-0.068,4.265c-0.058,0.021-0.061,0.096-0.114,0.122v0.053c-0.038,0.035-0.096,0.05-0.106,0.113
		c-0.037,0.004-0.05,0.031-0.06,0.061h-0.031c0,0.018-0.012,0.023-0.007,0.045c-0.026-0.012-0.058,0.026-0.069,0.053
		c-0.229,0.122-0.396,0.314-0.644,0.41c-0.066,0.074-0.208,0.074-0.265,0.159c-0.084,0.003-0.193,0.04-0.242,0.091
		c-0.125,0.034-0.225,0.064-0.303,0.121c-0.019-0.018-0.037,0.01-0.046-0.015c-0.161,0.074-0.379,0.084-0.515,0.174L129.832,35.778z
		"
        />
        <path
          fill="#FFFFFF"
          d="M140.571,10.191c-0.022-0.242-0.018-0.485-0.023-0.719l-0.015-0.008l0.008-0.023
		c-0.059-0.5-0.099-1.013-0.19-1.515c-0.02-0.008-0.009-0.047-0.03-0.053c-0.001-0.14-0.086-0.279-0.106-0.424
		c-0.048-0.048-0.053-0.141-0.106-0.182c-0.012-0.164-0.138-0.278-0.167-0.44c-0.04-0.017-0.05-0.065-0.06-0.113
		c-0.016-0.005-0.018-0.023-0.031-0.031c-0.051-0.154-0.142-0.264-0.204-0.401c-0.024-0.003-0.047-0.046-0.069-0.061l0.008-0.015
		c-0.096-0.17-0.238-0.296-0.311-0.44c-0.131-0.136-0.279-0.278-0.401-0.424h-0.031c-0.055-0.073-0.121-0.17-0.204-0.197
		c0.006-0.021-0.012-0.02-0.008-0.038c-0.106-0.048-0.13-0.178-0.235-0.227c0.005-0.033-0.021-0.034-0.022-0.061
		c-0.052-0.011-0.081-0.045-0.099-0.091c-0.042,0.004-0.05-0.025-0.083-0.03c-0.158-0.178-0.352-0.322-0.5-0.515
		c-0.06-0.016-0.075-0.077-0.144-0.083c-0.008-0.021-0.023-0.033-0.031-0.053c-0.082-0.032-0.117-0.11-0.196-0.144
		c-0.002-0.027-0.027-0.03-0.031-0.053c-0.031-0.009-0.049-0.032-0.076-0.046c-0.005-0.023-0.024-0.032-0.03-0.053
		c-0.247-0.127-0.4-0.321-0.652-0.424c0.009-0.024-0.011-0.019-0.015-0.031c-0.176-0.099-0.341-0.205-0.515-0.303
		c-0.034,0.007-0.045-0.01-0.076-0.007c-0.027-0.048-0.104-0.047-0.136-0.091c-0.041,0.021-0.054-0.038-0.091-0.015
		c-0.122-0.081-0.27-0.146-0.417-0.19c-0.007-0.025-0.04-0.025-0.068-0.03l-0.008-0.023c-0.094-0.015-0.113-0.067-0.212-0.083
		c-0.022-0.046-0.102-0.047-0.144-0.091c-0.109-0.009-0.19-0.087-0.28-0.091c0-0.013-0.001-0.024-0.007-0.03
		c-0.081-0.014-0.141-0.047-0.19-0.091c-0.106-0.009-0.16-0.108-0.257-0.099c0.011-0.031-0.026-0.009-0.038-0.015l-0.015-0.023
		c-0.098-0.002-0.174-0.072-0.273-0.075c-0.007-0.022-0.031-0.026-0.046-0.038c-0.019-0.002-0.036-0.002-0.045,0.007
		c-0.033-0.055-0.165-0.073-0.212-0.053c-0.014-0.019-0.033-0.033-0.046-0.053c-0.23-0.045-0.416-0.112-0.629-0.151
		c0.013-0.031-0.033-0.003-0.03-0.023c-0.067,0.004-0.083-0.001-0.136-0.023c-0.216-0.007-0.408-0.096-0.652-0.098
		c-0.015,0.01-0.043,0.007-0.061,0.015c-0.07-0.056-0.203,0.006-0.272-0.023l-0.023,0.008l-0.023-0.023
		c-0.016-0.001-0.034,0.006-0.053,0.015c0.003-0.015-0.01-0.015-0.015-0.023c-0.045,0.015-0.09,0.006-0.129-0.015l-0.007,0.015
		h-0.061l-0.008-0.015c-2.058-0.135-4.109,0.191-6.031,0.91c-0.005,0.017-0.019,0.026-0.03,0.037c-0.041-0.003-0.067,0.018-0.099,0
		c-0.025,0.033-0.068,0.048-0.128,0.046c-0.224,0.123-0.471,0.188-0.698,0.295c-0.005,0.021-0.027,0.023-0.037,0.038
		c-0.146,0-0.282,0.145-0.432,0.159c-0.169,0.125-0.375,0.167-0.531,0.281c-0.046,0.001-0.069,0.026-0.09,0.053
		c-0.085-0.009-0.134,0.083-0.228,0.091c-0.086,0.094-0.269,0.116-0.356,0.219c-0.175,0.068-0.277,0.178-0.47,0.273
		c-0.01,0.028-0.04,0.036-0.053,0.061c-0.041,0.016-0.07,0.045-0.121,0.053c-2.064,1.723-3.114,4.589-2.659,7.228
		c-0.048,0.117,0.07,0.282,0.022,0.387c0.068,0.644,0.247,1.226,0.432,1.818c0.029-0.004,0.034,0.016,0.046,0.03
		c-0.013,0.028,0.001,0.037-0.008,0.061c0.197,0.551,0.393,1.195,0.659,1.674c-0.009,0.032,0.053,0.076,0.038,0.122
		c0.11,0.211,0.225,0.404,0.296,0.613c0.095,0.19,0.235,0.418,0.333,0.622c0.19,0.289,0.414,0.586,0.606,0.894
		c0.037,0.006,0.043,0.043,0.084,0.045c-0.021,0.06,0.074,0.066,0.068,0.137c0.074,0.056,0.111,0.104,0.144,0.197
		c0.119,0.064,0.148,0.221,0.25,0.295c0.008,0.053,0.063,0.063,0.075,0.114h0.031c0.007,0.033,0.041,0.039,0.038,0.083
		c0.024,0.014,0.043,0.033,0.068,0.046c-0.009,0.001-0.009,0.011-0.008,0.022c0.066,0.056,0.121,0.12,0.159,0.205
		c0.096,0.027,0.074,0.178,0.182,0.197c-0.004,0.024,0.023,0.018,0.015,0.045c0.061,0.009,0.084,0.111,0.152,0.137
		c0.019,0.067,0.093,0.078,0.106,0.151c0.127,0.127,0.223,0.235,0.326,0.379c0.064,0.019,0.07,0.096,0.136,0.114
		c0.03,0.077,0.133,0.11,0.152,0.189c0.039,0.024,0.089,0.037,0.091,0.099c0.04,0.02,0.088,0.032,0.09,0.091
		c0.025-0.002,0.037,0.008,0.053,0.015c0.058,0.128,0.175,0.203,0.273,0.295c0,0.01,0.01,0.01,0.008,0.023
		c0.088,0.032,0.125,0.12,0.204,0.159c0.012,0.039,0.038,0.063,0.076,0.076c0.019,0.064,0.086,0.079,0.114,0.136
		c0.029-0.001,0.039,0.017,0.061,0.023c-0.007,0.023,0.026,0.009,0.015,0.038c0.233,0.153,0.443,0.418,0.636,0.614
		c0.025,0.005,0.037,0.024,0.061,0.03c0.014,0.069,0.101,0.066,0.113,0.136c0.034,0.009,0.051,0.036,0.084,0.046
		c0.043,0.07,0.108,0.118,0.159,0.182c0.044-0.005,0.065,0.036,0.083,0.068c0.146,0.056,0.213,0.234,0.364,0.272
		c0.069,0.143,0.276,0.199,0.348,0.341c0.163,0.075,0.266,0.23,0.394,0.349c0.075-0.006,0.065,0.072,0.137,0.068
		c0.002,0.025,0.021,0.033,0.03,0.053c0.089,0.039,0.13,0.128,0.22,0.167c-0.002,0.019,0.011,0.024,0.015,0.038
		c0.03,0.012,0.063,0.023,0.068,0.06c0.114,0.041,0.162,0.147,0.273,0.19c-0.001,0.014,0.009,0.016,0.008,0.03
		c0.032-0.002,0.039,0.022,0.068,0.023c0.094,0.112,0.238,0.174,0.333,0.288c0.048,0.001,0.073,0.034,0.076,0.06
		c0.175,0.118,0.31,0.276,0.477,0.402c-0.004,0.037,0.033,0.033,0.038,0.061c0.1,0.041,0.13,0.152,0.242,0.181
		c0.014,0.085,0.115,0.083,0.137,0.16c0.035,0.018,0.084,0.026,0.083,0.083c0.129,0.01,0.129,0.149,0.25,0.167
		c0.01,0.02,0.017,0.022,0.008,0.045c0.062,0.022,0.086,0.081,0.151,0.099c0.006,0.03,0.023,0.048,0.046,0.06
		c0.004,0.039,0.044,0.042,0.053,0.076c0.087,0.021,0.111,0.106,0.197,0.129c0.189,0.193,0.354,0.372,0.576,0.523
		c-0.002,0.012,0.001,0.024,0.007,0.03c0.028,0.007,0.046,0.025,0.069,0.038c0.143-0.096,0.347-0.221,0.454-0.387
		c0.048-0.007,0.068-0.043,0.114-0.053c0.126-0.136,0.294-0.231,0.424-0.363h0.03c0.098-0.124,0.23-0.167,0.334-0.281
		c0.043-0.006,0.044-0.044,0.083-0.038c0.233-0.201,0.478-0.398,0.697-0.629c0.001-0.022,0.017-0.034,0.023-0.053
		c0.021,0.007,0.02-0.01,0.038-0.007c0.007-0.015,0.015-0.03,0.023-0.046l0.022,0.008c0.079-0.095,0.179-0.187,0.281-0.265
		c0.005-0.061,0.082-0.054,0.098-0.129c0.107-0.075,0.171-0.193,0.28-0.265c0.003-0.023,0.025-0.027,0.016-0.061
		c0.026,0.013,0.019-0.005,0.045-0.007c0.119-0.144,0.264-0.262,0.379-0.41c0.01-0.002,0.014-0.011,0.03-0.007
		c0.063-0.123,0.182-0.19,0.235-0.303c0.078-0.036,0.106-0.121,0.174-0.167c-0.001-0.019,0.033-0.068,0.069-0.076
		c0.027-0.088,0.114-0.117,0.144-0.204c0.079-0.029,0.079-0.138,0.159-0.167c0.138-0.21,0.306-0.39,0.424-0.621
		c0.039-0.007,0.038-0.053,0.076-0.061c0.034-0.12,0.155-0.153,0.189-0.273l0.038-0.015c0.317-0.484,0.768-0.917,1.046-1.409
		c0.02,0.019,0.025-0.019,0.045-0.023c0.024-0.052,0.07-0.082,0.083-0.144c0.024-0.009,0.033-0.033,0.061-0.038
		c0.038-0.106,0.133-0.155,0.167-0.265c0.046-0.026,0.062-0.084,0.106-0.113c-0.008-0.046,0.026-0.05,0.022-0.091
		c0.038-0.007,0.042-0.047,0.069-0.046c0.001-0.039,0.03-0.05,0.03-0.091c0.039-0.004,0.032-0.054,0.076-0.053
		c0-0.04,0.034-0.047,0.03-0.091c0.038,0,0.027-0.049,0.061-0.053c0.044-0.148,0.164-0.22,0.204-0.371
		c0.026-0.016,0.077-0.053,0.038-0.076c0.043,0.005,0.029-0.047,0.068-0.045c0.067-0.113,0.152-0.243,0.205-0.372
		c0.052-0.032,0.082-0.094,0.083-0.151c0.019-0.009,0.037-0.019,0.053-0.03c0.013-0.104,0.104-0.129,0.114-0.235
		c0.035-0.02,0.043-0.068,0.083-0.084c0.026-0.093,0.095-0.142,0.122-0.235c0.031-0.003,0.031-0.039,0.06-0.045
		c-0.005-0.023,0.008-0.028,0.008-0.045c0.043-0.023,0.05-0.081,0.098-0.099v-0.045c0.05-0.022,0.076-0.098,0.099-0.167
		c0.084-0.052,0.082-0.191,0.159-0.25c0.021-0.076,0.072-0.178,0.129-0.243c0.005-0.083,0.063-0.113,0.075-0.189
		c0.089-0.061,0.09-0.208,0.167-0.28c-0.005-0.031,0.008-0.043,0.008-0.069c0.039-0.007,0.059-0.069,0.053-0.098
		c0.024-0.001,0.023-0.028,0.045-0.03c0.037-0.117,0.143-0.196,0.182-0.356c0.03-0.023,0.042-0.065,0.068-0.091V14.95
		c0.021-0.021,0.036-0.046,0.038-0.084c0.022-0.014,0.033-0.038,0.053-0.053c0.009-0.074,0.024-0.141,0.076-0.174
		c-0.009-0.042,0.029-0.054,0-0.076c0.044-0.019,0.047-0.078,0.061-0.129c0.015-0.007,0.026-0.019,0.037-0.03
		c0.112-0.38,0.301-0.804,0.387-1.205c0.024,0.007,0.01-0.025,0.03-0.022c0.006-0.112,0.062-0.162,0.038-0.235
		c0.034-0.004,0-0.027,0.038-0.023c0.143-0.521,0.265-1.093,0.326-1.621c0.01,0,0.01,0.01,0.023,0.007
		c0.016-0.067,0.01-0.152-0.008-0.204c0.044,0.013-0.014-0.062,0.03-0.046c0.001-0.119,0.051-0.28,0.015-0.394
		c0.017-0.039,0.043-0.096,0.016-0.121c0.031-0.018,0.007-0.158,0.037-0.205C140.543,10.281,140.551,10.249,140.571,10.191"
        />
        <path
          fill="#FFFFFF"
          d="M132.138,28.694c0.02-0.023,0.053-0.033,0.06-0.068c-0.035-0.051-0.076-0.095-0.143-0.114
		c0.001-0.034-0.038-0.028-0.038-0.06c-0.041-0.012-0.051-0.051-0.099-0.053c-0.062-0.07-0.137-0.126-0.22-0.175
		c-0.023-0.048-0.065-0.077-0.121-0.091c-0.007-0.045-0.054-0.051-0.068-0.091c-0.062-0.026-0.083-0.093-0.159-0.106
		c-0.09-0.112-0.24-0.164-0.341-0.265c-0.068-0.01-0.057-0.052-0.129-0.053c-0.021-0.037-0.059-0.058-0.106-0.068
		c-0.016,0.021-0.035,0.04-0.038,0.076c-0.062,0.054-0.135,0.097-0.182,0.166c-0.021-0.006-0.02,0.01-0.037,0.008
		c-0.124,0.185-0.323,0.332-0.44,0.561c-0.048,0.025-0.073,0.073-0.114,0.106c-0.006,0.026-0.001,0.019-0.007,0.045
		c-0.039,0.024-0.065,0.062-0.076,0.114c-0.045,0.035-0.081,0.081-0.099,0.144c-0.018-0.018-0.02,0.024-0.045,0.023
		c-0.021,0.066-0.086,0.09-0.091,0.174c-0.079,0.049-0.101,0.156-0.136,0.25c-0.021,0.002-0.027,0.019-0.046,0.023
		c-0.043,0.166-0.142,0.276-0.174,0.454c-0.029,0.024-0.024,0.082-0.061,0.099c-0.02,0.103-0.068,0.2-0.06,0.303
		c-0.037,0.011-0.03,0.065-0.046,0.098c0.075-0.041,0.139-0.093,0.197-0.151l0.008,0.015c0.05-0.07,0.124-0.073,0.174-0.137
		c0.055-0.017,0.079-0.069,0.137-0.075c0.381-0.232,0.799-0.466,1.197-0.614c0.009-0.016,0.03-0.02,0.038-0.038
		c0.074-0.024,0.141-0.055,0.197-0.098c0.071,0.002,0.153-0.061,0.212-0.091c0.023,0.019,0.071-0.021,0.068-0.031
		c0.029,0.018,0.03-0.014,0.053-0.022c0.063,0.014,0.107-0.048,0.167-0.038c0.005-0.018,0.019-0.027,0.03-0.038
		C131.797,28.884,131.959,28.691,132.138,28.694"
        />
      </g>
      <g v-if="showBottomLeft" id="bottom_x5F_left">
        <path
          class="fill-current"
          d="M12.302,94.313c-0.048,0.017-0.205,0.015-0.27,0.076c-0.1-0.001-0.201,0.042-0.265,0.083
		c-0.019-0.004-0.056-0.011-0.061,0.023c-0.23,0.091-0.482,0.149-0.69,0.281h-0.037c-0.129,0.079-0.261,0.151-0.394,0.212
		c-0.006,0.05-0.07,0.045-0.076,0.098c-0.071,0.027-0.075,0.12-0.129,0.167l0.008,0.023c-0.012,0.014-0.024,0.026-0.038,0.038
		c-0.007,0.03,0.001,0.046-0.008,0.06c0.003,0.02-0.01,0.025-0.03,0.023c-0.025,0.114-0.07,0.26-0.091,0.386
		c0.009,0.024,0.014,0.052,0.03,0.068c-0.015,0.128,0.053,0.253,0.106,0.372c0.028,0.015,0.04,0.046,0.068,0.06
		c-0.021,0.081,0.098,0.129,0.114,0.22c0.022,0.009,0.035,0.025,0.053,0.038c0.012,0.059,0.044,0.097,0.091,0.121
		c0.008,0.081,0.097,0.11,0.099,0.174c0.035,0.043,0.075,0.081,0.091,0.144c0.025,0.021,0.045,0.045,0.075,0.061
		c0,0.025,0.022,0.029,0.015,0.061c0.053,0.032,0.058,0.113,0.122,0.136c0.007,0.063,0.068,0.138,0.129,0.174
		c0.001,0.014-0.009,0.017-0.008,0.031c0.043,0.007,0.03,0.069,0.083,0.068c0.008,0.055,0.034,0.092,0.076,0.113
		c0.132,0.23,0.274,0.47,0.439,0.682c-0.005,0.023,0.013,0.023,0.008,0.046c0.111,0.137,0.193,0.288,0.318,0.439
		c0.008,0.093,0.059,0.144,0.129,0.197c-0.005,0.028,0.013,0.033,0.008,0.061c0.073,0.114,0.106,0.268,0.197,0.364
		c-0.022,0.044,0.033,0.058,0.022,0.113l0.023,0.008c0.13,0.35,0.292,0.707,0.356,1.076c-0.006,0.014-0.012,0.028-0.015,0.045
		c0.044,0.066,0.005,0.173,0.053,0.228c-0.004,0.319,0.092,0.63,0.046,0.947l0.015,0.045c-0.033,0.08,0.025,0.22-0.03,0.296
		c0.013,0.096-0.031,0.179-0.023,0.272c-0.039,0.032-0.01,0.111-0.061,0.144c-0.084,0.384-0.311,0.84-0.515,1.197
		c-0.029-0.006-0.027,0.019-0.046,0.023c-0.015,0.068-0.083,0.084-0.09,0.159c-0.056,0.005-0.045,0.077-0.099,0.084
		c-0.013,0.035-0.02,0.075-0.061,0.083c-0.118,0.157-0.242,0.308-0.401,0.424c0,0.018-0.013,0.023-0.008,0.046
		c-0.019-0.004-0.016,0.014-0.038,0.007c-0.012,0.031-0.053,0.033-0.053,0.076c-0.083,0.025-0.096,0.121-0.182,0.144
		c-0.003,0.019-0.029,0.016-0.022,0.045c-0.106,0.039-0.171,0.159-0.281,0.205c-0.008,0.024-0.033,0.033-0.038,0.061
		c-0.115,0.059-0.251,0.158-0.363,0.272c-0.069,0.012-0.09,0.1-0.167,0.107c-0.297,0.29-0.695,0.45-1.015,0.636
		c-0.228,0.034-0.462,0.129-0.675,0.197l-0.022-0.015c-0.06,0.047-0.188,0.001-0.25,0.068c-0.29,0.007-0.609,0.105-0.902,0.167
		c-0.035-0.018-0.072-0.008-0.083,0.015c-0.113-0.007-0.24,0.022-0.326,0.068c-0.737,0.105-1.492,0.357-2.22,0.447
		c-0.038,0.051-0.086-0.001-0.114,0.053c-0.017-0.005-0.039-0.006-0.053-0.015c-0.24,0.143-0.491,0.169-0.704,0.288
		c-0.084,0.003-0.247,0.023-0.311,0.068c-0.053-0.021-0.092,0.023-0.129-0.015c-0.08,0.043-0.145-0.029-0.258-0.015
		c-0.041-0.041-0.101-0.061-0.151-0.091c-0.016-0.067-0.092-0.075-0.098-0.152c-0.04-0.012-0.048-0.065-0.084-0.06
		c0.01-0.085-0.01-0.202,0.015-0.273c-0.03-0.059-0.016-0.075-0.007-0.144c0.021-0.027,0.058-0.038,0.053-0.091
		c0.023-0.009,0.043-0.023,0.045-0.053l-0.007,0.008c-0.013-0.01,0.005-0.055,0.007-0.076c0.032-0.017,0.077-0.02,0.106-0.038
		c0.024-0.069,0.104-0.083,0.121-0.159c0.061,0.009,0.105-0.048,0.137-0.091c0.412-0.263,0.959-0.275,1.44-0.409
		c0.07,0.026,0.107-0.029,0.181-0.008c0.019-0.006,0.043-0.007,0.053-0.023c0.268-0.007,0.629-0.011,0.849-0.106
		c0.047,0.024,0.157,0.003,0.22,0c0.043-0.059,0.138-0.008,0.189-0.06c0.013-0.001,0.039,0.025,0.046-0.008
		c0.574-0.129,1.201-0.149,1.765-0.371c0.064,0.019,0.126-0.023,0.167-0.053c0.237-0.083,0.54-0.186,0.75-0.341
		c0.147-0.043,0.323-0.168,0.447-0.28c0.156-0.035,0.317-0.209,0.477-0.273c0.003-0.027,0.02-0.019,0.008-0.046
		c0.141-0.047,0.213-0.162,0.348-0.242c-0.002-0.025,0.056-0.035,0.061-0.076c0.04,0.008,0.035-0.03,0.068-0.03
		c-0.001-0.057,0.054-0.057,0.053-0.114c0.041-0.01,0.071-0.03,0.076-0.076c0.03,0.003,0.068-0.043,0.076-0.075h0.022
		c0.022-0.026,0.045-0.067,0.053-0.099c0.023,0,0.026-0.02,0.046-0.023c0.043-0.101,0.146-0.141,0.174-0.257
		c0.175-0.141,0.273-0.326,0.349-0.568c0.054-0.037,0.049-0.133,0.106-0.167c0.191-0.565,0.2-1.24,0.136-1.788
		c-0.024-0.115-0.082-0.295-0.121-0.425l-0.023-0.007c-0.022-0.165-0.111-0.361-0.22-0.485c0.003-0.019-0.013-0.017-0.007-0.038
		c-0.03-0.015-0.052-0.039-0.076-0.061c0.007-0.031-0.015-0.035-0.015-0.06c-0.044-0.032-0.053-0.1-0.114-0.114
		c-0.021-0.052-0.038-0.108-0.098-0.121c-0.013-0.039-0.008-0.049-0.023-0.083c-0.033-0.013-0.03-0.062-0.068-0.069
		c-0.075-0.227-0.268-0.375-0.364-0.583c-0.162-0.164-0.234-0.39-0.409-0.53v-0.031c-0.182-0.192-0.288-0.42-0.439-0.621
		c0.01-0.024,0.001-0.025,0-0.053c-0.048-0.04-0.043-0.134-0.114-0.152c0.015-0.036-0.03-0.064-0.023-0.098
		c-0.056-0.053-0.071-0.142-0.129-0.197c0.004-0.051-0.017-0.057-0.015-0.091c-0.009-0.016-0.03-0.02-0.038-0.038
		c0.009-0.059-0.037-0.063-0.037-0.114c-0.007-0.011-0.007-0.029-0.031-0.022c0.028-0.087-0.047-0.154-0.076-0.228l0.008-0.022
		c-0.018-0.048-0.059-0.073-0.046-0.129c-0.221-0.628-0.155-1.337,0.251-1.872c-0.009-0.056,0.047-0.046,0.037-0.106
		c0.021,0.005,0.016-0.015,0.031-0.015c-0.001-0.049,0.022-0.073,0.053-0.091c0.01-0.068,0.077-0.079,0.091-0.144
		c0.123-0.111,0.241-0.235,0.356-0.348c0.062-0.004,0.09-0.042,0.106-0.091c0.127-0.042,0.211-0.128,0.348-0.159
		c0.075-0.072,0.229-0.079,0.288-0.144c0.093-0.014,0.138-0.052,0.235-0.046c0.01-0.005,0.014-0.016,0.015-0.03
		c0.041,0.034,0.037-0.028,0.084-0.008c0.04-0.04,0.131-0.03,0.166-0.075c0.071,0.001,0.172-0.037,0.243-0.076l0.023,0.015
		c0.061-0.024,0.113-0.058,0.204-0.053c0.01-0.013,0.035-0.009,0.03-0.038c0.067,0.028,0.128,0.005,0.182-0.03
		c0.021,0.005,0.061-0.01,0.069,0.007c0.096-0.047,0.198-0.107,0.303-0.113c0.006-0.014,0.03-0.01,0.022-0.038
		c0.057,0.023,0.069-0.015,0.091-0.046c0.049-0.001,0.1,0.021,0.122-0.038c0.112-0.001,0.159-0.12,0.265-0.151
		c0.014-0.022,0.041-0.029,0.045-0.061c0.047-0.021,0.106-0.03,0.121-0.083c0.089,0.005,0.128-0.105,0.212-0.129
		c0.067-0.078,0.149-0.126,0.16-0.25c0.178-0.131,0.225-0.394,0.257-0.606c0.02,0.005,0.015-0.015,0.031-0.015
		c0.051-0.203,0.09-0.384,0.06-0.599c0.028-0.02,0.034-0.058,0.015-0.091c0.046-0.238-0.006-0.598-0.045-0.826
		c-0.009-0.246-0.11-0.494-0.152-0.735c-0.029-0.037-0.058-0.087-0.06-0.128c-0.038,0.015-0.067,0.039-0.091,0.068
		c-0.193,0.082-0.357,0.225-0.546,0.295c-0.06,0.086-0.2,0.093-0.242,0.197c-0.113,0.02-0.18,0.148-0.281,0.197
		c-0.003,0.022-0.011,0.04-0.015,0.061c-0.064,0.003-0.078,0.045-0.113,0.091c-0.024-0.004-0.018,0.023-0.046,0.015
		c-0.012,0.028-0.042,0.039-0.053,0.068c-0.054,0.024-0.078,0.079-0.136,0.099c-0.019,0.057-0.08,0.072-0.099,0.129
		c-0.014,0.003-0.019,0.016-0.038,0.015c0.007,0.021-0.011,0.02-0.007,0.038c-0.147,0.139-0.309,0.303-0.409,0.469
		c-0.076,0.036-0.099,0.124-0.175,0.159c-0.025,0.084-0.101,0.117-0.136,0.19c-0.063,0.025-0.135,0.042-0.197,0.068
		c-0.053-0.016-0.124-0.02-0.174,0.008c-0.038-0.004-0.099-0.022-0.114,0.015c-0.025-0.015-0.049-0.024-0.098-0.03
		c-0.008-0.041-0.059-0.04-0.076-0.069c-0.032,0.01-0.047-0.007-0.076-0.007c-0.128-0.259-0.246-0.546-0.242-0.834l-0.023-0.022
		c-0.009-0.052,0.02-0.122-0.008-0.19c0.007-0.014,0.003-0.038,0.015-0.045c-0.046-0.215-0.035-0.405-0.045-0.637
		c0.062-0.103-0.02-0.269,0.045-0.379c-0.051-0.422,0.133-0.839,0.198-1.25c0.016,0.004,0.02-0.005,0.03-0.007
		c0.022-0.077,0.021-0.198,0.075-0.266c0.001-0.062,0.046-0.073,0.031-0.151c0.012-0.003,0.012,0.007,0.022,0.007
		c0.043-0.068,0.041-0.181,0.107-0.227c0.005-0.018,0.011-0.034,0.007-0.06c0.058-0.028,0.043-0.129,0.091-0.167
		c-0.004-0.036,0.015-0.049,0-0.076c0.071-0.097,0.144-0.206,0.197-0.326c0.047-0.025,0.102-0.063,0.106-0.136
		c0.028-0.02,0.055-0.042,0.083-0.061c0.052-0.141,0.124-0.244,0.205-0.371c-0.002-0.028,0.014-0.037,0.015-0.061
		c0.02-0.012,0.026-0.04,0.053-0.045c0.002-0.03,0.022-0.044,0.023-0.076c0.083-0.065,0.13-0.168,0.212-0.235
		c0.001-0.043,0.006-0.041,0.023-0.075c0.097-0.026,0.121-0.166,0.197-0.213c-0.037-0.029-0.058-0.073-0.114-0.083
		c-0.055-0.101-0.182-0.086-0.235-0.189l-0.007,0.022c-0.014-0.006-0.026-0.055-0.046-0.022c-0.033-0.043-0.089-0.062-0.106-0.122
		c-0.073-0.014-0.077-0.094-0.159-0.098c-0.079-0.092-0.182-0.125-0.265-0.22c-0.029,0.006-0.037-0.009-0.061-0.007
		c-0.046-0.055-0.096-0.107-0.174-0.129c-0.081-0.091-0.168-0.176-0.28-0.235c0.006-0.024-0.013-0.024-0.015-0.038
		c-0.351-0.251-0.612-0.587-0.963-0.826c0.007-0.021-0.01-0.02-0.007-0.038c-0.024-0.001-0.052-0.016-0.068-0.03v-0.023
		c-0.079-0.037-0.093-0.135-0.19-0.166c-0.108-0.166-0.286-0.243-0.386-0.402c-0.101-0.029-0.108-0.15-0.212-0.174
		C8.49,82.51,8.43,82.499,8.41,82.448c-0.009-0.006-0.02-0.01-0.038-0.008c-0.011-0.053-0.072-0.046-0.091-0.106
		c-0.113-0.072-0.235-0.166-0.31-0.265c-0.066-0.012-0.07-0.087-0.129-0.106c-0.061-0.109-0.173-0.167-0.235-0.273
		c-0.096-0.05-0.163-0.134-0.227-0.212c-0.013-0.002-0.014,0.006-0.016,0.015l-0.007-0.03c-0.056-0.033-0.101-0.076-0.129-0.136
		c-0.055-0.026-0.095-0.067-0.129-0.114c-0.026-0.003-0.019-0.021-0.045-0.008c-0.028-0.069-0.081-0.111-0.144-0.144
		c-0.022-0.087-0.118-0.099-0.144-0.181c-0.071-0.052-0.114-0.129-0.197-0.167c-0.046-0.094-0.132-0.147-0.22-0.197
		c-0.022-0.061-0.069-0.097-0.129-0.121c-0.019-0.047-0.078-0.086-0.129-0.114c-0.007-0.028-0.022-0.048-0.053-0.053
		c0.006-0.033-0.021-0.034-0.022-0.061c-0.051-0.01-0.051-0.07-0.106-0.075c-0.048-0.061-0.101-0.149-0.174-0.167
		c0.002-0.018-0.015-0.016-0.008-0.038c-0.063-0.045-0.11-0.107-0.19-0.136c-0.13-0.161-0.307-0.341-0.492-0.463
		c-0.009-0.044-0.03-0.075-0.076-0.083c-0.017-0.053-0.04-0.101-0.098-0.114c-0.051-0.072-0.127-0.116-0.167-0.197
		c-0.037,0.009-0.028-0.027-0.061-0.022c0.008-0.031-0.022-0.023-0.015-0.053c-0.101-0.024-0.127-0.144-0.219-0.197
		c-0.023-0.066-0.07-0.108-0.122-0.144c-0.001-0.024-0.04-0.033-0.023-0.053L4.25,78.432c-0.056-0.111-0.181-0.209-0.257-0.326
		H3.978c-0.028-0.053-0.071-0.109-0.129-0.151c-0.024-0.062-0.1-0.156-0.152-0.182c0.002-0.076-0.095-0.117-0.121-0.189
		c-0.021,0.006-0.02-0.01-0.038-0.008c-0.031-0.092-0.078-0.085-0.098-0.174c-0.036-0.023-0.047-0.07-0.091-0.084
		c-0.004-0.029-0.026-0.04-0.031-0.068c-0.125-0.108-0.187-0.282-0.303-0.386c-0.004-0.038-0.031-0.126-0.083-0.144
		c-0.013-0.043-0.033-0.078-0.068-0.099c-0.043-0.131-0.159-0.189-0.197-0.325c-0.022-0.007-0.018-0.038-0.046-0.038
		c-0.517-0.696-0.954-1.432-1.326-2.152c-0.071-0.31-0.259-0.602-0.386-0.887c0.005-0.022-0.013-0.022-0.008-0.045
		c-0.07-0.059-0.063-0.2-0.136-0.273c-0.002-0.071-0.035-0.143-0.083-0.189c-0.019-0.077-0.032-0.161-0.084-0.205
		c-0.043-0.182-0.125-0.264-0.144-0.485c-0.15-0.329-0.197-0.7-0.288-1.061c0.012-0.041,0.002-0.107-0.03-0.143
		c-0.07-0.501-0.129-1.026-0.083-1.516c-0.113-0.75,0.014-1.499,0.068-2.243c0.004-0.011,0.01,0.003,0.023,0
		c0.003-0.268,0.109-0.564,0.136-0.841c0.049-0.003,0.028-0.078,0.061-0.098c0.023-0.244,0.162-0.478,0.227-0.743l0.015-0.007
		l-0.007-0.015l0.022-0.016c0.014-0.053,0.013-0.082,0.046-0.136c-0.015-0.078,0.045-0.081,0.038-0.151
		c0.07-0.091,0.079-0.257,0.144-0.334l-0.007-0.004c0.005-0.041,0.065-0.089,0.06-0.163c0.06-0.055,0.082-0.15,0.106-0.242
		c0.024,0.009,0.006-0.024,0.03-0.015c-0.008-0.025-0.001-0.075,0.03-0.083c0.029-0.191,0.17-0.276,0.228-0.44
		c0.016-0.009,0.029-0.021,0.045-0.03c0.006-0.052,0.049-0.067,0.053-0.121c0.239-0.301,0.407-0.673,0.727-0.925
		c0.007-0.036,0.04-0.047,0.046-0.083c0.078-0.023,0.117-0.085,0.159-0.144c0.034-0.014,0.06-0.037,0.076-0.068
		c0.091-0.046,0.154-0.119,0.22-0.19c0.026,0.003,0.027-0.023,0.053-0.023c0.058-0.093,0.174-0.128,0.234-0.219
		c0.054-0.011,0.086-0.044,0.122-0.106c0.05-0.018,0.081-0.056,0.129-0.076c0.05-0.078,0.16-0.097,0.197-0.189
		c0.03,0.012,0.074-0.021,0.083-0.053c0.118-0.075,0.273-0.152,0.364-0.258c0.112-0.027,0.165-0.106,0.25-0.182
		c0.019,0.009,0.036-0.023,0.045,0c0.123-0.087,0.236-0.183,0.371-0.258c0.008-0.017,0.027-0.024,0.023-0.053
		c0.039-0.007,0.038-0.014,0.053-0.038c0.16-0.004,0.375-0.242,0.546-0.325c0.031,0.003,0.032-0.023,0.068-0.015
		c0.383-0.225,0.774-0.403,1.167-0.561c0.001-0.01,0.006-0.032,0.023-0.046c0.041,0.003,0.054-0.013,0.075-0.015
		c0.066-0.078,0.178-0.063,0.258-0.129l0.015,0.016c0.09-0.051,0.301-0.049,0.394-0.122c0.109-0.008,0.162-0.055,0.273-0.06
		c0.089-0.084,0.264-0.051,0.341-0.159c0.118,0.016,0.21-0.07,0.326-0.084l0.007-0.015c0.043-0.007,0.116,0.006,0.144-0.045
		c0.075-0.002,0.178-0.026,0.227-0.069c0.11,0.024,0.274-0.093,0.425-0.106c0-0.025,0.036-0.014,0.038-0.038
		c0.065,0.009,0.157-0.01,0.212-0.053c0.005,0.024,0.034,0.006,0.038,0.016c0.37-0.112,0.852-0.144,1.166-0.25
		c0.182,0.018,0.327-0.009,0.501-0.038c0.022,0.019,0.046-0.009,0.06,0.015c0.083-0.041,0.281,0.004,0.394-0.046
		c0.087,0.035,0.16-0.028,0.235,0c0.286-0.044,0.623-0.057,0.909-0.068l0.03-0.023c0.991,0.054,2.06,0.006,3.039,0.243
		c0.049-0.025,0.079-0.006,0.121,0.023c0.041-0.022,0.08,0.017,0.114,0c0.077,0.058,0.198,0.029,0.265,0.091
		c0.221,0.023,0.427,0.125,0.667,0.159c0.231,0.086,0.516,0.165,0.742,0.272c0.037-0.008,0.044,0.032,0.084,0.016
		c0.056,0.064,0.13,0.03,0.174,0.098c0.053-0.009,0.055,0.039,0.121,0.03c0.312,0.16,0.715,0.263,1,0.455
		c0.084,0.013,0.158,0.085,0.243,0.121c0.003,0.015,0.016,0.024,0.022,0.038c0.077,0.014,0.124,0.058,0.167,0.106
		c0.13,0.028,0.2,0.119,0.326,0.152c0.063,0.071,0.167,0.101,0.25,0.151c-0.004,0.017,0.005,0.021,0.008,0.031
		c0.155,0.058,0.242,0.174,0.401,0.242c0.037,0.058,0.094,0.066,0.159,0.083c0.009,0.037,0.039,0.052,0.076,0.061
		c0.035,0.061,0.098,0.093,0.174,0.114c0.004,0.022,0.048,0.027,0.053,0.068c0.026,0,0.029,0.021,0.061,0.015
		c0.007,0.025,0.03,0.035,0.038,0.061h0.038c0.007,0.045,0.064,0.041,0.075,0.083c0.123,0.063,0.256,0.189,0.349,0.303
		c0.039,0.009,0.055,0.04,0.098,0.046c0.007,0.036,0.053,0.032,0.053,0.075c0.025,0.009,0.041,0.026,0.069,0.031
		c0.001,0.031,0.033,0.032,0.053,0.045c0.022,0.048,0.06,0.081,0.113,0.099c0.021,0.053,0.08,0.065,0.099,0.121
		c0.026-0.001,0.031,0.019,0.053,0.023c0.081,0.136,0.214,0.231,0.333,0.333c-0.002,0.035,0.022,0.044,0.031,0.068
		c0.039,0.016,0.042,0.068,0.09,0.076c0.015,0.043,0.064,0.103,0.114,0.121v0.023c0.152,0.104,0.249,0.309,0.386,0.432
		c0.008,0.041,0.047,0.078,0.084,0.098c-0.009,0.034,0.02,0.031,0.023,0.053c0.015,0.012,0.03,0.026,0.053,0.031
		c0.003,0.044,0.035,0.046,0.068,0.075c0.015,0.061,0.038,0.148,0.106,0.144c0.049,0.135,0.153,0.213,0.204,0.341
		c0.056,0.028,0.085,0.087,0.114,0.159c0.029-0.01,0.028,0.051,0.076,0.061c0.008,0.116,0.103,0.274,0.197,0.379
		c-0.005,0.023,0.012,0.023,0.007,0.045c0.144,0.219,0.246,0.46,0.334,0.675c0.019,0.011,0.017,0.043,0.038,0.053
		c-0.001,0.034,0.021,0.062,0.03,0.091l-0.008,0.022c0.077,0.103,0.082,0.25,0.182,0.341c0.562,1.733,0.551,3.58,0.182,5.349
		c-0.012,0.013-0.014,0.037-0.03,0.046C24.27,71.31,24.099,71.692,24.056,72c-0.012,0.007-0.015,0.025-0.03,0.03
		c-0.035,0.185-0.117,0.349-0.167,0.53c-0.026,0-0.011,0.039-0.038,0.038c-0.019,0.128-0.102,0.191-0.121,0.318h-0.023
		c-0.001,0.036-0.018,0.052-0.015,0.091c-0.03-0.01-0.019,0.022-0.038,0.023c-0.114,0.339-0.263,0.726-0.477,1.008
		c-0.019,0.119-0.11,0.285-0.197,0.394c0.001,0.081-0.073,0.121-0.076,0.219c-0.084,0.074-0.086,0.213-0.159,0.281v0.038
		c-0.035,0.039-0.077,0.106-0.076,0.159c-0.022,0.01-0.022,0.043-0.045,0.053c-0.082,0.254-0.222,0.427-0.295,0.667
		c-0.033,0.007-0.07,0.043-0.061,0.083c-0.144,0.204-0.281,0.446-0.409,0.689c-0.021,0.011-0.026,0.036-0.046,0.046
		c-0.182,0.367-0.423,0.701-0.666,1.045v0.038c-0.016-0.006-0.035,0.021-0.016,0.023c-0.089,0.065-0.118,0.203-0.197,0.258
		c-0.011,0.072-0.077,0.089-0.083,0.166h-0.023c-0.116,0.193-0.281,0.34-0.348,0.554c-0.016,0.012-0.01-0.002-0.031,0
		c-0.016,0.059-0.064,0.087-0.075,0.151h-0.031c-0.011,0.054-0.048,0.082-0.06,0.136c-0.057,0.017-0.048,0.099-0.106,0.114
		c-0.035,0.085-0.1,0.138-0.129,0.227c-0.015,0.004-0.019,0.017-0.038,0.016c-0.014,0.054-0.067,0.069-0.076,0.128
		c-0.016,0.009-0.029,0.022-0.045,0.031c-0.052,0.093-0.11,0.178-0.182,0.25c0.001,0.036-0.029,0.041-0.03,0.075
		c-0.049,0.033-0.082,0.08-0.099,0.144c-0.09,0.085-0.164,0.185-0.227,0.296c-0.019,0.004-0.017,0.029-0.046,0.023
		c-0.219,0.334-0.468,0.677-0.742,0.977c0.006,0.022-0.012,0.02-0.008,0.038c-0.083,0.071-0.146,0.162-0.219,0.242
		c-0.023-0.002-0.025,0.018-0.046,0.016c-0.032,0.059-0.09,0.152-0.152,0.166c-0.011,0.042-0.043,0.063-0.053,0.106
		c-0.02,0.003-0.022,0.023-0.045,0.023c-0.014,0.052-0.07,0.062-0.076,0.121c-0.029-0.006-0.012,0.033-0.045,0.023
		c-0.055,0.089-0.139,0.213-0.22,0.288c-0.004,0.044-0.051,0.045-0.046,0.098c-0.167,0.176-0.331,0.382-0.499,0.561
		c-0.121,0.065-0.157,0.212-0.289,0.265c-0.031,0.052-0.076,0.09-0.136,0.114c-0.006,0.019-0.021,0.03-0.023,0.053
		c-0.055,0.015-0.085,0.056-0.106,0.106c-0.049,0.022-0.097,0.044-0.106,0.106h-0.03c-0.013,0.025-0.034,0.042-0.046,0.068
		c-0.026,0.004-0.035,0.026-0.06,0.031c-0.151,0.164-0.362,0.278-0.478,0.469c-0.109,0.032-0.133,0.149-0.234,0.19
		c-0.008,0.053-0.065,0.058-0.076,0.106c-0.034,0.009-0.064,0.021-0.068,0.061c-0.056,0.035-0.127,0.056-0.144,0.128
		c-0.043,0.024-0.108,0.05-0.129,0.106c-0.056-0.002-0.066,0.093-0.137,0.099c-0.037,0.071-0.116,0.101-0.151,0.174
		c-0.123,0.072-0.222,0.178-0.311,0.281c0.254,0.266,0.632,0.467,0.894,0.719c0.084,0.023,0.105,0.108,0.205,0.114
		c0.061,0.083,0.162,0.125,0.22,0.227c0.059,0.012,0.075,0.051,0.136,0.076c-0.034,0.022,0.041,0.032,0.061,0.046
		c-0.01,0.03,0.025,0.015,0.015,0.045c0.005,0.01,0.016,0.014,0.03,0.015c0.013,0.015-0.001,0.01,0,0.03
		c0.168,0.127,0.323,0.311,0.493,0.417c0.036,0.092,0.115,0.143,0.189,0.197c-0.019,0.019,0.022,0.017,0.015,0.046
		c0.181,0.214,0.4,0.407,0.44,0.689c-0.017-0.004-0.016,0.011-0.016,0.023c-0.84,0.304-1.707,0.203-2.515,0.667
		c0.338,1.364,0.622,2.971-0.068,4.265c-0.058,0.021-0.061,0.096-0.114,0.122v0.053c-0.038,0.035-0.096,0.05-0.106,0.113
		c-0.037,0.004-0.05,0.031-0.06,0.061h-0.031c0,0.018-0.012,0.023-0.007,0.045c-0.026-0.012-0.058,0.026-0.069,0.053
		c-0.229,0.122-0.396,0.314-0.644,0.41c-0.066,0.074-0.208,0.074-0.265,0.159c-0.084,0.003-0.193,0.04-0.242,0.091
		c-0.125,0.034-0.225,0.064-0.303,0.121c-0.019-0.018-0.037,0.01-0.046-0.015c-0.161,0.074-0.379,0.084-0.515,0.174L12.302,94.313z"
        />
        <path
          fill="#FFFFFF"
          d="M23.041,68.726c-0.022-0.242-0.018-0.485-0.023-0.719l-0.015-0.008l0.008-0.023
		c-0.059-0.5-0.099-1.013-0.19-1.515c-0.02-0.008-0.009-0.047-0.03-0.053c-0.001-0.14-0.086-0.279-0.106-0.424
		c-0.048-0.048-0.053-0.141-0.106-0.182c-0.012-0.164-0.137-0.278-0.167-0.44c-0.04-0.017-0.05-0.065-0.06-0.113
		c-0.016-0.005-0.018-0.023-0.031-0.031c-0.051-0.154-0.142-0.264-0.204-0.401c-0.024-0.003-0.047-0.046-0.069-0.061l0.008-0.015
		c-0.096-0.17-0.238-0.296-0.311-0.44c-0.131-0.136-0.279-0.278-0.401-0.424h-0.031c-0.055-0.073-0.121-0.17-0.204-0.197
		c0.006-0.021-0.012-0.02-0.008-0.038c-0.106-0.048-0.13-0.178-0.235-0.227c0.005-0.033-0.021-0.034-0.022-0.061
		c-0.052-0.011-0.081-0.045-0.099-0.091c-0.042,0.004-0.05-0.025-0.083-0.03c-0.158-0.178-0.352-0.322-0.5-0.515
		c-0.06-0.017-0.075-0.077-0.144-0.083c-0.008-0.021-0.023-0.033-0.031-0.053c-0.082-0.032-0.117-0.11-0.196-0.144
		c-0.002-0.027-0.027-0.03-0.031-0.053c-0.031-0.009-0.049-0.032-0.076-0.046c-0.005-0.023-0.024-0.032-0.03-0.053
		c-0.247-0.127-0.4-0.321-0.652-0.424c0.009-0.024-0.011-0.019-0.015-0.031c-0.176-0.099-0.341-0.205-0.515-0.303
		c-0.034,0.007-0.045-0.01-0.076-0.007c-0.027-0.048-0.104-0.047-0.136-0.091c-0.04,0.021-0.054-0.038-0.091-0.015
		c-0.122-0.081-0.27-0.147-0.417-0.19c-0.007-0.025-0.04-0.025-0.068-0.03l-0.008-0.023c-0.094-0.015-0.113-0.067-0.212-0.083
		c-0.022-0.046-0.102-0.047-0.144-0.091c-0.109-0.009-0.19-0.087-0.28-0.091c0-0.013-0.001-0.024-0.007-0.03
		c-0.081-0.014-0.141-0.047-0.19-0.091c-0.106-0.009-0.16-0.108-0.257-0.099c0.011-0.031-0.026-0.009-0.038-0.015l-0.015-0.023
		c-0.098-0.002-0.174-0.072-0.273-0.075c-0.007-0.022-0.031-0.026-0.046-0.038c-0.019-0.002-0.036-0.002-0.045,0.007
		c-0.033-0.055-0.165-0.073-0.212-0.053c-0.014-0.019-0.033-0.033-0.046-0.053c-0.229-0.045-0.416-0.112-0.629-0.151
		c0.013-0.031-0.032-0.003-0.03-0.023c-0.067,0.004-0.083-0.001-0.136-0.023c-0.216-0.007-0.408-0.096-0.652-0.098
		c-0.015,0.01-0.043,0.007-0.061,0.015c-0.07-0.056-0.203,0.006-0.272-0.023l-0.023,0.008l-0.023-0.023
		c-0.016-0.001-0.034,0.006-0.053,0.015c0.003-0.015-0.01-0.015-0.015-0.023c-0.045,0.015-0.09,0.006-0.129-0.015l-0.007,0.015
		H13.82l-0.008-0.015c-2.058-0.135-4.109,0.191-6.031,0.91c-0.005,0.017-0.019,0.026-0.03,0.037c-0.041-0.003-0.067,0.018-0.099,0
		c-0.025,0.033-0.068,0.048-0.128,0.046c-0.224,0.123-0.471,0.188-0.698,0.295c-0.005,0.021-0.027,0.023-0.037,0.038
		c-0.146,0-0.282,0.145-0.432,0.159c-0.169,0.125-0.375,0.167-0.531,0.281c-0.046,0.001-0.069,0.026-0.09,0.053
		c-0.085-0.009-0.134,0.083-0.228,0.091c-0.086,0.094-0.269,0.116-0.356,0.219c-0.175,0.068-0.277,0.178-0.47,0.273
		c-0.01,0.028-0.04,0.036-0.053,0.061c-0.041,0.016-0.07,0.045-0.121,0.053c-2.064,1.723-3.114,4.589-2.659,7.228
		c-0.048,0.117,0.07,0.282,0.022,0.387c0.068,0.644,0.247,1.226,0.432,1.818c0.029-0.004,0.034,0.016,0.046,0.03
		c-0.013,0.028,0.001,0.037-0.008,0.061C2.538,72.687,2.735,73.331,3,73.81c-0.009,0.032,0.053,0.076,0.038,0.122
		c0.11,0.211,0.225,0.404,0.296,0.613c0.095,0.19,0.235,0.418,0.333,0.622c0.19,0.289,0.414,0.586,0.606,0.894
		c0.037,0.006,0.043,0.043,0.084,0.045c-0.021,0.06,0.074,0.066,0.068,0.137c0.074,0.056,0.111,0.104,0.144,0.197
		c0.119,0.064,0.148,0.221,0.25,0.295c0.008,0.053,0.063,0.063,0.075,0.114h0.031c0.007,0.033,0.041,0.039,0.038,0.083
		c0.024,0.014,0.043,0.033,0.068,0.046C5.022,76.979,5.022,76.989,5.023,77c0.066,0.056,0.121,0.12,0.159,0.205
		c0.096,0.027,0.074,0.178,0.182,0.197c-0.004,0.024,0.023,0.018,0.015,0.045c0.061,0.009,0.084,0.111,0.152,0.137
		c0.019,0.067,0.093,0.078,0.106,0.151c0.127,0.127,0.224,0.235,0.326,0.379c0.064,0.019,0.07,0.096,0.136,0.114
		c0.03,0.077,0.133,0.11,0.152,0.189c0.039,0.024,0.089,0.037,0.091,0.099c0.04,0.02,0.088,0.032,0.09,0.091
		c0.025-0.002,0.037,0.008,0.053,0.015c0.058,0.128,0.176,0.203,0.273,0.295c0,0.01,0.01,0.01,0.008,0.023
		c0.088,0.032,0.125,0.12,0.204,0.159c0.012,0.039,0.038,0.063,0.076,0.076c0.019,0.064,0.086,0.079,0.114,0.136
		c0.029-0.001,0.039,0.017,0.061,0.023c-0.007,0.023,0.026,0.009,0.015,0.038c0.233,0.153,0.443,0.418,0.636,0.614
		c0.025,0.005,0.037,0.024,0.061,0.03c0.014,0.069,0.101,0.066,0.113,0.136c0.034,0.009,0.051,0.036,0.084,0.046
		c0.043,0.07,0.108,0.118,0.159,0.182c0.044-0.005,0.065,0.036,0.083,0.068c0.146,0.056,0.213,0.234,0.364,0.272
		c0.069,0.143,0.276,0.199,0.348,0.341c0.163,0.075,0.266,0.23,0.394,0.349c0.075-0.006,0.065,0.072,0.137,0.068
		c0.002,0.025,0.021,0.033,0.03,0.053c0.089,0.039,0.13,0.128,0.22,0.167c-0.001,0.019,0.011,0.024,0.015,0.038
		c0.03,0.012,0.063,0.023,0.068,0.06c0.114,0.041,0.162,0.147,0.273,0.19c-0.001,0.014,0.009,0.016,0.008,0.03
		c0.032-0.002,0.039,0.022,0.068,0.023c0.094,0.112,0.238,0.174,0.333,0.288c0.048,0.001,0.073,0.034,0.076,0.06
		c0.175,0.118,0.31,0.276,0.477,0.402c-0.004,0.037,0.033,0.033,0.038,0.061c0.1,0.041,0.13,0.152,0.242,0.181
		c0.014,0.085,0.115,0.083,0.137,0.16c0.035,0.018,0.084,0.026,0.083,0.083c0.129,0.01,0.129,0.149,0.25,0.167
		c0.01,0.02,0.017,0.022,0.008,0.045c0.062,0.022,0.086,0.081,0.151,0.099c0.006,0.03,0.023,0.048,0.046,0.06
		c0.004,0.039,0.044,0.042,0.053,0.076c0.087,0.021,0.111,0.106,0.197,0.129c0.189,0.193,0.354,0.372,0.576,0.523
		c-0.002,0.012,0.001,0.024,0.007,0.03c0.028,0.007,0.046,0.025,0.069,0.038c0.143-0.096,0.347-0.221,0.454-0.387
		c0.048-0.007,0.068-0.043,0.114-0.053c0.126-0.136,0.294-0.231,0.424-0.363h0.03c0.098-0.124,0.23-0.167,0.334-0.281
		c0.043-0.006,0.044-0.044,0.083-0.038c0.233-0.201,0.478-0.398,0.697-0.629c0.001-0.022,0.017-0.034,0.023-0.053
		c0.021,0.007,0.02-0.01,0.038-0.007c0.007-0.015,0.015-0.03,0.023-0.046l0.022,0.008c0.079-0.095,0.179-0.187,0.281-0.265
		c0.005-0.061,0.082-0.054,0.098-0.129c0.107-0.075,0.171-0.193,0.28-0.265c0.003-0.023,0.025-0.027,0.016-0.061
		c0.026,0.013,0.019-0.005,0.045-0.007c0.119-0.144,0.264-0.262,0.379-0.41c0.01-0.002,0.014-0.011,0.03-0.007
		c0.063-0.123,0.182-0.19,0.235-0.303c0.078-0.036,0.106-0.121,0.174-0.167c-0.001-0.019,0.033-0.068,0.069-0.076
		c0.027-0.088,0.114-0.117,0.144-0.204c0.079-0.029,0.079-0.138,0.159-0.167c0.138-0.21,0.306-0.39,0.424-0.621
		c0.039-0.007,0.038-0.053,0.076-0.061c0.034-0.12,0.155-0.153,0.189-0.273l0.038-0.015c0.317-0.484,0.768-0.917,1.046-1.409
		c0.02,0.019,0.025-0.019,0.045-0.023c0.024-0.052,0.07-0.082,0.083-0.144c0.024-0.009,0.033-0.033,0.061-0.038
		c0.038-0.106,0.133-0.155,0.167-0.265c0.046-0.026,0.062-0.084,0.106-0.113c-0.008-0.046,0.026-0.05,0.022-0.091
		c0.038-0.007,0.042-0.047,0.069-0.046c0.001-0.039,0.03-0.05,0.03-0.091c0.039-0.004,0.032-0.054,0.076-0.053
		c0-0.04,0.034-0.047,0.03-0.091c0.038,0,0.027-0.049,0.061-0.053c0.044-0.148,0.164-0.22,0.204-0.371
		c0.026-0.016,0.077-0.053,0.038-0.076c0.043,0.005,0.029-0.047,0.068-0.045c0.067-0.113,0.152-0.243,0.205-0.372
		c0.052-0.032,0.082-0.094,0.083-0.151c0.019-0.009,0.037-0.019,0.053-0.03c0.013-0.104,0.104-0.129,0.114-0.235
		c0.035-0.02,0.043-0.068,0.083-0.084c0.026-0.093,0.095-0.142,0.122-0.235c0.031-0.003,0.031-0.039,0.06-0.045
		c-0.005-0.023,0.008-0.028,0.008-0.045c0.043-0.023,0.05-0.081,0.098-0.099v-0.045c0.05-0.022,0.076-0.098,0.099-0.167
		c0.084-0.052,0.082-0.191,0.159-0.25c0.021-0.076,0.072-0.178,0.129-0.243c0.005-0.083,0.063-0.113,0.075-0.189
		c0.089-0.061,0.09-0.208,0.167-0.28c-0.005-0.031,0.008-0.043,0.008-0.069c0.039-0.007,0.059-0.069,0.053-0.098
		c0.024-0.001,0.023-0.028,0.045-0.03c0.037-0.117,0.142-0.196,0.182-0.356c0.03-0.023,0.042-0.065,0.068-0.091v-0.053
		c0.021-0.021,0.036-0.046,0.038-0.084c0.022-0.014,0.033-0.038,0.053-0.053c0.009-0.074,0.024-0.141,0.076-0.174
		c-0.009-0.042,0.029-0.054,0-0.076c0.044-0.019,0.047-0.078,0.061-0.129c0.015-0.007,0.026-0.019,0.037-0.03
		c0.112-0.38,0.301-0.804,0.387-1.205c0.024,0.007,0.01-0.025,0.03-0.022c0.006-0.112,0.062-0.162,0.038-0.235
		c0.034-0.004,0-0.027,0.038-0.023c0.143-0.521,0.265-1.093,0.326-1.621c0.01,0,0.01,0.01,0.023,0.007
		c0.016-0.067,0.01-0.152-0.008-0.204c0.044,0.013-0.014-0.062,0.03-0.046c0.001-0.119,0.051-0.28,0.015-0.394
		c0.017-0.039,0.043-0.096,0.016-0.121c0.031-0.018,0.007-0.158,0.037-0.205C23.013,68.816,23.021,68.784,23.041,68.726"
        />
        <path
          fill="#FFFFFF"
          d="M14.608,87.229c0.02-0.023,0.053-0.033,0.06-0.068c-0.035-0.051-0.076-0.095-0.143-0.114
		c0.001-0.034-0.038-0.028-0.038-0.06c-0.041-0.012-0.051-0.051-0.099-0.053c-0.062-0.07-0.137-0.126-0.22-0.175
		c-0.023-0.048-0.065-0.077-0.121-0.091c-0.007-0.045-0.054-0.051-0.068-0.091c-0.062-0.026-0.083-0.093-0.159-0.106
		c-0.09-0.112-0.24-0.164-0.341-0.265c-0.068-0.01-0.057-0.052-0.129-0.053c-0.021-0.037-0.059-0.058-0.106-0.068
		c-0.016,0.021-0.035,0.04-0.038,0.076c-0.062,0.054-0.135,0.097-0.182,0.166c-0.021-0.006-0.02,0.01-0.037,0.008
		c-0.124,0.185-0.323,0.332-0.44,0.561c-0.048,0.025-0.073,0.073-0.114,0.106c-0.006,0.026-0.001,0.019-0.007,0.045
		c-0.039,0.024-0.065,0.062-0.076,0.114c-0.045,0.035-0.081,0.081-0.099,0.144c-0.018-0.018-0.02,0.024-0.045,0.023
		c-0.021,0.066-0.086,0.09-0.091,0.174c-0.079,0.049-0.101,0.156-0.136,0.25c-0.02,0.002-0.027,0.019-0.046,0.023
		c-0.043,0.166-0.142,0.276-0.174,0.454c-0.029,0.024-0.024,0.082-0.061,0.099c-0.02,0.103-0.068,0.2-0.06,0.303
		c-0.037,0.011-0.03,0.065-0.046,0.098c0.075-0.041,0.139-0.093,0.197-0.151l0.008,0.015c0.05-0.07,0.124-0.073,0.174-0.137
		c0.055-0.017,0.079-0.069,0.137-0.075c0.381-0.231,0.799-0.465,1.197-0.614c0.009-0.016,0.03-0.02,0.038-0.038
		c0.074-0.024,0.141-0.055,0.197-0.098c0.071,0.002,0.153-0.061,0.212-0.091c0.023,0.019,0.071-0.021,0.068-0.031
		c0.029,0.018,0.03-0.014,0.053-0.022c0.063,0.014,0.107-0.048,0.167-0.038c0.005-0.018,0.019-0.027,0.03-0.038
		C14.267,87.42,14.429,87.227,14.608,87.229"
        />
      </g>
      <g v-if="showBottomRight" id="bottom_x5F_right">
        <path
          class="fill-current"
          d="M129.832,94.313c-0.048,0.017-0.205,0.015-0.27,0.076c-0.1-0.001-0.201,0.042-0.265,0.083
		c-0.019-0.004-0.056-0.011-0.061,0.023c-0.23,0.091-0.482,0.149-0.69,0.281h-0.037c-0.129,0.079-0.261,0.151-0.394,0.212
		c-0.006,0.05-0.07,0.045-0.076,0.098c-0.071,0.027-0.075,0.12-0.129,0.167l0.008,0.023c-0.012,0.014-0.024,0.026-0.038,0.038
		c-0.007,0.03,0.001,0.046-0.008,0.06c0.003,0.02-0.01,0.025-0.03,0.023c-0.025,0.114-0.07,0.26-0.091,0.386
		c0.009,0.024,0.014,0.052,0.03,0.068c-0.015,0.128,0.053,0.253,0.106,0.372c0.028,0.015,0.04,0.046,0.068,0.06
		c-0.021,0.081,0.098,0.129,0.114,0.22c0.022,0.009,0.035,0.025,0.053,0.038c0.012,0.059,0.044,0.097,0.091,0.121
		c0.008,0.081,0.097,0.11,0.099,0.174c0.035,0.043,0.075,0.081,0.091,0.144c0.025,0.021,0.045,0.045,0.075,0.061
		c0,0.025,0.022,0.029,0.015,0.061c0.053,0.032,0.058,0.113,0.122,0.136c0.007,0.063,0.068,0.138,0.129,0.174
		c0.001,0.014-0.009,0.017-0.008,0.031c0.043,0.007,0.03,0.069,0.083,0.068c0.008,0.055,0.034,0.092,0.076,0.113
		c0.132,0.23,0.274,0.47,0.439,0.682c-0.005,0.023,0.013,0.023,0.008,0.046c0.111,0.137,0.192,0.288,0.318,0.439
		c0.008,0.093,0.059,0.144,0.129,0.197c-0.005,0.028,0.013,0.033,0.008,0.061c0.073,0.114,0.106,0.268,0.197,0.364
		c-0.022,0.044,0.033,0.058,0.022,0.113l0.023,0.008c0.13,0.35,0.292,0.707,0.356,1.076c-0.006,0.014-0.012,0.028-0.015,0.045
		c0.044,0.066,0.005,0.173,0.053,0.228c-0.004,0.319,0.092,0.63,0.046,0.947l0.015,0.045c-0.033,0.08,0.025,0.22-0.03,0.296
		c0.013,0.096-0.031,0.179-0.023,0.272c-0.039,0.032-0.01,0.111-0.061,0.144c-0.084,0.384-0.311,0.84-0.515,1.197
		c-0.029-0.006-0.027,0.019-0.046,0.023c-0.015,0.068-0.083,0.084-0.09,0.159c-0.056,0.005-0.045,0.077-0.099,0.084
		c-0.013,0.035-0.02,0.075-0.061,0.083c-0.118,0.157-0.242,0.308-0.401,0.424c0,0.018-0.013,0.023-0.008,0.046
		c-0.019-0.004-0.016,0.014-0.038,0.007c-0.012,0.031-0.053,0.033-0.053,0.076c-0.083,0.025-0.096,0.121-0.182,0.144
		c-0.003,0.019-0.029,0.016-0.022,0.045c-0.106,0.039-0.171,0.159-0.281,0.205c-0.008,0.024-0.033,0.033-0.038,0.061
		c-0.116,0.06-0.25,0.158-0.363,0.272c-0.069,0.012-0.09,0.1-0.167,0.107c-0.298,0.29-0.695,0.45-1.015,0.636
		c-0.227,0.034-0.463,0.129-0.675,0.197l-0.022-0.015c-0.06,0.047-0.188,0.001-0.25,0.068c-0.29,0.007-0.609,0.105-0.902,0.167
		c-0.035-0.018-0.072-0.008-0.083,0.015c-0.113-0.007-0.24,0.022-0.326,0.068c-0.737,0.105-1.492,0.357-2.22,0.447
		c-0.038,0.051-0.086-0.001-0.114,0.053c-0.017-0.005-0.039-0.006-0.053-0.015c-0.24,0.143-0.491,0.169-0.704,0.288
		c-0.084,0.003-0.247,0.023-0.311,0.068c-0.053-0.021-0.092,0.023-0.129-0.015c-0.08,0.043-0.145-0.029-0.258-0.015
		c-0.041-0.041-0.101-0.061-0.151-0.091c-0.016-0.067-0.092-0.075-0.098-0.152c-0.04-0.012-0.048-0.065-0.084-0.06
		c0.01-0.085-0.01-0.202,0.015-0.273c-0.03-0.059-0.016-0.075-0.007-0.144c0.021-0.027,0.058-0.038,0.053-0.091
		c0.023-0.009,0.043-0.023,0.045-0.053l-0.007,0.008c-0.013-0.01,0.005-0.055,0.007-0.076c0.032-0.017,0.077-0.02,0.106-0.038
		c0.024-0.069,0.104-0.083,0.121-0.159c0.061,0.009,0.105-0.048,0.137-0.091c0.412-0.263,0.959-0.275,1.44-0.409
		c0.07,0.026,0.107-0.029,0.181-0.008c0.019-0.006,0.043-0.007,0.053-0.023c0.268-0.007,0.629-0.011,0.849-0.106
		c0.047,0.024,0.157,0.003,0.22,0c0.043-0.059,0.138-0.008,0.189-0.06c0.013-0.001,0.039,0.025,0.046-0.008
		c0.574-0.129,1.201-0.149,1.765-0.371c0.064,0.019,0.126-0.023,0.167-0.053c0.237-0.083,0.541-0.186,0.75-0.341
		c0.147-0.043,0.323-0.168,0.447-0.28c0.156-0.035,0.317-0.209,0.477-0.273c0.003-0.027,0.02-0.019,0.008-0.046
		c0.141-0.047,0.213-0.162,0.348-0.242c-0.002-0.025,0.056-0.035,0.061-0.076c0.04,0.008,0.035-0.03,0.068-0.03
		c-0.001-0.057,0.054-0.057,0.053-0.114c0.041-0.01,0.071-0.03,0.076-0.076c0.031,0.003,0.068-0.043,0.076-0.075h0.022
		c0.022-0.026,0.045-0.067,0.053-0.099c0.023,0,0.026-0.02,0.046-0.023c0.043-0.101,0.146-0.141,0.174-0.257
		c0.175-0.141,0.273-0.326,0.349-0.568c0.054-0.037,0.049-0.133,0.106-0.167c0.191-0.565,0.2-1.24,0.136-1.788
		c-0.024-0.115-0.082-0.295-0.121-0.425l-0.023-0.007c-0.022-0.165-0.111-0.361-0.22-0.485c0.003-0.019-0.013-0.017-0.007-0.038
		c-0.03-0.015-0.052-0.039-0.076-0.061c0.006-0.031-0.015-0.035-0.015-0.06c-0.044-0.032-0.053-0.1-0.114-0.114
		c-0.021-0.052-0.038-0.108-0.098-0.121c-0.013-0.039-0.008-0.049-0.023-0.083c-0.033-0.013-0.03-0.062-0.068-0.069
		c-0.075-0.227-0.268-0.375-0.364-0.583c-0.161-0.164-0.235-0.389-0.409-0.53v-0.031c-0.182-0.192-0.288-0.42-0.439-0.621
		c0.01-0.024,0.001-0.025,0-0.053c-0.048-0.04-0.043-0.134-0.114-0.152c0.015-0.036-0.03-0.064-0.023-0.098
		c-0.056-0.053-0.071-0.142-0.129-0.197c0.004-0.051-0.017-0.057-0.015-0.091c-0.009-0.016-0.03-0.02-0.038-0.038
		c0.009-0.059-0.037-0.063-0.037-0.114c-0.007-0.011-0.007-0.029-0.031-0.022c0.028-0.087-0.047-0.154-0.076-0.228l0.008-0.022
		c-0.018-0.048-0.059-0.073-0.046-0.129c-0.221-0.628-0.155-1.337,0.251-1.872c-0.009-0.056,0.047-0.046,0.037-0.106
		c0.021,0.005,0.016-0.015,0.031-0.015c-0.001-0.049,0.022-0.073,0.053-0.091c0.01-0.068,0.077-0.079,0.091-0.144
		c0.123-0.111,0.241-0.235,0.356-0.348c0.062-0.004,0.09-0.042,0.106-0.091c0.127-0.042,0.211-0.128,0.348-0.159
		c0.075-0.072,0.229-0.079,0.288-0.144c0.093-0.014,0.138-0.052,0.235-0.046c0.01-0.005,0.014-0.016,0.015-0.03
		c0.041,0.034,0.037-0.028,0.084-0.008c0.04-0.04,0.131-0.03,0.166-0.075c0.071,0.001,0.172-0.037,0.243-0.076l0.023,0.015
		c0.061-0.024,0.113-0.058,0.204-0.053c0.01-0.013,0.035-0.009,0.03-0.038c0.067,0.028,0.128,0.005,0.182-0.03
		c0.021,0.005,0.061-0.01,0.069,0.007c0.096-0.047,0.198-0.107,0.303-0.113c0.006-0.014,0.03-0.01,0.022-0.038
		c0.057,0.023,0.069-0.015,0.091-0.046c0.049-0.001,0.1,0.021,0.122-0.038c0.112-0.001,0.159-0.12,0.265-0.151
		c0.014-0.022,0.041-0.029,0.045-0.061c0.047-0.021,0.106-0.03,0.121-0.083c0.089,0.005,0.128-0.105,0.212-0.129
		c0.067-0.078,0.149-0.126,0.16-0.25c0.178-0.131,0.225-0.394,0.257-0.606c0.02,0.005,0.015-0.015,0.03-0.015
		c0.053-0.203,0.091-0.384,0.061-0.599c0.028-0.02,0.034-0.058,0.015-0.091c0.046-0.238-0.006-0.598-0.045-0.826
		c-0.009-0.246-0.11-0.494-0.152-0.735c-0.029-0.037-0.058-0.087-0.06-0.128c-0.038,0.015-0.067,0.039-0.091,0.068
		c-0.193,0.082-0.357,0.225-0.546,0.295c-0.06,0.086-0.2,0.093-0.242,0.197c-0.113,0.02-0.18,0.148-0.281,0.197
		c-0.003,0.022-0.011,0.04-0.015,0.061c-0.064,0.003-0.078,0.045-0.113,0.091c-0.024-0.004-0.018,0.023-0.046,0.015
		c-0.012,0.028-0.042,0.039-0.053,0.068c-0.054,0.024-0.078,0.079-0.136,0.099c-0.019,0.057-0.08,0.072-0.099,0.129
		c-0.014,0.003-0.019,0.016-0.038,0.015c0.007,0.021-0.011,0.02-0.007,0.038c-0.147,0.139-0.309,0.303-0.409,0.469
		c-0.076,0.036-0.099,0.124-0.175,0.159c-0.025,0.084-0.101,0.117-0.136,0.19c-0.063,0.025-0.135,0.042-0.197,0.068
		c-0.053-0.016-0.124-0.02-0.174,0.008c-0.038-0.004-0.099-0.022-0.114,0.015c-0.025-0.015-0.049-0.024-0.098-0.03
		c-0.008-0.041-0.059-0.04-0.076-0.069c-0.032,0.01-0.047-0.007-0.076-0.007c-0.128-0.259-0.246-0.546-0.242-0.834l-0.023-0.022
		c-0.009-0.052,0.02-0.122-0.008-0.19c0.007-0.014,0.003-0.038,0.015-0.045c-0.046-0.215-0.035-0.405-0.045-0.637
		c0.062-0.103-0.02-0.269,0.045-0.379c-0.051-0.422,0.133-0.839,0.198-1.25c0.016,0.004,0.02-0.005,0.03-0.007
		c0.022-0.077,0.021-0.198,0.075-0.266c0.001-0.062,0.046-0.073,0.031-0.151c0.012-0.003,0.012,0.007,0.022,0.007
		c0.043-0.068,0.041-0.181,0.107-0.227c0.005-0.018,0.011-0.034,0.007-0.06c0.058-0.028,0.043-0.129,0.091-0.167
		c-0.004-0.036,0.015-0.049,0-0.076c0.071-0.097,0.144-0.206,0.197-0.326c0.047-0.025,0.102-0.063,0.106-0.136
		c0.028-0.02,0.055-0.042,0.083-0.061c0.052-0.141,0.124-0.244,0.205-0.371c-0.002-0.028,0.014-0.037,0.015-0.061
		c0.02-0.012,0.026-0.04,0.053-0.045c0.002-0.03,0.022-0.044,0.023-0.076c0.083-0.065,0.13-0.168,0.212-0.235
		c0.001-0.043,0.006-0.041,0.023-0.075c0.097-0.026,0.121-0.166,0.197-0.213c-0.037-0.029-0.058-0.073-0.114-0.083
		c-0.055-0.101-0.182-0.086-0.235-0.189l-0.007,0.022c-0.014-0.006-0.026-0.055-0.046-0.022c-0.033-0.043-0.089-0.062-0.106-0.122
		c-0.073-0.014-0.077-0.094-0.159-0.098c-0.079-0.092-0.182-0.125-0.265-0.22c-0.029,0.006-0.037-0.009-0.061-0.007
		c-0.046-0.055-0.096-0.107-0.174-0.129c-0.081-0.091-0.168-0.176-0.28-0.235c0.006-0.024-0.013-0.024-0.015-0.038
		c-0.351-0.251-0.612-0.587-0.963-0.826c0.007-0.021-0.01-0.02-0.007-0.038c-0.024-0.001-0.052-0.016-0.069-0.03v-0.023
		c-0.077-0.038-0.093-0.135-0.189-0.166c-0.108-0.166-0.286-0.243-0.386-0.402c-0.101-0.029-0.108-0.15-0.212-0.174
		c-0.019-0.052-0.079-0.063-0.099-0.114c-0.009-0.006-0.02-0.01-0.038-0.008c-0.011-0.053-0.072-0.046-0.091-0.106
		c-0.113-0.072-0.235-0.166-0.31-0.265c-0.066-0.012-0.07-0.087-0.129-0.106c-0.061-0.109-0.173-0.167-0.235-0.273
		c-0.096-0.05-0.163-0.134-0.227-0.212c-0.013-0.002-0.014,0.006-0.016,0.015l-0.007-0.03c-0.056-0.033-0.101-0.076-0.129-0.136
		c-0.055-0.026-0.095-0.067-0.129-0.114c-0.026-0.003-0.019-0.021-0.045-0.008c-0.028-0.069-0.081-0.111-0.144-0.144
		c-0.022-0.087-0.118-0.099-0.144-0.181c-0.071-0.052-0.114-0.129-0.197-0.167c-0.046-0.094-0.132-0.147-0.22-0.197
		c-0.023-0.061-0.069-0.097-0.129-0.121c-0.019-0.047-0.078-0.086-0.129-0.114c-0.007-0.028-0.022-0.048-0.053-0.053
		c0.006-0.033-0.021-0.034-0.022-0.061c-0.051-0.01-0.051-0.07-0.106-0.075c-0.048-0.061-0.101-0.149-0.174-0.167
		c0.002-0.018-0.015-0.016-0.008-0.038c-0.063-0.045-0.11-0.107-0.19-0.136c-0.13-0.161-0.307-0.341-0.492-0.463
		c-0.009-0.044-0.03-0.075-0.076-0.083c-0.017-0.053-0.04-0.101-0.098-0.114c-0.051-0.072-0.127-0.116-0.167-0.197
		c-0.037,0.009-0.028-0.027-0.061-0.022c0.008-0.031-0.022-0.023-0.015-0.053c-0.101-0.024-0.127-0.144-0.219-0.197
		c-0.023-0.066-0.07-0.108-0.122-0.144c-0.001-0.024-0.04-0.033-0.023-0.053l-0.015,0.007c-0.056-0.111-0.181-0.209-0.257-0.326
		h-0.015c-0.028-0.053-0.071-0.109-0.129-0.151c-0.024-0.062-0.1-0.156-0.152-0.182c0.002-0.076-0.095-0.117-0.121-0.189
		c-0.021,0.006-0.02-0.01-0.038-0.008c-0.031-0.092-0.078-0.085-0.098-0.174c-0.036-0.023-0.047-0.07-0.091-0.084
		c-0.004-0.029-0.026-0.04-0.031-0.068c-0.125-0.108-0.187-0.282-0.303-0.386c-0.004-0.038-0.031-0.126-0.083-0.144
		c-0.013-0.043-0.033-0.078-0.068-0.099c-0.043-0.131-0.159-0.189-0.197-0.325c-0.022-0.007-0.018-0.038-0.046-0.038
		c-0.517-0.696-0.954-1.432-1.326-2.152c-0.071-0.31-0.259-0.602-0.386-0.887c0.005-0.022-0.013-0.022-0.008-0.045
		c-0.07-0.059-0.063-0.2-0.136-0.273c-0.002-0.071-0.035-0.143-0.083-0.189c-0.019-0.077-0.032-0.161-0.084-0.205
		c-0.043-0.182-0.125-0.264-0.144-0.485c-0.15-0.329-0.197-0.7-0.288-1.061c0.012-0.041,0.002-0.107-0.03-0.143
		c-0.07-0.501-0.129-1.026-0.083-1.516c-0.113-0.75,0.014-1.499,0.068-2.243c0.004-0.011,0.01,0.003,0.023,0
		c0.003-0.268,0.109-0.564,0.136-0.841c0.049-0.003,0.028-0.078,0.061-0.098c0.023-0.244,0.162-0.478,0.227-0.743l0.015-0.007
		l-0.007-0.015l0.022-0.016c0.014-0.053,0.013-0.082,0.046-0.136c-0.015-0.078,0.045-0.081,0.038-0.151
		c0.07-0.091,0.079-0.257,0.144-0.334l-0.007-0.004c0.005-0.041,0.065-0.089,0.06-0.163c0.06-0.055,0.082-0.15,0.106-0.242
		c0.024,0.009,0.006-0.024,0.03-0.015c-0.008-0.025-0.001-0.075,0.03-0.083c0.029-0.191,0.17-0.276,0.228-0.44
		c0.016-0.009,0.029-0.021,0.045-0.03c0.006-0.052,0.049-0.067,0.053-0.121c0.239-0.301,0.407-0.673,0.727-0.925
		c0.007-0.036,0.04-0.047,0.046-0.083c0.078-0.023,0.117-0.085,0.159-0.144c0.034-0.014,0.06-0.037,0.076-0.068
		c0.091-0.046,0.154-0.119,0.22-0.19c0.026,0.003,0.027-0.023,0.053-0.023c0.058-0.093,0.174-0.128,0.234-0.219
		c0.054-0.011,0.086-0.044,0.122-0.106c0.05-0.018,0.081-0.056,0.129-0.076c0.05-0.078,0.16-0.097,0.197-0.189
		c0.03,0.012,0.074-0.021,0.083-0.053c0.118-0.075,0.273-0.152,0.364-0.258c0.112-0.027,0.165-0.106,0.25-0.182
		c0.019,0.009,0.036-0.023,0.045,0c0.123-0.087,0.236-0.183,0.371-0.258c0.008-0.017,0.027-0.024,0.023-0.053
		c0.039-0.007,0.038-0.014,0.053-0.038c0.16-0.004,0.375-0.242,0.546-0.325c0.031,0.003,0.032-0.023,0.068-0.015
		c0.383-0.225,0.774-0.403,1.167-0.561c0.001-0.01,0.006-0.032,0.022-0.046c0.042,0.003,0.055-0.013,0.076-0.015
		c0.066-0.078,0.178-0.063,0.258-0.129l0.015,0.016c0.09-0.051,0.301-0.049,0.394-0.122c0.109-0.008,0.162-0.055,0.273-0.06
		c0.089-0.084,0.264-0.051,0.341-0.159c0.118,0.016,0.21-0.07,0.326-0.084l0.007-0.015c0.043-0.007,0.116,0.006,0.144-0.045
		c0.075-0.002,0.178-0.026,0.227-0.069c0.11,0.024,0.274-0.093,0.425-0.106c0-0.025,0.036-0.014,0.038-0.038
		c0.065,0.009,0.157-0.01,0.212-0.053c0.005,0.024,0.034,0.006,0.038,0.016c0.37-0.112,0.852-0.144,1.166-0.25
		c0.182,0.018,0.327-0.009,0.501-0.038c0.022,0.019,0.046-0.009,0.06,0.015c0.083-0.041,0.281,0.004,0.394-0.046
		c0.087,0.035,0.16-0.028,0.235,0c0.286-0.044,0.623-0.057,0.909-0.068l0.03-0.023c0.991,0.054,2.06,0.006,3.039,0.243
		c0.049-0.025,0.079-0.006,0.121,0.023c0.041-0.022,0.08,0.017,0.114,0c0.077,0.058,0.198,0.029,0.265,0.091
		c0.221,0.023,0.427,0.125,0.667,0.159c0.23,0.086,0.516,0.165,0.742,0.272c0.037-0.008,0.044,0.032,0.084,0.016
		c0.056,0.064,0.13,0.03,0.174,0.098c0.053-0.009,0.055,0.039,0.121,0.03c0.312,0.16,0.715,0.263,1,0.455
		c0.084,0.013,0.158,0.085,0.243,0.121c0.003,0.015,0.016,0.024,0.022,0.038c0.077,0.014,0.124,0.058,0.167,0.106
		c0.13,0.028,0.2,0.119,0.326,0.152c0.063,0.071,0.167,0.101,0.25,0.151c-0.004,0.017,0.005,0.021,0.008,0.031
		c0.155,0.058,0.242,0.174,0.401,0.242c0.037,0.058,0.094,0.066,0.159,0.083c0.009,0.037,0.039,0.052,0.076,0.061
		c0.035,0.061,0.098,0.093,0.174,0.114c0.004,0.022,0.048,0.027,0.053,0.068c0.026,0,0.029,0.021,0.061,0.015
		c0.007,0.025,0.03,0.035,0.038,0.061h0.038c0.007,0.045,0.064,0.041,0.075,0.083c0.123,0.063,0.256,0.189,0.349,0.303
		c0.039,0.009,0.055,0.04,0.098,0.046c0.007,0.036,0.053,0.032,0.053,0.075c0.025,0.009,0.041,0.026,0.069,0.031
		c0.001,0.031,0.033,0.032,0.053,0.045c0.022,0.048,0.06,0.081,0.113,0.099c0.021,0.053,0.08,0.065,0.099,0.121
		c0.026-0.001,0.031,0.019,0.053,0.023c0.081,0.136,0.214,0.231,0.333,0.333c-0.002,0.035,0.022,0.044,0.031,0.068
		c0.039,0.016,0.042,0.068,0.09,0.076c0.015,0.043,0.064,0.103,0.114,0.121v0.023c0.152,0.104,0.249,0.309,0.386,0.432
		c0.008,0.041,0.047,0.078,0.084,0.098c-0.009,0.034,0.02,0.031,0.023,0.053c0.015,0.012,0.03,0.026,0.053,0.031
		c0.003,0.044,0.035,0.046,0.068,0.075c0.015,0.061,0.038,0.148,0.106,0.144c0.049,0.135,0.153,0.213,0.204,0.341
		c0.056,0.028,0.085,0.087,0.114,0.159c0.029-0.01,0.028,0.051,0.076,0.061c0.008,0.116,0.103,0.274,0.197,0.379
		c-0.005,0.023,0.012,0.023,0.007,0.045c0.144,0.219,0.246,0.46,0.334,0.675c0.019,0.011,0.017,0.043,0.038,0.053
		c-0.001,0.034,0.021,0.062,0.03,0.091l-0.008,0.022c0.077,0.103,0.082,0.25,0.182,0.341c0.562,1.733,0.551,3.58,0.182,5.349
		c-0.012,0.013-0.014,0.037-0.03,0.046c-0.044,0.303-0.215,0.684-0.258,0.993c-0.012,0.007-0.015,0.025-0.03,0.03
		c-0.035,0.185-0.117,0.349-0.167,0.53c-0.026,0-0.011,0.039-0.038,0.038c-0.019,0.128-0.102,0.191-0.121,0.318h-0.023
		c-0.001,0.036-0.018,0.052-0.015,0.091c-0.03-0.01-0.019,0.022-0.038,0.023c-0.114,0.339-0.263,0.726-0.477,1.008
		c-0.019,0.119-0.11,0.285-0.197,0.394c0.001,0.081-0.073,0.121-0.076,0.219c-0.084,0.074-0.086,0.213-0.159,0.281v0.038
		c-0.035,0.039-0.077,0.106-0.076,0.159c-0.022,0.01-0.022,0.043-0.045,0.053c-0.082,0.254-0.222,0.427-0.295,0.667
		c-0.033,0.007-0.07,0.043-0.061,0.083c-0.144,0.204-0.281,0.446-0.409,0.689c-0.021,0.011-0.026,0.036-0.046,0.046
		c-0.182,0.367-0.423,0.701-0.666,1.045v0.038c-0.016-0.006-0.035,0.021-0.016,0.023c-0.089,0.065-0.118,0.203-0.197,0.258
		c-0.011,0.072-0.077,0.089-0.083,0.166h-0.023c-0.116,0.193-0.281,0.34-0.348,0.554c-0.016,0.012-0.01-0.002-0.031,0
		c-0.016,0.059-0.064,0.087-0.075,0.151h-0.031c-0.011,0.054-0.048,0.082-0.06,0.136c-0.057,0.017-0.048,0.099-0.106,0.114
		c-0.035,0.085-0.1,0.138-0.129,0.227c-0.015,0.004-0.019,0.017-0.038,0.016c-0.014,0.054-0.067,0.069-0.076,0.128
		c-0.016,0.009-0.029,0.022-0.045,0.031c-0.052,0.093-0.11,0.178-0.182,0.25c0.001,0.036-0.029,0.041-0.03,0.075
		c-0.049,0.033-0.082,0.08-0.099,0.144c-0.09,0.085-0.164,0.185-0.227,0.296c-0.019,0.004-0.017,0.029-0.046,0.023
		c-0.219,0.334-0.468,0.677-0.742,0.977c0.006,0.022-0.012,0.02-0.008,0.038c-0.083,0.071-0.146,0.162-0.22,0.242
		c-0.022-0.002-0.024,0.018-0.045,0.016c-0.032,0.059-0.09,0.152-0.152,0.166c-0.011,0.042-0.043,0.063-0.053,0.106
		c-0.02,0.003-0.022,0.023-0.045,0.023c-0.014,0.052-0.07,0.062-0.076,0.121c-0.029-0.006-0.012,0.033-0.045,0.023
		c-0.055,0.089-0.139,0.213-0.22,0.288c-0.004,0.044-0.051,0.045-0.046,0.098c-0.167,0.176-0.331,0.382-0.499,0.561
		c-0.121,0.065-0.157,0.212-0.289,0.265c-0.031,0.052-0.076,0.09-0.136,0.114c-0.006,0.019-0.021,0.03-0.023,0.053
		c-0.055,0.015-0.085,0.056-0.106,0.106c-0.049,0.022-0.097,0.044-0.106,0.106h-0.03c-0.013,0.025-0.034,0.042-0.046,0.068
		c-0.026,0.004-0.035,0.026-0.06,0.031c-0.151,0.164-0.362,0.278-0.478,0.469c-0.109,0.032-0.133,0.149-0.234,0.19
		c-0.008,0.053-0.065,0.058-0.076,0.106c-0.034,0.009-0.064,0.021-0.068,0.061c-0.056,0.035-0.127,0.056-0.144,0.128
		c-0.043,0.024-0.108,0.05-0.129,0.106c-0.056-0.002-0.066,0.093-0.137,0.099c-0.037,0.071-0.116,0.101-0.151,0.174
		c-0.123,0.072-0.222,0.178-0.311,0.281c0.254,0.266,0.632,0.467,0.894,0.719c0.084,0.023,0.105,0.108,0.205,0.114
		c0.061,0.083,0.162,0.125,0.22,0.227c0.059,0.012,0.075,0.051,0.136,0.076c-0.034,0.022,0.041,0.032,0.061,0.046
		c-0.01,0.03,0.025,0.015,0.015,0.045c0.005,0.01,0.016,0.014,0.03,0.015c0.013,0.015-0.001,0.01,0,0.03
		c0.168,0.127,0.323,0.311,0.493,0.417c0.036,0.092,0.115,0.143,0.189,0.197c-0.019,0.019,0.022,0.017,0.015,0.046
		c0.181,0.214,0.4,0.407,0.44,0.689c-0.017-0.004-0.016,0.011-0.016,0.023c-0.84,0.304-1.707,0.203-2.515,0.667
		c0.338,1.364,0.622,2.971-0.068,4.265c-0.058,0.021-0.061,0.096-0.114,0.122v0.053c-0.038,0.035-0.096,0.05-0.106,0.113
		c-0.037,0.004-0.05,0.031-0.06,0.061h-0.031c0,0.018-0.012,0.023-0.007,0.045c-0.026-0.012-0.058,0.026-0.069,0.053
		c-0.229,0.122-0.396,0.314-0.644,0.41c-0.066,0.074-0.208,0.074-0.265,0.159c-0.084,0.003-0.193,0.04-0.242,0.091
		c-0.125,0.034-0.225,0.064-0.303,0.121c-0.019-0.018-0.037,0.01-0.046-0.015c-0.161,0.074-0.379,0.084-0.515,0.174L129.832,94.313z
		"
        />
        <path
          fill="#FFFFFF"
          d="M140.571,68.726c-0.022-0.242-0.018-0.485-0.023-0.719l-0.015-0.008l0.008-0.023
		c-0.059-0.5-0.099-1.013-0.19-1.515c-0.02-0.008-0.009-0.047-0.03-0.053c-0.001-0.14-0.086-0.279-0.106-0.424
		c-0.048-0.048-0.053-0.141-0.106-0.182c-0.012-0.164-0.138-0.278-0.167-0.44c-0.04-0.017-0.05-0.065-0.06-0.113
		c-0.016-0.005-0.018-0.023-0.031-0.031c-0.051-0.154-0.142-0.264-0.204-0.401c-0.024-0.003-0.047-0.046-0.069-0.061l0.008-0.015
		c-0.096-0.17-0.238-0.296-0.311-0.44c-0.131-0.136-0.279-0.278-0.401-0.424h-0.031c-0.055-0.073-0.121-0.17-0.204-0.197
		c0.006-0.021-0.012-0.02-0.008-0.038c-0.106-0.048-0.13-0.178-0.235-0.227c0.005-0.033-0.021-0.034-0.022-0.061
		c-0.052-0.011-0.081-0.045-0.099-0.091c-0.042,0.004-0.05-0.025-0.083-0.03c-0.158-0.178-0.352-0.322-0.5-0.515
		c-0.06-0.017-0.075-0.077-0.144-0.083c-0.008-0.021-0.023-0.033-0.031-0.053c-0.082-0.032-0.117-0.11-0.196-0.144
		c-0.002-0.027-0.027-0.03-0.031-0.053c-0.031-0.009-0.049-0.032-0.076-0.046c-0.005-0.023-0.024-0.032-0.03-0.053
		c-0.247-0.127-0.4-0.321-0.652-0.424c0.009-0.024-0.011-0.019-0.015-0.031c-0.176-0.099-0.341-0.205-0.515-0.303
		c-0.034,0.007-0.045-0.01-0.076-0.007c-0.027-0.048-0.104-0.047-0.136-0.091c-0.041,0.021-0.054-0.038-0.091-0.015
		c-0.122-0.081-0.27-0.147-0.417-0.19c-0.007-0.025-0.04-0.025-0.068-0.03l-0.008-0.023c-0.094-0.015-0.113-0.067-0.212-0.083
		c-0.022-0.046-0.102-0.047-0.144-0.091c-0.109-0.009-0.19-0.087-0.28-0.091c0-0.013-0.001-0.024-0.007-0.03
		c-0.081-0.014-0.141-0.047-0.19-0.091c-0.106-0.009-0.16-0.108-0.257-0.099c0.011-0.031-0.026-0.009-0.038-0.015l-0.015-0.023
		c-0.098-0.002-0.174-0.072-0.273-0.075c-0.007-0.022-0.031-0.026-0.046-0.038c-0.019-0.002-0.036-0.002-0.045,0.007
		c-0.033-0.055-0.165-0.073-0.212-0.053c-0.014-0.019-0.033-0.033-0.046-0.053c-0.23-0.045-0.416-0.112-0.629-0.151
		c0.013-0.031-0.033-0.003-0.03-0.023c-0.067,0.004-0.083-0.001-0.136-0.023c-0.216-0.007-0.408-0.096-0.652-0.098
		c-0.015,0.01-0.043,0.007-0.061,0.015c-0.07-0.056-0.203,0.006-0.272-0.023l-0.023,0.008l-0.023-0.023
		c-0.016-0.001-0.034,0.006-0.053,0.015c0.003-0.015-0.01-0.015-0.015-0.023c-0.045,0.015-0.09,0.006-0.129-0.015l-0.007,0.015
		h-0.061l-0.008-0.015c-2.058-0.135-4.109,0.191-6.031,0.91c-0.005,0.017-0.019,0.026-0.03,0.037c-0.041-0.003-0.067,0.018-0.099,0
		c-0.025,0.033-0.068,0.048-0.128,0.046c-0.224,0.123-0.471,0.188-0.698,0.295c-0.005,0.021-0.027,0.023-0.037,0.038
		c-0.146,0-0.282,0.145-0.432,0.159c-0.169,0.125-0.375,0.167-0.531,0.281c-0.046,0.001-0.069,0.026-0.09,0.053
		c-0.085-0.009-0.134,0.083-0.228,0.091c-0.086,0.094-0.269,0.116-0.356,0.219c-0.175,0.068-0.277,0.178-0.47,0.273
		c-0.01,0.028-0.04,0.036-0.053,0.061c-0.041,0.016-0.07,0.045-0.121,0.053c-2.064,1.723-3.114,4.589-2.659,7.228
		c-0.048,0.117,0.07,0.282,0.022,0.387c0.068,0.644,0.247,1.226,0.432,1.818c0.029-0.004,0.034,0.016,0.046,0.03
		c-0.013,0.028,0.001,0.037-0.008,0.061c0.197,0.551,0.393,1.195,0.659,1.674c-0.009,0.032,0.053,0.076,0.038,0.122
		c0.11,0.211,0.225,0.404,0.296,0.613c0.095,0.19,0.235,0.418,0.333,0.622c0.19,0.289,0.414,0.586,0.606,0.894
		c0.037,0.006,0.043,0.043,0.084,0.045c-0.021,0.06,0.074,0.066,0.068,0.137c0.074,0.056,0.111,0.104,0.144,0.197
		c0.119,0.064,0.148,0.221,0.25,0.295c0.008,0.053,0.063,0.063,0.075,0.114h0.031c0.007,0.033,0.041,0.039,0.038,0.083
		c0.024,0.014,0.043,0.033,0.068,0.046c-0.009,0.001-0.009,0.011-0.008,0.022c0.066,0.056,0.121,0.12,0.159,0.205
		c0.096,0.027,0.074,0.178,0.182,0.197c-0.004,0.024,0.023,0.018,0.015,0.045c0.061,0.009,0.084,0.111,0.152,0.137
		c0.019,0.067,0.093,0.078,0.106,0.151c0.127,0.127,0.223,0.235,0.326,0.379c0.064,0.019,0.07,0.096,0.136,0.114
		c0.03,0.077,0.133,0.11,0.152,0.189c0.039,0.024,0.089,0.037,0.091,0.099c0.04,0.02,0.088,0.032,0.09,0.091
		c0.025-0.002,0.037,0.008,0.053,0.015c0.058,0.128,0.175,0.203,0.273,0.295c0,0.01,0.01,0.01,0.008,0.023
		c0.088,0.032,0.125,0.12,0.204,0.159c0.012,0.039,0.038,0.063,0.076,0.076c0.019,0.064,0.086,0.079,0.114,0.136
		c0.029-0.001,0.039,0.017,0.061,0.023c-0.007,0.023,0.026,0.009,0.015,0.038c0.233,0.153,0.443,0.418,0.636,0.614
		c0.025,0.005,0.037,0.024,0.061,0.03c0.014,0.069,0.101,0.066,0.113,0.136c0.034,0.009,0.051,0.036,0.084,0.046
		c0.043,0.07,0.108,0.118,0.159,0.182c0.044-0.005,0.065,0.036,0.083,0.068c0.146,0.056,0.213,0.234,0.364,0.272
		c0.069,0.143,0.276,0.199,0.348,0.341c0.163,0.075,0.266,0.23,0.394,0.349c0.075-0.006,0.065,0.072,0.137,0.068
		c0.002,0.025,0.021,0.033,0.03,0.053c0.089,0.039,0.13,0.128,0.22,0.167c-0.002,0.019,0.011,0.024,0.015,0.038
		c0.03,0.012,0.063,0.023,0.068,0.06c0.114,0.041,0.162,0.147,0.273,0.19c-0.001,0.014,0.009,0.016,0.008,0.03
		c0.032-0.002,0.039,0.022,0.068,0.023c0.094,0.112,0.238,0.174,0.333,0.288c0.048,0.001,0.073,0.034,0.076,0.06
		c0.175,0.118,0.31,0.276,0.477,0.402c-0.004,0.037,0.033,0.033,0.038,0.061c0.1,0.041,0.13,0.152,0.242,0.181
		c0.014,0.085,0.115,0.083,0.137,0.16c0.035,0.018,0.084,0.026,0.083,0.083c0.129,0.01,0.129,0.149,0.25,0.167
		c0.01,0.02,0.017,0.022,0.008,0.045c0.062,0.022,0.086,0.081,0.151,0.099c0.006,0.03,0.023,0.048,0.046,0.06
		c0.004,0.039,0.044,0.042,0.053,0.076c0.087,0.021,0.111,0.106,0.197,0.129c0.189,0.193,0.354,0.372,0.576,0.523
		c-0.002,0.012,0.001,0.024,0.007,0.03c0.028,0.007,0.046,0.025,0.069,0.038c0.143-0.096,0.347-0.221,0.454-0.387
		c0.048-0.007,0.068-0.043,0.114-0.053c0.126-0.136,0.294-0.231,0.424-0.363h0.03c0.098-0.124,0.23-0.167,0.334-0.281
		c0.043-0.006,0.044-0.044,0.083-0.038c0.233-0.201,0.478-0.398,0.697-0.629c0.001-0.022,0.017-0.034,0.023-0.053
		c0.021,0.007,0.02-0.01,0.038-0.007c0.007-0.015,0.015-0.03,0.023-0.046l0.022,0.008c0.079-0.095,0.179-0.187,0.281-0.265
		c0.005-0.061,0.082-0.054,0.098-0.129c0.107-0.075,0.171-0.193,0.28-0.265c0.003-0.023,0.025-0.027,0.016-0.061
		c0.026,0.013,0.019-0.005,0.045-0.007c0.119-0.144,0.264-0.262,0.379-0.41c0.01-0.002,0.014-0.011,0.03-0.007
		c0.063-0.123,0.182-0.19,0.235-0.303c0.078-0.036,0.106-0.121,0.174-0.167c-0.001-0.019,0.033-0.068,0.069-0.076
		c0.027-0.088,0.114-0.117,0.144-0.204c0.079-0.029,0.079-0.138,0.159-0.167c0.138-0.21,0.306-0.39,0.424-0.621
		c0.039-0.007,0.038-0.053,0.076-0.061c0.034-0.12,0.155-0.153,0.189-0.273l0.038-0.015c0.317-0.484,0.768-0.917,1.046-1.409
		c0.02,0.019,0.025-0.019,0.045-0.023c0.024-0.052,0.07-0.082,0.083-0.144c0.024-0.009,0.033-0.033,0.061-0.038
		c0.038-0.106,0.133-0.155,0.167-0.265c0.046-0.026,0.062-0.084,0.106-0.113c-0.008-0.046,0.026-0.05,0.022-0.091
		c0.038-0.007,0.042-0.047,0.069-0.046c0.001-0.039,0.03-0.05,0.03-0.091c0.039-0.004,0.032-0.054,0.076-0.053
		c0-0.04,0.034-0.047,0.03-0.091c0.038,0,0.027-0.049,0.061-0.053c0.044-0.148,0.164-0.22,0.204-0.371
		c0.026-0.016,0.077-0.053,0.038-0.076c0.043,0.005,0.029-0.047,0.068-0.045c0.067-0.113,0.152-0.243,0.205-0.372
		c0.052-0.032,0.082-0.094,0.083-0.151c0.019-0.009,0.037-0.019,0.053-0.03c0.013-0.104,0.104-0.129,0.114-0.235
		c0.035-0.02,0.043-0.068,0.083-0.084c0.026-0.093,0.095-0.142,0.122-0.235c0.031-0.003,0.031-0.039,0.06-0.045
		c-0.005-0.023,0.008-0.028,0.008-0.045c0.043-0.023,0.05-0.081,0.098-0.099v-0.045c0.05-0.022,0.076-0.098,0.099-0.167
		c0.084-0.052,0.082-0.191,0.159-0.25c0.021-0.076,0.072-0.178,0.129-0.243c0.005-0.083,0.063-0.113,0.075-0.189
		c0.089-0.061,0.09-0.208,0.167-0.28c-0.005-0.031,0.008-0.043,0.008-0.069c0.039-0.007,0.059-0.069,0.053-0.098
		c0.024-0.001,0.023-0.028,0.045-0.03c0.037-0.117,0.143-0.196,0.182-0.356c0.03-0.023,0.042-0.065,0.068-0.091v-0.053
		c0.021-0.021,0.036-0.046,0.038-0.084c0.022-0.014,0.033-0.038,0.053-0.053c0.009-0.074,0.024-0.141,0.076-0.174
		c-0.009-0.042,0.029-0.054,0-0.076c0.044-0.019,0.047-0.078,0.061-0.129c0.015-0.007,0.026-0.019,0.037-0.03
		c0.112-0.38,0.301-0.804,0.387-1.205c0.024,0.007,0.01-0.025,0.03-0.022c0.006-0.112,0.062-0.162,0.038-0.235
		c0.034-0.004,0-0.027,0.038-0.023c0.143-0.521,0.265-1.093,0.326-1.621c0.01,0,0.01,0.01,0.023,0.007
		c0.016-0.067,0.01-0.152-0.008-0.204c0.044,0.013-0.014-0.062,0.03-0.046c0.001-0.119,0.051-0.28,0.015-0.394
		c0.017-0.039,0.043-0.096,0.016-0.121c0.031-0.018,0.007-0.158,0.037-0.205C140.543,68.816,140.551,68.784,140.571,68.726"
        />
        <path
          fill="#FFFFFF"
          d="M132.138,87.229c0.02-0.023,0.053-0.033,0.06-0.068c-0.035-0.051-0.076-0.095-0.143-0.114
		c0.001-0.034-0.038-0.028-0.038-0.06c-0.041-0.012-0.051-0.051-0.099-0.053c-0.062-0.07-0.137-0.126-0.22-0.175
		c-0.023-0.048-0.065-0.077-0.121-0.091c-0.007-0.045-0.054-0.051-0.068-0.091c-0.062-0.026-0.083-0.093-0.159-0.106
		c-0.09-0.112-0.24-0.164-0.341-0.265c-0.068-0.01-0.057-0.052-0.129-0.053c-0.021-0.037-0.059-0.058-0.106-0.068
		c-0.016,0.021-0.035,0.04-0.038,0.076c-0.062,0.054-0.135,0.097-0.182,0.166c-0.021-0.006-0.02,0.01-0.037,0.008
		c-0.124,0.185-0.323,0.332-0.44,0.561c-0.048,0.025-0.073,0.073-0.114,0.106c-0.006,0.026-0.001,0.019-0.007,0.045
		c-0.039,0.024-0.065,0.062-0.076,0.114c-0.045,0.035-0.081,0.081-0.099,0.144c-0.018-0.018-0.02,0.024-0.045,0.023
		c-0.021,0.066-0.086,0.09-0.091,0.174c-0.079,0.049-0.101,0.156-0.136,0.25c-0.021,0.002-0.027,0.019-0.046,0.023
		c-0.043,0.166-0.142,0.276-0.174,0.454c-0.029,0.024-0.024,0.082-0.061,0.099c-0.02,0.103-0.068,0.2-0.06,0.303
		c-0.037,0.011-0.03,0.065-0.046,0.098c0.075-0.041,0.139-0.093,0.197-0.151l0.008,0.015c0.05-0.07,0.124-0.073,0.174-0.137
		c0.055-0.017,0.079-0.069,0.137-0.075c0.381-0.232,0.799-0.466,1.197-0.614c0.009-0.016,0.03-0.02,0.038-0.038
		c0.074-0.024,0.141-0.055,0.197-0.098c0.071,0.002,0.153-0.061,0.212-0.091c0.023,0.019,0.071-0.021,0.068-0.031
		c0.029,0.018,0.03-0.014,0.053-0.022c0.063,0.014,0.107-0.048,0.167-0.038c0.005-0.018,0.019-0.027,0.03-0.038
		C131.797,87.42,131.959,87.227,132.138,87.229"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: function (value) {
        if (value < 1) {
          return false
        }
        return value <= 5
      }
    }
  },
  computed: {
    showMiddle() {
      if (this.count === 1) {
        return true
      }
      if (this.count === 3) {
        return true
      }
      return this.count === 5
    },
    showTopLeft() {
      return this.count !== 1
    },
    showTopRight() {
      if (this.count === 4) {
        return true
      }
      return this.count === 5
    },
    showBottomLeft() {
      if (this.count === 4) {
        return true
      }
      return this.count === 5
    },
    showBottomRight() {
      return this.count !== 1
    }
  }
}
</script>
