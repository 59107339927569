<template>
  <div class="inline">
    <div
      v-if="available"
      ref="draggableElement"
      :class="classes"
      class="draggable-choice"
      :style="draggableStyle"
      @mousedown.stop="onInputDown"
      @touchstart.stop="onInputDown"
    >
      <slot />
    </div>
    <div v-if="dragging || !available" class="draggable-choice__placeholder">
      <slot />
    </div>
  </div>
</template>

<script>
import Coordinate from '../../../models/geometry/Coordinate'
import Rectangle from '../../../models/geometry/Rectangle'

export default {
  props: {
    dragging: { type: Boolean, default: false },
    available: { type: Boolean, default: true },
    readOnly: { type: Boolean, default: false },
    state: { type: String, default: 'default' }
  },
  data() {
    return {
      startingPosition: null,
      inputPosition: new Coordinate(0, 0),
      inputStartPosition: new Coordinate(0, 0),
      inputOffset: new Coordinate(0, 0)
    }
  },
  computed: {
    draggableStyle() {
      if (this.dragging) {
        return {
          position: 'fixed',
          'z-index': 10,
          top: `${this.position.y}px`,
          left: `${this.position.x}px`
        }
      }
      return {}
    },
    classes() {
      const classes = []
      if (this.dragging) {
        classes.push('draggeable-choice--dragging')
      }
      if (this.readOnly) {
        classes.push('draggable-choice--readonly')
      }
      if (this.state === 'correct') {
        classes.push('draggable-choice--correct')
      }
      if (this.state === 'incorrect') {
        classes.push('draggable-choice--incorrect')
      }
      return classes.join(' ')
    },
    position() {
      return this.inputPosition.subtract(this.inputOffset)
    }
  },
  methods: {
    onInputDown(event) {
      if (this.readOnly) {
        return
      }
      const bounds = this.$refs.draggableElement.getBoundingClientRect()

      this.startingPosition = new Coordinate(bounds.left, bounds.top)
      this.inputStartPosition = Coordinate.fromEvent(event)
      this.inputPosition = this.inputStartPosition
      this.inputOffset = this.inputPosition.subtract(this.startingPosition)
      window.addEventListener('mousemove', this.onInputMove)
      window.addEventListener('touchmove', this.onInputMove, { passive: false })
      window.addEventListener('mouseup', this.onInputUp)
      window.addEventListener('touchend', this.onInputUp, { passive: false })
      this.$emit('dragging-start')

      if (event.cancelable) {
        event.preventDefault()
      }
    },
    onInputMove(event) {
      if (event.cancelable) {
        event.preventDefault()
      }
      if (this.startingPosition === null) {
        return
      }
      this.inputPosition = Coordinate.fromEvent(event)
      // If the user does a very short drag, a select action is probably intended:
      if (this.inputStartPosition.distanceSq(this.inputPosition) < 25) {
        return
      }
      const rectangle = Rectangle.fromDOMRect(
        this.$refs.draggableElement.getBoundingClientRect()
      )
      if (this.dragging) {
        this.$emit('dragging', { position: this.inputPosition, rectangle })
      }
    },
    onInputUp() {
      this.unbindInputEvents()
      if (this.startingPosition === null) {
        return
      }
      this.startingPosition = null
      this.$emit('dropped')
    },
    unbindInputEvents() {
      window.removeEventListener('mousemove', this.onInputMove)
      window.removeEventListener('touchmove', this.onInputMove)
      window.removeEventListener('mouseup', this.onInputUp)
      window.removeEventListener('touchend', this.onInputUp)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../../component-library/assets/scss/wall';

.draggable-choice,
.draggable-choice__placeholder {
  @apply flex justify-center items-center cursor-pointer select-none;
  @apply rounded-xl bg-gray;
  width: 3.125em;
  height: 2.5em;
  transition: transform 100ms;
}

.draggable-choice__placeholder {
  @apply opacity-0; // hide placeholder
}

.draggeable-choice--dragging {
  transform: rotate(-15deg);
}

.draggable-choice--correct {
  @apply bg-score-green border-score-green text-white;
}

.draggable-choice--incorrect {
  @apply bg-score-red border-score-red text-white;
}

.draggable-choice--readonly {
  @apply cursor-default;
}
</style>
