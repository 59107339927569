import { QTI_VALUE } from '../services/qti/constants'

export default class CorrectResponse {
  constructor(correctValues) {
    this.correctValues = correctValues
  }

  /**
   * @param {Element} element
   */
  static createFromElement(element) {
    const valueNodes = Array.from(element.querySelectorAll(QTI_VALUE))
    const correctValues = valueNodes.map((value) => value.textContent)
    if (correctValues.length === 0) {
      console.warn('no correct value defined')
    }

    return new CorrectResponse(correctValues)
  }

  getFirstCorrectValue() {
    if (this.correctValues.length === 0) {
      return null
    }
    return this.correctValues[0]
  }
}
