<template>
  <div
    class="cursor-pointer text-xl flex flex-row gap-1 items-end"
    @click="home()"
  >
    <img
      :src="logoImage"
      style="width: 6em; margin-bottom: 0.3em"
      alt="Bareka"
    />
    <span
      v-if="hasLicense('BarekaUva')"
      class="ml-2 text-white bg-blue rounded px-1 text-sm"
    >
      UVA
    </span>
    <span
      v-else-if="$config.appEnv === 'beta'"
      class="ml-2 text-white bg-blue rounded px-1 text-sm"
    >
      BETA
    </span>
  </div>
</template>

<script>
import logoImage from '@/assets/images/bareka-logo.png'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      logoImage
    }
  },
  computed: {
    ...mapGetters({
      hasLicense: 'auth/hasLicense'
    })
  },
  methods: {
    home() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
