<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style="width: 6em; height: auto"
    viewBox="0 0 158.6 94.1"
  >
    <g v-if="showBottomRight" id="bottom_right">
      <path
        class="fill-current"
        d="M157.6,65.1c0.5,1.4,0.9,2.9,1,4.5c0.1,2.9-0.7,5.3-2,7.3c-0.6,1-1.3,2-2,2.9c-1.5,1.8-3,3.6-4.8,5.3
		c-0.9,0.9-1.8,1.6-2.7,2.5c-1.8,1.6-3.9,3-6.1,4.5c-0.7,0.5-1.6,1-2.5,1.5c-0.3,0.2-0.7,0.5-0.9,0.5c-0.2,0-0.5-0.2-0.7-0.3
		c-0.6-0.4-1.2-0.9-1.7-1.2c-0.8-0.6-1.6-1.1-2.3-1.7c-1.5-1.2-3.1-2.3-4.4-3.6c-1.4-1.3-2.8-2.6-4.1-4.1c-1.3-1.4-2.5-3-3.7-4.6
		c-0.6-0.8-1.2-1.7-1.7-2.6c-0.5-0.9-1-2-1.3-3.2c-0.8-3.3-0.2-5.9,0.7-8.1c0.5-1.3,1.3-2.6,2.3-3.6c1.3-1.3,3.2-2.6,5.5-3.2
		c2.8-0.7,5-0.4,6.9,0.3c1.9,0.7,3.6,1.7,4.9,3.3c0.1,0,0.2-0.2,0.3-0.3c1.5-1.6,3.7-2.9,6.8-3.4c3-0.4,5.6,0.5,7.5,1.6
		C154.8,60.7,156.7,62.6,157.6,65.1"
      />
      <path
        fill="#FFFFFF"
        d="M156.5,69.2c-0.1-1.4-0.5-2.7-1.1-3.9c-1.1-2-2.9-3.6-4.8-4.4c-1.9-0.8-4.7-1.1-7.4-0.1
		c-1.1,0.4-2.2,1.2-2.9,1.9c-0.5,0.5-1.2,1.2-1.5,1.9c-0.1,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.4-0.2-0.5-0.5c-0.9-1.7-2.4-3-3.8-3.7
		c-0.9-0.4-1.9-0.8-3-0.9c-1.1-0.2-2.4-0.2-3.7,0.1c-0.5,0.1-1,0.3-1.5,0.5c-3.1,1.3-5,3.8-5.7,6.7c-0.4,1.7-0.4,3.9,0.4,6
		c0.4,0.9,0.8,1.8,1.3,2.5c1,1.5,2,2.9,3.1,4.3c1.1,1.3,2.3,2.6,3.5,3.7c0.8,0.7,1.5,1.5,2.3,2.2c2.2,1.9,4.5,3.6,6.9,5.3
		c0.2,0.1,0.4,0.3,0.5,0.3c0.1,0,0.2-0.1,0.4-0.2c0.9-0.6,1.8-1.2,2.5-1.7c3.8-2.7,7.1-5.5,10-8.7c0.7-0.8,1.4-1.6,2.1-2.5
		c0.6-0.8,1.3-1.8,1.8-2.7c0.5-1,1.1-1.9,1.4-2.9C156.4,71.7,156.6,70.6,156.5,69.2"
      />
      <path
        fill="#231F20"
        d="M125.4,63.5c0.4,0,1.1,0.7,1,1.3c-0.1,0.5-0.6,0.7-0.9,1c-1.1,1.1-1.9,3-1.1,5.1c0.1,0.4,0.3,0.6,0.4,0.9
		c0,0.7-1,1.2-1.7,0.8c-0.3-0.2-0.5-0.7-0.7-1.1c-1.1-2.6-0.4-4.8,0.8-6.4c0.3-0.4,0.7-0.9,1.1-1.2c0.2-0.1,0.4-0.3,0.6-0.4
		C124.8,63.7,125.2,63.5,125.4,63.5"
      />
    </g>
    <g v-if="showBottomLeft" id="bottom_left">
      <path
        fill="#231F20"
        d="M40.2,65.1c0.5,1.4,0.9,2.9,1,4.5c0.1,2.9-0.7,5.3-2,7.3c-0.6,1-1.3,2-2,2.9c-1.5,1.8-3,3.6-4.8,5.3
		c-0.9,0.9-1.8,1.6-2.7,2.5c-1.8,1.6-3.9,3-6.1,4.5c-0.7,0.5-1.6,1-2.5,1.5c-0.3,0.2-0.7,0.5-0.9,0.5c-0.2,0-0.5-0.2-0.7-0.3
		c-0.6-0.4-1.2-0.9-1.7-1.2c-0.8-0.6-1.6-1.1-2.3-1.7c-1.5-1.2-3.1-2.3-4.4-3.6C9.7,86,8.3,84.7,7,83.2c-1.3-1.4-2.5-3-3.7-4.6
		C2.7,77.8,2.1,77,1.6,76c-0.5-0.9-1-2-1.3-3.2c-0.8-3.3-0.2-5.9,0.7-8.1c0.5-1.3,1.3-2.6,2.3-3.6c1.3-1.3,3.2-2.6,5.5-3.2
		c2.8-0.7,5-0.4,6.9,0.3c1.9,0.7,3.6,1.7,4.9,3.3c0.1,0,0.2-0.2,0.3-0.3c1.5-1.6,3.7-2.9,6.8-3.4c3-0.4,5.6,0.5,7.5,1.6
		C37.4,60.7,39.3,62.6,40.2,65.1"
      />
      <path
        fill="#FFFFFF"
        d="M39.1,69.2c-0.1-1.4-0.5-2.7-1.1-3.9c-1.1-2-2.9-3.6-4.8-4.4c-1.9-0.8-4.7-1.1-7.4-0.1
		c-1.1,0.4-2.2,1.2-2.9,1.9c-0.5,0.5-1.2,1.2-1.5,1.9c-0.1,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.4-0.2-0.5-0.5c-0.9-1.7-2.4-3-3.8-3.7
		c-0.9-0.4-1.9-0.8-3-0.9c-1.1-0.2-2.4-0.2-3.7,0.1c-0.5,0.1-1,0.3-1.5,0.5c-3.1,1.3-5,3.8-5.7,6.7C2,69,2,71.2,2.8,73.3
		c0.4,0.9,0.8,1.8,1.3,2.5c1,1.5,2,2.9,3.1,4.3c1.1,1.3,2.3,2.6,3.5,3.7c0.8,0.7,1.5,1.5,2.3,2.2c2.2,1.9,4.5,3.6,6.9,5.3
		c0.2,0.1,0.4,0.3,0.5,0.3c0.1,0,0.2-0.1,0.4-0.2c0.9-0.6,1.8-1.2,2.5-1.7c3.8-2.7,7.1-5.5,10-8.7c0.7-0.8,1.4-1.6,2.1-2.5
		c0.6-0.8,1.3-1.8,1.8-2.7c0.5-1,1.1-1.9,1.4-2.9C39,71.7,39.2,70.6,39.1,69.2"
      />
      <path
        class="fill-current"
        d="M8,63.5c0.4,0,1.1,0.7,1,1.3c-0.1,0.5-0.6,0.7-0.9,1c-1.1,1.1-1.9,3-1.1,5.1c0.1,0.4,0.3,0.6,0.4,0.9
		c0,0.7-1,1.2-1.7,0.8c-0.3-0.2-0.5-0.7-0.7-1.1c-1.1-2.6-0.4-4.8,0.8-6.4C6,64.8,6.4,64.3,6.8,64c0.2-0.1,0.4-0.3,0.6-0.4
		C7.4,63.7,7.8,63.5,8,63.5"
      />
    </g>
    <g v-if="showMiddle" id="middle">
      <path
        d="M98.9,36.3c0.5,1.4,0.9,2.9,1,4.5c0.1,2.9-0.7,5.3-2,7.3c-0.6,1-1.3,2-2,2.9c-1.5,1.8-3,3.6-4.8,5.3
		c-0.9,0.9-1.8,1.6-2.7,2.5c-1.8,1.6-3.9,3-6.1,4.5c-0.7,0.5-1.6,1-2.5,1.5c-0.3,0.2-0.7,0.5-0.9,0.5c-0.2,0-0.5-0.2-0.7-0.3
		c-0.6-0.4-1.2-0.9-1.7-1.2c-0.8-0.6-1.6-1.1-2.3-1.7c-1.5-1.2-3.1-2.3-4.4-3.6c-1.4-1.3-2.8-2.6-4.1-4.1c-1.3-1.4-2.5-3-3.7-4.6
		c-0.6-0.8-1.2-1.7-1.7-2.6c-0.5-0.9-1-2-1.3-3.2c-0.8-3.3-0.2-5.9,0.7-8.1c0.5-1.3,1.3-2.6,2.3-3.6c1.3-1.3,3.2-2.6,5.5-3.2
		c2.8-0.7,5-0.4,6.9,0.3c1.9,0.7,3.6,1.7,4.9,3.3c0.1,0,0.2-0.2,0.3-0.3c1.5-1.6,3.7-2.9,6.8-3.4c3-0.4,5.6,0.5,7.5,1.6
		C96.1,31.9,98,33.8,98.9,36.3"
      />
      <path
        fill="#FFFFFF"
        d="M97.8,40.5c-0.1-1.4-0.5-2.7-1.1-3.9c-1.1-2-2.9-3.6-4.8-4.4c-1.9-0.8-4.7-1.1-7.4-0.1
		c-1.1,0.4-2.2,1.2-2.9,1.9c-0.5,0.5-1.2,1.2-1.5,1.9c-0.1,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.4-0.2-0.5-0.5c-0.9-1.7-2.4-3-3.8-3.7
		c-0.9-0.4-1.9-0.8-3-0.9c-1.1-0.2-2.4-0.2-3.7,0.1c-0.5,0.1-1,0.3-1.5,0.5c-3.1,1.3-5,3.8-5.7,6.7c-0.4,1.7-0.4,3.9,0.4,6
		c0.4,0.9,0.8,1.8,1.3,2.5c1,1.5,2,2.9,3.1,4.3c1.1,1.3,2.3,2.6,3.5,3.7c0.8,0.7,1.5,1.5,2.3,2.2c2.2,1.9,4.5,3.6,6.9,5.3
		c0.2,0.1,0.4,0.3,0.5,0.3c0.1,0,0.2-0.1,0.4-0.2c0.9-0.6,1.8-1.2,2.5-1.7c3.8-2.7,7.1-5.5,10-8.7c0.7-0.8,1.4-1.6,2.1-2.5
		c0.6-0.8,1.3-1.8,1.8-2.7c0.5-1,1.1-1.9,1.4-2.9C97.7,43,97.9,41.9,97.8,40.5"
      />
      <path
        d="M66.7,34.8c0.4,0,1.1,0.7,1,1.3c-0.1,0.5-0.6,0.7-0.9,1c-1.1,1.1-1.9,3-1.1,5.1c0.1,0.4,0.3,0.6,0.4,0.9
		c0,0.7-1,1.2-1.7,0.8c-0.3-0.2-0.5-0.7-0.7-1.1c-1.1-2.6-0.4-4.8,0.8-6.4c0.3-0.4,0.7-0.9,1.1-1.2c0.2-0.1,0.4-0.3,0.6-0.4
		C66.1,34.9,66.5,34.8,66.7,34.8"
      />
    </g>
    <g v-if="showTopRight" id="top_right">
      <path
        d="M157.6,7.5c0.5,1.4,0.9,2.9,1,4.5c0.1,2.9-0.7,5.3-2,7.3c-0.6,1-1.3,2-2,2.9c-1.5,1.8-3,3.6-4.8,5.3
		c-0.9,0.9-1.8,1.6-2.7,2.5c-1.8,1.6-3.9,3-6.1,4.5c-0.7,0.5-1.6,1-2.5,1.5c-0.3,0.2-0.7,0.5-0.9,0.5c-0.2,0-0.5-0.2-0.7-0.3
		c-0.6-0.4-1.2-0.9-1.7-1.2c-0.8-0.6-1.6-1.1-2.3-1.7c-1.5-1.2-3.1-2.3-4.4-3.6c-1.4-1.3-2.8-2.6-4.1-4.1c-1.3-1.4-2.5-3-3.7-4.6
		c-0.6-0.8-1.2-1.7-1.7-2.6c-0.5-0.9-1-2-1.3-3.2c-0.8-3.3-0.2-5.9,0.7-8.1c0.5-1.3,1.3-2.6,2.3-3.6c1.3-1.3,3.2-2.6,5.5-3.2
		c2.8-0.7,5-0.4,6.9,0.3c1.9,0.7,3.6,1.7,4.9,3.3c0.1,0,0.2-0.2,0.3-0.3c1.5-1.6,3.7-2.9,6.8-3.4c3-0.4,5.6,0.5,7.5,1.6
		C154.8,3.2,156.7,5,157.6,7.5"
      />
      <path
        fill="#FFFFFF"
        d="M156.5,11.7c-0.1-1.4-0.5-2.7-1.1-3.9c-1.1-2-2.9-3.6-4.8-4.4c-1.9-0.8-4.7-1.1-7.4-0.1
		c-1.1,0.4-2.2,1.2-2.9,1.9c-0.5,0.5-1.2,1.2-1.5,1.9c-0.1,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.4-0.2-0.5-0.5c-0.9-1.7-2.4-3-3.8-3.7
		c-0.9-0.4-1.9-0.8-3-0.9c-1.1-0.2-2.4-0.2-3.7,0.1c-0.5,0.1-1,0.3-1.5,0.5c-3.1,1.3-5,3.8-5.7,6.7c-0.4,1.7-0.4,3.9,0.4,6
		c0.4,0.9,0.8,1.8,1.3,2.5c1,1.5,2,2.9,3.1,4.3c1.1,1.3,2.3,2.6,3.5,3.7c0.8,0.7,1.5,1.5,2.3,2.2c2.2,1.9,4.5,3.6,6.9,5.3
		c0.2,0.1,0.4,0.3,0.5,0.3c0.1,0,0.2-0.1,0.4-0.2c0.9-0.6,1.8-1.2,2.5-1.7c3.8-2.7,7.1-5.5,10-8.7c0.7-0.8,1.4-1.6,2.1-2.5
		c0.6-0.8,1.3-1.8,1.8-2.7c0.5-1,1.1-1.9,1.4-2.9C156.4,14.2,156.6,13.1,156.5,11.7"
      />
      <path
        class="fill-current"
        d="M125.4,6c0.4,0,1.1,0.7,1,1.3c-0.1,0.5-0.6,0.7-0.9,1c-1.1,1.1-1.9,3-1.1,5.1c0.1,0.4,0.3,0.6,0.4,0.9
		c0,0.7-1,1.2-1.7,0.8c-0.3-0.2-0.5-0.7-0.7-1.1c-1.1-2.6-0.4-4.8,0.8-6.4c0.3-0.4,0.7-0.9,1.1-1.2c0.2-0.1,0.4-0.3,0.6-0.4
		C124.8,6.1,125.2,6,125.4,6"
      />
    </g>
    <g v-if="showTopLeft" id="top_left">
      <path
        class="fill-current"
        d="M40.2,7.5c0.5,1.4,0.9,2.9,1,4.5c0.1,2.9-0.7,5.3-2,7.3c-0.6,1-1.3,2-2,2.9c-1.5,1.8-3,3.6-4.8,5.3
		c-0.9,0.9-1.8,1.6-2.7,2.5c-1.8,1.6-3.9,3-6.1,4.5c-0.7,0.5-1.6,1-2.5,1.5c-0.3,0.2-0.7,0.5-0.9,0.5c-0.2,0-0.5-0.2-0.7-0.3
		c-0.6-0.4-1.2-0.9-1.7-1.2c-0.8-0.6-1.6-1.1-2.3-1.7c-1.5-1.2-3.1-2.3-4.4-3.6c-1.4-1.3-2.8-2.6-4.1-4.1c-1.3-1.4-2.5-3-3.7-4.6
		c-0.6-0.8-1.2-1.7-1.7-2.6c-0.5-0.9-1-2-1.3-3.2C-0.4,12,0.1,9.4,1,7.1c0.5-1.3,1.3-2.6,2.3-3.6c1.3-1.3,3.2-2.6,5.5-3.2
		c2.8-0.7,5-0.4,6.9,0.3c1.9,0.7,3.6,1.7,4.9,3.3c0.1,0,0.2-0.2,0.3-0.3c1.5-1.6,3.7-2.9,6.8-3.4c3-0.4,5.6,0.5,7.5,1.6
		C37.4,3.2,39.3,5,40.2,7.5"
      />
      <path
        fill="#FFFFFF"
        d="M39.1,11.7C39,10.3,38.6,8.9,38,7.8c-1.1-2-2.9-3.6-4.8-4.4c-1.9-0.8-4.7-1.1-7.4-0.1
		c-1.1,0.4-2.2,1.2-2.9,1.9c-0.5,0.5-1.2,1.2-1.5,1.9c-0.1,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.4-0.2-0.5-0.5c-0.9-1.7-2.4-3-3.8-3.7
		c-0.9-0.4-1.9-0.8-3-0.9C12.2,2.3,11,2.2,9.6,2.6c-0.5,0.1-1,0.3-1.5,0.5c-3.1,1.3-5,3.8-5.7,6.7c-0.4,1.7-0.4,3.9,0.4,6
		c0.4,0.9,0.8,1.8,1.3,2.5c1,1.5,2,2.9,3.1,4.3c1.1,1.3,2.3,2.6,3.5,3.7c0.8,0.7,1.5,1.5,2.3,2.2c2.2,1.9,4.5,3.6,6.9,5.3
		c0.2,0.1,0.4,0.3,0.5,0.3c0.1,0,0.2-0.1,0.4-0.2c0.9-0.6,1.8-1.2,2.5-1.7c3.8-2.7,7.1-5.5,10-8.7c0.7-0.8,1.4-1.6,2.1-2.5
		c0.6-0.8,1.3-1.8,1.8-2.7c0.5-1,1.1-1.9,1.4-2.9C39,14.2,39.2,13.1,39.1,11.7"
      />
      <path
        class="fill-current"
        d="M8,6c0.4,0,1.1,0.7,1,1.3C8.9,7.8,8.4,8,8,8.4c-1.1,1.1-1.9,3-1.1,5.1c0.1,0.4,0.3,0.6,0.4,0.9
		c0,0.7-1,1.2-1.7,0.8c-0.3-0.2-0.5-0.7-0.7-1.1c-1.1-2.6-0.4-4.8,0.8-6.4C6,7.2,6.4,6.8,6.8,6.5c0.2-0.1,0.4-0.3,0.6-0.4
		C7.4,6.1,7.8,6,8,6"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: function (value) {
        if (value < 1) {
          return false
        }
        return value <= 5
      }
    }
  },
  computed: {
    showMiddle() {
      if (this.count === 1) {
        return true
      }
      if (this.count === 3) {
        return true
      }
      return this.count === 5
    },
    showTopLeft() {
      return this.count !== 1
    },
    showTopRight() {
      if (this.count === 4) {
        return true
      }
      return this.count === 5
    },
    showBottomLeft() {
      if (this.count === 4) {
        return true
      }
      return this.count === 5
    },
    showBottomRight() {
      return this.count !== 1
    }
  }
}
</script>
