<template>
  <button
    class="button text-lg"
    :disabled="disabled"
    :class="classNames"
    @click.prevent="onClick"
  >
    <div
      v-if="badge"
      class="rounded-4xl bg-red-dark text-white absolute px-2 py-1 text-sm"
      style="right: -3px; top: -7px; min-width: 20px; min-height: 20px"
    >
      <template v-if="badge !== true">{{ badge }}</template>
    </div>
    <slot />
  </button>
</template>

<script>
import { types } from './ButtonTypes'
export default {
  props: {
    type: { type: String, default: types.PRIMARY },
    size: { type: String, default: 'medium' },
    disabled: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    badge: { type: [Boolean, String], default: false },
    to: { type: String, default: null }
  },
  computed: {
    classNames() {
      const classes = [`button--${this.type}`, `button--${this.size}`]
      if (this.active) {
        classes.push('button--active')
      }
      return classes.join(' ')
    }
  },
  methods: {
    onClick() {
      if (this.disabled) {
        return
      }
      if (this.to) {
        this.$router.push(this.to)
      }
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/wall';

.button {
  @apply relative inline-flex flex-row items-center gap-1 justify-center align-middle font-normal outline-none leading-none rounded-2xl;
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-bottom-width: 4px;
  transition: all 100ms;
  &:active,
  &:focus {
    outline: none;
  }
}

.button:active,
.button--active {
  // swap border widths:
  border-top-width: 4px;
  border-bottom-width: 1px;
}

.button:disabled,
.button--disabled {
  @apply select-none pointer-events-none bg-gray border-gray text-gray-dark;
  border-top-width: 2px;
  border-bottom-width: 3px;
  filter: grayscale(100%);
}

//
// sizes
//
.button--tiny {
  // note: add leading to fix height when using an icon in label:
  @apply px-2 py-1 text-sm rounded-lg leading-tight;
}

.button--small {
  @apply px-4 py-2 text-base rounded-xl;
}

.button--medium {
  @apply px-5 py-3 text-lg;
}

.button--large {
  @apply px-6 py-4 text-2xl;
}

//
// types
//
.button--primary {
  @apply text-white bg-primary;
}

.button--secondary {
  @apply text-gray-dark bg-white;
  &:active,
  &.button--active {
    border-top-width: 4px;
    border-bottom-width: 2px;
  }
}

.button--danger {
  @apply text-white bg-red;
}

.button--orange {
  @apply text-white bg-orange;
}

.button--success {
  @apply text-white bg-green;
}

.button--score-red {
  @apply text-white bg-score-red;
}

.button--link {
  @apply bg-white;
}
</style>
