<script>
import componentNameForType from './componentNameForType'
import Bar from './Bar'

export default {
  props: {
    count: { type: [String, Number], required: true },
    type: { type: String, required: true },
    outline: { type: Boolean, default: false }
  },
  computed: {
    castCount() {
      return typeof this.count === 'string' ? parseInt(this.count) : this.count
    },
    componentName() {
      return componentNameForType(this.type)
    }
  },
  methods: {
    onClick() {
      if (!this.clickable) {
        return
      }
      this.$emit('click')
    }
  },
  render(h) {
    let countComponents
    // display 2 bar pictures when count > 10:
    if (this.type === 'bar' && this.castCount > 10) {
      countComponents = [
        h(Bar, { props: { count: 10 }, style: 'margin-bottom: 0.5em' }),
        h(Bar, { props: { count: this.castCount - 10 } })
      ]
    } else {
      countComponents = [
        h(componentNameForType(this.type), {
          props: { count: this.castCount }
        })
      ]
    }

    return h(
      'div',
      {
        class: {
          'count-picture': true,
          'count-picture--outlined': this.outline
        },
        on: { click: this.onClick }
      },
      countComponents
    )
  }
}
</script>

<style scoped lang="scss">
.count-picture {
  @apply inline-block;
}

.count-picture--outlined {
  @apply bg-gray-light border-gray-light rounded-4xl;
  border-width: 0.2em;
  padding: 0.5em;
}
</style>
