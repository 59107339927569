<template>
  <div>
    <slot />
  </div>
</template>

<script>
import QtiBaseMixin from '../../QtiBaseMixin'

export default {
  mixins: [QtiBaseMixin]
}
</script>
