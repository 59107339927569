<template>
  <img :src="srcWithHost" alt="" style="max-width: 24em" />
</template>

<script>
import QtiBaseMixin from '../QtiBaseMixin'

export default {
  mixins: [QtiBaseMixin],
  props: {
    src: { type: String, required: true }
  },
  computed: {
    srcWithHost() {
      return this.assetHost + '/' + this.src
    }
  }
}
</script>
