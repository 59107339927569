<template>
  <div v-if="show">
    <slot />
  </div>
</template>

<script>
import QtiBaseMixin from '../../QtiBaseMixin'

export default {
  mixins: [QtiBaseMixin],
  props: {
    templateIdentifier: { type: String, required: true },
    identifier: { type: String, required: true }
  },
  computed: {
    show() {
      return (
        this.settings.templateVariables[this.templateIdentifier] ===
        this.identifier
      )
    }
  }
}
</script>
