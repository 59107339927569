import { keys } from 'shared'

const { ARROW_LEFT, ARROW_RIGHT, BACKSPACE, ENTER, TAB } = keys
export const allowedInputKeys = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  '.',
  ','
]

export default function (responseSet) {
  return (event) => {
    if (event.repeat) {
      return
    }
    if (allowedInputKeys.includes(event.key)) {
      if (event.cancelable) {
        event.preventDefault()
      }
      if (!responseSet.responseWithFocus) {
        return
      }
      if (!responseSet.responseWithFocus.inputState.isEditable()) {
        return
      }
      responseSet.handleInput(
        responseSet.responseWithFocus.id,
        responseSet.responseWithFocus.value + event.key
      )
    }
    if (event.key === BACKSPACE) {
      event.preventDefault()
      handleBackspace(responseSet)
      return
    }
    if (event.key === ENTER) {
      event.preventDefault()
      if (responseSet.submitted) {
        if (responseSet.submitProcessed) {
          responseSet.setDone()
        }
      } else {
        responseSet.submit()
      }
    }
    if ([ARROW_LEFT, ARROW_RIGHT, TAB].includes(event.key)) {
      // when using left/right or tab, always set response to ready when
      // response has value:
      event.preventDefault()
      if (
        responseSet.responseWithFocus &&
        responseSet.responseWithFocus.value !== ''
      ) {
        responseSet.setResponseReady(responseSet.responseWithFocus.id)
      }
    }
    if (event.key === ARROW_LEFT) {
      responseSet.reversed
        ? responseSet.setFocusOnNextResponse()
        : responseSet.setFocusOnPreviousResponse()
      return
    }
    if (event.key === ARROW_RIGHT) {
      responseSet.reversed
        ? responseSet.setFocusOnPreviousResponse()
        : responseSet.setFocusOnNextResponse()
      return
    }
    if (event.key === TAB) {
      if (event.shiftKey) {
        // go to last response when using tab on first input:
        if (responseSet.firstResponseHasFocus) {
          responseSet.setFocusOnLastResponse()
          return
        }
        // go to previous response when using shift+tab:
        responseSet.setFocusOnPreviousResponse()
        return
      }
      // go to first response when using tab on last input:
      if (responseSet.finalResponseHasFocus) {
        responseSet.setFocusOnFirstResponse()
        return
      }
      // go to next response when using tab:
      responseSet.setFocusOnNextResponse()
    }
  }
}

function handleBackspace(responseSet) {
  if (!responseSet.responseWithFocus) {
    return
  }
  if (!responseSet.responseWithFocus.inputState.isEditable()) {
    return
  }
  if (responseSet.responseWithFocus.value.length > 0) {
    responseSet.handleInput(
      responseSet.responseWithFocus.id,
      responseSet.responseWithFocus.value.substring(
        0,
        responseSet.responseWithFocus.value.length - 1
      )
    )
    return
  }
  // when response was empty and backspace is entered,
  // go to previous response:
  if (responseSet.responseWithFocus.value.length === 0) {
    responseSet.setFocusOnPreviousResponse()
  }
}
