<template>
  <transition name="fade">
    <div v-if="show" class="toaster-balloon rounded-full" :class="classes">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    duration: { type: Number, required: true },
    position: { type: String, default: 'bottom left' },
    type: { type: String, default: 'info' }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    classes() {
      const classes = []
      const position = this.position.split(' ')
      if (position.includes('top')) {
        classes.push('top-0')
        if (position.includes('right')) {
          classes.push('rounded-tr-none')
        } else {
          classes.push('rounded-tl-none')
        }
      } else if (position.includes('bottom')) {
        classes.push('bottom-0')
        if (position.includes('right')) {
          classes.push('rounded-br-none')
        } else {
          classes.push('rounded-bl-none')
        }
      } else {
        classes.push('rounded-bl-none')
      }
      if (position.includes('left')) {
        classes.push('left-0')
      } else if (position.includes('right')) {
        classes.push('right-0')
      } else if (position.includes('center')) {
        classes.push('left-50')
      }
      switch (this.type) {
        case 'primary':
          classes.push('primary')
          break
        default:
          classes.push('info')
          break
      }
      return classes.join(' ')
    }
  },
  mounted() {
    this.show = true
    setTimeout(() => {
      this.show = false
    }, this.duration * 1000)
  }
}
</script>

<style scoped>
.toaster-balloon {
  @apply absolute p-6 px-12 m-4;
  width: 300px;
  z-index: 20;
}

.info {
  @apply bg-yellow-500 text-gray-darkest;
}

.primary {
  @apply bg-primary text-white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
