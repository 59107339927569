<template>
  <div class="choice" :class="classes">
    <div
      v-for="choice in choices"
      :key="choice.value"
      class="choice__choice"
      :class="{ 'choice__choice--selected': value === choice.value }"
      @click="$emit('input', choice.value)"
    >
      {{ choice.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    choices: { type: Array, required: true },
    value: { type: String, required: true },
    hasFocus: { type: Boolean, default: true },
    orientation: { type: String, default: 'horizontal' },
    size: { type: String, default: 'medium' },
    choiceContentType: { type: String, default: 'text' },
    readOnly: { type: Boolean, default: false },
    inline: { type: Boolean, default: true }
  },
  computed: {
    classes() {
      return {
        [`choice--${this.orientation}`]: true,
        [`choice--${this.size}`]: true,
        [`choice--${this.choiceContentType}`]: true,
        'choice--inline': this.inline,
        'choice--block': !this.inline,
        'choice--focus': this.hasFocus,
        'choice--readonly': this.readOnly
      }
    }
  }
}
</script>

<style lang="scss">
.choice {
  @apply inline-flex relative;
  padding: 0.1em 0.2em;
}

.choice--block {
  gap: 0.8em;
}

.choice--horizontal {
  @apply flex-row;
  .choice__choice {
    @apply flex-none rounded-xl;
  }
}

.choice--vertical {
  @apply flex-col;
  .choice__choice {
    @apply flex-auto rounded-xl;
  }
}

.choice--inline {
  .choice__choice {
    &:hover:not(.choice__choice--selected) {
      @apply bg-gray-light;
    }
  }

  &.choice--horizontal {
    .choice__choice {
      @apply rounded-none;
      margin-left: -4px;
      &:first-child {
        @apply rounded-l-xl;
        margin-left: 0;
      }
      &:last-child {
        @apply rounded-r-xl;
      }
    }
  }
  &.choice--vertical {
    @apply flex-col;
    .choice__choice {
      @apply rounded-none;
      margin-top: -4px;
      &:first-child {
        @apply rounded-t-xl;
        margin-top: 0;
      }
      &:last-child {
        @apply rounded-b-xl;
      }
    }
  }

  .choice__choice--selected {
    @apply bg-gray cursor-default;
  }

  &.choice--correct {
    .choice__choice--selected {
      @apply bg-green border-green text-white;
    }
  }
  &.choice--incorrect {
    .choice__choice--selected {
      @apply bg-red border-red text-white;
    }
  }
}

.choice__choice {
  @apply cursor-pointer flex justify-center items-center;
  @apply border-gray bg-gray-lighter py-2 px-4;
  border-width: 0.125em;
  font-size: 1em;
  z-index: 0;
}

.choice--large {
  .choice__choice {
    @apply px-6;
    font-size: 1em;
  }
}

.choice--correct,
.choice--incorrect,
.choice--readonly {
  .choice__choice {
    @apply pointer-events-none cursor-default;
  }
}

.choice--block {
  .choice--focus {
    @apply border-yellow-500;
  }

  .choice__choice {
    @apply p-8;
    border-width: 6px;
    &:hover:not(.choice__choice--selected) {
      @apply bg-gray-light;
    }
  }

  .choice__choice--selected {
    @apply bg-gray cursor-default;
  }

  &.choice--correct {
    .choice__choice--selected {
      @apply border-green;
    }
  }
  &.choice--incorrect {
    .choice__choice--selected {
      @apply border-red;
    }
  }
}
</style>
