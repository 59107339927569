<template>
  <DropDown :initially-open="open" @opened="open = true" @closed="open = false">
    <template #trigger>
      <div
        ref="trigger"
        class="inline-flex flex-row items-center border-2 border-gray py-3 px-4 gap-x-2 rounded-lg select-none"
        :class="{ 'border-blue': open }"
        :style="{ 'min-width': `${minWidth}px`, width: width }"
      >
        <span class="flex-auto">{{ selectedText }}</span>
        <IconChevronDown />
      </div>
    </template>
    <DropdownContent
      class="math-wall-dropdown flex flex-col selectable-list bg-white"
    >
      <div class="flex-row justify-between close-button-header p-3">
        Kies een steentje
        <IconButton @click="open = false">
          <IconClose />
        </IconButton>
      </div>
      <Wall
        phase-labels="small"
        :custom-bricks="bricks"
        class="bg-white whitespace-normal p-3 relative w-full h-full"
      >
        <template #brick="{ brick, getContentClass }">
          <div
            :class="'bareka-brick flex-fill pointer ' + getContentClass(brick)"
          >
            <button
              v-if="brickActive(brick)"
              :id="'brick-' + brick.id"
              class="bareka-brick-overlay"
              @click="selectBrick(brick)"
            >
              {{ brick.name }}
            </button>
            <div v-else class="text-gray bareka-brick-overlay">
              {{ brick.name }}
            </div>
          </div>
        </template>
      </Wall>
    </DropdownContent>
  </DropDown>
</template>
<script>
import DropDown from '../dropdown/DropDown.vue'
import DropdownContent from '../dropdown/DropdownContent.vue'
import IconButton from '../button/IconButton.vue'
import { IconChevronDown, IconClose } from '../icons'
import Wall from '../wall/Wall.vue'

export default {
  components: {
    Wall,
    IconChevronDown,
    DropdownContent,
    DropDown,
    IconButton,
    IconClose
  },
  props: {
    value: { type: String, default: null },
    validBrickIds: { type: Array, default: null },
    width: { type: String, default: 'auto' }
  },
  data() {
    return {
      open: false,
      minWidth: '100px',
      bricks: null,
      selectedBrick: null
    }
  },
  computed: {
    listValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    selectedText() {
      if (this.selectedBrick === null) {
        return 'Kies een steentje'
      }
      return this.selectedBrick.name
    }
  },
  watch: {
    value() {
      this.selectBrickByValue()
    },
    initiallyOpen(open) {
      this.open = open
    },
    bricks() {
      this.selectBrickByValue()
    }
  },
  mounted() {
    this.fetchBricks()
    if (this.$refs.trigger !== undefined) {
      this.minWidth = this.$refs.trigger.offsetWidth
    }
    this.selectBrickByValue()
  },
  methods: {
    async fetchBricks() {
      const { bricks } = await this.$axios.$get('/bricks')
      this.bricks = bricks
    },
    selectBrick(brick) {
      this.selectedBrick = brick
      this.listValue = this.selectedBrick.id
      setTimeout(() => {
        this.open = false
      }, 200)
    },
    selectBrickByValue() {
      if (this.listValue !== null && this.bricks !== null) {
        const foundBrick = this.bricks.find(
          (brick) => this.listValue === brick.id
        )
        if (foundBrick !== undefined) {
          this.selectedBrick = foundBrick
        }
      }
    },
    brickActive(brick) {
      if (this.validBrickIds === null) {
        return true
      } else {
        return this.validBrickIds.includes(brick.id)
      }
    }
  }
}
</script>

<style lang="scss">
.close-button-header {
  display: none;
  @media (max-width: 1095px), (max-height: 780px) {
    display: flex;
  }
}

.math-wall-dropdown {
  @media (max-width: 1095px), (max-height: 780px) {
    position: fixed !important;
    width: auto !important;
    height: auto !important;
    left: 0;
    top: 0 !important;
    bottom: 0;
    right: 0;
    overflow: auto;
    margin: 10px !important;
  }
}
</style>
