<template>
  <div
    class="choice-input__choice"
    :class="classes"
    @click="$emit('select', value)"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String, required: true },
    selected: { type: Boolean, required: true }
  },
  computed: {
    classes() {
      return {
        'choice-input__choice--selected': this.selected
      }
    }
  }
}
</script>
