<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="200px"
    viewBox="0 0 97.98 30"
    class="mb-6"
  >
    <g id="separators" fill="currentColor">
      <path
        d="M50.85,9.21c0,0.24-0.05,0.48-0.15,0.72c-0.1,0.24-0.23,0.44-0.39,0.6c-0.16,0.16-0.36,0.29-0.6,0.39
		c-0.24,0.1-0.48,0.15-0.72,0.15c-0.24,0-0.48-0.05-0.72-0.15c-0.24-0.1-0.44-0.23-0.6-0.39c-0.16-0.16-0.29-0.36-0.39-0.6
		c-0.1-0.24-0.15-0.48-0.15-0.72c0-0.24,0.05-0.48,0.15-0.72c0.1-0.22,0.23-0.41,0.39-0.57c0.2-0.18,0.4-0.32,0.6-0.42
		c0.18-0.08,0.42-0.12,0.72-0.12c0.3,0,0.54,0.04,0.72,0.12c0.22,0.1,0.42,0.24,0.6,0.42c0.16,0.16,0.29,0.35,0.39,0.57
		C50.8,8.73,50.85,8.97,50.85,9.21z"
      />
      <path
        d="M50.85,21.57c0,0.24-0.05,0.48-0.15,0.72c-0.1,0.24-0.23,0.44-0.39,0.6c-0.16,0.16-0.36,0.29-0.6,0.39
		c-0.24,0.1-0.48,0.15-0.72,0.15c-0.24,0-0.48-0.05-0.72-0.15c-0.24-0.1-0.44-0.23-0.6-0.39c-0.16-0.16-0.29-0.36-0.39-0.6
		s-0.15-0.48-0.15-0.72c0-0.24,0.05-0.48,0.15-0.72c0.1-0.22,0.23-0.41,0.39-0.57c0.2-0.2,0.4-0.34,0.6-0.42
		c0.18-0.08,0.42-0.12,0.72-0.12c0.3,0,0.54,0.04,0.72,0.12c0.22,0.08,0.42,0.22,0.6,0.42c0.16,0.16,0.29,0.35,0.39,0.57
		C50.8,21.09,50.85,21.33,50.85,21.57z"
      />
    </g>
    <DigitalNumber
      v-for="position in 4"
      :key="position"
      :number="numberForPosition(position)"
      :translate-x="translateXForPosition(position)"
    />
  </svg>
</template>

<script>
import DigitalNumber from './DigitalNumber'
export default {
  components: { DigitalNumber },
  props: {
    hour: { type: [Number, String], default: 12 },
    minutes: { type: [Number, String], default: 0 }
  },
  computed: {
    castHour() {
      return typeof this.hour === 'string' ? parseInt(this.hour) : this.hour
    },
    castMinutes() {
      return typeof this.minutes === 'string'
        ? parseInt(this.minutes)
        : this.minutes
    }
  },
  methods: {
    translateXForPosition(position) {
      const translateXValues = [0, 24.48, 54.96, 79.44]
      return translateXValues[position - 1] || 0
    },
    numberForPosition(position) {
      if (position === 1) {
        if (this.castHour === 24) {
          return 0
        }
        if (this.castHour < 10) {
          return 0
        }
        return parseInt(this.castHour.toString().charAt(0))
      }
      if (position === 2) {
        if (this.castHour === 24) {
          return 0
        }
        const charAt = this.castHour < 10 ? 0 : 1
        return parseInt(this.castHour.toString().charAt(charAt))
      }
      if (position === 3) {
        if (this.castMinutes === 60) {
          return 0
        }
        if (this.castMinutes < 10) {
          return 0
        }
        return parseInt(this.castMinutes.toString().charAt(0))
      }
      if (position === 4) {
        if (this.castMinutes === 60) {
          return 0
        }
        const charAt = this.castMinutes < 10 ? 0 : 1
        return parseInt(this.castMinutes.toString().charAt(charAt))
      }
    }
  }
}
</script>
