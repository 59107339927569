import {
  QTI_AREA_MAPPING,
  QTI_CHOICE_INTERACTION,
  QTI_CORRECT_RESPONSE,
  QTI_CUSTOM_INTERACTION,
  QTI_INLINE_CHOICE_INTERACTION,
  QTI_ITEM_BODY,
  QTI_ORDER_INTERACTION,
  QTI_SELECT_POINT_INTERACTION,
  QTI_TEXT_ENTRY_INTERACTION
} from '../constants'
import { isTemplateNode, includeForTemplate } from '../utils'
import { Response } from '../../../models/Response'
import CorrectResponse from '../../../models/CorrectResponse'
import InputState from '../../../models/InputState'
import AreaMapping from './AreaMapping'
import { createSpeechInputHandlerForInteractionElement } from '../speech'
import getSupportedInputModes from './getSupportedInputModes'

/**
 * @param {Document} doc
 * @param {ItemSettings} settings
 * @returns {Array<Response>}
 */
export default (doc, settings) => {
  // fetch all responses in response-declarations:
  const responses = extractDeclaredResponses(doc, settings)
  // considering the template variables, some responses
  // may not be in the interaction, filter out:
  const responseIdsInInteraction = extractResponseIdsInInteractionForTemplate(
    doc,
    settings.templateVariables
  )
  // 19 JUNE 2024 - there was no response body when displaying the results
  if (!responseIdsInInteraction) {
    return []
  }
  return responseIdsInInteraction.map((responseId) =>
    responses.find((response) => response.id === responseId)
  )
}

export function isCustomInteraction(node) {
  return node.tagName === QTI_CUSTOM_INTERACTION
}

export function isNumberInputElement(node) {
  return node.tagName === QTI_TEXT_ENTRY_INTERACTION
}

export function isSplitNumberInteraction(node) {
  if (!isCustomInteraction(node)) {
    return false
  }
  return node.getAttribute('data-interaction-type') === 'split-number'
}

export function isJumpInteraction(node) {
  if (!isCustomInteraction(node)) {
    return false
  }
  return node.getAttribute('data-interaction-type') === 'jump'
}

export function isPronounceInput(node) {
  if (!isCustomInteraction(node)) {
    return false
  }
  return node.getAttribute('data-interaction-type') === 'pronounce'
}

export function isSelectPointInteraction(node) {
  return node.tagName === QTI_SELECT_POINT_INTERACTION
}

/**
 * @param {Element} element
 */
export const isOrderInteractionElement = (element) => {
  return element.tagName === QTI_ORDER_INTERACTION
}

export function isChoiceInputElement(node) {
  return [QTI_CHOICE_INTERACTION, QTI_INLINE_CHOICE_INTERACTION].includes(
    node.tagName
  )
}

export function getChoices(node) {
  const choiceTagName =
    node.tagName === QTI_INLINE_CHOICE_INTERACTION
      ? 'qti-inline-choice'
      : 'qti-simple-choice'
  const choiceNodes = Array.from(node.querySelectorAll(choiceTagName))
  return choiceNodes.map((choiceNode) => {
    return {
      id: choiceNode.getAttribute('identifier'),
      content: choiceNode.textContent
    }
  })
}

/**
 * @param {Document} doc
 * @param {ItemSettings} settings
 * @return {Array<Response>}
 */
function extractDeclaredResponses(doc, settings) {
  const responseDeclarations = Array.from(
    doc.querySelectorAll('qti-response-declaration')
  )
  if (responseDeclarations.length === 0) {
    console.warn(`no responses defined in interaction`)
    return []
  }
  const responses = []
  for (let responseDeclaration of responseDeclarations) {
    const id = responseDeclaration.getAttribute('identifier')
    // find matching interaction node:
    const interactionNode = doc.querySelector(`[response-identifier="${id}"]`)
    if (!interactionNode) {
      throw new Error(
        `no interaction node associated with response declaration ${id}`
      )
    }
    // const correctValue = parseCorrectValue(
    //   responseDeclaration.querySelector(QTI_CORRECT_RESPONSE)
    // )

    responses.push(
      createFromQti(responseDeclaration, interactionNode, settings)
    )
  }

  return responses
}

/**
 * @param {Element} responseDeclaration
 * @param {Element} interactionNode
 * @param {ItemSettings} settings
 */
function createFromQti(responseDeclaration, interactionNode, settings) {
  const id = responseDeclaration.getAttribute('identifier')
  const cardinality =
    responseDeclaration.getAttribute('cardinality') || 'single'
  const baseType = responseDeclaration.getAttribute('base-type')
  const correctResponseElement =
    responseDeclaration.querySelector(QTI_CORRECT_RESPONSE)
  const correctResponse = correctResponseElement
    ? CorrectResponse.createFromElement(correctResponseElement)
    : null
  const inputState = settings.readonly
    ? InputState.createPredefined()
    : InputState.createEditable()
  const value = settings.showAnswer ? correctResponse.correctValues : ''
  const areaMappingElement = responseDeclaration.querySelector(QTI_AREA_MAPPING)
  const areaMapping = areaMappingElement
    ? AreaMapping.createFromElement(areaMappingElement)
    : null

  // create the speech handler per interaction node and generate list
  // of supported input modes:
  const handleSpeechInput = createSpeechInputHandlerForInteractionElement(
    interactionNode,
    correctResponse
  )
  const supportedInputModes = getSupportedInputModes(interactionNode)

  return new Response(
    id,
    value,
    inputState,
    cardinality,
    baseType,
    correctResponse,
    areaMapping,
    handleSpeechInput,
    supportedInputModes
  )
}

function extractResponseIdsInInteractionForTemplate(doc, templateVariables) {
  const responseIdentifiers = []
  const qtiItemBody = doc.querySelector(QTI_ITEM_BODY)
  // 19 JUNE 2024 - to display the results, you won't find a body becasue there is none
  if (!qtiItemBody) {
    return
  }
  walkTemplateDomNodes(qtiItemBody, templateVariables, (node) => {
    if (
      node.nodeType !== Node.ELEMENT_NODE ||
      !node.hasAttribute('response-identifier')
    ) {
      return
    }
    responseIdentifiers.push(node.getAttribute('response-identifier'))
  })

  return responseIdentifiers
}

// execute callback for each node that is included considering
// the template variables:
function walkTemplateDomNodes(node, templateVariables, callback) {
  callback(node)
  node = firstChildForTemplate(node, templateVariables)
  while (node) {
    walkTemplateDomNodes(node, templateVariables, callback)
    node = nextSiblingForTemplate(node, templateVariables)
  }
}

/**
 *
 * @param {Node} node
 * @param {Object} templateVariables
 */
function firstChildForTemplate(node, templateVariables) {
  const children = Array.from(node.childNodes)
  return firstNodeForTemplate(children, templateVariables)
}

/**
 *
 * @param {Node} node
 * @param {Object} templateVariables
 */
function nextSiblingForTemplate(node, templateVariables) {
  const siblings = getSibilings(node)
  return firstNodeForTemplate(siblings, templateVariables)
}

/**
 *
 * @param {Node[]} nodes
 * @param {Object} templateVariables
 * @returns {?Node}
 */
function firstNodeForTemplate(nodes, templateVariables) {
  for (const node of nodes) {
    if (!isTemplateNode(node)) {
      return node
    }
    if (includeForTemplate(node, templateVariables)) {
      return node
    }
  }
  return null
}

/**
 * @param {Node} node
 */
function getSibilings(node) {
  const siblings = []
  let sibling = node.nextSibling
  if (sibling) {
    siblings.push(sibling)
  }
  while (sibling) {
    siblings.push(sibling)
    sibling = sibling.nextSibling
  }
  return siblings
}
