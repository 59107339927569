<template>
  <Dialog :buttons="[{ title: 'Ok', handler: () => $emit('close') }]">
    <template #content>
      {{ message }}
    </template>
  </Dialog>
</template>

<script>
import { Dialog } from 'component-library'

export default {
  components: { Dialog },
  props: {
    message: { type: String, required: true }
  }
}
</script>
