<template>
  <nav class="navbar">
    <div class="navbar__content">
      <slot />
      <div class="ml-auto flex items-center">
        <div class="navbar__buttons mr-3">
          <template v-if="userIsTeacher">
            <slot name="buttons" />
            <IconButton @click="navigateToDashboard">
              <IconDashboard /> Dashboard
            </IconButton>
            <FeedbackButton
              class="focus:outline-none text-2xl text-black bg-yellow-400 p-2 rounded"
            >
              <IconChat
                style="
                  height: 1em;
                  display: inline-block;
                  vertical-align: middle;
                  flex: none;
                "
              />&nbsp;Feedback
            </FeedbackButton>
          </template>
          <IconButton title="Volledig scherm" @click="toggleFullscreen">
            <IconFullscreenExit v-if="fullscreen" />
            <IconFullscreen v-else />
          </IconButton>
        </div>
        <DropDown class="ml-auto flex items-center">
          <template #trigger>
            <Avatar :name="user.name" />
          </template>
          <DropdownContent align="right">
            <div
              class="flex flex-col items-start bg-white border-2 border-gray rounded overflow-hidden p-4 rounded-lg shadow-sm"
            >
              <span class="mb-2">Hallo {{ displayName }}</span>
              <slot name="userMenu" />
              <span v-if="hijackedUserId !== null" class="mb-2 text-red">
                Ingelogd als {{ hijackedUserId }}
              </span>
              <div v-if="$config.debug" class="mb-2">
                <Button size="small" @click="produceError(404)">
                  Fout (404)
                </Button>
                <Button size="small" @click="produceError(403)">
                  Fout (403)
                </Button>
                <Button size="small" @click="produceError(500)">
                  Fout (500)
                </Button>
                <Button size="small" @click="produceError(null)">
                  Fout (lokaal)
                </Button>
              </div>
              <Button size="small" type="secondary" @click="onLogout">
                <IconLogout />
                Uitloggen
              </Button>
            </div>
          </DropdownContent>
        </DropDown>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  IconChat,
  IconDashboard,
  IconFullscreen,
  IconFullscreenExit,
  IconLogout
} from './icons'
import IconButton from './button/IconButton.vue'
import Avatar from './Avatar.vue'
import DropDown from './dropdown/DropDown.vue'
import DropdownContent from './dropdown/DropdownContent.vue'
import Button from './button/Button.vue'
import FeedbackButton from './FeedbackButton.vue'

export default {
  components: {
    IconChat,
    FeedbackButton,
    IconDashboard,
    IconFullscreen,
    IconFullscreenExit,
    IconButton,
    Avatar,
    DropDown,
    DropdownContent,
    IconLogout,
    Button
  },
  props: {
    user: { type: Object, default: null }
  },
  data() {
    return {
      fullscreen: false
    }
  },
  computed: {
    ...mapState({
      hijackedUserId: (state) => state.auth.hijackUserId
    }),
    ...mapGetters({
      userIsTeacher: 'auth/userIsTeacher'
    }),
    displayName() {
      return this.user.fullName
    }
  },
  mounted() {
    this.calcFullscreen()
  },
  methods: {
    onLogout() {
      this.$emit('logout')
    },
    navigateToDashboard() {
      const { dashboardUrl } = this.$config
      window.location = dashboardUrl
    },
    calcFullscreen() {
      this.fullscreen = document.fullscreenElement !== null
    },
    async toggleFullscreen() {
      try {
        if (this.fullscreen) {
          await document.exitFullscreen()
        } else {
          await document.documentElement.requestFullscreen()
        }
        this.calcFullscreen()
      } catch (e) {
        // Ignore
      }
    },
    produceError(statusCode) {
      if (statusCode === 500) {
        this.$axios.$get('/error')
      } else if (statusCode === 404) {
        this.$router.push('/error/404')
      } else if (statusCode === 403) {
        this.$axios.$get('/users/abc/overview')
      } else {
        // Local
        throw new Error(`Test lokale fout`)
      }
    }
  }
}
</script>

<style lang="scss">
.navbar {
  @apply w-full fixed border-b-2 border-gray bg-white z-20;
  height: 4.125rem;
}
.navbar__content {
  @apply h-full mx-auto px-4;
  @apply flex flex-row items-center;
}

.navbar__buttons {
  @apply flex items-center gap-2;
  button {
    @apply font-normal text-sm uppercase mr-3 text-gray-dark;
    svg {
      @apply text-2xl mb-1;
    }
  }

  button:hover {
    @apply text-gray-darkest;
  }
}
</style>
