<template>
  <input
    ref="range"
    type="range"
    class="range-slider"
    :min="min"
    :max="max"
    :step="step"
    :value="value"
    @input="onInput"
  />
</template>

<script>
export default {
  props: {
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    step: { type: Number, default: 1 },
    value: { type: Number, default: null }
  },
  methods: {
    onInput() {
      const value = parseFloat(this.$refs.range.value)
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.range-slider {
  @apply rounded-full appearance-none bg-blue outline-none;
  height: 8px;
  &::-webkit-slider-thumb {
    @apply bg-white rounded-full appearance-none shadow;
    @apply w-8 h-8;
    border: 4px solid #00b8ec;
    cursor: ew-resize;
  }

  &:disabled {
    @apply bg-gray;
    &::-webkit-slider-thumb {
      @apply border-gray shadow-none;
    }
  }
}
</style>
