<template>
  <IconBase fill="none" stroke="currentColor">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M 8.5 15.3 h 7 M 9 10 h 0.01 M 15 10 h 0.01 M 21 12 a 9 9 0 1 1 -18 0 a 9 9 0 0 1 18 0 z"
    ></path>
  </IconBase>
</template>

<script>
import IconBase from './IconBase.vue'
export default {
  components: { IconBase }
}
</script>
