<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style="width: 2.5em; height: auto"
    viewBox="0 0 48.189 48.189"
  >
    <path
      style="fill: #ffffff; stroke: currentColor"
      d="M12.5,0.5c-6.627,0-12,5.373-12,12v23.189
	c0,6.627,5.373,12,12,12h23.189c6.627,0,12-5.373,12-12V12.5c0-6.627-5.373-12-12-12H12.5z"
    />
    <circle
      v-if="showMidRight"
      id="mr"
      class="fill-current"
      cx="35.433"
      cy="24.094"
      r="4.252"
    />
    <path
      v-if="showTopLeft"
      id="tl"
      class="fill-current"
      d="M12.756,6.803c-2.345,0-4.252,1.907-4.252,4.252s1.907,4.252,4.252,4.252s4.252-1.908,4.252-4.252
	S15.101,6.803,12.756,6.803z"
    />
    <path
      v-if="showTopRight"
      id="tr"
      class="fill-current"
      d="M35.433,6.803c-2.345,0-4.252,1.907-4.252,4.252s1.907,4.252,4.252,4.252s4.252-1.908,4.252-4.252
	S37.778,6.803,35.433,6.803z"
    />
    <circle
      v-if="showBottomLeft"
      id="bl"
      class="fill-current"
      cx="12.756"
      cy="37.133"
      r="4.252"
    />
    <circle
      v-if="showBottomRight"
      id="br"
      class="fill-current"
      cx="35.433"
      cy="37.133"
      r="4.252"
    />
    <circle
      v-if="showMidLeft"
      id="ml"
      class="fill-current"
      cx="12.756"
      cy="24.094"
      r="4.252"
    />
    <path
      v-if="showMidMid"
      id="mm"
      class="fill-current"
      d="M24.094,19.8442c-2.345,0-4.252,1.907-4.252,4.252s1.907,4.252,4.252,4.252s4.252-1.907,4.252-4.252
			S26.439,19.842,24.094,19.842z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: function (value) {
        if (value < 1) {
          return false
        }
        return value <= 6
      }
    }
  },
  computed: {
    showTopLeft() {
      return this.count !== 1
    },
    showTopRight() {
      return [4, 5, 6].includes(this.count)
    },
    showMidLeft() {
      return this.count === 6
    },
    showMidMid() {
      return [1, 3, 5].includes(this.count)
    },
    showMidRight() {
      return this.count === 6
    },
    showBottomLeft() {
      return [4, 5, 6].includes(this.count)
    },
    showBottomRight() {
      return this.count !== 1
    }
  }
}
</script>
