<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-102 -102 204 204"
    style="width: 4em; height: auto"
  >
    <circle
      v-if="castPartCount === 1"
      r="1"
      :fill="castColoredPartsCount >= 1 ? partColor : '#ffffff'"
      stroke="#3c3c3c"
      stroke-width="2"
    />
    <g v-else style="transform: rotate(-90deg)">
      <path
        v-for="partNumber in castPartCount"
        :key="partNumber"
        :d="partPaths[partNumber]"
        :fill="partNumber <= castColoredPartsCount ? partColor : '#ffffff'"
        stroke="#3c3c3c"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PieChart',
  props: {
    partCount: { type: [String, Number], required: true },
    coloredPartsCount: { type: [String, Number], default: 0 },
    partColor: { type: String, default: '#cccccc' }
  },
  computed: {
    partPaths() {
      const partPaths = {}
      let partNumber = 1
      let cumulative = 0
      const total = 100
      const value = 100 / this.castPartCount
      while (partNumber <= this.castPartCount) {
        const startX = Math.round(
          Math.cos(2 * (cumulative / total) * Math.PI) * 100
        )
        const startY = Math.round(
          Math.sin(2 * (cumulative / total) * Math.PI) * 100
        )
        cumulative += value
        const endX = Math.round(
          Math.cos(2 * (cumulative / total) * Math.PI) * 100
        )
        const endY = Math.round(
          Math.sin(2 * (cumulative / total) * Math.PI) * 100
        )
        const largeArcFlag = value >= total / 2 ? 1 : 0
        partPaths[partNumber] = [
          'M 0 0',
          `L ${startX} ${startY}`,
          `A 100 100 0 ${largeArcFlag} 1 ${endX} ${endY}`,
          'Z'
        ].join(' ')
        partNumber++
      }
      return partPaths
    },
    castPartCount() {
      return typeof this.partCount === 'string'
        ? parseInt(this.partCount)
        : this.partCount
    },
    castColoredPartsCount() {
      return typeof this.coloredPartsCount === 'string'
        ? parseInt(this.coloredPartsCount)
        : this.coloredPartsCount
    }
  }
}
</script>
