<template>
  <Container class="bg-gray-lighter" style="min-width: 640px">
    <div
      v-if="bricks"
      class="flex flex-col items-center justify-center"
      style="min-width: 640px; width: 100%; max-width: 1500px"
    >
      <div
        :style="{ 'min-height': showImage ? '17vh' : '0' }"
        class="flex items-center relative w-full justify-center pt-2"
      >
        <slot />
        <div class="absolute left-0 bottom-0 py-3 px-4 flex flex-row gap-2">
          <Button
            :disabled="!previousEnabled"
            size="tiny"
            type="secondary"
            @click="previous"
          >
            <IconLeft />
            Ouder
          </Button>
          <Button
            :disabled="!nextEnabled"
            size="tiny"
            type="secondary"
            @click="next"
          >
            <IconRight />
            Nieuwer
          </Button>
        </div>
        <div class="flex flex-col items-center pb-2">
          <div class="text-4xl">Je Rekenmuur</div>
          <div class="text-gray-dark">{{ humanReferenceDate }}</div>
        </div>
        <img
          v-if="showImage"
          src="../../assets/images/vrouw1.svg"
          alt="Bareka"
          class="absolute"
          style="
            bottom: -2.5vmin;
            margin-left: 30vw;
            height: 14vmin;
            transform: scaleX(-1);
          "
        />
        <div
          class="absolute right-0 bottom-0 px-4 pb-2 flex flex-row gap-2 items-center"
          style="font-size: 0.875rem"
        >
          <div
            class="bg-score-green highlight-pulse rounded-4xl"
            style="width: 1rem; height: 1rem"
          ></div>
          = Laatst bijgewerkt
        </div>
      </div>
      <div class="relative px-3 w-full" style="height: 70vh; max-height: 700px">
        <Wall
          :custom-bricks="bricks"
          class="h-full w-full"
          :reference-date="referenceDate"
        >
          <template
            v-if="interactive"
            #brick="{ brick, getContentClass, getBackgroundClass }"
          >
            <BrickWithPractice
              :brick="brick"
              :get-content-class="getContentClass"
              :get-background-class="getBackgroundClass"
              :practice-messages="practiceMessages"
              @start-practice="$emit('start-practice', brick.id)"
            />
          </template>
        </Wall>
      </div>
    </div>
    <Loader v-else />
  </Container>
</template>

<script>
import Wall from './Wall.vue'
import Container from '../layout/Container.vue'
import Button from '../button/Button.vue'
import { IconLeft, IconRight } from '../icons'
import Loader from '../Loader.vue'
import BrickWithPractice from './practice/BrickWithPractice.vue'

import { humanDate } from 'shared'

export default {
  components: {
    BrickWithPractice,
    IconLeft,
    IconRight,
    Button,
    Wall,
    Container,
    Loader
  },
  props: {
    testResults: { type: Boolean, default: true },
    practiceResults: { type: Boolean, default: true },
    showImage: { type: Boolean, default: true },
    interactive: { type: Boolean, default: true },
    practiceMessages: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      referenceDate: null,
      bricks: null,
      dates: null,
      abortController: null,
      signal: null
    }
  },
  computed: {
    slots() {
      return this.$slots
    },
    showNavigation() {
      return this.dates && this.dates.length > 1
    },
    previousEnabled() {
      return this.dates && this.dates.indexOf(this.referenceDate) > 0
    },
    nextEnabled() {
      return (
        this.dates &&
        this.dates.indexOf(this.referenceDate) < this.dates.length - 1
      )
    },
    humanReferenceDate() {
      return humanDate(this.referenceDate)
    }
  },
  async mounted() {
    this.controller = new AbortController()
    this.signal = this.controller.signal
    await this.loadDates()
    await this.loadWall()
  },
  methods: {
    async loadDates() {
      const data = (
        await this.get(
          `/bricks/me/dates?practice_results=${
            this.practiceResults ? '1' : '0'
          }&test_results=${this.testResults ? '1' : '0'}`
        )
      )?.data
      if (data) {
        this.dates = data.dates
        this.referenceDate =
          this.dates.length > 0 ? this.dates[this.dates.length - 1] : null
      }
    },
    async loadWall() {
      const data = (
        await this.get(
          `/bricks/me?practice_results=${
            this.practiceResults ? '1' : '0'
          }&test_results=${this.testResults ? '1' : '0'}&ref_date=${
            this.referenceDate || ''
          }`
        )
      )?.data
      if (data) {
        this.bricks = data.bricks
      }
    },
    async previous() {
      const index = this.dates.indexOf(this.referenceDate)
      this.referenceDate = this.dates[index - 1]
      await this.loadWall()
    },
    async next() {
      const index = this.dates.indexOf(this.referenceDate)
      this.referenceDate = this.dates[index + 1]
      await this.loadWall()
    },
    async get(url) {
      try {
        return await this.$axios.get(url, { signal: this.signal })
      } catch (err) {
        if (err.name === 'CanceledError' && err.code === 'ERR_CANCELED') {
          // if cancelled by abort, do nothing
        } else {
          console.error('Error loading WallForStudent: ', err)
        }
      }
    },
    abort() {
      this.controller.abort()
    }
  }
}
</script>
