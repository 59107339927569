<template>
  <img :src="src" alt="" class="w-full" />
</template>

<script>
import QtiBaseMixin from '../../QtiBaseMixin'

export default {
  mixins: [QtiBaseMixin],
  props: {
    label: { type: String, default: 'Toon hulp' },
    mimeType: { type: String, default: '' }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    src() {
      return this.assetHost + '/' + this.$slots['default'][0].text
    }
  }
}
</script>
