<template>
  <ChoiceOption
    :value="identifier"
    :selected="isSelected()"
    @select="(value) => $emit('select', value)"
  >
    <slot />
  </ChoiceOption>
</template>

<script>
import ChoiceOption from '../../input/ChoiceOption'
import QtiBaseMixin from '../../QtiBaseMixin'
export default {
  components: { ChoiceOption },
  mixins: [QtiBaseMixin],
  props: {
    identifier: { type: String, required: true }
  },
  computed: {
    classes() {
      return {
        'choice-input__choice--selected': this.isSelected(this.identifier)
      }
    },
    responseIdentifier() {
      return this.findInAncestors('responseIdentifier')
    },
    response() {
      return this.responseSet.responseById(this.responseIdentifier)
    }
  },
  methods: {
    isSelected() {
      return this.response.value === this.identifier
    },
    findInAncestors(property, component = this.$parent) {
      if (component) {
        return component[property]
          ? component[property]
          : component.$parent
          ? this.findInAncestors(property, component.$parent)
          : null
      }
      return null
    }
  }
}
</script>
