<template>
  <div v-click-outside="close" class="relative inline-block">
    <div @click="toggle">
      <slot name="trigger">
        <Button>Toggle</Button>
      </slot>
    </div>
    <slot />
  </div>
</template>

<script>
import clickOutside from '../mixins/click-outside'
import Button from '../button/Button.vue'

export default {
  components: { Button },
  mixins: [clickOutside],
  provide() {
    return {
      dropdownState: this.dropdownState
    }
  },
  props: {
    initiallyOpen: { type: Boolean, default: false }
  },
  data() {
    return {
      dropdownState: {
        open: this.initiallyOpen
      }
    }
  },
  watch: {
    initiallyOpen(open) {
      if (open === false && this.dropdownState.open === true) {
        this.close()
        return
      }
      if (open === true && this.dropdownState.open === false) {
        this.open()
      }
    }
  },
  methods: {
    toggle() {
      if (this.dropdownState.open) {
        this.close()
        return
      }
      this.open()
    },
    close() {
      this.dropdownState.open = false
      this.$emit('closed')
    },
    open() {
      this.dropdownState.open = true
      this.$emit('opened')
    }
  }
}
</script>
