export const state = () => ({
  settings: {}
})

export const getters = {
  getSetting: (state) => (key) => {
    if (state.settings[key] === undefined) {
      return null
    }
    return state.settings[key]
  },
  getAutoplayAudio: (state, getters) => getters.getSetting('autoplayAudio')
}

export const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings
  }
}

export const actions = {
  async load({ commit }) {
    const { settings } = await this.$axios.$get('/settings')
    commit('SET_SETTINGS', settings)
  },
  async save({ commit, state }, settings) {
    await this.$axios.$put('/settings', { settings: settings })
    commit('SET_SETTINGS', { ...state.settings, ...settings })
  }
}
